import * as Types from '../../types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type ActiveStudySessionQueryVariables = Types.Exact<{
  enrollmentId: Types.Scalars['ID']['input'];
}>;

export type ActiveStudySessionQuery = {
  __typename?: 'Query';
  activeStudySession?: {
    __typename?: 'PersonalDeckStudySession';
    id: string;
    totalQuestions: number;
    numCompleted: number;
  } | null;
};

export type StudySessionByIdQueryVariables = Types.Exact<{
  personalDeckStudySessionId: Types.Scalars['ID']['input'];
}>;

export type StudySessionByIdQuery = {
  __typename?: 'Query';
  studySessionById: {
    __typename?: 'PersonalDeckStudySession';
    id: string;
    totalQuestions: number;
    numCompleted: number;
    totalCorrect: number;
    finishedAt?: any | null;
  };
};

export const ActiveStudySessionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'activeStudySession' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'enrollmentId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activeStudySession' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'enrollmentId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'enrollmentId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'totalQuestions' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'numCompleted' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ActiveStudySessionQuery,
  ActiveStudySessionQueryVariables
>;
export const StudySessionByIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'studySessionById' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'personalDeckStudySessionId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'studySessionById' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'personalDeckStudySessionId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'personalDeckStudySessionId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'totalQuestions' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'numCompleted' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'totalCorrect' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'finishedAt' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  StudySessionByIdQuery,
  StudySessionByIdQueryVariables
>;
