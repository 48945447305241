import { useQuery } from '@apollo/client';
import type { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { useContext, useMemo, useState } from 'react';
import { AppContext } from '../../../../AppContext';
import {
  bubbleGreen,
  bubbleRed,
  bubbleYellow,
} from '../../../../assets/shared-styles/Bubble';
import { StudentStandardMasteriesDocument } from '../../../../gql/queries/__generated__/masteries.generated';
import { StandardsChartDocument } from '../../../../gql/queries/__generated__/standardsChart.generated';
import { CustomDataTable } from '../../../shared/CustomTable/CustomDataTable';
import { StandardDetails } from './StandardDetails';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    flexFlow: 'column',
  },
  tableContainer: {
    padding: theme.spacing(5),
    position: 'relative',
    height: '100%',
  },
  bubbleGreen: {
    ...bubbleGreen(),
  },
  bubbleYellow: {
    ...bubbleYellow(),
  },
  bubbleRed: {
    ...bubbleRed(),
  },
  empty: {},
  clickableButton: {
    '&:hover': {
      cursor: 'pointer',
      opacity: 0.8,
    },
  },
}));

type RowStandard = {
  title: string;
  id: string;
  description: string;
};

export function StandardsProgress() {
  const classes = useStyles();
  const {
    appState: { activeEnrollment },
  } = useContext(AppContext);
  const [openStandardId, setOpenStandardId] = useState('');
  const [openStandardTitle, setOpenStandardTitle] = useState('');
  const standardsChartId = activeEnrollment?.group?.course.standardsChartId;
  const { data: standardsChartData } = useQuery(StandardsChartDocument, {
    skip: !standardsChartId,
    variables: {
      standardsChartId: standardsChartId || '',
    },
  });

  const standardIds =
    standardsChartData?.standardsChart?.standardsCategories?.flatMap((elem) =>
      elem.standards.map((standard) => standard.id)
    ) || [];
  // console.log(activeEnrollment, standardsChartData, 'blah');
  const { data: studentData, loading: studentDataLoading } = useQuery(
    StudentStandardMasteriesDocument,
    {
      skip: !activeEnrollment || !standardsChartData,
      variables: {
        studentIds: [activeEnrollment?.student.id || ''],
        groupIds: [activeEnrollment?.groupId || ''],
        standardIds: standardIds,
      },
    }
  );

  const tableData = useMemo(() => {
    const enrollmentId = activeEnrollment?.id || '';
    if (!studentData?.studentStandardMasteries[0] || !enrollmentId) {
      return [];
    }
    const results: {
      [key: string]: {
        standardsCategory: string;
        standard: RowStandard;
        mastery: number;
      };
    } = {};
    const tempObj: { [key: string]: number } = {};
    studentData.studentStandardMasteries[0].data.forEach((elem) => {
      tempObj[elem.standardId] = elem.average;
    });
    standardsChartData?.standardsChart.standardsCategories?.forEach(
      (category) => {
        category.standards.forEach((standard) => {
          results[standard.id] = {
            standardsCategory: category.category,
            standard: {
              title: standard.title,
              description: standard.description,
              id: standard.id,
            },
            mastery: tempObj[standard.id],
          };
        });
      }
    );

    return Object.values(results).filter((row) => {
      return row['mastery'] !== undefined;
    });
  }, [
    activeEnrollment?.id,
    standardsChartData?.standardsChart.standardsCategories,
    studentData,
  ]);
  const handleDetailsOpen = (standardId: string, standardTitle: string) => {
    return () => {
      setOpenStandardId(standardId);
      setOpenStandardTitle(standardTitle);
    };
  };
  const closeDetails = () => setOpenStandardId('');

  return (
    <div className={classes.root}>
      <div className={classes.tableContainer}>
        <CustomDataTable
          loading={studentDataLoading}
          title="STANDARDS MASTERY"
          data={tableData}
          options={{
            search: false,
            sortOrder: {
              name: 'mastery',
              direction: 'asc',
            },
          }}
          columns={[
            {
              name: 'standardsCategory',
              label: 'STANDARD CATEGORY',
            },
            {
              name: 'standard',
              label: 'STANDARD',
              options: {
                customBodyRender: (value: RowStandard) => {
                  return (
                    <div>
                      <strong>{value.title} - </strong>
                      <span>{value.description}</span>
                    </div>
                  );
                },
              },
            },
            {
              name: 'mastery',
              label: 'MY MASTERY',
              options: {
                sort: true,
                hint: 'average of streaks on questions under this standard',
                customBodyRender: (value, tableMeta) => {
                  let style;
                  if (value > 1.0) {
                    style = classes.bubbleGreen;
                  } else if (value > 0) {
                    style = classes.bubbleYellow;
                  } else if (!isNaN(value)) {
                    style = classes.bubbleRed;
                  } else {
                    return <div></div>;
                  }
                  const standard: RowStandard = tableMeta.rowData[1];
                  return (
                    <div>
                      <button
                        onClick={handleDetailsOpen(standard.id, standard.title)}
                        className={clsx(style, classes.clickableButton)}
                      >
                        {value}
                      </button>
                    </div>
                  );
                },
              },
            },
          ]}
        />
      </div>
      <StandardDetails
        enrollmentId={activeEnrollment?.id || ''}
        standardId={openStandardId}
        standardTitle={
          openStandardTitle.substring(0, 40) +
          `${openStandardTitle.length > 40 ? '...' : ''}`
        }
        handleClose={closeDetails}
      />
    </div>
  );
}
