import { useMutation, useQuery } from '@apollo/client';
import { MoreHoriz } from '@mui/icons-material';
import {
  Button,
  Card,
  CardActionArea,
  CardContent,
  Divider,
  Theme,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import { UpdateEnrollmentDocument } from '../../../gql/mutations/__generated__/enrollment.generated';
import { ClaimFreeSessionDocument } from '../../../gql/mutations/__generated__/personalDeckStudySession.generated';
import {
  NumPersonalQuestionsDueDocument,
  NumSessionsCompletedTodayDocument,
} from '../../../gql/queries/__generated__/enrollment.generated';
import { ActiveStudySessionDocument } from '../../../gql/queries/__generated__/personalDeckStudySession.generated';
import { StudentDocument } from '../../../gql/queries/__generated__/student.generated';
import { StudentsAssignmentsDatumDocument } from '../../../gql/queries/__generated__/studentsAssignmentsDatum.generated';
import { AssignmentStatusEnum } from '../../../gql/types';
import { onError } from '../../../utils/apollo/apolloHelper';
import { groupsAssignmentPastDue } from '../../../utils/graphql-object-helpers/studentsAssignmentsDatum';
import { DatumType } from '../../../views/Assignments';
import { StandardButton } from '../../shared/Buttons/StandardButton';
import { LoadingSkeletons } from '../../shared/Loaders/LoadingSkeletons';
import { AlertsContext } from '../Alerts/context';
import { openDialog } from '../Alerts/context/actions';
import { HelpKitPersonalDeck } from '../HelpKit/HelpKitArticles/HelpKitPersonalDeck';
import { DistributedAssignmentCard } from './DistributedAssignmentCard';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginBottom: theme.spacing(2),
    border: `1px solid ${theme.palette.secondary.main}`,
    backgroundColor: theme.palette.accent.light,
    // backgroundImage: `linear-gradient(to top, ${theme.palette.common.white}, ${theme.palette.grays.light})`,
  },
  title: {
    marginBottom: theme.spacing(0.5),
  },
  xpDetails: {
    marginBottom: theme.spacing(1),
    width: 'fit-content',
    backgroundColor: theme.palette.warning.main,
    padding: theme.spacing(2),
    borderRadius: 4,
    fontWeight: 'bold', // Add bold font weight to make it more prominent
    '& a': {
      // Add styling to the link inside the title
      color: theme.palette.primary.main, // Use primary color for the link
      '&:hover': {
        textDecoration: 'underline', // Add underline on hover
      },
    },
    display: 'flex',
    alignItems: 'center',
  },
  detailsButton: {
    marginLeft: theme.spacing(1.5),
  },
  assignmentCardActionArea: {
    padding: theme.spacing(2),
  },
  frozenDeckArea: {
    paddingBottom: theme.spacing(2),
  },
  frozenDeckCard: {
    backgroundImage: 'linear-gradient(to top, #FFE9E7, #FFE9E7)',
  },
  bold: {
    fontWeight: theme.typography.fontWeightBold,
  },
  sessionContainer: {
    display: 'flex',
  },
  sessionStats: {
    marginLeft: theme.spacing(0.5),
    fontWeight: theme.typography.fontWeightBold,
  },
  button: {
    paddingTop: theme.spacing(0.5),
  },
  divider: {
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1),
  },
  helpKitContainer: {
    marginBottom: theme.spacing(1),
  },
}));

type PersonalDeckAssignmentCardProps = {
  activeDistributedAssignment?: DatumType;
  lateDistributedAssignment?: DatumType;
};

export function PersonalDeckAssignmentCard({
  activeDistributedAssignment,
  lateDistributedAssignment,
}: PersonalDeckAssignmentCardProps) {
  const classes = useStyles();
  const navigate = useNavigate();
  const { dispatch } = useContext(AlertsContext);
  const { data, loading } = useQuery(StudentDocument, {
    fetchPolicy: 'cache-and-network',
  });

  const [updateEnrollment] = useMutation(UpdateEnrollmentDocument, {
    onError: onError(dispatch),
  });
  const {
    appState: { activeEnrollment },
  } = useContext(AppContext);

  const selectedEnrollment = data?.student?.enrollments?.find(
    (enrollment) => enrollment.id === activeEnrollment?.id
  );

  const { data: personalDeckData, loading: personalDeckLoading } = useQuery(
    NumPersonalQuestionsDueDocument,
    {
      skip: !selectedEnrollment,
      pollInterval: 300000, // 5 minutes
      variables: {
        enrollmentId: selectedEnrollment?.id || '',
      },
      fetchPolicy: 'cache-and-network',
    }
  );

  const groupsAssignmentId =
    activeDistributedAssignment?.groupsAssignment?.id || '';
  const { data: distributedAssignmentDatum, refetch: refetchDatum } = useQuery(
    StudentsAssignmentsDatumDocument,
    {
      skip: !activeDistributedAssignment,
      variables: {
        groupsAssignmentId,
        studentId: data?.student.id,
      },
    }
  );

  const lateGroupsAssignmentId =
    lateDistributedAssignment?.groupsAssignment?.id || '';

  const { data: lateDistributedAssignmentDatum, refetch: refetchLateDatum } =
    useQuery(StudentsAssignmentsDatumDocument, {
      skip: !lateDistributedAssignment,
      variables: {
        groupsAssignmentId: lateGroupsAssignmentId,
        studentId: data?.student.id,
      },
    });

  const { data: sessionsData, refetch: refetchSessionsData } = useQuery(
    NumSessionsCompletedTodayDocument,
    {
      skip: !selectedEnrollment,
      variables: {
        enrollmentId: selectedEnrollment?.id || '',
      },
      fetchPolicy: 'cache-and-network',
    }
  );

  const [claimSession] = useMutation(ClaimFreeSessionDocument, {
    variables: {
      enrollmentId: selectedEnrollment?.id || '',
      groupsAssignmentId,
    },
    onError: onError(dispatch),
    onCompleted: () => {
      dispatch(
        openDialog({
          title: 'Nice job!',
          message:
            "You were able to claim a free session just now because you've been keeping up with your personal deck and had no questions due today. Keep up the great work!",
          error: false,
        })
      );
      refetchDatum();
      refetchLateDatum();
      refetchSessionsData();
    },
  });

  const { data: activeStudySession } = useQuery(ActiveStudySessionDocument, {
    variables: {
      enrollmentId: selectedEnrollment?.id || '',
    },
    fetchPolicy: 'cache-and-network',
  });

  const handleClaimFreeSession = () => {
    claimSession();
  };

  const isLoading = loading || personalDeckLoading;
  const numDue = personalDeckData?.enrollment.numDuePersonalDecksQuestions;
  let numDueText;
  const frozen =
    activeEnrollment?.group?.freezePersonalDeck &&
    !activeEnrollment.unfreezePersonalDeck;
  if (activeEnrollment?.group?.freezePersonalDeck) {
    if (activeEnrollment.unfreezePersonalDeck) {
      numDueText = `${numDue} personal deck question(s) due `;
    } else {
      numDueText = 'No personal deck questions are due at this time!';
    }
  } else if (numDue) {
    numDueText = `${numDue} personal deck question(s) due `;
  } else {
    numDueText = 'No personal deck questions are due at this time!';
  }

  let frozenDeckText;
  if (!activeEnrollment?.unfreezePersonalDeck) {
    frozenDeckText = (
      <p>
        Your personal deck is <b>disabled</b> because your teacher froze it. If
        you want to temporarily enable your personal deck, click the unfreeze
        button below. Please note that after you unfreeze it, it will
        automatically refreeze after a period of inactivity.
      </p>
    );
  } else {
    frozenDeckText = (
      <p>
        Your teacher froze your personal deck, but it&apos;s currently{' '}
        <b>enabled</b> because you manually unfroze it. If you want to disable
        your personal deck, click the freeze button below. Otherwise, your
        personal deck will automatically refreeze after a period of inactivity.
      </p>
    );
  }

  const handleUnfreezeChange = () => {
    if (!activeEnrollment?.id) {
      return;
    }

    updateEnrollment({
      variables: {
        enrollmentId: activeEnrollment.id,
        unfreezePersonalDeck: !activeEnrollment?.unfreezePersonalDeck,
      },
    });
  };

  const frozenDeckContent = activeEnrollment?.group?.freezePersonalDeck ? (
    <div className={classes.frozenDeckArea}>
      <Card className={classes.frozenDeckCard}>
        <CardContent>
          <Typography variant="body1">{frozenDeckText}</Typography>
          <div className={classes.button}>
            <Button
              color="primary"
              variant="contained"
              onClick={handleUnfreezeChange}
            >
              {activeEnrollment?.unfreezePersonalDeck ? 'Freeze' : 'Unfreeze'}
            </Button>
          </div>
        </CardContent>
      </Card>
    </div>
  ) : null;

  const studyPersonalDeckEnabled =
    !isLoading &&
    (!activeEnrollment?.group?.freezePersonalDeck ||
      (activeEnrollment?.group.freezePersonalDeck &&
        activeEnrollment?.unfreezePersonalDeck));

  const studySession = activeStudySession?.activeStudySession;

  const activePersonalDeckAssignment =
    distributedAssignmentDatum &&
    distributedAssignmentDatum.studentsAssignmentsDatum?.groupsAssignment
      ?.assignment.assignmentStatus !== AssignmentStatusEnum.PastDue;

  const allowFreeClaimSession =
    (activePersonalDeckAssignment || lateDistributedAssignment) &&
    !sessionsData?.enrollment.numStudySessionsCompletedToday &&
    numDue === 0 &&
    !frozen;

  return (
    <div>
      {frozenDeckContent}
      <Card className={classes.root}>
        {isLoading ? (
          <LoadingSkeletons num={5} />
        ) : (
          <CardContent>
            <Typography variant="h3" color="primary" className={classes.title}>
              Personal Deck Practice
            </Typography>
            <div className={classes.xpDetails}>
              <Typography variant="h4" color="primary">
                XP (Experience): {personalDeckData?.enrollment.points || 0}
              </Typography>
              <Link to="/progress/xp-details">
                <Button
                  className={classes.detailsButton}
                  variant="contained"
                  color="primary"
                  startIcon={<MoreHoriz />}
                >
                  Details
                </Button>
              </Link>
            </div>
            <div className={classes.helpKitContainer}>
              <HelpKitPersonalDeck />
            </div>
            <Typography variant="body1" className={classes.bold}>
              {numDueText}
            </Typography>
            {studySession?.id && (
              <div className={classes.sessionContainer}>
                <Typography variant="body1" className={classes.bold}>
                  Study Session In Progress:
                </Typography>
                <Typography
                  variant="body1"
                  color="primary"
                  className={classes.sessionStats}
                >{`${studySession.numCompleted} / ${studySession.totalQuestions} completed`}</Typography>
              </div>
            )}
            {allowFreeClaimSession ? (
              <CardActionArea className={classes.assignmentCardActionArea}>
                <StandardButton
                  text="Claim Free Session"
                  fullWidth
                  onClick={handleClaimFreeSession}
                />
              </CardActionArea>
            ) : null}

            {numDue ||
            activeStudySession?.activeStudySession?.totalQuestions ? (
              <CardActionArea className={classes.assignmentCardActionArea}>
                <StandardButton
                  disabled={!studyPersonalDeckEnabled}
                  text={
                    studySession
                      ? 'Resume Study Session'
                      : 'Start Study Session'
                  }
                  fullWidth
                  onClick={() => {
                    navigate(`/personal-deck/study`);
                  }}
                />
              </CardActionArea>
            ) : null}
            {(distributedAssignmentDatum?.studentsAssignmentsDatum ||
              lateDistributedAssignmentDatum?.studentsAssignmentsDatum) && (
              <div>
                <Divider className={classes.divider} />
                <Typography>
                  Completing Personal Deck Study Sessions currently earns points
                  towards the following assignment(s):
                </Typography>
              </div>
            )}
            {distributedAssignmentDatum?.studentsAssignmentsDatum && (
              <DistributedAssignmentCard
                late={
                  activeDistributedAssignment
                    ? groupsAssignmentPastDue(activeDistributedAssignment)
                    : false
                }
                activeDistributedAssignment={
                  distributedAssignmentDatum?.studentsAssignmentsDatum
                }
              />
            )}
            {lateDistributedAssignmentDatum?.studentsAssignmentsDatum && (
              <DistributedAssignmentCard
                late={
                  lateDistributedAssignment
                    ? groupsAssignmentPastDue(lateDistributedAssignment)
                    : false
                }
                activeDistributedAssignment={
                  lateDistributedAssignmentDatum?.studentsAssignmentsDatum
                }
              />
            )}
          </CardContent>
        )}
      </Card>
    </div>
  );
}
