import type { Theme } from '@mui/material';
import { Box, useTheme } from '@mui/material';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';

const AssignmentStatusColors = {
  'Not Started': 'info',
  'In Progress': 'accent',
  'Past Due': 'error',
  Completed: 'success',
} as const satisfies Record<string, keyof Theme['palette']>;

export type DisplayStatus = keyof typeof AssignmentStatusColors;
type AssignmentCardHeaderProps = {
  assignmentStatus?: DisplayStatus;
  assignmentName: string;
};

export function AssignmentCardHeader(props: AssignmentCardHeaderProps) {
  const theme = useTheme();
  const themeKey = props.assignmentStatus
    ? AssignmentStatusColors[props.assignmentStatus]
    : null;
  const themeColor = themeKey ? theme.palette[themeKey] : null;
  return (
    <Box
      sx={{
        padding: theme.spacing(1),
        paddingBottom: theme.spacing(2),
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Typography variant={'h3'} sx={{ fontWeight: 500 }}>
        {props.assignmentName}
      </Typography>
      {themeColor ? (
        <Chip
          label={props.assignmentStatus}
          variant="filled"
          sx={{
            height: theme.spacing(4),
            color: themeColor['900'],
            background: themeColor['300'],
            borderColor: themeColor['500'],
            borderWidth: '1px',
            borderStyle: 'solid',
          }}
        />
      ) : null}
    </Box>
  );
}
