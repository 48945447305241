import {
  Card,
  CardContent,
  Skeleton,
  Typography,
  type Theme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { AssignmentTypeEnum } from '../../../gql/types';
import {
  filterActiveAssignments,
  getDueDate,
  getStatus,
  getUnderlyingAssignment,
} from '../../../utils/assignments';
import { DatumType } from '../../../views/Assignments';
import { DistributedAssignmentCard } from './DistributedAssignmentCard';
import { StandardAssignmentCard } from './Home/StandardAssignmentCard';
import { PersonalDeckAssignmentCard } from './PersonalDeckAssignmentCard';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: `${theme.spacing(3.5)} auto 0 auto`,
    borderRadius: 6,
    maxWidth: 1200,
    padding: theme.spacing(1),
  },
  cardContainers: {
    marginTop: theme.spacing(6),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  title: {
    marginBottom: theme.spacing(1),
  },
  distributedAssignmentContainer: {
    marginBottom: theme.spacing(2),
  },
}));

type CurrentAssignmentsProps = {
  activeDistributedAssignment?: DatumType;
  assignments: DatumType[];
  lateDistributedAssignment?: DatumType;
  loading: boolean;
  optionalAssignments?: boolean;
};

export function CurrentAssignments({
  assignments,
  activeDistributedAssignment,
  lateDistributedAssignment,
  loading,
  optionalAssignments,
}: CurrentAssignmentsProps) {
  const classes = useStyles();

  const sortedAssignedAssignments = filterActiveAssignments(assignments).sort(
    (a, b) => {
      const dueDate1 = a.groupsAssignment
        ? new Date(a.groupsAssignment.dueDate).getTime()
        : new Date(a.enrollmentsAssignment?.dueDate).getTime();
      const dueDate2 = b.groupsAssignment
        ? new Date(b.groupsAssignment.dueDate).getTime()
        : new Date(b.enrollmentsAssignment?.dueDate).getTime();
      return dueDate2 - dueDate1;
    }
  );

  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography variant="h2" color="primary" className={classes.title}>
          {optionalAssignments ? 'OPTIONAL ASSIGNMENTS' : 'CURRENT ASSIGNMENTS'}
        </Typography>
        <Typography variant="caption">
          {optionalAssignments
            ? 'Teacher has marked these assignments as optional.'
            : ''}
        </Typography>

        <div className={classes.cardContainers}>
          {loading && (
            <Skeleton width="100%" height={130} variant="rectangular" />
          )}
          {!optionalAssignments && (
            <PersonalDeckAssignmentCard
              activeDistributedAssignment={activeDistributedAssignment}
              lateDistributedAssignment={lateDistributedAssignment}
            />
          )}
          {sortedAssignedAssignments.map((datum) => {
            if (
              datum.groupsAssignment?.assignment.assignmentType ===
              AssignmentTypeEnum.Distributed
            ) {
              return (
                <div
                  className={classes.distributedAssignmentContainer}
                  key={datum.id}
                >
                  <DistributedAssignmentCard
                    activeDistributedAssignment={datum}
                    late
                    inactive
                  />
                </div>
              );
            }
            return (
              <StandardAssignmentCard
                key={getUnderlyingAssignment(datum).id}
                id={getUnderlyingAssignment(datum).id}
                assignmentName={getUnderlyingAssignment(datum).name}
                dueDate={getDueDate(datum)}
                status={getStatus(datum)}
                completedQuestions={datum.questionsCompleted}
                totalQuestions={datum.questionsTotal}
              />
            );
          })}
        </div>
      </CardContent>
    </Card>
  );
}
