import { CssBaseline, Toolbar, type Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useContext, useEffect, useState } from 'react';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { AppContext } from '../AppContext';
import { Alerts } from '../components/application/Alerts';
import { AlertsContext } from '../components/application/Alerts/context';
import { AssignmentSession } from '../components/application/Assignments/StandardAssignment/AssignmentSession';
import { Header } from '../components/application/Layout/Header';
import { Nav } from '../components/application/Layout/Nav';
import { ArchivedModal } from '../components/application/Profile/ArchivedModal';
import { TempLeaderboard } from '../components/application/TempLeaderboard';
import { LoadingSkeletons } from '../components/shared/Loaders/LoadingSkeletons';
import { NotFound } from '../components/shared/NotFound';
import { StudentDocument } from '../gql/queries/__generated__/student.generated';
import { onError } from '../utils/apollo/apolloHelper';
// relative components
import { useQuery } from '@apollo/client';
import { EnrollmentStatusEnum } from '../gql/types';
import { Account } from './Account';
import { Assignments } from './Assignments';
import { HomePage } from './Home/HomePage';
import { Mastery } from './Mastery';
import { NotificationsView as Notifications } from './Notifications';
import { PersonalDeckView as PersonalDeck } from './PersonalDeck';
import { StudyProgress } from './StudyProgress';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    '& .link': {
      color: theme.palette.secondary.main,
      textDecoration: 'underline',
    },
  },
  sideNav: {
    [theme.breakpoints.up('md')]: {
      flexShrink: 0,
    },
  },
  content: {
    flexGrow: 1,
    flexDirection: 'column',
    overflow: 'auto',
    width: '100%',
    height: '100%',
  },
}));

export function Views() {
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = useState(false);
  const { dispatch } = useContext(AlertsContext);
  const location = useLocation();
  const [archivedModalOpen, setArchivedModalOpen] = useState(false);
  const { data, loading } = useQuery(StudentDocument, {
    onError: onError(dispatch),
  });
  const {
    appState: { activeEnrollment },
  } = useContext(AppContext);
  const navigate = useNavigate();

  useEffect(() => {
    const currentlyInArchivedCourse =
      activeEnrollment?.group?.course.coursesTeachers.some((ct) => ct.archived);
    if (currentlyInArchivedCourse) {
      setArchivedModalOpen(true);
    }
  }, [activeEnrollment]);

  const closeArchivedModal = () => setArchivedModalOpen(false);

  useEffect(() => {
    if (activeEnrollment) {
      return;
    }

    if (!data?.student.enrollments) {
      return;
    }

    const { firstName, lastName } = data.student;
    const missingInfo =
      firstName === null ||
      firstName === '' ||
      lastName === null ||
      lastName === '';

    const validEnrollments = data.student.enrollments.find((enrollment) => {
      const notArchived = !enrollment.group?.course.coursesTeachers.some(
        (ct) => ct.archived
      );
      const isEnrolled =
        enrollment.enrollmentStatus === EnrollmentStatusEnum.Enrolled;
      return notArchived && isEnrolled;
    });
    // if user has no enrolled groups, navigate user to /account
    if (missingInfo || !validEnrollments) {
      navigate('/account');
    }
  }, [data, activeEnrollment, navigate]);

  const handleDrawerToggle = () => {
    setMobileOpen((currentState) => !currentState);
  };

  const closeDrawer = () => setMobileOpen(false);

  return (
    <main className={classes.root}>
      <CssBaseline />
      <Header handleDrawerToggle={handleDrawerToggle} />
      <div className={classes.sideNav}>
        <Nav closeDrawer={closeDrawer} mobileOpen={mobileOpen} />
      </div>

      <div className={classes.content}>
        <Toolbar />
        {loading || !data ? (
          <LoadingSkeletons num={20} />
        ) : (
          <Routes>
            <Route path="account" element={<Account key={location.key} />} />
            <Route
              path="leaderboard"
              element={<TempLeaderboard key={location.key} />}
            />
            <Route
              path="assignments/:assignmentId"
              element={<AssignmentSession />}
            />
            <Route
              path="assignments"
              element={<Assignments key={location.key} />}
            />
            <Route path="home" element={<HomePage key={location.key} />} />
            <Route path="personal-deck/*" element={<PersonalDeck />} />
            <Route path="notifications" element={<Notifications />} />
            <Route path="mastery" element={<Mastery key={location.key} />} />
            <Route
              path="progress/*"
              element={<StudyProgress key={location.key} />}
            />

            <Route path="/" element={<Navigate to="/home" replace />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        )}
      </div>
      <ArchivedModal
        open={archivedModalOpen}
        handleClose={closeArchivedModal}
      />
      <Alerts />
    </main>
  );
}
