import type { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useLocation } from 'react-router-dom';
import { capitalizeAndUnDasherize } from '../../../../utils/general';

const useStyles = makeStyles((theme: Theme) => ({
  pageTitle: {
    margin: 0,
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
  },
}));

export function HeaderTitle() {
  const classes = useStyles();
  const location = useLocation();
  const title = capitalizeAndUnDasherize(location.pathname.split('/')[1]);

  return <h1 className={classes.pageTitle}>{title}</h1>;
}
