import { useQuery } from '@apollo/client';
import {
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  type Theme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { TempLeaderboardDocument } from '../../../gql/queries/__generated__/tempLeaderboard.generated';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginBottom: theme.spacing(4),
  },
  tableContainer: {
    marginTop: theme.spacing(2),
    borderRadius: 4,
    border: `1px solid ${theme.palette.primary.main}`,
    backgroundColor: 'rgb(239,249,244, 0.3)',
    maxHeight: '80vh',
  },
  titleContainer: {
    padding: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.grays.main}`,
    color: theme.palette.primary.main,
  },
  table: {
    overflowX: 'scroll',
  },
  row: {
    '& td': {
      padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
      borderBottom: `1px solid ${theme.palette.grays.main}`,
      maxWidth: '40vw',
    },
  },
  tableHead: {
    '& .MuiTableCell-stickyHeader': {
      backgroundColor: theme.palette.primary.main,
    },
    '& th': {
      color: theme.palette.common.white,
      padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
    },
  },
  noDataContainer: {
    textAlign: 'center',
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.primary.main,
    padding: theme.spacing(2),
  },
}));

export function Leaderboard() {
  const classes = useStyles();
  const { data, loading } = useQuery(TempLeaderboardDocument);
  const leaderboard: unknown = data?.tempLeaderboard;
  const renderLoading = () => {
    if (loading) {
      return (
        <div>
          <LinearProgress />
        </div>
      );
    }
    return null;
  };

  return (
    <div className={classes.root}>
      <TableContainer className={classes.tableContainer}>
        <div className={classes.titleContainer}>
          <Typography variant="h6">Leaderboard</Typography>
          <Typography variant="caption">
            &lsquo;Points&rsquo; are the number of Personal Deck Questions that
            are currently NOT due. Earn points by completing Personal Deck
            Questions.
          </Typography>
        </div>
        {Array.isArray(leaderboard) && leaderboard.length > 0 ? (
          <Table stickyHeader className={classes.table}>
            <TableHead className={classes.tableHead}>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Points</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {leaderboard.map((d) => (
                <TableRow key={`qkey-${d[0]}`} className={classes.row}>
                  <TableCell>{d[1]}</TableCell>
                  <TableCell>{d[3]}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          renderLoading()
        )}
      </TableContainer>
    </div>
  );
}
