import { useQuery } from '@apollo/client';
import { ExpandMore } from '@mui/icons-material';
import {
  Card,
  Chip,
  Collapse,
  IconButton,
  Typography,
  type Theme,
} from '@mui/material';
import { amber, blue } from '@mui/material/colors';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { useContext, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { AppContext } from '../../../AppContext';
import {
  bubbleGreen,
  bubbleRed,
  bubbleYellow,
} from '../../../assets/shared-styles/Bubble';
import { EnrollmentsActionsDocument } from '../../../gql/queries/__generated__/enrollmentsAction.generated';
import { CurrentXp } from './CurrentXp';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
  },
  currentXpContainer: {
    margin: '0 auto',
    maxWidth: 1100,
  },
  infiniteScrollContainer: {
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: 4,
    padding: theme.spacing(2),
    height: '78vh',
    overflow: 'auto',
    maxWidth: 1100,
    margin: '0 auto',
  },
  container: {
    padding: theme.spacing(2),
    margin: theme.spacing(2),
  },
  innerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  date: {
    marginTop: theme.spacing(1),
  },
  total: {
    ...bubbleYellow(),
    fontWeight: theme.typography.fontWeightBold,
    width: 50,
    height: 50,
  },
  positive: {
    ...bubbleGreen(),
    width: 50,
    height: 50,
  },
  negative: {
    ...bubbleRed(),
    width: 50,
    height: 50,
  },
  pointsDetailsContainer: {
    display: 'flex',
    marginLeft: theme.spacing(1.5),
  },
  pointsDetails: {
    borderRadius: 4,
    padding: theme.spacing(1),
    whiteSpace: 'nowrap',
  },
  base: {
    border: `2px solid ${blue[800]}`,
    background: blue[100],
    marginRight: theme.spacing(0.5),
    padding: theme.spacing(0.5),
  },
  bonus: {
    border: `2px solid ${amber[800]}`,
    background: amber[100],
    padding: theme.spacing(0.5),
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: theme.spacing(1),
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
    color: theme.palette.secondary.main,
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  expandButton: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: '',
  },
  collapseContainer: {
    display: 'flex',
    marginTop: theme.spacing(3),
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

const PAGINATION_COUNT = 10;

export function XpDetails() {
  const classes = useStyles();
  const [expandedSet, setExpandedSet] = useState<Set<string>>(new Set());
  const {
    appState: { activeEnrollment },
  } = useContext(AppContext);
  const enrollmentId = activeEnrollment?.id || '';
  const { data, fetchMore } = useQuery(EnrollmentsActionsDocument, {
    skip: !enrollmentId,
    variables: {
      enrollmentId,
      first: PAGINATION_COUNT,
    },
    fetchPolicy: 'cache-and-network',
  });

  const fetchNextBatch = () => {
    if (!fetchMore) {
      return;
    }

    fetchMore({
      variables: {
        first: PAGINATION_COUNT,
        after: data?.enrollmentsActions.pageInfo.endCursor,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return previousResult;
        }

        fetchMoreResult.enrollmentsActions.edges = [
          ...(previousResult.enrollmentsActions.edges || []),
          ...(fetchMoreResult.enrollmentsActions.edges || []),
        ];

        return { ...fetchMoreResult };
      },
    });
  };
  const numItems = data?.enrollmentsActions?.edges?.length || 0;
  const totalItems = data?.enrollmentsActions?.totalCount || 0;
  const toggleExpanded = (id: string) => {
    return () => {
      const newExpanded = new Set(expandedSet);
      if (expandedSet.has(id)) {
        newExpanded.delete(id);
      } else {
        newExpanded.add(id);
      }
      setExpandedSet(newExpanded);
    };
  };

  return (
    <section className={classes.root}>
      <div className={classes.currentXpContainer}>
        <CurrentXp />
      </div>
      <div
        className={classes.infiniteScrollContainer}
        id="infinite-scroll-enrollments-actions"
      >
        <InfiniteScroll
          dataLength={numItems}
          scrollableTarget="infinite-scroll-enrollments-actions"
          next={fetchNextBatch}
          hasMore={numItems < totalItems}
          loader={<div>Loading...</div>}
        >
          {data?.enrollmentsActions?.edges?.map((edge) => {
            const ea = edge?.node;
            if (!ea) return null;
            const basePoints = ea.base || 0;
            const bonusPoints = ea.bonus || 0;
            const totalPoints = basePoints + bonusPoints;
            const expanded = expandedSet.has(ea.id);
            return (
              <Card key={`ea-key-${ea.id}`} className={classes.container}>
                <div className={classes.innerContainer}>
                  <div>
                    <Typography variant="h4" color="primary">
                      {ea.action.name}
                    </Typography>
                    <Chip
                      className={classes.date}
                      color="secondary"
                      label={ea.date}
                      variant="outlined"
                      size="small"
                    />
                  </div>
                  <div>
                    <Typography
                      className={clsx(classes.total, {
                        [classes.positive]: totalPoints > 0,
                        [classes.negative]: totalPoints < 0,
                      })}
                    >
                      {`${totalPoints > 0 ? '+' : ''}${totalPoints}`}
                    </Typography>
                    <IconButton
                      className={clsx(classes.expand, {
                        [classes.expandOpen]: expanded,
                      })}
                      onClick={toggleExpanded(ea.id)}
                      aria-expanded={expanded}
                      aria-label="show more"
                      size="large"
                    >
                      <ExpandMore />
                    </IconButton>
                  </div>
                </div>
                <Collapse in={expanded}>
                  <div className={classes.collapseContainer}>
                    <div>
                      <Typography>
                        <strong>Description: </strong>
                        {ea.action.description}
                      </Typography>
                    </div>
                    <div className={classes.pointsDetailsContainer}>
                      <Typography
                        className={clsx(classes.pointsDetails, classes.base)}
                      >
                        Base: {ea.base}
                      </Typography>
                      <Typography
                        className={clsx(classes.pointsDetails, classes.bonus)}
                      >
                        Bonus: {ea.bonus}
                      </Typography>
                    </div>
                  </div>
                </Collapse>
              </Card>
            );
          })}
        </InfiniteScroll>
      </div>
    </section>
  );
}
