import { useQuery } from '@apollo/client';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { NextAssignmentQuestionDocument } from '../../../../gql/queries/__generated__/question.generated';
import { StudentsAssignmentsDatumDocument } from '../../../../gql/queries/__generated__/studentsAssignmentsDatum.generated';
import { onError } from '../../../../utils/apollo/apolloHelper';
import { AlertsContext } from '../../Alerts/context';
import { StudySessionContainer } from '../../StudySession/StudySessionContainer';
import { StudySessionProvider } from '../../StudySession/context';

type StandardAssignmentProps = {
  groupsAssignmentId?: string;
  enrollmentsAssignmentId?: string;
  selectedAssignmentName: string;
};

export function StandardAssignment({
  groupsAssignmentId,
  enrollmentsAssignmentId,
  selectedAssignmentName,
}: StandardAssignmentProps) {
  const { dispatch } = useContext(AlertsContext);
  const { data: completionData, refetch: refetchCompletionData } = useQuery(
    StudentsAssignmentsDatumDocument,
    {
      variables: {
        groupsAssignmentId,
        enrollmentsAssignmentId,
      },
    }
  );

  const { data, loading, refetch } = useQuery(NextAssignmentQuestionDocument, {
    skip: !groupsAssignmentId && !enrollmentsAssignmentId,
    fetchPolicy: 'cache-and-network',
    variables: {
      groupsAssignmentId,
      enrollmentsAssignmentId,
      previousQuestionId: null,
    },
    onError: onError(dispatch),
    notifyOnNetworkStatusChange: true,
  });
  const [questionNum, setQuestionNum] = useState(1);
  const navigate = useNavigate();

  const getNextQuestion = () => {
    const questionId = data?.nextAssignmentQuestion?.id;
    refetch({
      groupsAssignmentId,
      enrollmentsAssignmentId,
      previousQuestionId: questionId,
    });
    setQuestionNum((currentQuestionNum) => currentQuestionNum + 1);
    refetchCompletionData();
  };

  const handleClose = () => {
    navigate('/');
    const questionId = data?.nextAssignmentQuestion?.id;
    refetch({
      groupsAssignmentId,
      enrollmentsAssignmentId,
      previousQuestionId: questionId,
    });
    refetchCompletionData();
  };

  return (
    <StudySessionProvider
      questionsCompleted={
        completionData?.studentsAssignmentsDatum?.questionsCompleted
      }
      questionsTotal={completionData?.studentsAssignmentsDatum?.questionsTotal}
      groupsAssignmentId={groupsAssignmentId}
      enrollmentsAssignmentId={enrollmentsAssignmentId}
      question={data?.nextAssignmentQuestion}
      loading={loading}
      getNextQuestion={getNextQuestion}
    >
      <StudySessionContainer
        questionNum={questionNum}
        assignmentFinished={!loading && !!data && !data.nextAssignmentQuestion}
        name={selectedAssignmentName}
        handleClose={handleClose}
      />
    </StudySessionProvider>
  );
}
