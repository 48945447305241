import { Card, type Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useContext } from 'react';
import { QuillDeltaAsHtml } from '../../shared/QuillDeltaAsHtml';
import { TextToSpeech } from '../../shared/TextToSpeech';
import { TranslateText } from '../../shared/TranslateText';
import { StudySessionContext } from '../StudySession/context';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100%',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  card: {
    height: '100%',
    padding: theme.spacing(1),
  },
}));

export function Question(): JSX.Element | null {
  const classes = useStyles();
  const { question } = useContext(StudySessionContext);

  if (!question) return null;

  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <TextToSpeech text={question.plainText} />
        {/* Matt: Convert richText */}
        <TranslateText delta={question.richText.ops} />
        <QuillDeltaAsHtml delta={question.richText.ops} />
      </Card>
    </div>
  );
}
