import { Grid, Typography } from '@mui/material';
import { default as AssignmentSVG } from './assets/assignment.svg?react';
import { default as DeckSVG } from './assets/deck.svg?react';
import { default as NotepadSVG } from './assets/notepad.svg?react';
export function EmptyState() {
  return (
    <Grid container sx={{ paddingY: '64px', paddingX: '40px' }}>
      <Grid
        item
        xs={12}
        sx={{
          display: 'flex',
          gap: '12px',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          paddingBottom: '64px',
        }}
      >
        <NotepadSVG />
        <Typography
          sx={{
            fontSize: '40px',
            fontWeight: 600,
            textAlign: 'center',
            lineHeight: '46px',
          }}
        >
          There’s nothing to do yet!
        </Typography>
        <Typography
          sx={{
            fontSize: '28px',
            fontWeight: 400,
            textAlign: 'center',
            width: {
              xs: '100%',
              md: '50%',
            },
            lineHeight: '36px',
          }}
        >
          Your teacher hasn’t assigned you work yet. Stay tuned for assignments
          and your personal deck!
        </Typography>
      </Grid>

      <Grid
        item
        container
        xs={12}
        sx={{ gap: '80px', justifyContent: 'center' }}
      >
        <Grid
          item
          lg={5}
          xs={12}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '12px',
          }}
        >
          <AssignmentSVG />
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: '24px',
              lineHeight: '28px',
              textAlign: 'center',
            }}
          >
            Assignments
          </Typography>
          <Typography
            sx={{
              fontWeight: 300,
              fontSize: '20px',
              lineHeight: '26px',
              textAlign: 'center',
              width: {
                xs: '100%',
                md: '440px',
              },
            }}
          >
            Podsie assignments are like regular homework assignments (except
            more awesome). Answer questions and get a score!
          </Typography>
        </Grid>
        <Grid
          item
          lg={5}
          xs={12}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '12px',
          }}
        >
          <DeckSVG />
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: '24px',
              lineHeight: '28px',
              textAlign: 'center',
            }}
          >
            Personal Decks
          </Typography>
          <Typography
            sx={{
              fontWeight: 300,
              fontSize: '20px',
              lineHeight: '26px',
              textAlign: 'center',
              width: {
                xs: '100%',
                md: '440px',
              },
            }}
          >
            Personal decks compile ALL questions from all of your assignments.
            The more you practice, the more points (XP) you get! Learn more
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}
