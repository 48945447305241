import { useMutation } from '@apollo/client';
import {
  LinearProgress,
  TextField,
  Typography,
  type Theme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useContext, useState } from 'react';
import { CreateEnrollmentDocument } from '../../../gql/mutations/__generated__/enrollment.generated';
import { StudentDocument } from '../../../gql/queries/__generated__/student.generated';
import { onError } from '../../../utils/apollo/apolloHelper';
import { OutlinedButton } from '../../shared/Buttons/OutlinedButton';
import { StandardButton } from '../../shared/Buttons/StandardButton';
import { AlertsContext } from '../Alerts/context';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  codeField: {
    marginBottom: theme.spacing(2),
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  title: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightBold,
  },
}));

export function EnrollGroup() {
  const classes = useStyles();
  const [groupCode, setGroupCode] = useState('');
  const [createMode, setCreateMode] = useState(false);
  const { dispatch } = useContext(AlertsContext);
  const [createEnrollment, { loading }] = useMutation(
    CreateEnrollmentDocument,
    {
      onError: onError(dispatch),
      onCompleted: () => {
        setGroupCode('');
        setCreateMode(false);
      },
      refetchQueries: [{ query: StudentDocument }],
    }
  );

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    createEnrollment({ variables: { groupCode } });
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setGroupCode(e.target.value);
  };

  return (
    <div className={classes.root}>
      <header className={classes.header}>
        <Typography className={classes.title} variant="h6">
          MY CLASSES
        </Typography>
        <Typography variant="subtitle1">
          Add a class or select one to set it as active
        </Typography>
      </header>
      {createMode ? (
        <form className={classes.form} onSubmit={handleSubmit}>
          <TextField
            onChange={handleChange}
            className={classes.codeField}
            value={groupCode}
            label="Class Code"
          />
          {loading ? (
            <LinearProgress />
          ) : (
            <StandardButton text="ENROLL IN CLASS" type="submit" />
          )}
        </form>
      ) : (
        <div className={classes.buttonContainer}>
          <OutlinedButton
            text="ADD CLASS"
            onClick={() => setCreateMode(true)}
          />
        </div>
      )}
    </div>
  );
}
