import {
  LinearProgress,
  StyledEngineProvider,
  TableContainer,
  ThemeProvider,
  type Theme,
} from '@mui/material';

import { makeStyles } from '@mui/styles';

import MUIDataTable, { type MUIDataTableProps } from 'mui-datatables';
import customDataTableTheme from './CustomDataTableTheme';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100%',
    borderRadius: 8,
    '& h6': {
      color: theme.palette.primary.main,
      ...theme.typography.h2,
    },
  },
}));

export function CustomDataTable({
  loading,
  ...props
}: { loading: boolean } & MUIDataTableProps) {
  const classes = useStyles();

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={customDataTableTheme}>
        <TableContainer className={classes.root}>
          {loading && <LinearProgress />}
          <MUIDataTable
            {...props}
            options={{
              rowsPerPage: 100,
              download: false,
              responsive: 'simple',
              fixedSelectColumn: false,
              selectableRowsHeader: false,
              selectableRows: 'none',
              filter: false,
              print: false,
              viewColumns: false,
              textLabels: {
                body: {
                  noMatch: 'No matching records found',
                },
              },
              ...props.options,
            }}
          />
        </TableContainer>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
