import { Tooltip, Typography, type Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { format, formatDistance } from 'date-fns';
import { dateFormat } from '../../../utils/dates';

const useStyles = makeStyles((theme: Theme) => ({
  due: {
    display: 'flex',
    alignItems: 'baseline',
    marginTop: theme.spacing(3),
  },
  dueIn: {
    marginRight: theme.spacing(1),
  },
  dueStatus: {
    color: theme.palette.accent.main,
  },
}));

type AssignmentCardDueDateProps = {
  due: Date;
  current: Date;
  pastDue: boolean;
  distributed?: boolean;
};

export function AssignmentCardDueDate({
  due,
  current,
  pastDue,
  distributed,
}: AssignmentCardDueDateProps) {
  const classes = useStyles();
  const oneDay = 24 * 60 * 60 * 1000;
  const daysTilDue = Math.round(
    Math.abs((current.getTime() - due.getTime()) / oneDay)
  );
  let dueText = '';
  let tooltip = '';
  const formattedDate = format(due, dateFormat);
  const distance = formatDistance(current, due);
  let dueLabel = '';
  if (pastDue || daysTilDue > 1) {
    dueText = formattedDate;
    tooltip = distance;
    dueLabel = 'At';
  } else {
    dueText = distance;
    tooltip = formattedDate;
    dueLabel = 'In';
  }

  return (
    <span className={classes.due}>
      <Typography
        color={distributed ? 'primary' : 'secondary'}
        variant="caption"
        className={classes.dueIn}
      >
        {`Due ${dueLabel}:`}
      </Typography>
      <Tooltip title={tooltip}>
        <Typography variant="h4" className={classes.dueStatus}>
          {dueText}
        </Typography>
      </Tooltip>
    </span>
  );
}
