import * as Types from '../../types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type UpdateActiveEnrollmentMutationVariables = Types.Exact<{
  groupId: Types.Scalars['ID']['input'];
}>;

export type UpdateActiveEnrollmentMutation = {
  __typename?: 'Mutation';
  updateActiveEnrollment: {
    __typename?: 'Enrollment';
    id: string;
    enrollmentStatus: Types.EnrollmentStatusEnum;
    groupId?: string | null;
    unfreezePersonalDeck: boolean;
    points: number;
    active: boolean;
    group?: {
      __typename?: 'Group';
      id: string;
      groupCode: string;
      name: string;
      freezePersonalDeck: boolean;
      freezeDate?: any | null;
      course: {
        __typename?: 'Course';
        aiTranslationEnabled: boolean;
        id: string;
        name: string;
        standardsChartId?: string | null;
        studentFacingAiEnabled: boolean;
        teachers: Array<{
          __typename?: 'Teacher';
          id: string;
          email: string;
          firstName?: string | null;
          lastName?: string | null;
        }>;
        coursesTeachers: Array<{
          __typename?: 'CoursesTeacher';
          id: string;
          archived: boolean;
        }>;
      };
    } | null;
    student: {
      __typename?: 'Student';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      fullName?: string | null;
      email: string;
      school: { __typename?: 'School'; timezone: string };
    };
  };
};

export const UpdateActiveEnrollmentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateActiveEnrollment' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'groupId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateActiveEnrollment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'groupId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'groupId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'enrollmentAttributes' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'enrollmentAttributes' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Enrollment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'enrollmentStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'unfreezePersonalDeck' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'points' } },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'group' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'groupCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'freezePersonalDeck' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'freezeDate' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'course' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'aiTranslationEnabled' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'standardsChartId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'studentFacingAiEnabled' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'teachers' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'email' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'firstName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'lastName' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'coursesTeachers' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'archived' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'student' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'school' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'timezone' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateActiveEnrollmentMutation,
  UpdateActiveEnrollmentMutationVariables
>;
