import type { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  stackTrace: {
    backgroundColor: theme.palette.common.black,
    padding: theme.spacing(2),
    color: 'lime',
  },
}));

type ErrorPageProps = {
  error: null | Error;
  componentStack: null | string;
  resetError: () => void;
};

export function ErrorPage({
  error,
  componentStack,
  resetError,
}: ErrorPageProps) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div>Something went wrong</div>
      <div>{error?.toString()}</div>

      <pre className={classes.stackTrace}>{componentStack}</pre>
      <button onClick={resetError}>reset</button>
    </div>
  );
}
