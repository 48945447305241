import { useMutation, useQuery } from '@apollo/client';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
  TextField,
  Typography,
  type Theme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { produce } from 'immer';
import React, { useContext, useState } from 'react';
import { UpdateStudentDocument } from '../../../gql/mutations/__generated__/student.generated';
import { StudentDocument } from '../../../gql/queries/__generated__/student.generated';
import { AlertsContext } from '../Alerts/context';
import { pushSnack } from '../Alerts/context/actions';

const useStyles = makeStyles((theme: Theme) => ({
  firstTextField: {
    marginRight: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(2),
    },
  },
  cancelButton: {
    marginRight: theme.spacing(2),
  },
  actionsContainer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    margin: '0 20px',
    padding: '15px 0',
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  inputContainer: {
    padding: '32px 0',
  },
  required: {
    color: theme.palette.error.main,
  },
}));

type EditProfileModalProps = {
  open: boolean;
  handleClose: () => void;
};

export function EditProfileModal({ open, handleClose }: EditProfileModalProps) {
  const classes = useStyles();
  const { data } = useQuery(StudentDocument);

  const [firstName, setFirstName] = useState(data?.student.firstName ?? '');
  const [lastName, setLastName] = useState(data?.student.lastName ?? '');
  const { dispatch } = useContext(AlertsContext);

  const [updateStudent, { loading }] = useMutation(UpdateStudentDocument, {
    variables: {
      firstName,
      lastName,
    },
    update: (cache, res) => {
      const data = cache.readQuery({ query: StudentDocument });
      if (!data) return;

      const response = res.data?.updateStudent;
      if (!response) return;

      const updatedStudent = produce(data.student, (studentDraft) => {
        studentDraft.firstName = response.firstName;
        studentDraft.lastName = response.lastName;
      });

      cache.writeQuery({
        query: StudentDocument,
        data: {
          student: updatedStudent,
        },
      });

      dispatch(
        pushSnack({
          message: 'Updated information successfully!',
          severity: 'success',
        })
      );
    },
  });

  if (!data) return null;

  const handleFirstNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFirstName(e.target.value);
  };

  const handleLastNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLastName(e.target.value);
  };

  const handleSave = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    updateStudent();
  };

  const { student } = data;
  const namesChanged =
    firstName !== student.firstName || lastName !== student.lastName;

  return (
    <Dialog
      maxWidth="md"
      fullWidth
      open={open}
      aria-labelledby="form-dialog-title"
    >
      {loading ? <LinearProgress /> : null}
      <DialogTitle id="form-dialog-title">Edit Profile</DialogTitle>
      <form onSubmit={handleSave}>
        <DialogContent>
          <DialogContentText>
            <Typography variant="body1" color="primary">
              Update your personal information
            </Typography>
          </DialogContentText>
          <div className={classes.inputContainer}>
            <DialogContentText>
              <Typography variant="h4" color="primary">
                Name
                <span className={classes.required}> *</span>
              </Typography>
            </DialogContentText>
            <TextField
              required
              disabled={!student.allowNameChange}
              className={classes.firstTextField}
              helperText={firstName ? '' : 'First name is required'}
              error={!firstName}
              id="firstName"
              label="First Name"
              type="firstName"
              variant="outlined"
              size="small"
              value={firstName}
              onChange={handleFirstNameChange}
            />
            <TextField
              required
              disabled={!student.allowNameChange}
              helperText={lastName ? '' : 'Last name is required'}
              error={!lastName}
              id="lastName"
              label="Last Name"
              type="lastName"
              variant="outlined"
              size="small"
              value={lastName}
              onChange={handleLastNameChange}
            />
          </div>
          <DialogContentText>
            <Typography variant="h4" color="primary">
              School Email
              <span className={classes.required}> *</span>
            </Typography>
          </DialogContentText>
          <TextField
            id="email"
            label="Email"
            type="email"
            size="small"
            variant="outlined"
            value={student.email}
            disabled={true}
          />
        </DialogContent>
        <DialogActions>
          <div className={classes.actionsContainer}>
            <Button
              className={classes.cancelButton}
              variant="outlined"
              onClick={handleClose}
              color="secondary"
              disabled={!student.firstName || !student.lastName}
            >
              Close
            </Button>
            <Button
              variant="outlined"
              type="submit"
              disabled={!namesChanged || !firstName || !lastName}
              color="secondary"
            >
              Save
            </Button>
          </div>
        </DialogActions>
      </form>
    </Dialog>
  );
}
