export const bubbleGreen = () => ({
  height: '38px',
  width: '38px',
  backgroundColor: '#def3ef',
  border: 'solid 1px #38b866',
  borderRadius: '50%',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export const bubbleYellow = () => ({
  height: '38px',
  width: '38px',
  backgroundColor: '#ffdead',
  border: 'solid 1px #ffa500',
  borderRadius: '50%',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export const bubbleRed = () => ({
  height: '38px',
  width: '38px',
  backgroundColor: '#ffd6cf',
  border: 'solid 1px #ff2500',
  borderRadius: '50%',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
});
