import { useMutation } from '@apollo/client';
import { LinearProgress, Typography, type Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from 'react';
import { CreateMultipleChoiceResponseDocument } from '../../../../gql/mutations/__generated__/response.generated';
import { Question, QuestionTypeEnum } from '../../../../gql/types';
import { onError } from '../../../../utils/apollo/apolloHelper';
import shuffle from '../../../../utils/shuffle';
import { StandardButton } from '../../../shared/Buttons/StandardButton';
import { AlertsContext } from '../../Alerts/context';
import { openDialog, pushSnack } from '../../Alerts/context/actions';
import { StudySessionContext } from '../../StudySession/context';
import { Choice } from './Choice';
import { useExperimentCheck } from './useExperimentCheck';

const useStyles = makeStyles((theme: Theme) => ({
  answerChoicesContainer: {
    padding: theme.spacing(2),
  },
  progress: {
    marginTop: theme.spacing(1),
  },
  form: {
    height: '100%',
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'space-between',
  },
  selectedCountContainer: {
    flex: '1 1 auto',
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

type MultipleChoiceProps = {
  handleShow?: () => void;
  isPersonal?: boolean;
  reviewQuestion?: Question;
  setSupplementOpen: Dispatch<SetStateAction<boolean>>;
};

export function MultipleChoice({
  setSupplementOpen,
  isPersonal = false,
  reviewQuestion,
  handleShow,
}: MultipleChoiceProps) {
  const classes = useStyles();
  const { isPrePostTest } = useExperimentCheck();
  const [checked, setChecked] = useState<Set<string>>(new Set());
  const [noneOfTheAbove, setNoneOfTheAbove] = useState(false);
  const { dispatch } = useContext(AlertsContext);
  const contextValues = useContext(StudySessionContext);
  const question =
    reviewQuestion !== undefined ? reviewQuestion : contextValues.question;
  const {
    feedbackVisible,
    setFeedbackVisible,
    setIsCorrect,
    groupsAssignmentId,
    enrollmentsAssignmentId,
    personalDecksQuestionId,
    personalDeckStudySessionId,
    setResponseMeta,
    getNextQuestion,
  } = contextValues;

  const [createMultipleChoiceResponse, { loading }] = useMutation(
    CreateMultipleChoiceResponseDocument,
    {
      variables: {
        questionId: question?.id || '',
        groupsAssignmentId,
        personalDecksQuestionId,
        personalDeckStudySessionId,
        enrollmentsAssignmentId,
        answerChoiceIds: Array.from(checked),
      },
      onError: onError(dispatch, true),
      onCompleted: (data) => {
        if (isPrePostTest) {
          getNextQuestion();
          return;
        }

        const isCorrect = data.createMultipleChoiceResponse.isCorrect;

        setIsCorrect(isCorrect);
        if (!isCorrect) {
          setSupplementOpen(true);
        }
        setFeedbackVisible(true);
        if (question) {
          setResponseMeta({
            id: data.createMultipleChoiceResponse.id,
            type: question.questionType,
            numPriorResponses:
              data.createMultipleChoiceResponse.numPriorResponses,
          });
        }
        if (isPersonal && isCorrect) {
          dispatch(
            pushSnack({
              message: "You've earned 1 XP for your successful recall effort!",
              severity: 'success',
            })
          );
        }
      },
    }
  );

  const answerChoices = useMemo(() => {
    if (!question || !question.multipleChoiceAnswerChoices) {
      return [];
    }

    return shuffle(question.multipleChoiceAnswerChoices);
  }, [question]);

  if (!question) {
    return null;
  }

  const handleNoneOfTheAbove = () => {
    setNoneOfTheAbove(true);
    setChecked(new Set());
  };

  const handleInputChange = (answerChoiceId: string) => {
    return () => {
      let updated: undefined | Set<string>;
      if (question.questionType === QuestionTypeEnum.SelectAllMultipleChoice) {
        updated = new Set(checked);
        if (checked.has(answerChoiceId)) {
          updated.delete(answerChoiceId);
        } else {
          setNoneOfTheAbove(false);
          updated.add(answerChoiceId);
        }
      } else if (
        question.questionType === QuestionTypeEnum.SelectOneMultipleChoice
      ) {
        updated = new Set();
        updated.add(answerChoiceId);
      }

      if (updated) {
        setChecked(updated);
      }
    };
  };
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (
      checked.size === 0 &&
      question.questionType === QuestionTypeEnum.SelectOneMultipleChoice
    ) {
      dispatch(
        openDialog({
          message: 'Please select an answer choice!',
          title: 'Answer choice required',
        })
      );
    } else {
      if (reviewQuestion === undefined) {
        createMultipleChoiceResponse();
      } else {
        handleShow && handleShow();
      }
    }
  };
  const isSelectAll =
    question.questionType === QuestionTypeEnum.SelectAllMultipleChoice;
  const instructions = isSelectAll
    ? 'Select all that apply:'
    : 'Select your response:';
  return (
    <form className={classes.form} onSubmit={handleSubmit}>
      <div className={classes.answerChoicesContainer}>
        <Typography variant="h4" color="primary">
          {instructions}
        </Typography>
        {answerChoices.map((answerChoice) => {
          const isChecked = checked.has(answerChoice.id);
          const inputId = `answerChoice-${answerChoice.id}`;
          return (
            <Choice
              answerChoice={answerChoice}
              key={inputId}
              isChecked={isChecked}
              handleInputChange={handleInputChange(answerChoice.id)}
              inputId={inputId}
              answerSubmitted={feedbackVisible}
            />
          );
        })}
        {isSelectAll && (
          <Choice
            text="None of the above"
            inputId="none-of-the-above"
            answerSubmitted={feedbackVisible}
            handleInputChange={handleNoneOfTheAbove}
            isChecked={noneOfTheAbove}
          />
        )}
        {isSelectAll && (
          <div className={classes.selectedCountContainer}>
            <Typography
              variant="h5"
              color="primary"
            >{`Answers Selected: ${checked.size}`}</Typography>
          </div>
        )}
        {!feedbackVisible && (
          <div className={classes.buttonContainer}>
            <StandardButton
              text="CHECK ANSWER"
              type="submit"
              disabled={loading}
            />
          </div>
        )}
        {loading && <LinearProgress />}
      </div>
    </form>
  );
}
