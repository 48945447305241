import * as Types from '../../types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type NextAssignmentQuestionQueryVariables = Types.Exact<{
  groupsAssignmentId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  enrollmentsAssignmentId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  previousQuestionId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
}>;

export type NextAssignmentQuestionQuery = {
  __typename?: 'Query';
  nextAssignmentQuestion?: {
    __typename?: 'Question';
    numAttemptsUntilAnswerShown: number;
    richText: any;
    id: string;
    plainText: string;
    questionType: Types.QuestionTypeEnum;
    freeResponseAnswer?: {
      __typename?: 'FreeResponseAnswer';
      id: string;
      text: string;
      richText: any;
    } | null;
    multipleChoiceAnswerChoices?: Array<{
      __typename?: 'MultipleChoiceAnswerChoice';
      id: string;
      isCorrect: boolean;
      plainText: string;
      richText: any;
      text: string;
    }> | null;
    shortAnswerAnswers?: Array<{
      __typename?: 'ShortAnswerAnswer';
      hidden: boolean;
      id: string;
      isCaseSensitive: boolean;
      richText: any;
      text: string;
    }> | null;
    supplement?: {
      __typename?: 'Supplement';
      id: string;
      plainText: string;
      richText: any;
    } | null;
    standards?: Array<{
      __typename?: 'Standard';
      description: string;
      id: string;
      title: string;
    }> | null;
  } | null;
};

export const NextAssignmentQuestionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'nextAssignmentQuestion' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'groupsAssignmentId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'enrollmentsAssignmentId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'previousQuestionId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nextAssignmentQuestion' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'groupsAssignmentId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'groupsAssignmentId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'enrollmentsAssignmentId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'enrollmentsAssignmentId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'previousQuestionId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'previousQuestionId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'questionAttributes' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'questionIndexAttributes' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Question' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plainText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'questionType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'standards' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'questionAttributes' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Question' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'questionIndexAttributes' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'freeResponseAnswer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                { kind: 'Field', name: { kind: 'Name', value: 'richText' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'multipleChoiceAnswerChoices' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isCorrect' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plainText' } },
                { kind: 'Field', name: { kind: 'Name', value: 'richText' } },
                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'numAttemptsUntilAnswerShown' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'richText' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shortAnswerAnswers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'hidden' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isCaseSensitive' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'richText' } },
                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'supplement' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plainText' } },
                { kind: 'Field', name: { kind: 'Name', value: 'richText' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  NextAssignmentQuestionQuery,
  NextAssignmentQuestionQueryVariables
>;
