import { Alert } from '@mui/lab';
import { Typography, type Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  alert: {
    margin: `${theme.spacing(2)} ${theme.spacing(1)}`,
  },
}));

export function DistributedAssignmentExplanation() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <p>
        <Typography>
          A Personal Deck Assignment is a special type of assignment where
          students try to reach the target number of points by completing
          personal deck study sessions.
        </Typography>
      </p>
      <p>
        <Typography>
          Here are the different ways that students can earn points:
        </Typography>
      </p>
      <ul>
        <li>
          Students earn 1 point by completing a personal deck study session.
        </li>
        <li>
          Any additional study sessions completed after the first session of
          that day will count as a bonus 0.5 points.
        </li>
        <li>
          <strong>
            If there are no personal deck questions due, students can claim a
            &ldquo;free session&rdquo; by clicking on the &ldquo;Claim Your Free
            Session!&rdquo; button.
          </strong>
        </li>
      </ul>
      <div className={classes.alert}>
        <Alert severity="info">
          As an example, let&apos;s say that the teacher sets a target of 5
          points, and provides 7 days for students to complete this assignment.
          In this example, a student can reach the target 5 points by logging in
          to Podsie 5 out of the 7 day to complete a personal deck study
          session.
        </Alert>
      </div>
    </div>
  );
}
