import { useQuery } from '@apollo/client';
import { Close } from '@mui/icons-material';
import {
  Dialog,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
  Typography,
  type Theme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import type { UnknownRecord } from '@podsie/utils/object.js';
import { utcToZonedTime } from 'date-fns-tz';
import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../../AppContext';
import { buttonLink } from '../../../../assets/shared-styles/Button-Link';
import { DistributedAssignmentResultsDocument } from '../../../../gql/queries/__generated__/groupsAssignment.generated';
import { StudySessionResponsesDocument } from '../../../../gql/queries/__generated__/personalDeckQuestion.generated';
import { StudySessionByIdDocument } from '../../../../gql/queries/__generated__/personalDeckStudySession.generated';
import { StudentsAssignmentsDatumQuery } from '../../../../gql/queries/__generated__/studentsAssignmentsDatum.generated';
import { Aggregate } from '../../../shared/Aggregate';
import { CustomDataTable } from '../../../shared/CustomTable/CustomDataTable';
import { ResultsModal } from '../ResultsModal';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  paper: { overflow: 'hidden' },
  aggregateContainer: {
    marginBottom: theme.spacing(1),
  },
  tableContainer: {
    marginTop: theme.spacing(2),
    border: `1px solid ${theme.palette.grays.dark}`,
    borderRadius: 4,
    padding: theme.spacing(2),
  },
  closeButton: {
    color: theme.palette.common.white,
    padding: 0,
  },
  text: {
    color: theme.palette.common.white,
  },
  buttonLink: {
    ...buttonLink(theme),
    fontWeight: theme.typography.fontWeightBold,
  },
  header: {
    borderBottom: `1px solid ${theme.palette.primary.main}`,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(3),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  content: {
    // padding: theme.spacing(3),
  },
}));

const calculateEarnedPoints = (hash: UnknownRecord, dateString: string) => {
  let earnedPoints = 0;
  const entriesForDate = hash[dateString];
  if (Array.isArray(entriesForDate)) {
    entriesForDate.forEach((_, index) => {
      if (index === 0) {
        earnedPoints += 1;
      } else {
        earnedPoints += 0.5;
      }
    });
  }
  return earnedPoints;
};

type PersonalDeckAssignmentResultsProps = {
  activeDistributedAssignment: StudentsAssignmentsDatumQuery['studentsAssignmentsDatum'];
  closeDialog: () => void;
};

export function PersonalDeckAssignmentResults({
  activeDistributedAssignment,
  closeDialog,
  ...dialogProps
}: PersonalDeckAssignmentResultsProps & DialogProps) {
  const classes = useStyles();
  const {
    appState: { activeEnrollment },
  } = useContext(AppContext);
  const [personalDeckStudySessionId, setPersonalDeckStudySessionId] = useState<
    undefined | string
  >();

  const assignmentId =
    activeDistributedAssignment?.groupsAssignment?.assignment.id || '';
  const groupId = activeDistributedAssignment?.groupsAssignment?.group.id || '';
  const enrollmentId = activeEnrollment?.id || '';
  const { data, refetch } = useQuery(DistributedAssignmentResultsDocument, {
    skip: !assignmentId || !groupId || !enrollmentId,
    variables: {
      assignmentId,
      groupId,
      enrollmentId,
    },
  });

  useEffect(() => {
    refetch();
  }, [refetch, activeDistributedAssignment?.distributedPointsEarned]);

  const { data: studySessionData, loading: studySessionLoading } = useQuery(
    StudySessionResponsesDocument,
    {
      skip: !personalDeckStudySessionId,
      variables: {
        personalDeckStudySessionId: personalDeckStudySessionId || '',
      },
    }
  );
  const { data: studySessionInfo } = useQuery(StudySessionByIdDocument, {
    skip: !personalDeckStudySessionId,
    variables: {
      personalDeckStudySessionId: personalDeckStudySessionId || '',
    },
  });
  const handleClose = () => setPersonalDeckStudySessionId(undefined);
  const handleOpen = (sessionId: string) => {
    return () => {
      setPersonalDeckStudySessionId(sessionId);
    };
  };
  const timezone = activeEnrollment?.student.school.timezone;
  const rows = [];
  if (
    timezone &&
    activeDistributedAssignment?.groupsAssignment?.assignment.launchAt &&
    activeDistributedAssignment?.groupsAssignment.dueDate &&
    data
  ) {
    const hash = data.distributedAssignmentResults[enrollmentId];
    const startDate = new Date(
      activeDistributedAssignment?.groupsAssignment?.assignment.launchAt
    );
    const dueDate = new Date(
      activeDistributedAssignment?.groupsAssignment?.dueDate
    );
    const zonedStartDate = utcToZonedTime(startDate, timezone);
    const zonedDueDate = utcToZonedTime(dueDate, timezone);
    zonedStartDate.setHours(0, 0, 0, 0);
    zonedDueDate.setHours(0, 0, 0, 0);
    const existing: { [key: string]: boolean } = {};
    for (
      let t = zonedStartDate;
      t <= zonedDueDate;
      t.setDate(t.getDate() + 1)
    ) {
      const dateString = t.toLocaleDateString('en-US', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
      });
      const earnedPoints = calculateEarnedPoints(hash, dateString);
      rows.push({
        date: dateString,
        study_sessions: hash[dateString],
        earnedPoints,
      });
      existing[dateString] = true;
    }

    Object.keys(hash).forEach((dateString) => {
      if (dateString === 'student' || dateString === 'total_points') {
        return;
      }
      if (existing[dateString]) {
        return;
      }
      const earnedPoints = calculateEarnedPoints(hash, dateString);
      rows.push({
        date: dateString,
        study_sessions: hash[dateString],
        earnedPoints,
      });
    });
  }
  return (
    <Dialog
      {...dialogProps}
      className={classes.root}
      PaperProps={{
        className: classes.paper,
      }}
      fullScreen
      scroll="paper"
    >
      <DialogTitle className={classes.header}>
        <Typography variant="h2" className={classes.text}>
          Personal Deck Assignment Details
        </Typography>
        <IconButton
          className={classes.closeButton}
          aria-label="close"
          onClick={closeDialog}
          size="large"
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <div className={classes.aggregateContainer}>
          <Aggregate
            style={{ padding: '12px 32px' }}
            label="Target Points"
            count={
              activeDistributedAssignment?.groupsAssignment?.assignment
                .targetDistributedPoints || 0
            }
          />
        </div>
        <div className={classes.aggregateContainer}>
          <Aggregate
            style={{ padding: '12px 32px' }}
            label="Total Points Earned"
            count={activeDistributedAssignment?.distributedPointsEarned || 0}
          />
        </div>
        <div className={classes.tableContainer}>
          <CustomDataTable
            title="Study Session Details"
            columns={[
              { label: 'Date', name: 'date', options: { sort: false } },
              {
                label: 'Study Sessions',
                name: 'study_sessions',
                options: {
                  sort: false,
                  customBodyRender: (value) => {
                    if (!value) {
                      return '';
                    }
                    return (
                      <div>
                        {value.map(
                          (
                            session: {
                              id: string;
                              total_correct: number;
                              total_questions: number;
                            },
                            index: number
                          ) => {
                            return (
                              <div key={session.id}>
                                <button
                                  className={classes.buttonLink}
                                  onClick={handleOpen(session.id)}
                                >
                                  {`${
                                    session.total_questions === 0
                                      ? 'Free Session'
                                      : `${session.total_correct} / ${session.total_questions}`
                                  } (${
                                    index === 0 ? '1 point' : '0.5 points'
                                  })`}
                                </button>
                              </div>
                            );
                          }
                        )}
                      </div>
                    );
                  },
                },
              },
              {
                label: 'Points Earned',
                name: 'earnedPoints',
                options: { sort: false },
              },
            ]}
            options={{
              search: false,
              pagination: false,
              rowHover: false,
              elevation: 0,
            }}
            loading={false}
            data={rows}
          />
        </div>
      </DialogContent>
      <ResultsModal
        open={!!personalDeckStudySessionId}
        loading={studySessionLoading}
        assignmentLoading={studySessionLoading}
        handleClose={handleClose}
        data={
          studySessionData
            ? {
                studentsAssignmentsQuestions:
                  studySessionData.studySessionResponses,
              }
            : undefined
        }
        assignmentData={
          studySessionInfo
            ? {
                studentsAssignmentsDatum: {
                  student: {
                    fullName: activeEnrollment?.student.fullName || '',
                  },
                  groupsAssignment: {
                    assignment: { name: 'Personal Deck Session' },
                    dueDate: studySessionInfo.studySessionById.finishedAt,
                    group: { name: activeEnrollment?.group?.name || '' },
                  },
                  questionsTotal:
                    studySessionInfo.studySessionById.totalQuestions,
                  questionsCompleted:
                    studySessionInfo.studySessionById.numCompleted,
                  questionsCorrect:
                    studySessionInfo.studySessionById.totalCorrect,
                  questionsAttempted:
                    studySessionInfo.studySessionById.totalQuestions,
                },
              }
            : undefined
        }
      />
    </Dialog>
  );
}
