import type { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import type { DeltaOperation } from 'quill';
import type { ComponentPropsWithoutRef } from 'react';
import { forwardRef } from 'react';
import { quillDeltaOperationsToHTMLString } from '../../utils/quillHelper';
import { Textbox } from './Textbox';

type StyleProps = { multipleChoice?: boolean; zoomed?: boolean };

const useStyles = makeStyles((theme: Theme) => ({
  html: {
    overflowWrap: 'break-word',
    wordWrap: 'break-word',
    borderRadius: 4,
    whiteSpace: 'pre-wrap',
    padding: theme.spacing(1),
    color: 'black',
    zoom: ({ zoomed }: StyleProps) => (zoomed ? 1.5 : 1),
    lineHeight: ({ zoomed }: StyleProps) => (zoomed ? '16px' : 'inherit'),
    background: ({ multipleChoice }: StyleProps) =>
      multipleChoice ? 'inherit' : theme.palette.common.white,
    '& img': {
      margin: '0 auto',
      display: 'inherit',
      maxWidth: '100%',
    },
    '& pre': {
      whiteSpace: 'pre-wrap',
      wordWrap: 'break-word',
    },
    '& .katex': {
      position: 'relative',
    },
    '& p': {
      margin: ({ multipleChoice }: StyleProps) => (multipleChoice ? 0 : 'auto'),
    },
    '& a': {
      color: '#0000EE',
      textDecoration: 'underline',
    },
  },
}));

type QuillDeltaAsHtmlProps = {
  delta: DeltaOperation[];
  multipleChoice?: boolean;
  zoomed?: boolean;
} & ComponentPropsWithoutRef<'div'>;

export const QuillDeltaAsHtml = forwardRef<
  HTMLDivElement,
  QuillDeltaAsHtmlProps
>(({ delta, multipleChoice, zoomed, ...props }: QuillDeltaAsHtmlProps, ref) => {
  const classes = useStyles({ multipleChoice, zoomed });

  // TODO: If the given `delta` props are are treated _immutably_ and not
  //       modified via methods like `push` or `splice`, we should cache this
  //       computation with `useMemo` since the conversion is expensive by
  //       converting a potentially large payload to HTML elements, serializing
  //       that to a string and then passing it back to React only to convert it
  //       back to HTML elements again.
  const deltaHTMLString = quillDeltaOperationsToHTMLString(delta);

  return (
    <Textbox
      {...props}
      ref={ref}
      style={
        multipleChoice ? { padding: '2px 4px', ...props.style } : props.style
      }
      className={`${classes.html} ${props.className ? props.className : ''}`}
      htmlText={deltaHTMLString}
    ></Textbox>
  );
});

QuillDeltaAsHtml.displayName = 'QuillDeltaAsHtml';
