/**
 * @module Globals
 *
 * This module exists to encapsulate all the _global_ side-effects needed to
 * properly use this application.
 */

import katex from 'katex';
import 'mathquill/build/mathquill.css';
import 'mathquill4quill/mathquill4quill.css';
import mathquill from 'react-mathquill';
import 'react-quill/dist/quill.snow.css';
import './index.css';

declare global {
  interface Window {
    katex: typeof import('katex');
    mathquill: typeof import('react-mathquill');
  }
}

/**
 * This is needed for the Quill editor to work correctly. Requiring the
 * "formula" module when configuring the editor has Quill search for the global
 * `katex` on the `window` object, throwing an error when it is not found.
 * Therefore, this side-effect must take place before any Quill editor using the
 * formula module is instantiated.
 */
window.katex = katex;
window.mathquill = mathquill;
