import { HelpKitButton } from '../HelpKitButton';

export function HelpKitPersonalDeck() {
  return (
    <HelpKitButton
      label="How do XP and the Personal Deck work?"
      articleId="1rhiJetL6tGyiKv6XNWpAT"
    />
  );
}
