import { Typography, type Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useContext } from 'react';
import { AppContext } from '../../../AppContext';
import { HelpKitXP } from '../HelpKit/HelpKitArticles/HelpKitXP';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
    display: 'flex',
  },
  helpkitContainer: {
    marginLeft: theme.spacing(1),
  },
}));

export function CurrentXp() {
  const classes = useStyles();
  const {
    appState: { activeEnrollment },
  } = useContext(AppContext);
  return (
    <div className={classes.root}>
      <Typography variant="h5" color="primary">{`Current Total XP: ${
        activeEnrollment?.points || 0
      }`}</Typography>
      <span className={classes.helpkitContainer}>
        (<HelpKitXP />)
      </span>
    </div>
  );
}
