import type { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useContext } from 'react';
import { AppContext } from '../../../../AppContext';
import { Leaderboard } from './Leaderboard';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(4),
  },
}));

export function LeaderboardContainer() {
  const classes = useStyles();
  const { activeEnrollment } = useContext(AppContext).appState;
  if (!activeEnrollment || !activeEnrollment.group) return null;

  const groupId = activeEnrollment.group.id;
  const enrollmentPoints = activeEnrollment.points;

  return (
    <div className={classes.root}>
      <Leaderboard groupId={groupId} enrollmentPoints={enrollmentPoints} />
    </div>
  );
}
