import { useQuery } from '@apollo/client/react/hooks/useQuery';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, Grid, Skeleton, Typography, useTheme } from '@mui/material';
import { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import { DeckAssignmentCard } from '../../components/application/Assignments/Home/PersonalDeckAssignmentCard';
import { DeckPracticeCard } from '../../components/application/Assignments/Home/PersonalDeckPracticeCard';
import { StandardAssignmentCard } from '../../components/application/Assignments/Home/StandardAssignmentCard';
import { EmptyState } from '../../components/application/EmptyStates/Home/EmptyState';
import { PersonalDeckAssignmentResults } from '../../components/application/Results/PersonalDeckAssignmentResults';
import { NumPersonalQuestionsDueDocument } from '../../gql/queries/__generated__/enrollment.generated';
import { ActiveStudySessionDocument } from '../../gql/queries/__generated__/personalDeckStudySession.generated';
import {
  StudentsAssignmentsDataDocument,
  StudentsAssignmentsDataQuery,
} from '../../gql/queries/__generated__/studentsAssignmentsDatum.generated';
import {
  filterActiveAssignments,
  getDueDate,
  getHighestPriorityDeckAssignment,
  getStatus,
  getUnderlyingAssignment,
  isPersonalDeckAssignment,
  isStandardAssignment,
  isViewable,
} from '../../utils/assignments';

export function HomePage() {
  const theme = useTheme();
  const {
    appState: { activeEnrollment },
  } = useContext(AppContext);
  const assignmentsQuery = useQuery(StudentsAssignmentsDataDocument, {
    skip: activeEnrollment?.groupId === undefined,
    variables: { groupId: activeEnrollment?.groupId || '' },
    fetchPolicy: 'cache-and-network',
  });

  const studySessionQuery = useQuery(ActiveStudySessionDocument, {
    skip: !activeEnrollment,
    variables: {
      enrollmentId: activeEnrollment?.id || '',
    },
    fetchPolicy: 'cache-and-network',
  });

  const personalDeckQuery = useQuery(NumPersonalQuestionsDueDocument, {
    skip: !activeEnrollment,
    variables: {
      enrollmentId: activeEnrollment?.id || '',
    },
    fetchPolicy: 'cache-and-network',
  });

  const [showProgress, setShowProgress] = useState(false);

  if (!activeEnrollment) {
    return (
      <Box sx={{ padding: '2em' }}>
        <Typography variant="h6" color="primary">
          You currently do not have an active class enabled. Please go to the{' '}
          <Link
            style={{
              textDecoration: 'underline',
              color: theme.palette.secondary.main,
            }}
            to="/account"
          >
            My Account
          </Link>{' '}
          page to either enroll in a class or set a current class as
          &apos;active&apos;!
        </Typography>
      </Box>
    );
  }

  if (
    assignmentsQuery.loading ||
    personalDeckQuery.loading ||
    studySessionQuery.loading
  )
    return <Skeleton width={'100%'} />;

  if (
    !assignmentsQuery.data ||
    !personalDeckQuery.data ||
    !studySessionQuery.data
  )
    return null;

  const viewableAssignments =
    assignmentsQuery.data.studentsAssignmentsData.filter(isViewable);
  const deckAssignments = viewableAssignments.filter(isPersonalDeckAssignment);
  const standardAssignments = viewableAssignments.filter(isStandardAssignment);
  const noAssignmentsOrDeckQuestions =
    viewableAssignments.length == 0 &&
    personalDeckQuery.data.enrollment.numDuePersonalDecksQuestions == 0;
  if (noAssignmentsOrDeckQuestions) return <EmptyState />;

  const deckFrozen =
    activeEnrollment?.group?.freezePersonalDeck &&
    !activeEnrollment.unfreezePersonalDeck;
  // Personal Deck Card defaults to Deck Practice, unless there is an active Deck Assignment
  let personalDeckCard: JSX.Element = (
    <DeckPracticeCard
      completedSessionQuestions={
        studySessionQuery.data.activeStudySession?.numCompleted || 0
      }
      totalSessionQuestions={
        studySessionQuery.data.activeStudySession?.totalQuestions || 0
      }
      totalDeckQuestions={
        personalDeckQuery.data.enrollment.numDuePersonalDecksQuestions || 0
      }
      points={personalDeckQuery.data.enrollment.points}
    />
  );

  const currentDeckAssignment =
    getHighestPriorityDeckAssignment(deckAssignments);

  const renderDeckAssignmentCard = (
    assignment: StudentsAssignmentsDataQuery['studentsAssignmentsData'][0],
    hideXP = false
  ) => {
    return (
      <DeckAssignmentCard
        hideXP={hideXP}
        onClickShowProgress={() => {
          setShowProgress(true);
        }}
        studySessionActive={!!studySessionQuery.data?.activeStudySession}
        assignmentName={getUnderlyingAssignment(assignment).name}
        dueDate={getDueDate(assignment)}
        status={getStatus(assignment)}
        targetPoints={
          assignment.groupsAssignment?.assignment.targetDistributedPoints || 0
        }
        currentPoints={assignment.distributedPointsEarned || 0}
        totalSessionQuestions={
          studySessionQuery.data?.activeStudySession?.totalQuestions || 0
        }
        totalQuestions={
          personalDeckQuery.data?.enrollment.numDuePersonalDecksQuestions || 0
        }
        completedQuestions={
          studySessionQuery.data?.activeStudySession?.numCompleted || 0
        }
        points={personalDeckQuery.data?.enrollment.points || 0}
        frozen={deckFrozen || false}
        id={assignment.groupsAssignment?.id || ''}
        freeSessionAvailable={false}
      />
    );
  };

  if (currentDeckAssignment) {
    personalDeckCard = renderDeckAssignmentCard(currentDeckAssignment, true);
  }

  return (
    <Grid container padding={6} spacing={6}>
      <Grid
        item
        sm={12}
        lg={6}
        xl={4}
        display={'flex'}
        flexDirection={'column'}
        gap={theme.spacing(2)}
      >
        <Typography
          display={'flex'}
          alignItems={'center'}
          gap={theme.spacing(1)}
          fontWeight={'normal'}
          textTransform={'capitalize'}
          variant={'h2'}
          component={'h2'}
        >
          Personal Deck{' '}
          <InfoOutlinedIcon
            sx={{ cursor: 'pointer' }}
            onClick={(e) => {
              e.preventDefault();
              window.Helpkit.show(
                'popover',
                'article',
                'nog8MHD4MCDdDzVNQhkopY'
              );
            }}
          />
        </Typography>
        <Typography fontWeight={'light'}>
          All of your assignment questions in one place. Review to keep
          learning! More practice = more XP{' '}
          <Typography
            sx={{
              display: 'inline',
              height: '1rem',
              cursor: 'pointer',
              fontWeight: 'bold',
              textTransform: 'capitalize',
              fontSize: '16px',
            }}
            onClick={(e) => {
              e.preventDefault();
              window.Helpkit.show(
                'popover',
                'article',
                'j9iKz7DzLkqe7A7vZX2sp3'
              );
            }}
          >
            Learn more
          </Typography>
        </Typography>
        {personalDeckCard}
      </Grid>
      <Grid item sm={12} lg={6} xl={8} container>
        <Grid
          item
          sm={12}
          lg={12}
          display={'flex'}
          flexDirection={'column'}
          gap={theme.spacing(2)}
          sx={{ width: '100%' }}
        >
          <Typography
            fontWeight={'normal'}
            textTransform={'capitalize'}
            variant={'h2'}
            component={'h2'}
          >
            Current Assignments
          </Typography>
          <Typography
            fontWeight={'light'}
            sx={{ width: { sm: '100%', xl: '50%' } }}
          >
            Current assignments that you need to complete. To see all of your
            assignments, go to the{' '}
            <Link style={{ fontWeight: 'bold' }} to="/assignments">
              Assignments page
            </Link>
            .
          </Typography>
          <Grid item sm={12} container spacing={3} sx={{ flexWrap: 'wrap' }}>
            {filterActiveAssignments(standardAssignments).map(
              (assignment, index) => (
                <Grid item md={12} lg={12} xl={6} key={index}>
                  <StandardAssignmentCard
                    id={getUnderlyingAssignment(assignment).id}
                    assignmentName={getUnderlyingAssignment(assignment).name}
                    dueDate={getDueDate(assignment)}
                    status={getStatus(assignment)}
                    completedQuestions={assignment.questionsCompleted}
                    totalQuestions={assignment.questionsTotal}
                  />
                </Grid>
              )
            )}
          </Grid>
        </Grid>
      </Grid>
      <PersonalDeckAssignmentResults
        open={showProgress}
        onClose={() => {
          setShowProgress(false);
        }}
        closeDialog={() => {
          setShowProgress(false);
        }}
        activeDistributedAssignment={currentDeckAssignment}
      />
    </Grid>
  );
}
