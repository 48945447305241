import { useMutation } from '@apollo/client';
import { LinearProgress, Typography, type Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import type { Sources } from 'quill';
import React, {
  useContext,
  useState,
  type Dispatch,
  type SetStateAction,
} from 'react';
import { Quill, type UnprivilegedEditor, type Value } from 'react-quill';
import {
  CreateShortAnswerResponseDocument,
  ShortAnswerTypoCheckDocument,
} from '../../../../gql/mutations/__generated__/response.generated';
import type { Question } from '../../../../gql/types';
import { onError } from '../../../../utils/apollo/apolloHelper';
import { isRichText } from '../../../../utils/question';
import { extractPlainAndRichText } from '../../../../utils/quillHelper';
import { StandardButton } from '../../../shared/Buttons/StandardButton';
import { InlineEditor } from '../../../shared/Editor/inline';
import { AlertsContext } from '../../Alerts/context';
import { openDialog, pushSnack } from '../../Alerts/context/actions';
import { StudySessionContext } from '../../StudySession/context';
import { useExperimentCheck } from './useExperimentCheck';

const Delta = Quill.import('delta');

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  responseField: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
    width: '50%',
    [theme.breakpoints.down('md')]: {
      width: '80%',
    },
  },
  textFieldContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  form: {
    height: '100%',
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'space-between',
  },
  formFields: {
    padding: theme.spacing(3),
  },
  progress: {
    marginTop: theme.spacing(1),
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

type ShortAnswerProps = {
  handleShow?: () => void;
  isPersonal?: boolean;
  reviewQuestion?: Question;
  setSupplementOpen: Dispatch<SetStateAction<boolean>>;
};

export function ShortAnswer({
  setSupplementOpen,
  isPersonal = false,
  reviewQuestion,
  handleShow,
}: ShortAnswerProps) {
  const DEFAULT_EMPTY_RICH_TEXT = new Delta({ ops: [] });
  const MAX_RESPONSE_LENGTH = 40;
  const [submitted, setSubmitted] = useState(false);
  const classes = useStyles();
  const { dispatch } = useContext(AlertsContext);
  const contextValues = useContext(StudySessionContext);
  const { isPrePostTest } = useExperimentCheck();
  const question =
    reviewQuestion !== undefined ? reviewQuestion : contextValues.question;
  const {
    getNextQuestion,
    feedbackVisible,
    setFeedbackVisible,
    setIsCorrect,
    setIsRetry,
    groupsAssignmentId,
    enrollmentsAssignmentId,
    personalDecksQuestionId,
    personalDeckStudySessionId,
    setResponseMeta,
  } = contextValues;

  const [isShortAnswerTypo] = useMutation(ShortAnswerTypoCheckDocument, {
    onError: onError(dispatch, true),
  });

  interface ComponentState {
    canSubmit: boolean;
    response: string;
    richText: unknown;
    formError: boolean;
    maxResponse: boolean;
  }

  const initialComponentState: ComponentState = {
    canSubmit: true,
    response: '',
    richText: DEFAULT_EMPTY_RICH_TEXT,
    formError: false,
    maxResponse: false,
  };

  const [componentState, setComponentState] = useState(initialComponentState);

  const [createShortAnswerResponse, { loading }] = useMutation(
    CreateShortAnswerResponseDocument,
    {
      onError: onError(dispatch, true),
      variables: {
        answer: componentState.response,
        richText: componentState.richText,
        groupsAssignmentId,
        personalDecksQuestionId,
        personalDeckStudySessionId,
        enrollmentsAssignmentId,
        questionId: question?.id || '',
      },
      onCompleted: (data) => {
        if (isPrePostTest) {
          getNextQuestion();
          return;
        }
        setFeedbackVisible(true);
        setComponentState((currentState) => ({
          ...currentState,
          canSubmit: true,
        }));
        const isCorrect = data.createShortAnswerResponse.isCorrect;
        setIsCorrect(isCorrect);
        if (!isCorrect) {
          setSupplementOpen(true);
        }
        if (question) {
          setResponseMeta({
            id: data.createShortAnswerResponse.id,
            type: question.questionType,
            numPriorResponses: data.createShortAnswerResponse.numPriorResponses,
          });
        }
        if (isPersonal && isCorrect) {
          dispatch(
            pushSnack({
              message: "You've earned 1 XP for your successful recall effort!",
              severity: 'success',
            })
          );
        }
      },
    }
  );

  const handleSubmit = (
    e: React.FormEvent<HTMLFormElement> | React.KeyboardEvent
  ) => {
    e.preventDefault();
    // prevent multiple submissions without going to the next question:
    if (submitted) {
      dispatch(
        pushSnack({
          message: 'Please click "Next Question" to continue.',
          severity: 'error',
          horizontal: 'center',
        })
      );
      return;
    }
    if (componentState.response.length > 0 && question) {
      setComponentState((currentState) => ({
        ...currentState,
        canSubmit: false,
      }));

      if (reviewQuestion === undefined) {
        isShortAnswerTypo({
          variables: {
            answer: componentState.response,
            richText: componentState.richText,
            groupsAssignmentId,
            personalDecksQuestionId,
            enrollmentsAssignmentId,
            questionId: question.id,
          },
        })
          .then((result) => {
            if (result?.data?.shortAnswerTypoCheck.result) {
              setIsRetry(true);
              setFeedbackVisible(true);
              setComponentState((currentState) => ({
                ...currentState,
                canSubmit: true,
              }));
            } else {
              createShortAnswerResponse();
              setSubmitted(true);
            }
          })
          .catch((err) => {
            console.error(err);
            dispatch(
              openDialog({
                title: 'Error occurred.',
                message:
                  'Something went wrong. Please refresh your page and try again.',
                error: true,
              })
            );
          });
      } else {
        handleShow && handleShow();
      }
    } else {
      setComponentState((currentState) => ({
        ...currentState,
        formError: true,
      }));
    }
  };

  const handleOnChange = (
    value: string,
    delta: Value,
    source: Sources,
    editor: UnprivilegedEditor
  ) => {
    const editorContents = editor.getContents();
    const { text, richText } = extractPlainAndRichText(editorContents);
    // check last character of text. If it's a newline, then it means that
    // the user pressed enter and is trying to submit the answer.

    if (text[text.length - 1] === '\n') {
      handleSubmit(
        new KeyboardEvent('keydown', {
          key: 'Enter',
        }) as unknown as React.KeyboardEvent
      );
      return;
    }

    if (text.length > MAX_RESPONSE_LENGTH) {
      setComponentState((currentState) => ({
        ...currentState,
        maxResponse: true,
        response: text,
        richText,
        canSubmit: false,
      }));
      return;
    }

    setComponentState((currentState) => ({
      ...currentState,
      maxResponse: false,
      response: text,
      richText,
      canSubmit: true,
    }));
  };

  const handleEnterKey = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      handleSubmit(e);
    }
  };

  const getErrorText = () => {
    if (componentState.formError) {
      return "Answer can't be blank.";
    } else if (componentState.maxResponse) {
      return 'Answer exceeds max limit of 40 characters!';
    } else {
      return '';
    }
  };

  return (
    <form className={classes.form} onSubmit={handleSubmit}>
      <div className={classes.formFields}>
        <Typography variant="h4" color="primary">
          Type in your answer below:
        </Typography>
        <div className={classes.textFieldContainer}>
          <div style={{ margin: '15px 0' }}>
            <InlineEditor
              value={
                isRichText(componentState.richText)
                  ? (componentState.richText as Value)
                  : undefined
              }
              onChange={handleOnChange}
              errorMessage={
                componentState.formError || componentState.maxResponse
                  ? getErrorText()
                  : ''
              }
              onKeyDown={handleEnterKey}
            />
          </div>
        </div>
        {!feedbackVisible && (
          <div className={classes.buttonContainer}>
            <StandardButton
              text="CHECK ANSWER"
              type="submit"
              disabled={!componentState.canSubmit}
            />
          </div>
        )}
        {loading && <LinearProgress />}
      </div>
    </form>
  );
}
