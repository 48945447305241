import {
  ExpandMore,
  HelpOutline,
  SentimentSatisfied,
} from '@mui/icons-material';
import { Button, Collapse, Theme, Tooltip, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { useState } from 'react';
import type { QuestionAttributesFragment } from '../../../../gql/fragments/__generated__/question.generated';
import type {
  FreeResponseAttributesFragment,
  MultipleChoiceResponseAttributesFragment,
  ShortAnswerResponseAttributesFragment,
} from '../../../../gql/fragments/__generated__/response.generated';
import { AssessmentValueEnum, QuestionTypeEnum } from '../../../../gql/types';
import theme from '../../../../theme';
import {
  ASSESSMENT_VALUE_IS_CORRECT,
  ASSESSMENT_VALUE_TO_EMOJI,
} from '../../../../utils/assessmentValueMappings';
import { countTotalAttempts } from '../../../../utils/question';
import { Emoji } from '../../../shared/Emoji';
import { QuillDeltaAsHtml } from '../../../shared/QuillDeltaAsHtml';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  collapseOpener: {
    fontWeight: 'bold',
    textDecoration: 'underline',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  buttonContainer: {
    padding: `0 ${theme.spacing(2)} ${theme.spacing(2)} ${theme.spacing(2)}`,
    [theme.breakpoints.down('md')]: {
      padding: `0 ${theme.spacing(1)} ${theme.spacing(2)} ${theme.spacing(1)}`,
    },
  },
  collapseSection: {
    padding: `${theme.spacing(1.5)} ${theme.spacing(2)}`,
    backgroundColor: theme.palette.mint.main,
  },
  list: {
    paddingLeft: theme.spacing(1),
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: theme.spacing(1),
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
    color: theme.palette.secondary.main,
  },
  noBullets: {
    listStyleType: 'none',
    marginBottom: theme.spacing(1),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  expandButton: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: '',
  },
  attemptLabelContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  selectAllContainer: {
    // matching the styles of the other question types, which have a ul element
    // as a container:
    paddingLeft: theme.spacing(1),
    margin: '14px 0',
  },
  freeResponseLabelContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  selfRatingLabel: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  emojiLabelContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  incompleteLabel: {
    color: theme.palette.error.main,
  },
  incompleteSpan: {
    display: 'flex',
    alignItems: 'center',
  },
  helpIcon: {
    color: theme.palette.secondary.main,
    fontSize: 16,
  },
  overrideIcon: {
    fontSize: '1.2rem',
    color: theme.palette.error.main,
  },
  richTextContainer: {
    marginTop: theme.spacing(1),
    paddingLeft: theme.spacing(1),
  },
}));

const useAttemptTypographyStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'inline-block',
    marginRight: theme.spacing(1),
  },
}));

function AttemptTypography({ text }: { text: string }) {
  const classes = useAttemptTypographyStyles();
  return (
    <Typography variant="h4" className={classes.root}>
      {text}
    </Typography>
  );
}

type AttemptsProps = {
  question: QuestionAttributesFragment;
  freeResponseResponses?: FreeResponseAttributesFragment[] | null;
  shortAnswerResponses?: ShortAnswerResponseAttributesFragment[] | null;
  multipleChoiceResponses?: MultipleChoiceResponseAttributesFragment[] | null;
};

export function Attempts({
  question,
  freeResponseResponses,
  shortAnswerResponses,
  multipleChoiceResponses,
}: AttemptsProps) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  if (
    !freeResponseResponses ||
    !shortAnswerResponses ||
    !multipleChoiceResponses
  ) {
    return null;
  }

  const attempts = () => {
    switch (question.questionType) {
      case QuestionTypeEnum.FreeResponse:
        return (
          <ul className={classes.list}>
            {freeResponseResponses
              .slice()
              .sort(
                (a, b) =>
                  new Date(a.createdAt).getTime() -
                  new Date(b.createdAt).getTime()
              )
              .map(
                (
                  { selfAssessment, richText, teacherAssessment, autograded },
                  i
                ) => {
                  let emoji, label;
                  if (selfAssessment) {
                    ({ emoji, label } =
                      ASSESSMENT_VALUE_TO_EMOJI(selfAssessment));
                  }
                  let teacherEmoji, teacherLabel;
                  if (teacherAssessment) {
                    ({ emoji: teacherEmoji, label: teacherLabel } =
                      ASSESSMENT_VALUE_TO_EMOJI(teacherAssessment));
                  }

                  return (
                    <li
                      className={classes.noBullets}
                      key={`freeResponseAttempt-${i}`}
                    >
                      <div className={classes.freeResponseLabelContainer}>
                        <Typography variant="h4">{`ATTEMPT ${
                          i + 1
                        }`}</Typography>
                        {emoji && (
                          <Typography className={classes.emojiLabelContainer}>
                            <span className={classes.selfRatingLabel}>
                              {`(${
                                autograded ? 'Auto-Graded' : 'Self-Rating'
                              }:`}
                            </span>
                            <Emoji symbol={emoji} label={label} />
                            <span>)</span>
                          </Typography>
                        )}{' '}
                        {teacherEmoji && (
                          <Typography className={classes.emojiLabelContainer}>
                            <span className={classes.selfRatingLabel}>
                              (Override-Rating:
                            </span>
                            <Emoji symbol={teacherEmoji} label={teacherLabel} />
                            <span>)</span>
                          </Typography>
                        )}
                      </div>
                      <div className={classes.richTextContainer}>
                        <QuillDeltaAsHtml delta={richText.ops} />
                      </div>
                    </li>
                  );
                }
              )}
          </ul>
        );
      case QuestionTypeEnum.ShortAnswer:
        return (
          <ul className={classes.list}>
            {shortAnswerResponses
              .slice()
              .sort(
                (a, b) =>
                  new Date(a.createdAt).getTime() -
                  new Date(b.createdAt).getTime()
              )
              .map(({ answer }, i) => {
                return (
                  <li
                    className={classes.noBullets}
                    key={`shortAnswerAttempt-${i}`}
                  >
                    <AttemptTypography text={`ATTEMPT ${i + 1}: `} />
                    {answer}
                  </li>
                );
              })}
          </ul>
        );

      case QuestionTypeEnum.SelectAllMultipleChoice:
        return (
          <div className={classes.selectAllContainer}>
            {multipleChoiceResponses
              .slice()
              .sort(
                (a, b) =>
                  new Date(a.createdAt).getTime() -
                  new Date(b.createdAt).getTime()
              )
              .map(({ selectedMultipleChoiceAnswerChoices }, i) => {
                return (
                  <div key={`mcAttempt-${i}`}>
                    <AttemptTypography
                      text={`ATTEMPT ${i + 1} - selected choice(s):`}
                    />
                    <ul>
                      {selectedMultipleChoiceAnswerChoices.length ? (
                        selectedMultipleChoiceAnswerChoices.map(
                          ({
                            multipleChoiceAnswerChoice: { id, text, richText },
                          }) => {
                            return (
                              <li key={`choice-${id}`}>
                                {' '}
                                {richText ? (
                                  <QuillDeltaAsHtml
                                    multipleChoice
                                    delta={richText.ops}
                                  />
                                ) : (
                                  <div>{text}</div>
                                )}
                              </li>
                            );
                          }
                        )
                      ) : (
                        <li>
                          <strong>None of the above</strong>
                        </li>
                      )}
                    </ul>
                  </div>
                );
              })}
          </div>
        );
      case QuestionTypeEnum.SelectOneMultipleChoice:
        return (
          <ul className={classes.list}>
            {multipleChoiceResponses.map(
              ({ selectedMultipleChoiceAnswerChoices }, i) => {
                if (selectedMultipleChoiceAnswerChoices.length === 0) {
                  return null;
                }
                return (
                  <li
                    className={classes.noBullets}
                    key={`singleMcAttempt-${i}`}
                  >
                    <AttemptTypography text={`ATTEMPT ${i + 1}:`} />
                    <span>
                      {selectedMultipleChoiceAnswerChoices[0]
                        .multipleChoiceAnswerChoice.richText ? (
                        <QuillDeltaAsHtml
                          multipleChoice
                          delta={
                            selectedMultipleChoiceAnswerChoices[0]
                              .multipleChoiceAnswerChoice.richText.ops
                          }
                        />
                      ) : (
                        <div>
                          {
                            selectedMultipleChoiceAnswerChoices[0]
                              .multipleChoiceAnswerChoice.text
                          }
                        </div>
                      )}
                    </span>
                  </li>
                );
              }
            )}
          </ul>
        );

      default:
        return null;
    }
  };

  const complete =
    freeResponseResponses.some(
      (response) =>
        response.selfAssessment === AssessmentValueEnum.Three ||
        response.selfAssessment === AssessmentValueEnum.Four ||
        response.selfAssessment === AssessmentValueEnum.Five
    ) ||
    multipleChoiceResponses.some((mcr) => mcr.isCorrect) ||
    shortAnswerResponses.some((sar) => sar.isCorrect);
  const numAttempts = countTotalAttempts({
    freeResponseResponses,
    shortAnswerResponses,
    multipleChoiceResponses,
  });
  const teacherOverride = freeResponseResponses.some((response) => {
    return (
      response.teacherAssessment &&
      !ASSESSMENT_VALUE_IS_CORRECT(response.teacherAssessment) &&
      response.teacherAssessment !== response.selfAssessment
    );
  });
  const labelStyle = {
    color:
      teacherOverride || numAttempts > 1 || (numAttempts === 1 && !complete)
        ? theme.palette.error.main
        : theme.palette.success.main,
  };
  return (
    <div className={classes.root}>
      <div className={classes.buttonContainer}>
        <Button onClick={() => setOpen(!open)} className={classes.expandButton}>
          <span className={classes.attemptLabelContainer}>
            <Typography
              color="secondary"
              variant="body1"
              style={labelStyle}
              className={classes.collapseOpener}
            >
              {`# of Attempts: ${countTotalAttempts({
                freeResponseResponses,
                shortAnswerResponses,
                multipleChoiceResponses,
              })}`}
            </Typography>
            {numAttempts > 0 && (
              <ExpandMore
                className={clsx(classes.expand, {
                  [classes.expandOpen]: open,
                })}
                style={labelStyle}
              />
            )}{' '}
            {teacherOverride && (
              <Tooltip title="Teacher gave a different rating than student.">
                <SentimentSatisfied className={classes.overrideIcon} />
              </Tooltip>
            )}
          </span>
          {!complete && (
            <Tooltip
              enterTouchDelay={0}
              title="A question is not complete until it has been answered correctly."
            >
              <span className={classes.incompleteSpan}>
                <HelpOutline className={classes.helpIcon} />
                <Typography variant="body1" className={classes.incompleteLabel}>
                  Incomplete
                </Typography>
              </span>
            </Tooltip>
          )}
        </Button>
      </div>
      {numAttempts > 0 && (
        <Collapse className={classes.collapseSection} in={open}>
          {attempts()}
        </Collapse>
      )}
    </div>
  );
}
