import { Close } from '@mui/icons-material';
import { IconButton, Typography, type Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    color: theme.palette.primary.main,
  },
  header: {
    backgroundColor: theme.palette.common.white,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: theme.spacing(1.5),
  },
}));

type StudySessionHeaderProps = {
  name: string;
  handleClose: () => void;
};

export function StudySessionHeader({
  name,
  handleClose,
}: StudySessionHeaderProps) {
  const classes = useStyles();

  return (
    <header className={classes.header}>
      <Typography variant="h5" className={classes.title}>
        {name}
      </Typography>
      <IconButton aria-label="close" onClick={handleClose} size="large">
        <Close />
      </IconButton>
    </header>
  );
}
