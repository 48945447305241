import type { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { StudySession } from '.';
import { AssignmentFinished } from '../Assignments/StandardAssignment/AssignmentFinished';
import { StudySessionHeader } from './header';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    [theme.breakpoints.up('sm')]: {
      backgroundImage: "url('/images/study-session-background.svg')",
      backgroundSize: 'cover',
    },
    display: 'block',
    height: '100vh',
    overflow: 'auto',
    backgroundColor: theme.palette.background.default,
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
  },
  sessionContainer: {
    marginTop: theme.spacing(7),
    width: 1400,
    [theme.breakpoints.down('xl')]: {
      width: '80%',
      marginTop: theme.spacing(6),
    },
    [theme.breakpoints.down('lg')]: {
      width: '87%',
      marginTop: theme.spacing(5),
    },
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(3),
      width: '90%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginTop: theme.spacing(1),
    },
  },
}));

type StudySessionContainerProps = {
  handleClose: () => void;
  name: string;
  assignmentFinished: boolean;
  questionNum: number;
  isPersonal?: boolean;
};

export function StudySessionContainer({
  questionNum,
  handleClose,
  name,
  assignmentFinished,
  isPersonal = false,
}: StudySessionContainerProps) {
  const classes = useStyles();

  return (
    <>
      <StudySessionHeader name={name} handleClose={handleClose} />
      <main className={classes.root}>
        <div className={classes.container}>
          {assignmentFinished ? (
            <AssignmentFinished name={name} />
          ) : (
            <div className={classes.sessionContainer}>
              <StudySession questionNum={questionNum} isPersonal={isPersonal} />
            </div>
          )}
        </div>
      </main>
    </>
  );
}
