import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { useContext } from 'react';
import { AlertsContext } from './context';
import { closeConfirmation } from './context/actions';

export function AlertsConfirmation() {
  const {
    alerts: { confirmation },
    dispatch,
  } = useContext(AlertsContext);
  const {
    message,
    maxWidth = 'lg',
    confirmButtonText = 'OK',
    confirmFunc,
    cancelFunc = () => console.log('Action cancelled.'),
  } = confirmation;

  const handleClose = () => {
    cancelFunc();
    dispatch(closeConfirmation());
  };

  const handleConfirm = () => {
    confirmFunc();
    dispatch(closeConfirmation());
  };

  return (
    <Dialog
      maxWidth={maxWidth}
      disableScrollLock
      fullWidth={true}
      open={message.length > 0}
      onClose={handleClose}
    >
      <DialogTitle>Confirm</DialogTitle>
      <DialogContent>{message}</DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleConfirm} color="primary">
          {confirmButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
