import { Theme, Typography, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { buttonLink } from '../../../../assets/shared-styles/Button-Link';
import type { QuestionAttributesFragment } from '../../../../gql/fragments/__generated__/question.generated';
import {
  FreeResponseResponse,
  Maybe,
  MultipleChoiceAnswerChoice,
  MultipleChoiceResponse,
  QuestionTypeEnum,
  SelectedMultipleChoiceAnswerChoice,
  ShortAnswerResponse,
} from '../../../../gql/types';
import { secondaryMain } from '../../../../theme';
import { CustomTooltip } from '../../../shared/CustomTooltip';
import { QuillDeltaAsHtml } from '../../../shared/QuillDeltaAsHtml';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.darks.main,
    height: '100%',
    padding: '20px 23px',
  },
  questionContainer: {
    padding: `${theme.spacing(3)} 0`,
    borderBottom: `1px solid ${theme.palette.secondary.main}`,
  },
  richTextTooltip: {
    padding: 0,
  },
  seeMoreButton: {
    ...buttonLink(theme),
    padding: 0,
    marginTop: '20px',
  },
}));

const scrollToRef = (id: string) => {
  const el: HTMLDivElement | null = document.querySelector(
    `#assignment-questions-result-${id}`
  );
  if (el) {
    el.scrollIntoView({ behavior: 'smooth', block: 'start' });
    const originalBackground = el.style.backgroundColor;
    el.style.backgroundColor = secondaryMain;
    setTimeout(() => {
      el.style.backgroundColor = originalBackground;
    }, 1000);
  }
};

type TopRepeatedQuestionsProps = {
  mostRepeated: Array<{
    question: { __typename?: 'Question' } & QuestionAttributesFragment;
    freeResponseResponses?: Maybe<
      Array<
        { __typename?: 'FreeResponseResponse' } & Pick<
          FreeResponseResponse,
          'id' | 'selfAssessment' | 'answer'
        >
      >
    >;
    shortAnswerResponses?: Maybe<
      Array<
        { __typename?: 'ShortAnswerResponse' } & Pick<
          ShortAnswerResponse,
          'id' | 'answer' | 'isCorrect'
        >
      >
    >;
    multipleChoiceResponses?: Maybe<
      Array<
        { __typename?: 'MultipleChoiceResponse' } & Pick<
          MultipleChoiceResponse,
          'id' | 'isCorrect'
        > & {
            selectedMultipleChoiceAnswerChoices: Array<
              { __typename?: 'SelectedMultipleChoiceAnswerChoice' } & Pick<
                SelectedMultipleChoiceAnswerChoice,
                'id'
              > & {
                  multipleChoiceAnswerChoice: {
                    __typename?: 'MultipleChoiceAnswerChoice';
                  } & Pick<MultipleChoiceAnswerChoice, 'id' | 'text'>;
                }
            >;
          }
      >
    >;
  }>;
};

export function TopRepeatedQuestions({
  mostRepeated,
}: TopRepeatedQuestionsProps) {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <div className={classes.root}>
      <Typography variant="h5">
        TOP {mostRepeated.length} MISSED QUESTION(S):
      </Typography>

      {mostRepeated.map(({ question }) => {
        const isMultipleChoice =
          question.questionType === QuestionTypeEnum.SelectAllMultipleChoice ||
          question.questionType === QuestionTypeEnum.SelectOneMultipleChoice;

        const scrollToQuestion = () => scrollToRef(question.id);

        const questionRichTextOrPlainTextTooltip =
          Array.isArray(question.richText?.ops) &&
          question.richText.ops.length > 0 ? (
            <QuillDeltaAsHtml
              className={classes.richTextTooltip}
              delta={question.richText.ops}
              multipleChoice={isMultipleChoice}
              style={{ backgroundColor: theme.palette.common.white }}
            />
          ) : (
            question.plainText
          );

        return (
          <div
            className={classes.questionContainer}
            key={`most-repeated-${question.id}`}
          >
            <CustomTooltip
              placement="top"
              title={questionRichTextOrPlainTextTooltip}
            >
              <Typography noWrap variant="body1">
                {question.plainText}
              </Typography>
            </CustomTooltip>
            <button
              className={classes.seeMoreButton}
              onClick={scrollToQuestion}
            >
              <Typography color="secondary" variant="body1">
                See More
              </Typography>
            </button>
          </div>
        );
      })}
    </div>
  );
}
