import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  type Theme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import type * as React from 'react';
import type { Supplement as SupplementType } from '../../../../gql/types';
import { StandardButton } from '../../../shared/Buttons/StandardButton';
import { QuillDeltaAsHtml } from '../../../shared/QuillDeltaAsHtml';
import { TextToSpeech } from '../../../shared/TextToSpeech';
import { TranslateText } from '../../../shared/TranslateText';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  supplementContainer: {
    backgroundColor: theme.palette.mint.main,
  },
}));

type SupplementProps = {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  open: boolean;
  supplement: SupplementType;
};

export function Supplement({ setOpen, supplement, open }: SupplementProps) {
  const classes = useStyles();

  return (
    <Dialog open={open} fullWidth={true} maxWidth="md" className={classes.root}>
      <DialogTitle>Deeper Learning</DialogTitle>
      <DialogContent>
        <TextToSpeech text={supplement.plainText} />
        <TranslateText delta={supplement.richText.ops} />
        <QuillDeltaAsHtml delta={supplement.richText.ops} />
      </DialogContent>
      <DialogActions>
        <StandardButton onClick={() => setOpen(false)} text="Close" />
      </DialogActions>
    </Dialog>
  );
}
