import { ErrorOutline } from '@mui/icons-material';
import { Chip, type Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  lateBadge: {
    color: theme.palette.error.main,
    border: `1px solid ${theme.palette.error.main}`,
  },
}));

export function LateAssignmentChip() {
  const classes = useStyles();

  return (
    <Chip
      size="small"
      icon={<ErrorOutline />}
      label="Late Assignment"
      className={classes.lateBadge}
    />
  );
}
