import type { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}));

export function NotFound() {
  const classes = useStyles();

  return <div className={classes.root}>Page Not Found</div>;
}
