import { HelpKitButton } from '../HelpKitButton';

export function HelpKitMasteryScore() {
  return (
    <HelpKitButton
      label="How is the 'Mastery' score calculated?"
      articleId="muezz8znqgbC7tqXyVXV7o"
    />
  );
}
