import { makeStyles } from '@mui/styles';
import { EnrollGroup } from '../../components/application/Profile/EnrollGroup';
import { GroupList } from '../../components/application/Profile/GroupList';
import { StudentInfo } from '../../components/application/Profile/StudentInfo';

const useStyles = makeStyles(() => ({
  root: {},
}));

export function Account() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <StudentInfo />
      <EnrollGroup />
      <GroupList />
    </div>
  );
}
