import { Typography, type Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useContext } from 'react';
import { StandardButton } from '../../../shared/Buttons/StandardButton';
import { StudySessionContext } from '../../StudySession/context';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  title: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(2),
    color: theme.palette.primary.main,
  },
  button: {
    backgroundColor: theme.palette.warning.main,
    '&:hover': {
      backgroundColor: theme.palette.warning.dark,
    },
    color: theme.palette.primary.main,
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: theme.spacing(3),
  },
}));

export function ShortAnswerTypoTryAgain() {
  const classes = useStyles();
  const { setFeedbackVisible, setIsRetry } = useContext(StudySessionContext);

  return (
    <div className={classes.root}>
      <Typography variant="h4" className={classes.title}>
        We think you had a misspelling since your answer is very close to being
        correct, so we didn&apos;t mark it as wrong. Try again!
      </Typography>
      <div className={classes.buttonContainer}>
        <div className={classes.button}>
          <StandardButton
            text="Try again"
            onClick={() => {
              setFeedbackVisible(false);
              setIsRetry(false);
            }}
            className={classes.button}
          />
        </div>
      </div>
    </div>
  );
}
