import { Auth0Provider } from '@auth0/auth0-react';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import React from 'react';
import ReactDOM from 'react-dom';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import { App } from './App';
import { IndexProvider } from './IndexContext';
import { AlertsProvider } from './components/application/Alerts/context';
import { ErrorPage } from './components/application/ErrorPages/ErrorPage';
import { config } from './podsie-config';
import './setup-globals';

if (config.sentry) {
  Sentry.init({
    dsn: config.sentry.dsn,
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        ),
      }),
    ],
    tracesSampleRate: 0.2,
  });
}

ReactDOM.render(
  <React.StrictMode>
    <Sentry.ErrorBoundary
      fallback={({ error, componentStack, resetError }) => (
        <ErrorPage
          error={error}
          componentStack={componentStack}
          resetError={resetError}
        />
      )}
    >
      <Auth0Provider
        domain={config.auth0.domain}
        clientId={config.auth0.clientId}
        authorizationParams={{
          audience: config.auth0.audience,
          redirect_uri: window.location.origin,
        }}
      >
        <AlertsProvider>
          <IndexProvider>
            <App />
          </IndexProvider>
        </AlertsProvider>
      </Auth0Provider>
    </Sentry.ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
