import React, { useState } from 'react';
import { QuestionAttributesFragment } from '../../../../gql/fragments/__generated__/question.generated';
import { QuestionTypeEnum } from '../../../../gql/types';

const initialState = {
  question: undefined,
  feedbackVisible: false,
  isRetry: false,
  isCorrect: false,
  questionsCompleted: undefined,
  questionsTotal: undefined,
  loading: false,
  groupsAssignmentId: undefined,
  personalDecksQuestionId: undefined,
  responseMeta: undefined,
  setResponseMeta: () => {},
  setFeedbackVisible: () => {},
  setIsRetry: () => {},
  setIsCorrect: () => {},
  setFreeResponseWithoutSelfAssesmentId: () => {},
  getNextQuestion: () => {},
};

export type ResponseMeta = {
  id: string;
  type: QuestionTypeEnum;
  autograded?: boolean;
  numPriorResponses?: number | null;
};

export const StudySessionContext = React.createContext<{
  question?: ({ __typename?: 'Question' } & QuestionAttributesFragment) | null;
  getNextQuestion: () => void;
  loading: boolean;
  feedbackVisible: boolean;
  isRetry: boolean;
  isCorrect: boolean;
  groupsAssignmentId?: string;
  enrollmentsAssignmentId?: string;
  personalDecksQuestionId?: string;
  personalDeckStudySessionId?: string;
  questionsCompleted?: number;
  responseMeta?: ResponseMeta;
  setResponseMeta: React.Dispatch<
    React.SetStateAction<ResponseMeta | undefined>
  >;
  setFeedbackVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setIsRetry: React.Dispatch<React.SetStateAction<boolean>>;
  setIsCorrect: React.Dispatch<React.SetStateAction<boolean>>;
  setFreeResponseWithoutSelfAssesmentId: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
  questionsTotal?: number;
  freeResponseWithoutSelfAssessmentId?: string;
}>(initialState);

type StudySessionProviderProps = {
  children: React.ReactNode;
  question?: ({ __typename?: 'Question' } & QuestionAttributesFragment) | null;
  getNextQuestion: () => void;
  loading: boolean;
  groupsAssignmentId?: string;
  enrollmentsAssignmentId?: string;
  personalDecksQuestionId?: string;
  personalDeckStudySessionId?: string;
  questionsCompleted?: number;
  questionsTotal?: number;
};

export function StudySessionProvider({
  children,
  question,
  groupsAssignmentId,
  enrollmentsAssignmentId,
  personalDecksQuestionId,
  personalDeckStudySessionId,
  loading,
  getNextQuestion,
  questionsCompleted,
  questionsTotal,
}: StudySessionProviderProps) {
  const [feedbackVisible, setFeedbackVisible] = useState(false);
  const [isRetry, setIsRetry] = useState(false);
  const [isCorrect, setIsCorrect] = useState(false);
  const [responseMeta, setResponseMeta] = useState<ResponseMeta | undefined>();
  const [
    freeResponseWithoutSelfAssessmentId,
    setFreeResponseWithoutSelfAssesmentId,
  ] = useState<undefined | string>(undefined);
  return (
    <StudySessionContext.Provider
      value={{
        responseMeta,
        setResponseMeta,
        question,
        groupsAssignmentId,
        enrollmentsAssignmentId,
        personalDecksQuestionId,
        personalDeckStudySessionId,
        freeResponseWithoutSelfAssessmentId,
        setFreeResponseWithoutSelfAssesmentId,
        feedbackVisible,
        setFeedbackVisible,
        isRetry,
        setIsRetry,
        isCorrect,
        setIsCorrect,
        loading,
        getNextQuestion,
        questionsCompleted,
        questionsTotal,
      }}
    >
      {children}
    </StudySessionContext.Provider>
  );
}
