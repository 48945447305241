import { useQuery } from '@apollo/client';
import { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AppContext } from '../../../../AppContext';
import { AssignmentStageDocument } from '../../../../gql/queries/__generated__/assignment.generated';

export enum AssignmentStage {
  PreTest = 'pre-test',
  PostTest = 'post-test',
  Experiment = 'experiment',
  None = '',
}

export function useExperimentCheck() {
  const { assignmentId } = useParams<{ assignmentId: string }>();
  const { activeEnrollment } = useContext(AppContext).appState;
  const [isPrePostTest, setIsPrePostTest] = useState<boolean>(false);
  const [isExperiment, setIsExperiment] = useState<boolean>(false);

  useQuery(AssignmentStageDocument, {
    skip: !assignmentId,
    variables: {
      assignmentId: assignmentId || '',
    },
    onCompleted: (data) => {
      if (data.assignmentCollaboration.stage) {
        setIsExperiment(!!data.assignmentCollaboration.stage);
        setIsPrePostTest(
          [AssignmentStage.PreTest, AssignmentStage.PostTest].includes(
            data.assignmentCollaboration.stage as AssignmentStage
          )
        );
      }
    },
  });

  return { isPrePostTest, isExperiment };
}
