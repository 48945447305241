import { adaptV4Theme, createTheme, Theme } from '@mui/material/styles';

import theme from '../../../theme';

export const secondaryMain = '#30B671';

const customDataTableTheme: Theme = createTheme(
  adaptV4Theme({
    ...theme,
    overrides: {
      MuiTooltip: {
        tooltip: {
          fontSize: 14,
        },
      },
      MuiTableCell: {
        head: {
          color: theme.palette.primary.main,
          ...theme.typography.h5,
        },
      },
      MUIDataTable: {
        responsiveBase: {
          maxHeight: '60vh',
          [theme.breakpoints.up('sm')]: {
            margin: theme.spacing(3),
          },
          [theme.breakpoints.up('md')]: {
            margin: theme.spacing(4),
          },
        },
      },
      MUIDataTablePagination: {
        tableCellContainer: {
          borderBottom: 'none',
        },
      },
      MUIDataTableHeadCell: {
        root: {
          borderBottom: `1px solid ${theme.palette.primary.main}`,
        },
        sortAction: {
          alignItems: 'center',
        },
      },
      MuiPaper: {
        root: {
          boxShadow: 'none !important',
        },
      },
    },
  })
);
export default customDataTableTheme;
