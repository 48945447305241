import { ApolloError } from '@apollo/client';
import { openDialog } from '../../components/application/Alerts/context/actions';
import { AlertAction } from '../../components/application/Alerts/context/reducer';

export const onError = (
  dispatch: (action: AlertAction) => void,
  reloadOnClose: boolean = false
) => {
  return (e: ApolloError) => {
    dispatch(
      openDialog({
        title: 'Error occurred.',
        message: e.message,
        error: true,
        reloadOnClose: reloadOnClose,
      })
    );
  };
};

export const stripTypename = <Obj extends Record<string, unknown>>(
  obj: Obj
): Omit<Obj, '__typename'> => {
  const { __typename, ...strippedObj } = obj;
  return strippedObj;
};
