import { Stop } from '@mui/icons-material';
import { Typography, type Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FlaggedResponseTypeEnum } from '../../../gql/types';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  text: {
    fontWeight: theme.typography.fontWeightLight,
  },
}));

type ActionTypeProps = {
  actionType: FlaggedResponseTypeEnum;
};

export function ActionType({ actionType }: ActionTypeProps) {
  const classes = useStyles();

  let text = '';
  let fill = 'red';
  switch (actionType) {
    case FlaggedResponseTypeEnum.Student:
      text = 'Student Flagged Response';
      break;
    case FlaggedResponseTypeEnum.System:
      text = 'Flagged Response';
      fill = 'blue';
      break;
    default:
      break;
  }

  return (
    <div className={classes.root}>
      <Stop style={{ fill, marginRight: 6, marginLeft: -4, padding: 0 }} />
      <Typography variant="body2" className={classes.text}>
        {text}
      </Typography>
    </div>
  );
}
