import type { ButtonBaseProps, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { BaseButton } from './BaseButton';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    color: theme.palette.common.white,
  },
}));

interface ButtonProps extends ButtonBaseProps {
  text: string;
  type?: 'submit';
  fullWidth?: boolean;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export function StandardButton(props: ButtonProps) {
  const classes = useStyles();

  return <BaseButton className={classes.root} {...props} variant="contained" />;
}
