import * as Types from '../../types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type NewMessageSubscriptionVariables = Types.Exact<{
  questionId: Types.Scalars['ID']['input'];
  enrollmentId: Types.Scalars['ID']['input'];
}>;

export type NewMessageSubscription = {
  __typename?: 'Subscription';
  newMessage: {
    __typename?: 'NewMessagePayload';
    chatLog: Array<{
      __typename?: 'ChatLog';
      id: string;
      text: string;
      role: string;
    }>;
  };
};

export const NewMessageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'newMessage' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'questionId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'enrollmentId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'newMessage' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'questionId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'questionId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'enrollmentId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'enrollmentId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'chatLog' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  NewMessageSubscription,
  NewMessageSubscriptionVariables
>;
