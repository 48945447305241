import { useQuery } from '@apollo/client';
import {
  AccountCircleOutlined,
  AssignmentOutlined,
  Grade,
  Home,
  Notifications,
  Poll,
  TrendingUp,
} from '@mui/icons-material';
import {
  Badge,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  List,
  MenuItem,
  Select,
  SelectChangeEvent,
  Toolbar,
  Typography,
  type Theme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useContext, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { AppContext } from '../../../../AppContext';
import { default as Logo } from '../../../../assets/PodsieLogo.svg?react';
import { NotificationsCountDocument } from '../../../../gql/queries/__generated__/actionItem.generated';
import { StudentDocument } from '../../../../gql/queries/__generated__/student.generated';
import { EnrollmentStatusEnum, type Group } from '../../../../gql/types';
import { OWNER_TYPE } from '../../Notifications';
import { NavListItem } from './NavListItem';

const useStyles = makeStyles((theme: Theme) => ({
  navList: {
    width: '100%',
    maxWidth: 360,
    marginTop: theme.spacing(2),
  },
  listContainer: {
    overflow: 'auto',
    overflowX: 'hidden',
  },
  icon: {
    color: theme.palette.secondary.main,
  },
  logo: {
    fill: theme.palette.secondary.main,
    width: 160,
    padding: 3,
  },
  groupList: {
    background: theme.palette.warning.main,
    position: 'absolute',
    bottom: 0,
  },
  select: {
    borderRadius: 0,
  },
}));

type NavListProps = {
  closeDrawer: () => void;
};

export function NavList({ closeDrawer }: NavListProps) {
  const classes = useStyles();
  const { data } = useQuery(StudentDocument);
  const {
    appState: { activeEnrollment },
    updateActiveEnrollment,
  } = useContext(AppContext);

  const handleActiveGroupUpdate = (event: SelectChangeEvent<Group['id']>) => {
    updateActiveEnrollment(event.target.value);
  };
  const [notified, setNotified] = useState(false);
  const [open, setOpen] = useState(false);
  const enrollments = data?.student.enrollments || [];
  const activeEnrollments = enrollments.filter(
    (enrollment) =>
      enrollment.enrollmentStatus === EnrollmentStatusEnum.Enrolled
  );
  const activeEnrollmentId = activeEnrollment?.id || '';
  const courseId = activeEnrollment?.group?.course.id;
  const { data: actionItemsCount } = useQuery(NotificationsCountDocument, {
    skip: !activeEnrollmentId,
    pollInterval: 60000,
    variables: {
      ownerId: activeEnrollmentId,
      ownerType: OWNER_TYPE,
    },
  });
  const notificationsCount = actionItemsCount?.notificationsCount || 0;
  const location = useLocation();

  useEffect(() => {
    if (
      !notified &&
      location.pathname !== '/notifications' &&
      notificationsCount > 0
    ) {
      // pop open notification dialog
      setOpen(true);
      setNotified(true);
    }
  }, [notified, notificationsCount, location.pathname]);

  const navigate = useNavigate();

  const handleClose = () => setOpen(false);

  const goToNotifications = () => {
    navigate('/notifications');
    setOpen(false);
  };

  return (
    <div>
      <Toolbar>
        <Link to={'/'}>
          <Logo className={classes.logo} />
        </Link>
      </Toolbar>
      <div className={classes.listContainer}>
        <List
          component="nav"
          aria-labelledby="navigation"
          className={classes.navList}
          onClick={closeDrawer}
        >
          <NavListItem Icon={Home} text="HOME" route="/home" />
          <NavListItem
            Icon={AssignmentOutlined}
            text="DONE or OPTIONAL"
            route="/assignments"
          />
          <NavListItem Icon={Grade} text="MASTERY" route="/mastery" />
          <NavListItem
            Icon={TrendingUp}
            text="STUDY PROGRESS"
            route="/progress"
          />
          <NavListItem
            Icon={() => (
              <Badge
                color="error"
                variant="dot"
                invisible={!notificationsCount}
              >
                <Notifications />
              </Badge>
            )}
            text="NOTIFICATIONS"
            route="/notifications"
          />
          <NavListItem
            Icon={AccountCircleOutlined}
            text="MY ACCOUNT"
            route="/account"
          />
          {courseId === '1114' && (
            <NavListItem Icon={Poll} text="LEADERBOARD" route="/leaderboard" />
          )}
        </List>
      </div>
      <FormControl fullWidth className={classes.groupList} variant="outlined">
        {activeEnrollments.length > 1 && activeEnrollment?.groupId ? (
          <Select
            onChange={handleActiveGroupUpdate}
            value={activeEnrollment?.groupId}
            className={classes.select}
          >
            {activeEnrollments.map(({ group }) => {
              if (group) {
                return (
                  <MenuItem
                    onClick={closeDrawer}
                    key={`group-${group.id}`}
                    value={group.id}
                  >
                    <strong>{group.course.name}</strong>
                    {` - ${group.name}`}
                  </MenuItem>
                );
              }
            })}
          </Select>
        ) : null}
      </FormControl>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Head&apos;s Up!</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            You have active notifications. Please go through and archive them
            when you are done reviewing your notifications.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="contained">
            Close
          </Button>
          <Button
            onClick={goToNotifications}
            color="primary"
            variant="contained"
          >
            Go to Notifications
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
