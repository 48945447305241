import type { Theme } from '@mui/material';
import type { CreateCSSProperties } from '@mui/styles';

export const buttonLink = (theme: Theme): CreateCSSProperties => ({
  padding: 0,
  color: theme.palette.secondary.main,
  textDecoration: 'underline',
  border: 'none',
  textAlign: 'left',
  backgroundColor: 'transparent',
  '&:hover': {
    cursor: 'pointer',
  },
});
