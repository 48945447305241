import { makeStyles } from '@mui/styles';
import React from 'react';
import { BaseButton } from './BaseButton';

const useStyles = makeStyles(() => ({
  root: {},
}));

type ButtonProps = {
  text: string;
  type?: 'submit';
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

export function OutlinedButton(props: ButtonProps) {
  const classes = useStyles();

  return <BaseButton {...props} variant="outlined" className={classes.root} />;
}
