import type { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import type { ComponentPropsWithoutRef } from 'react';
import { forwardRef } from 'react';

type StyleProps = { multipleChoice?: boolean; zoomed?: boolean };

const useStyles = makeStyles((theme: Theme) => ({
  html: {
    overflowWrap: 'break-word',
    wordWrap: 'break-word',
    borderRadius: 4,
    whiteSpace: 'pre-wrap',
    padding: theme.spacing(1),
    color: 'black',
    zoom: ({ zoomed }: StyleProps) => (zoomed ? 1.5 : 1),
    lineHeight: ({ zoomed }: StyleProps) => (zoomed ? '16px' : 'inherit'),
    background: ({ multipleChoice }: StyleProps) =>
      multipleChoice ? 'inherit' : theme.palette.common.white,
    '& img': {
      margin: '0 auto',
      display: 'inherit',
      maxWidth: '100%',
    },
    '& pre': {
      whiteSpace: 'pre-wrap',
      wordWrap: 'break-word',
    },
    '& .katex': {
      position: 'relative',
    },
    '& p': {
      margin: ({ multipleChoice }: StyleProps) => (multipleChoice ? 0 : 'auto'),
    },
    '& a': {
      color: '#0000EE',
      textDecoration: 'underline',
    },
  },
}));

type TextboxProps = {
  htmlText: string;
  multipleChoice?: boolean;
  zoomed?: boolean;
} & ComponentPropsWithoutRef<'div'>;

export const Textbox = forwardRef<HTMLDivElement, TextboxProps>(
  ({ htmlText, multipleChoice, zoomed, ...props }: TextboxProps, ref) => {
    const classes = useStyles({ multipleChoice, zoomed });

    return (
      <div
        {...props}
        ref={ref}
        style={
          multipleChoice ? { padding: '2px 4px', ...props.style } : props.style
        }
        className={`${classes.html} ${props.className ? props.className : ''}`}
        dangerouslySetInnerHTML={{ __html: htmlText }}
      />
    );
  }
);

Textbox.displayName = 'Textbox';
