import { AlertAction } from './reducer';

//--------------------------- DIALOG -------------------------------
export enum DialogActionTypes {
  OPEN_DIALOG = 'OPEN_DIALOG',
  CLOSE_DIALOG = 'CLOSE_DIALOG',
}

export type DialogMaxWidth = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | undefined;
export type AlertsDialog = {
  title: string;
  message: string;
  maxWidth?: DialogMaxWidth;
  error?: boolean;
  reloadOnClose?: boolean;
};

export const openDialog = (payload: AlertsDialog): AlertAction => ({
  type: DialogActionTypes.OPEN_DIALOG,
  payload,
});

export const closeDialog = (): AlertAction => ({
  type: DialogActionTypes.CLOSE_DIALOG,
});

//--------------------------- CONFIRMATION ----------------------------

export enum ConfirmationActionTypes {
  OPEN_CONFIRMATION = 'OPEN_CONFIRMATION',
  CLOSE_CONFIRMATION = 'CLOSE_CONFIRMATION',
}

export type AlertsConfirmation = {
  message: string;
  confirmButtonText?: string;
  confirmFunc: () => void;
  cancelFunc?: () => void;
  maxWidth?: DialogMaxWidth;
};

export const openConfirmation = (payload: AlertsConfirmation): AlertAction => ({
  type: ConfirmationActionTypes.OPEN_CONFIRMATION,
  payload,
});

export const closeConfirmation = (): AlertAction => ({
  type: ConfirmationActionTypes.CLOSE_CONFIRMATION,
});

//--------------------------- SNACK -----------------------------------

export enum SnackActionTypes {
  PUSH_SNACK = 'PUSH_SNACK',
  CLOSE_SNACK = 'CLOSE_SNACK',
  UPDATE_SNACK_QUEUE = 'UPDATE_SNACK_QUEUE',
}

export type AlertsSnack = {
  message: string;
  key?: string;
  severity?: 'success' | 'warning' | 'error' | 'info';
  vertical?: 'top' | 'bottom';
  horizontal?: 'left' | 'center' | 'right';
};

export const pushSnack = (payload: AlertsSnack): AlertAction => ({
  type: SnackActionTypes.PUSH_SNACK,
  payload,
});

export const closeSnack = (): AlertAction => ({
  type: SnackActionTypes.CLOSE_SNACK,
});

export const updateSnackQueue = (): AlertAction => ({
  type: SnackActionTypes.UPDATE_SNACK_QUEUE,
});
