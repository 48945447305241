import { Grid, Tooltip, Typography, type Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { format } from 'date-fns';
import type { StudentsAssignmentsDatumQuery } from '../../../gql/queries/__generated__/studentsAssignmentsDatum.generated';
import { dateFormat } from '../../../utils/dates';
import { LateAssignmentChip } from '../../shared/Chips/LateAssignmentChip';
import { CircularProgressWithLabel } from '../../shared/Loaders/CircularProgressWithLabel';
import { LoadingSkeletons } from '../../shared/Loaders/LoadingSkeletons';
import { PersonalDeckQuestionsInfo } from '../Assignments/StandardAssignment/AssignmentFinished';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundImage: `linear-gradient(to bottom, ${theme.palette.primary.main} 44%, #0c6d7e 123%)`,
    color: theme.palette.common.white,
    display: 'flex',
    justifyContent: 'space-around',
    padding: `22px ${theme.spacing(2)}`,
  },
  leftSection: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(1.5),
  },
  textContainer: {
    display: 'flex',
    marginBottom: theme.spacing(1.5),
  },
  studentName: {
    fontWeight: 'normal',
    marginBottom: theme.spacing(2),
    color: theme.palette.secondary.main,
  },
  assignmentName: {
    color: theme.palette.common.white,
    marginLeft: theme.spacing(1),
  },
  rightSection: {
    display: 'flex',
    justifyContent: 'center',
  },
  contentContainer: {
    maxWidth: '65%',
    [theme.breakpoints.down('md')]: {
      maxWidth: '80%',
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
  },
  statsContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  scoreContainer: {
    width: 128,
    marginRight: theme.spacing(2),
    padding: theme.spacing(1),
  },
  label: {
    color: theme.palette.common.white,
  },
  completionStats: {
    fontWeight: theme.typography.fontWeightLight,
    textTransform: 'none',
    marginBottom: theme.spacing(1),
  },
}));

type AssignmentInfoProps = {
  assignmentLoading: boolean;
  assignmentData?: StudentsAssignmentsDatumQuery | PersonalDeckQuestionsInfo;
};

export function AssignmentInfo({
  assignmentLoading,
  assignmentData,
}: AssignmentInfoProps) {
  const classes = useStyles();

  if (assignmentLoading || !assignmentData?.studentsAssignmentsDatum) {
    return <LoadingSkeletons num={10} />;
  }

  const name =
    assignmentData?.studentsAssignmentsDatum?.groupsAssignment?.assignment.name;
  const dueDate =
    assignmentData?.studentsAssignmentsDatum?.groupsAssignment?.dueDate;
  const groupName =
    assignmentData?.studentsAssignmentsDatum?.groupsAssignment?.group.name;
  const {
    student: { fullName },
    questionsTotal,
    questionsCompleted,
    completedAt,
    questionsCorrect,
  } = assignmentData.studentsAssignmentsDatum;
  let questionsAttempted;
  if ('questionsAttempted' in assignmentData.studentsAssignmentsDatum) {
    ({ questionsAttempted } = assignmentData.studentsAssignmentsDatum);
  }
  let progressLabel;
  if (questionsTotal === 0) {
    progressLabel = 100;
  } else {
    const denominator =
      questionsAttempted === undefined || questionsAttempted === 0
        ? questionsTotal
        : questionsAttempted;
    progressLabel = (questionsCorrect / denominator) * 100;
  }

  const completedDate = completedAt ? new Date(completedAt) : undefined;
  return (
    <Grid container className={classes.root}>
      <Grid xs={12} sm={5} item className={classes.leftSection}>
        <div>
          <Typography variant="h3" className={classes.studentName}>
            {fullName}
          </Typography>
          <span className={classes.textContainer}>
            <Typography
              variant="h5"
              className={classes.label}
            >{`ASSIGNMENT:`}</Typography>
            <Typography className={classes.assignmentName} variant="body1">
              {name}
            </Typography>
          </span>
          <span className={classes.textContainer}>
            <Typography
              variant="h5"
              className={classes.label}
            >{`CLASS:`}</Typography>
            <Typography className={classes.assignmentName} variant="body1">
              {groupName}
            </Typography>
          </span>
          {completedDate && (
            <span className={classes.textContainer}>
              <Typography
                variant="h5"
                className={classes.label}
              >{`Finished:`}</Typography>
              <Typography className={classes.assignmentName} variant="body1">
                {format(completedDate, dateFormat)}
              </Typography>
            </span>
          )}
          {completedDate && completedDate > new Date(dueDate) ? (
            <Tooltip title={`Submitted ${format(completedDate, dateFormat)}`}>
              <span>
                <LateAssignmentChip />
              </span>
            </Tooltip>
          ) : null}
        </div>
      </Grid>
      <Grid xs={12} sm={7} item className={classes.rightSection}>
        <div className={classes.contentContainer}>
          <div className={classes.statsContainer}>
            <Tooltip
              placement="top"
              title={`${questionsCorrect} / ${questionsTotal}`}
            >
              <div className={classes.scoreContainer}>
                <CircularProgressWithLabel value={progressLabel} />
              </div>
            </Tooltip>
            <div>
              <Typography className={classes.completionStats} variant="h1">
                {`${questionsCompleted} of ${questionsTotal}`}
              </Typography>
              <Typography variant="h4">Questions Completed</Typography>
            </div>
          </div>
          <Typography variant="caption">
            Percentage is based on how many questions the student answered
            correctly on their first attempt.
          </Typography>
        </div>
      </Grid>
    </Grid>
  );
}
