import { Chip, ChipProps, Tooltip, type Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  chip: {
    background: theme.palette.common.white,
    color: theme.palette.primary.main,
    overflow: 'hidden',
    maxWidth: '100%',
  },
}));

function StyledChip({ label, ...props }: ChipProps & { label: string }) {
  const classes = useStyles();
  return (
    <Chip
      {...props}
      label={label}
      variant="outlined"
      className={`${classes.chip} ${props.className ? props.className : ''}`}
      color="secondary"
    />
  );
}

type CustomChipProps = {
  label: string;
  tooltip?: string;
};

export function CustomChip({
  label,
  tooltip,
  ...props
}: ChipProps & CustomChipProps) {
  if (tooltip) {
    return (
      <Tooltip title={tooltip}>
        <span>
          <StyledChip {...props} label={label} />
        </span>
      </Tooltip>
    );
  }

  return <StyledChip {...props} label={label} />;
}
