import type { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useState } from 'react';
import { buttonLink } from '../../../../assets/shared-styles/Button-Link';
import { CustomTooltip } from '../../../shared/CustomTooltip';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  buttonLink: {
    ...buttonLink(theme),
    color: 'inherit',
    marginLeft: theme.spacing(1),
    fontWeight: theme.typography.fontWeightBold,
  },
}));

export function AnswerHidden() {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const openTooltip = () => setOpen(true);
  const closeTooltip = () => setOpen(false);

  return (
    <div className={classes.root}>
      Answer currently hidden. Please study the deeper learning (click on book
      icon in top right corner).
      <CustomTooltip
        open={open}
        title={
          <div>
            Instead of seeing the correct answer right away, your teacher would
            look for you to first try to figure out the correct answer through
            the deeper learning.
          </div>
        }
      >
        <button
          className={classes.buttonLink}
          onBlur={closeTooltip}
          onClick={openTooltip}
        >
          Why is the correct answer currently hidden?
        </button>
      </CustomTooltip>
    </div>
  );
}
