import type { ActionItemQuery } from '../../../../gql/queries/__generated__/actionItem.generated';
import { FlaggedResponse } from './FlaggedResponse';

type DetailsProps = {
  actionItem: ActionItemQuery['actionItem'];
};

export function Details({ actionItem }: DetailsProps) {
  switch (actionItem.actionableType) {
    case 'FlaggedResponse':
      return <FlaggedResponse response={actionItem.actionable} />;

    default:
      return <div>hi</div>;
  }
}
