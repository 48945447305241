import {
  CheckBox,
  RadioButtonChecked,
  ShortText,
  ViewHeadline,
} from '@mui/icons-material';
import { QuestionTypeEnum } from '../gql/types';

export const renderQuestionType = (questionType: QuestionTypeEnum) => {
  switch (questionType) {
    case QuestionTypeEnum.FreeResponse:
      return 'Free Response';
    case QuestionTypeEnum.ShortAnswer:
      return 'Short Answer';
    case QuestionTypeEnum.SelectAllMultipleChoice:
      return 'Select All';
    case QuestionTypeEnum.SelectOneMultipleChoice:
      return 'Multiple Choice';
  }
};

export const renderQuestionTypeIcon = (questionType: QuestionTypeEnum) => {
  switch (questionType) {
    case QuestionTypeEnum.FreeResponse:
      return <ViewHeadline />;
    case QuestionTypeEnum.ShortAnswer:
      return <ShortText />;
    case QuestionTypeEnum.SelectAllMultipleChoice:
      return <CheckBox />;
    case QuestionTypeEnum.SelectOneMultipleChoice:
      return <RadioButtonChecked />;
  }
};
