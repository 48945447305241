import { useMutation } from '@apollo/client';
import StarIcon from '@mui/icons-material/Star';
import { Box, Grid } from '@mui/material';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../../../../AppContext';
import { UpdateEnrollmentDocument } from '../../../../gql/mutations/__generated__/enrollment.generated';
import { ClaimFreeSessionDocument } from '../../../../gql/mutations/__generated__/personalDeckStudySession.generated';
import { NumPersonalQuestionsDueDocument } from '../../../../gql/queries/__generated__/enrollment.generated';
import theme from '../../../../theme';
import { onError } from '../../../../utils/apollo/apolloHelper';
import { StandardButton } from '../../../shared/Buttons/StandardButton';
import { AlertsContext } from '../../Alerts/context';
import { openDialog } from '../../Alerts/context/actions';
import { DisplayStatus } from './AssignmentCardHeader';
import { BaseCard } from './BaseCard';
import { DueDate } from './DueDate';

type DeckAssignmentCardProps = {
  id: string;
  assignmentName: string;
  dueDate?: Date;
  status: DisplayStatus;
  completedQuestions: number;
  totalQuestions: number;
  totalSessionQuestions: number;
  targetPoints: number;
  currentPoints: number;
  studySessionActive: boolean;
  points: number;
  freeSessionAvailable: boolean;
  frozen: boolean;
  onClickShowProgress: () => void;
  hideXP?: boolean;
};

export function DeckAssignmentCard(props: DeckAssignmentCardProps) {
  const targetPercentage = Math.round(
    (props.currentPoints / (props.targetPoints || 1)) * 100
  );
  const {
    appState: { activeEnrollment },
  } = useContext(AppContext);
  let actionsSection;
  if (props.frozen) {
    actionsSection = <FrozenDeckActions />;
  } else if (props.freeSessionAvailable) {
    actionsSection = (
      <FreeSessionActions
        groupsAssignmentId={props.id}
        enrollmentId={activeEnrollment?.id || ''}
      />
    );
  } else if (props.totalQuestions == 0) {
    actionsSection = (
      <>
        {!props.hideXP && <EmptyDeckActions />}
        <Button
          variant="outlined"
          sx={{ fontWeight: 'light' }}
          onClick={props.onClickShowProgress}
        >
          View Progress
        </Button>
      </>
    );
  } else {
    actionsSection = (
      <DefaultDeckActions
        id={props.id}
        studySessionActive={props.studySessionActive}
        onClickShowProgress={props.onClickShowProgress}
      />
    );
  }

  const contentSection = (
    <>
      <Grid
        container
        sx={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          gap: theme.spacing(1),
        }}
      >
        <Grid item sx={{ width: '100%' }}>
          {!props.hideXP && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: theme.spacing(1),
                fontWeight: 400,
                backgroundColor: '#FFF7B4',
                color: '#775601',
                width: '100%',
                padding: theme.spacing(1),
                marginBottom: theme.spacing(1),
              }}
            >
              <StarIcon sx={{ color: '#ffd600' }} />
              XP Level: {props.points} Points
            </Box>
          )}
        </Grid>
        <Grid item sx={{ gap: theme.spacing(0.5), display: 'flex' }}>
          <Typography sx={{ fontWeight: 'light' }}>Target Points: </Typography>
          <Typography sx={{ fontWeight: '500' }}>
            {props.targetPoints}
          </Typography>
        </Grid>
        <Grid item sx={{ gap: theme.spacing(0.5), display: 'flex' }}>
          <Typography sx={{ fontWeight: 'light' }}>Current Points:</Typography>
          <Typography sx={{ fontWeight: '500' }}>
            {props.currentPoints} ({targetPercentage}%)
          </Typography>
        </Grid>
      </Grid>

      <Grid
        container
        sx={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          gap: theme.spacing(1),
        }}
      >
        <Grid item sx={{ gap: theme.spacing(0.5), display: 'flex' }}>
          <Typography sx={{ fontWeight: 'light' }}>
            Questions Completed:{' '}
          </Typography>
          <Typography sx={{ fontWeight: '500' }}>
            {props.completedQuestions}/{props.totalSessionQuestions}
          </Typography>
        </Grid>
        <Grid item sx={{ gap: theme.spacing(0.5), display: 'flex' }}>
          <Typography sx={{ fontWeight: 'light' }}>
            Total Questions Due:
          </Typography>
          <Typography sx={{ fontWeight: '500' }}>
            {props.totalQuestions}
          </Typography>
        </Grid>
      </Grid>
      {props.dueDate && (
        <DueDate status={props.status} dueDate={props.dueDate} />
      )}
    </>
  );
  return (
    <BaseCard
      assignmentName={props.assignmentName}
      actions={actionsSection}
      content={contentSection}
    />
  );
}

function DefaultDeckActions(props: {
  id: string;
  studySessionActive: boolean;
  onClickShowProgress: () => void;
}) {
  const navigate = useNavigate();
  return (
    <>
      <Button
        variant="contained"
        fullWidth
        sx={{ fontWeight: 'light', backgroundColor: '#5CB277' }}
        onClick={() => {
          navigate(`/personal-deck/study`);
        }}
      >
        {props.studySessionActive
          ? 'Continue Study Session'
          : 'Start Study Session'}
      </Button>
      <Button
        variant="outlined"
        sx={{ fontWeight: 'light' }}
        onClick={props.onClickShowProgress}
      >
        View Progress
      </Button>
    </>
  );
}

export function EmptyDeckActions() {
  return (
    <Typography sx={{ fontWeight: '400' }}>
      No questions due at this time.
    </Typography>
  );
}

function FreeSessionActions({
  groupsAssignmentId,
  enrollmentId,
}: {
  groupsAssignmentId: string;
  enrollmentId: string;
}) {
  const { dispatch } = useContext(AlertsContext);
  const [claimSession] = useMutation(ClaimFreeSessionDocument, {
    variables: {
      enrollmentId,
      groupsAssignmentId,
    },
    onError: onError(dispatch),
    onCompleted: () => {
      dispatch(
        openDialog({
          title: 'Nice job!',
          message:
            "You were able to claim a free session just now because you've been keeping up with your personal deck and had no questions due today. Keep up the great work!",
          error: false,
        })
      );
    },
  });

  return (
    <StandardButton
      text="Claim Free Session"
      fullWidth
      onClick={async () => {
        await claimSession();
      }}
    />
  );
}

function FrozenDeckActions() {
  const { dispatch } = useContext(AlertsContext);
  const [updateEnrollment] = useMutation(UpdateEnrollmentDocument, {
    onError: onError(dispatch),
  });
  const {
    appState: { activeEnrollment },
  } = useContext(AppContext);
  return (
    <>
      <Typography sx={{ fontWeight: '400' }}>
        Your personal deck is disabled because your teacher froze it. If you
        want to temporarily enable your personal deck, click the unfreeze button
        below. Please note that after you unfreeze it, it will automatically
        refreeze after a period of inactivity. No personal deck questions are
        due at this time.
      </Typography>
      <Button
        variant="contained"
        sx={{ fontWeight: 'light', backgroundColor: '#5CB277' }}
        onClick={async () => {
          if (!activeEnrollment) return;
          await updateEnrollment({
            variables: {
              enrollmentId: activeEnrollment.id,
              unfreezePersonalDeck: !activeEnrollment?.unfreezePersonalDeck,
            },
            refetchQueries: [NumPersonalQuestionsDueDocument],
          });
        }}
      >
        Unfreeze
      </Button>
    </>
  );
}
