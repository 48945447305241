import { useEffect, useState } from 'react';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import rehypeKatex from 'rehype-katex';
import remarkMath from 'remark-math';
import { Supplement } from '../../../../gql/types';
import { streamTokens } from '../../../../utils/stream';

type AutograderExplanationStreamerProps = {
  questionId: string;
  responseId: string;
  supplement: Supplement | undefined | null;
};
export function AutograderExplanationStreamer({
  questionId,
  responseId,
  supplement,
}: AutograderExplanationStreamerProps) {
  const [explanation, setExplanation] = useState<string>('');

  useEffect(() => {
    const teacherExplanation = supplement ? supplement.plainText : undefined;
    (async () => {
      await streamTokens({
        url: '/autograde/explain',
        updaterFunc: (token) => {
          setExplanation((prevExplanation) => prevExplanation + token);
        },
        params: {
          questionId,
          responseId,
          teacherExplanation,
        },
      });
    })();
  }, [questionId, responseId, supplement]);

  return (
    <ReactMarkdown remarkPlugins={[remarkMath]} rehypePlugins={[rehypeKatex]}>
      {explanation}
    </ReactMarkdown>
  );
}
