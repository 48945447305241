import * as Types from '../../types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type StartStudySessionMutationVariables = Types.Exact<{
  enrollmentId: Types.Scalars['ID']['input'];
}>;

export type StartStudySessionMutation = {
  __typename?: 'Mutation';
  createStudySession: { __typename?: 'PersonalDeckStudySession'; id: string };
};

export type ClaimFreeSessionMutationVariables = Types.Exact<{
  enrollmentId: Types.Scalars['ID']['input'];
  groupsAssignmentId: Types.Scalars['ID']['input'];
}>;

export type ClaimFreeSessionMutation = {
  __typename?: 'Mutation';
  claimFreeSession: {
    __typename?: 'PersonalDeckStudySession';
    id: string;
    groupsAssignmentId?: number | null;
    enrollmentId: number;
    totalQuestions: number;
  };
};

export const StartStudySessionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'startStudySession' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'enrollmentId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createStudySession' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'enrollmentId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'enrollmentId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  StartStudySessionMutation,
  StartStudySessionMutationVariables
>;
export const ClaimFreeSessionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'claimFreeSession' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'enrollmentId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'groupsAssignmentId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'claimFreeSession' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'enrollmentId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'enrollmentId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'groupsAssignmentId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'groupsAssignmentId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'groupsAssignmentId' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'enrollmentId' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'totalQuestions' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ClaimFreeSessionMutation,
  ClaimFreeSessionMutationVariables
>;
