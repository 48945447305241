import { useMutation, useQuery } from '@apollo/client';
import { Check, Close } from '@mui/icons-material';
import { Button, Divider, Typography, type Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { produce } from 'immer';
import { useContext } from 'react';
import { OWNER_TYPE, PAGINATION_COUNT } from '.';
import { AppContext } from '../../../AppContext';
import { ToggleActionItemStateDocument } from '../../../gql/mutations/__generated__/actionItem.generated';
import {
  ActionItemDocument,
  ActionItemsDocument,
  NotificationsCountDocument,
} from '../../../gql/queries/__generated__/actionItem.generated';
import { ActionItemStateEnum } from '../../../gql/types';
import { LoadingSkeletons } from '../../shared/Loaders/LoadingSkeletons';
import { Details } from './Details';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
  },
  main: {
    flex: 1,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  body: {
    flex: 1,
    overflow: 'auto',
  },
  divider: {
    color: theme.palette.divider,
    borderWidth: '1px',
    width: '100%',
  },
  footer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  teacherEvaluation: {
    display: 'flex',
    alignItems: 'center',
    margin: theme.spacing(2),
  },
  button: {
    margin: theme.spacing(2, 0, 1, 0),
  },
  answerState: {
    marginLeft: theme.spacing(1),
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#d1ffd9',
    padding: theme.spacing(0.5),
    borderRadius: theme.spacing(0.5),
  },
  incorrectAnswer: {
    backgroundColor: '#feb8b7',
  },
  icon: {
    marginRight: theme.spacing(0.25),
  },
}));

type DetailPanelProps = {
  selectedActionItemId: string;
};

export function DetailPanel({ selectedActionItemId }: DetailPanelProps) {
  const classes = useStyles();
  const { data, loading } = useQuery(ActionItemDocument, {
    skip: !selectedActionItemId,
    variables: { actionItemId: selectedActionItemId },
    fetchPolicy: 'cache-and-network',
  });
  const { appState } = useContext(AppContext);
  const activeEnrollmentId = appState.activeEnrollment?.id || '';
  const [toggleState, { loading: toggleLoading }] = useMutation(
    ToggleActionItemStateDocument,
    {
      update: (cache, res) => {
        // remove recently toggled item from the list it was toggled away from:
        const variables = {
          ownerId: activeEnrollmentId,
          first: PAGINATION_COUNT,
          ownerType: OWNER_TYPE,
        };
        const open =
          res.data?.toggleActionItemState.state !== ActionItemStateEnum.Open;
        const removedVariables = {
          ...variables,
          open,
        };
        const addedVariables = { ...variables, open: !open };
        const removeFromResults = cache.readQuery({
          variables: removedVariables,
          query: ActionItemsDocument,
        });
        const addedToResults = cache.readQuery({
          variables: addedVariables,
          query: ActionItemsDocument,
        });
        if (res.data?.toggleActionItemState && removeFromResults) {
          const index = removeFromResults.actionItems.edges?.findIndex(
            (actionItem) =>
              actionItem?.node?.id === res.data?.toggleActionItemState.id
          );
          if (addedToResults) {
            const addedTo = produce(
              addedToResults.actionItems,
              (actionItemsDraft) => {
                const edge =
                  removeFromResults.actionItems.edges &&
                  index !== undefined &&
                  index !== -1
                    ? removeFromResults.actionItems.edges[index]
                    : null;
                if (edge) {
                  actionItemsDraft.edges?.unshift(edge);
                  actionItemsDraft.totalCount += 1;
                }
              }
            );
            cache.writeQuery({
              variables: addedVariables,
              query: ActionItemsDocument,
              data: {
                actionItems: addedTo,
              },
            });
          }
          const removedFrom = produce(
            removeFromResults.actionItems,
            (actionItemsDraft) => {
              if (index !== undefined && index !== -1) {
                actionItemsDraft.edges?.splice(index, 1);
                actionItemsDraft.totalCount -= 1;
              }
            }
          );

          // when something was just archived, check if the open list still has
          // items, and if not, then turn off notification badge:
          if (
            res.data.toggleActionItemState.state ===
              ActionItemStateEnum.Archived &&
            removedFrom.edges?.length === 0 &&
            activeEnrollmentId
          ) {
            cache.writeQuery({
              variables: { ownerId: activeEnrollmentId, ownerType: OWNER_TYPE },
              query: NotificationsCountDocument,
              data: {
                notificationsCount: 0,
              },
            });
          }
          cache.writeQuery({
            variables: removedVariables,
            query: ActionItemsDocument,
            data: {
              actionItems: removedFrom,
            },
          });
        }
      },
    }
  );
  if (loading) {
    return <LoadingSkeletons num={10} />;
  }

  if (!data || !selectedActionItemId) {
    return null;
  }

  const handleArchive = () => {
    if (!data) {
      return;
    }
    const {
      actionItem: { state, id },
    } = data;
    toggleState({
      variables: {
        actionItemId: id,
        state:
          state === ActionItemStateEnum.Archived
            ? ActionItemStateEnum.Open
            : ActionItemStateEnum.Archived,
      },
    });
  };

  const actionItem = data.actionItem;

  const isAcceptable =
    actionItem &&
    actionItem.actionable.response.__typename === 'ShortAnswerResponse' &&
    actionItem.actionable.response.question.shortAnswerAnswers &&
    actionItem.actionable.response.question.shortAnswerAnswers.some(
      (answer) =>
        actionItem.actionable.response.__typename === 'ShortAnswerResponse' &&
        answer.plainText === actionItem.actionable.response.answer
    );

  const systemAutomated = actionItem.actionable.automatedResolution;
  const actor = systemAutomated ? 'System' : 'teacher';

  return (
    <div className={classes.root}>
      <div className={classes.main}>
        <div className={classes.body}>
          <Details actionItem={actionItem} />
        </div>
        <Divider className={classes.divider} />
        <div className={classes.footer}>
          <Typography className={classes.teacherEvaluation}>
            {`The ${actor} graded your answer as `}
            <span
              className={clsx(classes.answerState, {
                [classes.incorrectAnswer]: !isAcceptable,
              })}
            >
              {isAcceptable ? (
                <Check className={classes.icon} />
              ) : (
                <Close className={classes.icon} />
              )}
              {isAcceptable ? 'Acceptable' : 'Incorrect'}
            </span>
          </Typography>
          <Divider className={classes.divider} />
          <div className={classes.button}>
            <Button
              onClick={handleArchive}
              variant="contained"
              color="primary"
              disabled={toggleLoading}
            >{`${
              data.actionItem.state === ActionItemStateEnum.Open
                ? 'Archive'
                : 'Unarchive'
            }`}</Button>
          </div>
        </div>
      </div>
    </div>
  );
}
