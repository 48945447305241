import { useQuery } from '@apollo/client';
import { Alert } from '@mui/lab';
import { Tooltip, Typography, type Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { parseISO, sub } from 'date-fns';
import { useContext, useState } from 'react';
import CalendarHeatmap from 'react-calendar-heatmap';
import 'react-calendar-heatmap/dist/styles.css';
import ReactTooltip from 'react-tooltip';
import {
  convertDateToTime,
  findTimeZone,
  getUnixTime,
  setTimeZone,
} from 'timezone-support';
import { AppContext } from '../../../../AppContext';
import { PersonalDeckDataDocument } from '../../../../gql/queries/__generated__/personalDeckDatum.generated';
import {
  EnrollmentResponsesByDateDocument,
  PersonalDeckStreakDocument,
} from '../../../../gql/queries/__generated__/personalDeckQuestion.generated';
import { Aggregate } from '../../../shared/Aggregate';
import { ResultsModal } from '../../Results/ResultsModal';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  title: {
    textAlign: 'center',
    marginBottom: theme.spacing(3),
  },
  titleContainer: {
    marginTop: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  alert: {
    marginTop: theme.spacing(2),
    maxWidth: 1200,
    margin: '0 auto',
    display: 'flex',
    justifyContent: 'center',
  },
  container: {
    margin: '24px auto 8px',
    maxWidth: 1200,
  },
}));

const TODAY = new Date();
const THREE_MONTHS_AGO = sub(TODAY, { months: 3 }).toDateString();

type HeatMapCell = {
  date: Date;
  count: number | null;
  frozen: boolean;
  numAttempted: number;
  numCompleted: number;
  numDue: number;
  numCorrect: number;
};

type SelectedInfo = {
  numCompleted: number;
  numCorrect: number;
  numAttempted: number;
  numDue: number;
};

export function PersonalDeckProgress() {
  const classes = useStyles();
  const [streak, setStreak] = useState(0);
  const {
    appState: { activeEnrollment },
  } = useContext(AppContext);
  useQuery(PersonalDeckStreakDocument, {
    skip: !activeEnrollment,
    variables: { enrollmentId: activeEnrollment?.id || '' },
    onCompleted: (res) => {
      setStreak(res.personalDeckStreak);
    },
  });
  const { data } = useQuery(PersonalDeckDataDocument, {
    skip: !activeEnrollment,
    variables: {
      enrollmentId: activeEnrollment?.id || '',
      startDay: THREE_MONTHS_AGO,
    },
  });
  const [selectedDate, setSelectedDate] = useState<Date | undefined>();
  const [selectedInfo, setSelectedInfo] = useState<undefined | SelectedInfo>();
  const updateFilterTimezone = () => {
    const timezone = activeEnrollment?.student.school.timezone;

    if (timezone && selectedDate) {
      const inputTimeObj = convertDateToTime(selectedDate);
      const zonedTimeObj = setTimeZone(inputTimeObj, findTimeZone(timezone));
      // This is already the start of the selectedDate, since the original selectedDate
      // object had HH:MM:SS of 0:0:0 and we replaced the timezone
      const start = new Date(getUnixTime(zonedTimeObj));
      return start;
    }
    return selectedDate;
  };
  const { data: responseData, loading: responseLoading } = useQuery(
    EnrollmentResponsesByDateDocument,
    {
      skip: !selectedDate || !activeEnrollment,
      variables: {
        enrollmentId: activeEnrollment?.id || '',
        startDate: updateFilterTimezone(),
      },
      fetchPolicy: 'cache-and-network',
    }
  );
  const handleClose = () => setSelectedDate(undefined);

  const handleClick = (value: HeatMapCell) => {
    if (!value || !value.date) {
      return;
    }
    setSelectedInfo({
      numAttempted: value.numAttempted,
      numCompleted: value.numCompleted,
      numCorrect: value.numCorrect,
      numDue: value.numDue,
    });
    setSelectedDate(value.date);
  };

  const processed =
    data?.personalDeckDataByEnrollment.map((datum) => {
      const count = datum.numDueAtStartOfDay - datum.numCompleted;

      return {
        date: parseISO(datum.date),
        count: count < 0 ? 0 : count,
        frozen: datum.frozenAtEndOfDay,
        numAttempted: datum.numAttempted,
        numCorrect: datum.numCorrect,
        numDue: datum.numDueAtStartOfDay,
        numCompleted: datum.numCompleted,
      };
    }) || [];

  return (
    <div className={classes.root}>
      <div className={classes.titleContainer}>
        <Typography variant="h2" color="primary" className={classes.title}>
          Personal Deck Progress over Past 3 Months
        </Typography>
        <Tooltip
          title={`${streak} day(s) in a row with 0 questions due at the end of the day.`}
        >
          <Aggregate label="Streak" count={streak} />
        </Tooltip>
        <Alert severity="info" className={classes.alert}>
          Color coding is based on number of questions remaining at the end of
          each day.
        </Alert>
      </div>
      <div className={classes.container}>
        <CalendarHeatmap
          startDate={THREE_MONTHS_AGO}
          endDate={TODAY}
          showWeekdayLabels
          values={processed}
          tooltipDataAttrs={(value: HeatMapCell) => {
            if (!value.date || value.count === null) {
              return {
                'data-tip': 'No Personal Deck Data',
              };
            }
            if (value.frozen) {
              return {
                'data-tip': 'Personal Deck Frozen',
              };
            }
            return {
              'data-html': true,
              'data-tip': `
                <div>
                  <strong style="text-decoration: underline">${value.date
                    .toDateString()
                    .slice(0, 10)}</strong>
                  <div>
                    <strong>Question(s) Due at Start of Day:</strong> ${
                      value.numDue
                    }
                  </div>
                  <div>
                    <strong>Question(s) Completed</strong> ${value.numCompleted}
                  </div>
                  <div>
                    <strong>Question(s) Due at End of Day:</strong> ${
                      value.count
                    }
                  </div>
                </div>
                `,
            };
          }}
          onClick={handleClick}
          classForValue={(value) => {
            if (!value || value.count === null) {
              return 'color-empty';
            }
            if (value.frozen) {
              return 'clickable color-scale-1';
            }
            if (value.count === 0) {
              return 'clickable color-scale-2';
            }
            if (value.count > 0 && value.count < 10) {
              return 'clickable color-scale-3';
            }
            if (value.count >= 10 && value.count <= 20) {
              return 'clickable color-scale-4';
            }

            if (value.count > 20 && value.count <= 30) {
              return 'clickable color-scale-5';
            }
            if (value.count > 30) {
              return 'clickable color-scale-6';
            }
          }}
        />
      </div>
      <ResultsModal
        open={!!selectedDate}
        loading={responseLoading}
        assignmentLoading={responseLoading}
        handleClose={handleClose}
        data={
          responseData
            ? {
                studentsAssignmentsQuestions:
                  responseData?.enrollmentResponsesByDate,
              }
            : undefined
        }
        assignmentData={
          selectedInfo
            ? {
                studentsAssignmentsDatum: {
                  student: {
                    fullName: activeEnrollment?.student.fullName || '',
                  },
                  groupsAssignment: {
                    assignment: {
                      name: `Personal Deck ${
                        selectedDate
                          ? `on ${selectedDate.toDateString().slice(0, 10)}`
                          : ''
                      }`,
                    },
                    dueDate: selectedDate,
                    group: { name: activeEnrollment?.group?.name || '' },
                  },
                  questionsTotal:
                    selectedInfo.numDue < selectedInfo.numCompleted
                      ? selectedInfo.numCompleted
                      : selectedInfo.numDue,
                  questionsCompleted: selectedInfo.numCompleted,
                  questionsCorrect: selectedInfo.numCorrect,
                  questionsAttempted: selectedInfo.numAttempted,
                },
              }
            : undefined
        }
      />{' '}
      <ReactTooltip multiline html />
    </div>
  );
}
