import { Typography, type Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ComponentPropsWithRef } from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    border: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: 4,
    padding: theme.spacing(1.5),
    display: 'flex',
  },
  label: {
    marginRight: theme.spacing(1),
  },
}));

type AggregateProps = {
  label: string;
  count: number;
};

export function Aggregate(
  props: AggregateProps & ComponentPropsWithRef<'div'>
) {
  const classes = useStyles();
  const { count, label, ...divProps } = props;

  return (
    <div {...divProps} className={classes.root}>
      <Typography variant="h6" color="primary" className={classes.label}>
        {`${label}:`}
      </Typography>
      <Typography variant="h6" color="secondary">
        {count}
      </Typography>
    </div>
  );
}
