import { Drawer, Hidden, type Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { NavList } from './NavList';

export const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: drawerWidth,
  },
  drawerPaper: {
    color: theme.palette.common.white,
    background: theme.palette.primary.main,
    width: drawerWidth,
  },
}));

type NavProps = {
  mobileOpen: boolean;
  closeDrawer: () => void;
};

export function Nav({ mobileOpen, closeDrawer }: NavProps) {
  const classes = useStyles();

  return (
    <nav>
      <Hidden mdDown implementation="js">
        <Drawer
          className={classes.root}
          variant="permanent"
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <NavList closeDrawer={closeDrawer} />
        </Drawer>
      </Hidden>
      <Hidden mdUp implementation="js">
        <Drawer
          variant="temporary"
          anchor="left"
          open={mobileOpen}
          onClose={closeDrawer}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          <NavList closeDrawer={closeDrawer} />
        </Drawer>
      </Hidden>
    </nav>
  );
}
