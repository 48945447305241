import { Grid, useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';
import { DisplayStatus } from './AssignmentCardHeader';

export function DueDate(props: { status: DisplayStatus; dueDate: Date }) {
  const theme = useTheme();
  return (
    <Grid container sx={{ gap: theme.spacing(0.5) }}>
      <Typography sx={{ fontWeight: 'light' }}>Due: </Typography>
      <Typography
        color={props.status === 'Past Due' ? 'error' : 'default'}
        sx={{ fontWeight: 500 }}
      >
        {Intl.DateTimeFormat('en-US', {
          month: '2-digit',
          day: '2-digit',
          year: 'numeric',
          hour: 'numeric',
          minute: '2-digit',
          hour12: true,
        }).format(props.dueDate)}
      </Typography>
    </Grid>
  );
}
