import * as Types from '../../types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type TempLeaderboardQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type TempLeaderboardQuery = {
  __typename?: 'Query';
  tempLeaderboard: any;
};

export const TempLeaderboardDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'tempLeaderboard' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'tempLeaderboard' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  TempLeaderboardQuery,
  TempLeaderboardQueryVariables
>;
