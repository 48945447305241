import { Route, Routes } from 'react-router-dom';
import { PersonalDeck } from '../../components/application/PersonalDeck';

export function PersonalDeckView() {
  return (
    <Routes>
      <Route path="study" element={<PersonalDeck />} />
    </Routes>
  );
}
