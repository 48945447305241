import { Typography, type Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  alert: {
    margin: `${theme.spacing(2)} ${theme.spacing(1)}`,
  },
}));

export function ActiveLateDistributedAssignmentExplanation() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <p>
        <Typography>
          You can earn points towards late Personal Deck Assignments either:
        </Typography>
      </p>
      <ul>
        <li>
          When you&apos;ve finished a currently active Personal Deck Assignment
          (by hitting the required &ldquo;Target Points&rdquo;), any extra
          credit points earned will count towards BOTH the active Personal Deck
          Assignment and one late Personal Deck Assignment (the points
          &ldquo;double dip&rdquo; 😮).
        </li>
        <li>
          When there isn&apos;t another active Personal Deck Assignments, study
          sessions automatically count towards the most recent late Personal
          Deck Assignment.
        </li>
      </ul>
      <p>
        <Typography>
          In other words, this late Personal Deck assignment is showing up here
          because you are currently able to earn points towards it! 😄
        </Typography>
      </p>
    </div>
  );
}
