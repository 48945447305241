import { useMutation } from '@apollo/client';
import type { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { subWeeks } from 'date-fns';
import React, { useState } from 'react';
import { TrackEventDocument } from '../../../../gql/queries/__generated__/event.generated';
import { VISIT_QUESTIONS_MASTERY } from '../../../../utils/events';
import { QuestionsProgressDetails } from './Details';
import { ProgressBreakdown } from './ProgressBreakdown';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  container: {
    display: 'flex',
    justifyContent: 'center',
    overflow: 'scroll',
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(2),
      justifyContent: 'flex-start',
    },
  },
}));

const timeFrames = [
  {
    label: 'Recently Introduced',
    description: 'Questions introduced in the last week',
    firstSeenStart: subWeeks(new Date(), 1),
    firstSeenEnd: null,
  },
  {
    label: '1-3 weeks ago',
    description: 'Questions introduced 1-3 weeks ago',
    firstSeenStart: subWeeks(new Date(), 3),
    firstSeenEnd: subWeeks(new Date(), 1),
  },
  {
    label: '3 - 8 weeks ago',
    description: 'Questions introduced 3-8 weeks ago',
    firstSeenStart: subWeeks(new Date(), 8),
    firstSeenEnd: subWeeks(new Date(), 3),
  },
  {
    label: '8+ weeks ago',
    description: 'Questions introduced more than 2 months ago',
    firstSeenStart: null,
    firstSeenEnd: subWeeks(new Date(), 8),
  },
];

export function QuestionsProgress() {
  const classes = useStyles();
  const [selectedQuestionIds, setSelectedQuestionIds] = useState<string[]>([]);
  const [trackEvent] = useMutation(TrackEventDocument, {
    variables: {
      event: VISIT_QUESTIONS_MASTERY,
    },
  });
  React.useEffect(() => {
    trackEvent();
  }, [trackEvent]);

  const handleModalClose = () => setSelectedQuestionIds([]);
  const handleClick = (questionIds: string[]) => {
    console.log('handleClick', questionIds);
    setSelectedQuestionIds(questionIds);
  };

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        {timeFrames.map(({ label, firstSeenStart, firstSeenEnd }) => (
          <ProgressBreakdown
            key={label}
            handleClick={handleClick}
            label={label}
            firstSeenStart={firstSeenStart}
            firstSeenEnd={firstSeenEnd}
          />
        ))}
      </div>
      {selectedQuestionIds.length > 0 && (
        <QuestionsProgressDetails
          questionIds={selectedQuestionIds}
          handleClose={handleModalClose}
        />
      )}
    </div>
  );
}
