import type { ReactQuillProps } from 'react-quill';
import { Editor } from '.';

type EditorProps = {
  errorMessage?: string;
  onSubmit?: (e: React.KeyboardEvent<HTMLDivElement>) => void;
};

export function InlineEditor(props: EditorProps & ReactQuillProps) {
  return (
    <div className="inline-react-quill-editor">
      <Editor
        value={props.value}
        onChange={props.onChange}
        modules={{
          formula: true,
          toolbar: [['formula']],
        }}
        style={{ minWidth: '200px' }}
        theme={props.theme}
        defaultValue={''}
        errorMessage={props.errorMessage}
      />
    </div>
  );
}
