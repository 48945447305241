import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  createHttpLink,
  InMemoryCache,
  Operation,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { useAuth0 } from '@auth0/auth0-react';
import { LinearProgress } from '@mui/material';
import { isUnknownRecord } from '@podsie/utils/object.js';
import * as Sentry from '@sentry/react';
import ActionCable from 'actioncable';
import ActionCableLink from 'graphql-ruby-client/subscriptions/ActionCableLink';
import React, { useContext, useEffect, useState } from 'react';
import { IndexContext } from './IndexContext';
import { config } from './podsie-config';
import { LS_PODSIE_STUDENT_VERSION } from './utils/localStorageKeys';

const httpLink = createHttpLink({
  uri: config.server,
});

const httpLinkBackup = createHttpLink({
  uri: config.serverBackup,
});

const checkVersionLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((response) => {
    if (
      isUnknownRecord(response) &&
      typeof response[LS_PODSIE_STUDENT_VERSION] === 'string'
    ) {
      const savedVersion = localStorage.getItem(LS_PODSIE_STUDENT_VERSION);
      if (savedVersion !== response[LS_PODSIE_STUDENT_VERSION].toString()) {
        localStorage.setItem(
          LS_PODSIE_STUDENT_VERSION,
          response[LS_PODSIE_STUDENT_VERSION]
        );
        // if there was previously a saved version, then reload the page:
        if (savedVersion) {
          window.location.reload();
        }
      }
    }
    return response;
  });
});

const errorLink = onError(({ networkError }) => {
  if (networkError) {
    Sentry.captureMessage(`Network error: ${networkError}`);
    if ('statusCode' in networkError && networkError.statusCode === 503) {
      networkError.message =
        'Podsie is currently not available due to scheduled maintenance. This should only take a few minutes, but please email our team at hello@podsie.org if you have any questions.';
    } else {
      networkError.message =
        'A network error occurred. Please refresh your page. If this issue persists, please email our team at hello@podsie.org.';
    }
  }
});

const hasSubscriptionOperation = ({ query: { definitions } }: Operation) => {
  return definitions.some(
    (node) =>
      node.kind === 'OperationDefinition' && node.operation === 'subscription'
  );
};

type CustomApolloProviderProps = {
  children: React.ReactElement;
};

export function CustomApolloProvider({ children }: CustomApolloProviderProps) {
  const {
    getAccessTokenSilently,
    loginWithRedirect,
    isAuthenticated,
    logout,
    isLoading,
    user,
  } = useAuth0();
  const { enrollmentId } = useContext(IndexContext);
  const [client, setClient] = useState<ApolloClient<unknown> | null>(null);

  useEffect(() => {
    if (isLoading) return;
    getAccessTokenSilently()
      .then((token) => {
        const setAuthLink = setContext((_, { headers }) => ({
          headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : null,
            'enrollment-id': enrollmentId,
          },
        }));

        let userLink = httpLink;
        let cable = ActionCable.createConsumer(config.websocketServer ?? '');

        if (
          user &&
          user.email &&
          (user.email.includes('lexrich') ||
            user.email === 'kyobs@hotmail.com' ||
            user.email.indexOf('collierschools') !== -1)
        ) {
          userLink = httpLinkBackup;
          cable = ActionCable.createConsumer(
            config.websocketServerBackup ?? ''
          );
        }
        const httpAndAuthLink = ApolloLink.from([
          errorLink,
          checkVersionLink,
          setAuthLink,
          userLink,
        ]);
        const link = ApolloLink.split(
          hasSubscriptionOperation,
          new ActionCableLink({ cable }),
          httpAndAuthLink
        );

        const client = new ApolloClient({
          link,
          cache: new InMemoryCache({
            typePolicies: {
              Query: {
                fields: {
                  // explicitly delete dangling reference for QuestionShow component
                  question(existingQuestion, { canRead }) {
                    return canRead(existingQuestion)
                      ? existingQuestion
                      : undefined;
                  },
                },
              },
            },
          }),
          connectToDevTools: config.env === 'development',
        });

        setClient(client);
      })
      .catch((err) => {
        console.error('Error Occurred:', err);
        // call logout to ensure that credentials are fully cleared
        // when something goes wrong:
        logout();
        loginWithRedirect();
      });
  }, [
    isAuthenticated,
    user,
    enrollmentId,
    isLoading,
    getAccessTokenSilently,
    logout,
    loginWithRedirect,
  ]);

  if (!client) {
    return <LinearProgress />;
  }

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}
