import { Typography, type Theme } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import type { ActionItemQuery } from '../../../../gql/queries/__generated__/actionItem.generated';
import { renderQuestionType } from '../../../../utils/renderQuestionType';
import { QuillDeltaAsHtml } from '../../../shared/QuillDeltaAsHtml';
import { FlaggedResponseAnswers } from './FlaggedResponseAnswers';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100%',
    position: 'relative',
  },
  info: {
    marginTop: theme.spacing(1),
    height: '100%',
    overflow: 'auto',
  },
}));

type FlaggedResponseProps = {
  response: ActionItemQuery['actionItem']['actionable'];
};

export function FlaggedResponse({ response }: FlaggedResponseProps) {
  const theme = useTheme();
  const classes = useStyles();
  const question = response.response.question;
  const type = question.questionType;

  return (
    <div className={classes.root}>
      <div className={classes.info}>
        <Typography variant="h5">
          {renderQuestionType(type)} Question:
        </Typography>
        <QuillDeltaAsHtml
          delta={question.richText.ops}
          style={{
            backgroundColor: 'inherit',
            padding: `${theme.spacing(1)} 0`,
          }}
        />
        <FlaggedResponseAnswers flaggedResponse={response} />
      </div>
    </div>
  );
}
