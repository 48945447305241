import { useQuery } from '@apollo/client';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Tab, type Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import { CurrentAssignments } from '../../components/application/Assignments/CurrentAssignments';
import { PreviousAssignments } from '../../components/application/Assignments/PreviousAssignments';
import {
  StudentsAssignmentsDataDocument,
  type StudentsAssignmentsDataQuery,
} from '../../gql/queries/__generated__/studentsAssignmentsDatum.generated';
import { AssignmentStatusEnum, AssignmentTypeEnum } from '../../gql/types';
import { isCompleted } from '../../utils/assignments';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  tabsContainer: {
    borderBottom: `1px solid ${theme.palette.grays.dark}`,
    marginTop: theme.spacing(4),
    paddingLeft: theme.spacing(4),
  },
}));

export type DatumType =
  StudentsAssignmentsDataQuery['studentsAssignmentsData'][0];

export function Assignments() {
  const classes = useStyles();
  const {
    appState: { activeEnrollment },
  } = useContext(AppContext);
  const { data, refetch, loading } = useQuery(StudentsAssignmentsDataDocument, {
    skip: !activeEnrollment?.groupId,
    variables: {
      // @ts-expect-error as we skip the query if the group id doesn't exist
      groupId: activeEnrollment?.groupId,
    },
    fetchPolicy: 'cache-and-network',
  });
  const location = useLocation();
  const { pathname } = location;
  useEffect(() => {
    if (activeEnrollment?.groupId) {
      refetch();
    }
  }, [pathname, refetch, activeEnrollment]);
  const [value, setValue] = useState('1');

  const handleChange = (event: unknown, newValue: string) => {
    setValue(newValue);
  };

  const completedAssignments: DatumType[] = [];
  const optionalAssignments: DatumType[] = [];

  const currentAssignments: DatumType[] = [];
  let activeDistributedAssignment: DatumType | undefined;
  let lateDistributedAssignment: DatumType | undefined;

  const current = new Date();

  data?.studentsAssignmentsData.forEach((sad) => {
    let due;
    if (sad.groupsAssignment) {
      due = new Date(sad.groupsAssignment.dueDate);
    } else if (sad.enrollmentsAssignment) {
      due = new Date(sad.enrollmentsAssignment.dueDate);
    }
    const pastDue =
      (due && due < current) ||
      sad.groupsAssignment?.assignment.assignmentStatus ===
        AssignmentStatusEnum.PastDue ||
      sad.enrollmentsAssignment?.assignment.assignmentStatus ===
        AssignmentStatusEnum.PastDue;
    const archived =
      sad.groupsAssignment?.assignment.assignmentStatus ===
        AssignmentStatusEnum.Archived ||
      sad.enrollmentsAssignment?.assignment.assignmentStatus ===
        AssignmentStatusEnum.Archived;
    const optional =
      sad.groupsAssignment?.assignment?.optional ||
      sad.enrollmentsAssignment?.assignment?.optional;

    const distributed =
      sad.groupsAssignment?.assignment.assignmentType ===
      AssignmentTypeEnum.Distributed;

    if (archived) {
      // don't display archived assignments
      return;
    }
    const pointsEarned = sad.distributedPointsEarned || 0;
    const targetPoints =
      sad.groupsAssignment?.assignment.targetDistributedPoints || 0;

    const standardPast =
      !distributed && pastDue && sad.questionsCompleted >= sad.questionsTotal;
    const distributedPast =
      (distributed && pastDue && pointsEarned >= targetPoints) ||
      (distributed && pastDue && optional); // distributed late optional assignments should always show up under completed
    const past = standardPast || distributedPast;
    if (past || isCompleted(sad)) {
      completedAssignments.push(sad);
    } else if (optional && !distributed) {
      optionalAssignments.push(sad);
    } else {
      if (distributed) {
        if (!pastDue) {
          activeDistributedAssignment = sad;
        } else if (
          !lateDistributedAssignment &&
          pastDue &&
          (!activeDistributedAssignment ||
            activeDistributedAssignment.completedAt)
        ) {
          // if there isn't a lateDistributedAssignment set yet,
          // and it's the distributed assignment is past due,
          // and either there is not an activeDistributedAssignment, or
          // there is one but it's complete, then show a lateDistributedAssignment
          // that students can make progress towards:
          lateDistributedAssignment = sad;
        } else {
          currentAssignments.push(sad);
        }
      } else {
        currentAssignments.push(sad);
      }
    }
  });
  return (
    <div className={classes.root}>
      <TabContext value={value}>
        <TabList
          className={classes.tabsContainer}
          onChange={handleChange}
          aria-label="Assignments type tabs"
        >
          <Tab label="DONE" value="1" />
          <Tab label={`OPTIONAL (${optionalAssignments.length})`} value="2" />
        </TabList>
        <TabPanel value={'1'}>
          <PreviousAssignments
            studentsAssignmentsData={completedAssignments}
            loading={loading}
          />
        </TabPanel>
        <TabPanel value={'2'}>
          <CurrentAssignments
            assignments={optionalAssignments}
            optionalAssignments
            loading={loading}
          />
        </TabPanel>
      </TabContext>
    </div>
  );
}
