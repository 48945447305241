import * as Types from '../../types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type AssignmentQueryVariables = Types.Exact<{
  assignmentId: Types.Scalars['ID']['input'];
}>;

export type AssignmentQuery = {
  __typename?: 'Query';
  assignment: {
    __typename?: 'Assignment';
    id: string;
    createdAt: any;
    name: string;
    description?: string | null;
    numSpiraledQuestions: number;
    assignmentType: Types.AssignmentTypeEnum;
    stage?: string | null;
    standards: Array<{
      __typename?: 'Standard';
      title: string;
      description: string;
      id: string;
    }>;
    groupsAssignments?: Array<{
      __typename?: 'GroupsAssignment';
      id: string;
      dueDate?: any | null;
      group: { __typename?: 'Group'; name: string; id: string };
    }> | null;
    questions: Array<{
      __typename?: 'Question';
      numAttemptsUntilAnswerShown: number;
      richText: any;
      id: string;
      plainText: string;
      questionType: Types.QuestionTypeEnum;
      freeResponseAnswer?: {
        __typename?: 'FreeResponseAnswer';
        id: string;
        text: string;
        richText: any;
      } | null;
      multipleChoiceAnswerChoices?: Array<{
        __typename?: 'MultipleChoiceAnswerChoice';
        id: string;
        isCorrect: boolean;
        plainText: string;
        richText: any;
        text: string;
      }> | null;
      shortAnswerAnswers?: Array<{
        __typename?: 'ShortAnswerAnswer';
        hidden: boolean;
        id: string;
        isCaseSensitive: boolean;
        richText: any;
        text: string;
      }> | null;
      supplement?: {
        __typename?: 'Supplement';
        id: string;
        plainText: string;
        richText: any;
      } | null;
      standards?: Array<{
        __typename?: 'Standard';
        description: string;
        id: string;
        title: string;
      }> | null;
    }>;
  };
};

export type AssignmentStageQueryVariables = Types.Exact<{
  assignmentId: Types.Scalars['ID']['input'];
}>;

export type AssignmentStageQuery = {
  __typename?: 'Query';
  assignmentCollaboration: {
    __typename?: 'Assignment';
    id: string;
    stage?: string | null;
  };
};

export const AssignmentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'assignment' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'assignmentId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assignment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'assignmentId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'assignmentId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'assignmentShowAttributes' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'questionIndexAttributes' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Question' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plainText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'questionType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'standards' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'questionAttributes' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Question' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'questionIndexAttributes' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'freeResponseAnswer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                { kind: 'Field', name: { kind: 'Name', value: 'richText' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'multipleChoiceAnswerChoices' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isCorrect' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plainText' } },
                { kind: 'Field', name: { kind: 'Name', value: 'richText' } },
                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'numAttemptsUntilAnswerShown' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'richText' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shortAnswerAnswers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'hidden' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isCaseSensitive' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'richText' } },
                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'supplement' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plainText' } },
                { kind: 'Field', name: { kind: 'Name', value: 'richText' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'assignmentShowAttributes' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Assignment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'numSpiraledQuestions' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'assignmentType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stage' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'standards' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'groupsAssignments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dueDate' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'group' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'questions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'questionAttributes' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AssignmentQuery, AssignmentQueryVariables>;
export const AssignmentStageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'assignmentStage' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'assignmentId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assignmentCollaboration' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'assignmentId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'assignmentId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'stage' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AssignmentStageQuery,
  AssignmentStageQueryVariables
>;
