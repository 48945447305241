import { useQuery } from '@apollo/client';
import { Dialog, type Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { StandardAssignment } from '.';
import { AppContext } from '../../../../AppContext';
import { EnrollmentsAssignmentsByStudentDocument } from '../../../../gql/queries/__generated__/enrollmentsAssignments.generated';
import { ActiveGroupsAssignmentsForStudentDocument } from '../../../../gql/queries/__generated__/groupsAssignment.generated';
import { AssignmentStatusEnum } from '../../../../gql/types';
import { LoadingSkeletons } from '../../../shared/Loaders/LoadingSkeletons';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
  },
}));

type RouteParams = {
  assignmentId: string;
};

export function AssignmentSession() {
  const classes = useStyles();
  const { activeEnrollment } = useContext(AppContext).appState;
  const {
    data: enrollmentsAssignmentData,
    loading: enrollmentsAssignmentsLoading,
  } = useQuery(EnrollmentsAssignmentsByStudentDocument, {
    variables: {
      enrollmentId: activeEnrollment?.id ?? '',
      statuses: [
        AssignmentStatusEnum.Active,
        AssignmentStatusEnum.InProgress,
        AssignmentStatusEnum.PastDue,
      ],
    },
  });

  const { data: groupsAssignmentsData, loading: groupsAssignmentsLoading } =
    useQuery(ActiveGroupsAssignmentsForStudentDocument, {
      variables: {},
    });
  const params = useParams<RouteParams>();

  if (groupsAssignmentsLoading || enrollmentsAssignmentsLoading) {
    return <LoadingSkeletons num={20} />;
  }

  const selectedGroupsAssignment =
    groupsAssignmentsData?.activeGroupsAssignmentsForStudent.find(
      (groupsAssignment) => {
        return (
          groupsAssignment.assignment.id === params.assignmentId &&
          groupsAssignment.group.id === activeEnrollment?.groupId
        );
      }
    );

  const selectedEnrollmentsAssignment =
    enrollmentsAssignmentData?.enrollmentsAssignmentsByStudent.find(
      (enrollmentsAssignment) => {
        return enrollmentsAssignment.assignment.id === params.assignmentId;
      }
    );

  let assignmentName = '';
  if (selectedEnrollmentsAssignment) {
    if (selectedEnrollmentsAssignment.enrollment.id !== activeEnrollment?.id) {
      assignmentName = selectedEnrollmentsAssignment.assignment.name;
    }
  }

  if (!selectedEnrollmentsAssignment && !selectedGroupsAssignment) {
    return <LoadingSkeletons num={20} />;
  }
  return (
    <Dialog
      classes={{ paper: classes.root }}
      disableEnforceFocus
      fullScreen
      open
    >
      <StandardAssignment
        enrollmentsAssignmentId={selectedEnrollmentsAssignment?.id}
        groupsAssignmentId={selectedGroupsAssignment?.id}
        selectedAssignmentName={assignmentName}
      />
    </Dialog>
  );
}
