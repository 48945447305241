import { Grid } from '@mui/material';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import theme from '../../../../theme';
import { DisplayStatus } from './AssignmentCardHeader';
import { BaseCard } from './BaseCard';
import { DueDate } from './DueDate';

type StandardAssignmentCardProps = {
  id: string;
  assignmentName: string;
  dueDate?: Date;
  status: DisplayStatus;
  completedQuestions: number;
  totalQuestions: number;
};

export function StandardAssignmentCard(props: StandardAssignmentCardProps) {
  const navigate = useNavigate();
  const content = (
    <>
      <Grid
        container
        sx={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          gap: theme.spacing(1),
        }}
      >
        <Grid item gap={theme.spacing(0.5)} display={'flex'}>
          <Typography sx={{ fontWeight: 'light' }}>Completed: </Typography>
          <Typography sx={{ fontWeight: 500 }}>
            {props.completedQuestions}/{props.totalQuestions}
          </Typography>
        </Grid>
        <Grid item gap={theme.spacing(0.5)} display={'flex'}>
          <Typography sx={{ fontWeight: 'light' }}>Questions Left: </Typography>
          <Typography sx={{ fontWeight: 500 }}>
            {props.totalQuestions - props.completedQuestions}
          </Typography>
        </Grid>
      </Grid>
      {props.dueDate && (
        <DueDate status={props.status} dueDate={props.dueDate} />
      )}
    </>
  );

  const actions = (
    <Button
      variant="contained"
      sx={{ fontWeight: 'light', backgroundColor: '#5CB277' }}
      onClick={() => {
        navigate(`/assignments/${props.id}`);
      }}
    >
      Begin Assignment
    </Button>
  );
  return (
    <BaseCard
      content={content}
      actions={actions}
      assignmentName={props.assignmentName}
      assignmentStatus={props.status}
    />
  );
}
