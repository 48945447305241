import { fetchEventSource } from '@microsoft/fetch-event-source';
import { config } from '../podsie-config';

const API_URL = config.server;

export async function* readTextStream(
  endpoint: string,
  params?: Record<string, string | undefined>
) {
  if (!API_URL) throw new Error(`fetchStream: VITE_SERVER is not set`);
  const url = new URL(endpoint, API_URL);
  for (const [key, value] of Object.entries(params || {})) {
    if (value !== undefined) {
      url.searchParams.append(key, value);
    }
  }
  const response = await fetch(url.toString());
  if (!response.ok)
    throw new Error(
      `fetchStream: http error: ${response.status}, ${response.statusText}`
    );
  if (!response.body) throw new Error(`fetchStream: response has no body`);
  const reader = response.body.getReader();
  const decoder = new TextDecoder('utf-8');
  while (true) {
    const { done, value } = await reader.read();
    if (done) break;
    yield decoder.decode(value, { stream: true });
  }
}

// remove the "/graphql" from end of the url:
const serverUrl = API_URL?.replace(/\/graphql$/, '') || '';

export async function streamTokens({
  url,
  updaterFunc,
  params,
}: {
  url: string;
  updaterFunc: (text: string) => void;
  params?: unknown;
}) {
  if (!serverUrl) throw new Error(`fetchStream: server url is not set`);

  await fetchEventSource(`${serverUrl}/api/v1${url}`, {
    openWhenHidden: true,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(params),
    onmessage(ev) {
      let token = ev.data;
      switch (ev.data) {
        case '':
          token = '\n';
          break;
        default:
          break;
      }
      updaterFunc(token);
    },
    onerror(err) {
      console.error(err);
    },
  });
}
