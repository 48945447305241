import { useQuery } from '@apollo/client';
import { Typography, type Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../../../../AppContext';
import {
  StudySessionResponsesDocument,
  StudySessionResponsesQuery,
} from '../../../../gql/queries/__generated__/personalDeckQuestion.generated';
import { StudySessionByIdDocument } from '../../../../gql/queries/__generated__/personalDeckStudySession.generated';
import { StudentsAssignmentsDatumDocument } from '../../../../gql/queries/__generated__/studentsAssignmentsDatum.generated';
import type {
  FreeResponseResponse,
  MultipleChoiceResponse,
  Question,
  Scalars,
  ShortAnswerResponse,
} from '../../../../gql/types';
import { StandardButton } from '../../../shared/Buttons/StandardButton';
import { LoadingSkeletons } from '../../../shared/Loaders/LoadingSkeletons';
import { AssignmentResult } from '../../Results/AssignmentResult';
import { ResultsModal } from '../../Results/ResultsModal';
import { StudySessionContext } from '../../StudySession/context';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    width: '100%',
    height: '100vh',
    overflow: 'auto',
    display: 'flex',
    justifyContent: 'center',
  },
  textContainer: {
    color: theme.palette.common.white,
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '95%',
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      width: '65%',
    },
    [theme.breakpoints.up('md')]: {
      width: '55%',
    },
    [theme.breakpoints.up('lg')]: {
      width: '45%',
    },
    [theme.breakpoints.up('xl')]: {
      width: '40%',
    },
  },
  confettiContainer: {
    paddingTop: '25%',
    paddingBottom: '19%',
    marginBottom: theme.spacing(2),
    backgroundImage: "url('/images/confetti-background.svg')",
    backgroundSize: 'cover',
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'center',
    width: '100%',
  },
  congratsContainer: {
    marginTop: theme.spacing(4),
  },
  congrats: {
    fontSize: 60,
    textAlign: 'center',
    marginBottom: theme.spacing(2.5),
  },
  buttonsContainer: {
    marginTop: theme.spacing(4),
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '50%',
    marginBottom: theme.spacing(3),
  },
  resultsButton: {
    width: '100%',
    color: theme.palette.common.white,
  },
  assignmentPageButton: {
    width: '100%',
    marginTop: theme.spacing(2),
    color: theme.palette.secondary.main,
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.secondary.main}`,
    '&:hover': {
      backgroundColor: theme.palette.grays.main,
    },
  },
}));

export type PersonalDeckQuestionsResult = {
  question: Question;
  freeResponseResponses: Array<FreeResponseResponse>;
  shortAnswerResponses: Array<ShortAnswerResponse>;
  multipleChoiceResponses: Array<MultipleChoiceResponse>;
};

export type PersonalDeckQuestionsResults = {
  studentsAssignmentsQuestions: StudySessionResponsesQuery['studySessionResponses'];
};

export type PersonalDeckQuestionsInfo = {
  studentsAssignmentsDatum: {
    student: {
      fullName: string;
    };
    groupsAssignment: {
      assignment: { name: string };
      dueDate: Scalars['ISO8601Date']['input'];
      group: { name: string };
    };
    questionsTotal: number;
    questionsCompleted: number;
    questionsAttempted?: number;
    completedAt?: Scalars['ISO8601Date'];
    questionsCorrect: number;
  };
};

type AssignmentFinishedProps = {
  name: string;
};

export function AssignmentFinished({ name }: AssignmentFinishedProps) {
  const classes = useStyles();
  const navigate = useNavigate();
  const {
    groupsAssignmentId,
    enrollmentsAssignmentId,
    personalDeckStudySessionId,
  } = useContext(StudySessionContext);
  const {
    appState: { activeEnrollment },
  } = useContext(AppContext);
  const [open, setOpen] = useState(false);
  const navToAssignments = () => navigate('/assignments');
  const closeModal = () => setOpen(false);
  const openResults = () => setOpen(true);
  const { data: completionData, loading } = useQuery(
    StudentsAssignmentsDatumDocument,
    {
      skip: !groupsAssignmentId && !enrollmentsAssignmentId,
      variables: {
        groupsAssignmentId,
        enrollmentsAssignmentId,
      },
    }
  );
  const { data: studySessionData, loading: studySessionLoading } = useQuery(
    StudySessionResponsesDocument,
    {
      skip: !personalDeckStudySessionId,
      variables: {
        personalDeckStudySessionId: personalDeckStudySessionId || '',
      },
    }
  );
  const { data: studySessionInfo } = useQuery(StudySessionByIdDocument, {
    skip: !personalDeckStudySessionId,
    variables: {
      personalDeckStudySessionId: personalDeckStudySessionId || '',
    },
  });
  if (loading) {
    return <LoadingSkeletons num={20} />;
  }

  return (
    <div className={classes.root}>
      <div className={classes.textContainer}>
        <div className={classes.confettiContainer}>
          <Typography variant="body1">{name}</Typography>
          <Typography variant="body1">Completed</Typography>
          <div className={classes.congratsContainer}>
            <Typography className={classes.congrats} variant="h1">
              GREAT
            </Typography>
            <Typography className={classes.congrats} variant="h1">
              JOB!
            </Typography>
          </div>
        </div>
        <Typography variant="body1">
          Keep up the good work and watch your knowledge grow!
        </Typography>
        <div className={classes.buttonsContainer}>
          <StandardButton
            className={classes.resultsButton}
            text="VIEW RESULTS"
            onClick={openResults}
          />
          <StandardButton
            onClick={navToAssignments}
            text="Assignments Page"
            className={classes.assignmentPageButton}
          />
        </div>
      </div>
      {completionData && (
        <AssignmentResult
          open={open}
          groupsAssignmentId={groupsAssignmentId}
          enrollmentsAssignmentId={enrollmentsAssignmentId}
          studentId={activeEnrollment?.student.id || ''}
          onClose={closeModal}
          fullWidth
          maxWidth="md"
          handleClose={closeModal}
        />
      )}
      {personalDeckStudySessionId && (
        <ResultsModal
          open={open}
          loading={studySessionLoading}
          assignmentLoading={studySessionLoading}
          handleClose={closeModal}
          data={
            studySessionData
              ? {
                  studentsAssignmentsQuestions:
                    studySessionData.studySessionResponses,
                }
              : undefined
          }
          assignmentData={
            studySessionInfo
              ? {
                  studentsAssignmentsDatum: {
                    student: {
                      fullName: activeEnrollment?.student.firstName || '',
                    },
                    groupsAssignment: {
                      assignment: { name: 'Personal Deck Session' },
                      dueDate: studySessionInfo.studySessionById.finishedAt,
                      group: { name: activeEnrollment?.group?.name || '' },
                    },
                    questionsTotal:
                      studySessionInfo.studySessionById.totalQuestions,
                    questionsCompleted:
                      studySessionInfo.studySessionById.numCompleted,
                    questionsCorrect:
                      studySessionInfo.studySessionById.totalCorrect,
                    questionsAttempted:
                      studySessionInfo.studySessionById.totalQuestions,
                  },
                }
              : undefined
          }
        />
      )}
    </div>
  );
}
