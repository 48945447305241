import * as Types from '../../types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type NextPersonalDeckQuestionQueryVariables = Types.Exact<{
  personalDeckStudySessionId: Types.Scalars['ID']['input'];
  previousQuestionId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
}>;

export type NextPersonalDeckQuestionQuery = {
  __typename?: 'Query';
  nextPersonalDeckQuestion?: {
    __typename?: 'PersonalDecksQuestion';
    id: string;
    question: {
      __typename?: 'Question';
      numAttemptsUntilAnswerShown: number;
      richText: any;
      id: string;
      plainText: string;
      questionType: Types.QuestionTypeEnum;
      freeResponseAnswer?: {
        __typename?: 'FreeResponseAnswer';
        id: string;
        text: string;
        richText: any;
      } | null;
      multipleChoiceAnswerChoices?: Array<{
        __typename?: 'MultipleChoiceAnswerChoice';
        id: string;
        isCorrect: boolean;
        plainText: string;
        richText: any;
        text: string;
      }> | null;
      shortAnswerAnswers?: Array<{
        __typename?: 'ShortAnswerAnswer';
        hidden: boolean;
        id: string;
        isCaseSensitive: boolean;
        richText: any;
        text: string;
      }> | null;
      supplement?: {
        __typename?: 'Supplement';
        id: string;
        plainText: string;
        richText: any;
      } | null;
      standards?: Array<{
        __typename?: 'Standard';
        description: string;
        id: string;
        title: string;
      }> | null;
    };
  } | null;
};

export type StudySessionResponsesQueryVariables = Types.Exact<{
  personalDeckStudySessionId: Types.Scalars['ID']['input'];
}>;

export type StudySessionResponsesQuery = {
  __typename?: 'Query';
  studySessionResponses: Array<{
    __typename?: 'PersonalDecksQuestion';
    question: {
      __typename?: 'Question';
      numAttemptsUntilAnswerShown: number;
      richText: any;
      id: string;
      plainText: string;
      questionType: Types.QuestionTypeEnum;
      freeResponseAnswer?: {
        __typename?: 'FreeResponseAnswer';
        id: string;
        text: string;
        richText: any;
      } | null;
      multipleChoiceAnswerChoices?: Array<{
        __typename?: 'MultipleChoiceAnswerChoice';
        id: string;
        isCorrect: boolean;
        plainText: string;
        richText: any;
        text: string;
      }> | null;
      shortAnswerAnswers?: Array<{
        __typename?: 'ShortAnswerAnswer';
        hidden: boolean;
        id: string;
        isCaseSensitive: boolean;
        richText: any;
        text: string;
      }> | null;
      supplement?: {
        __typename?: 'Supplement';
        id: string;
        plainText: string;
        richText: any;
      } | null;
      standards?: Array<{
        __typename?: 'Standard';
        description: string;
        id: string;
        title: string;
      }> | null;
    };
    freeResponseResponses?: Array<{
      __typename?: 'FreeResponseResponse';
      createdAt: any;
      id: string;
      selfAssessment?: Types.AssessmentValueEnum | null;
      teacherAssessment?: Types.AssessmentValueEnum | null;
      answer: string;
      richText: any;
      autograded: boolean;
    }> | null;
    shortAnswerResponses?: Array<{
      __typename?: 'ShortAnswerResponse';
      createdAt: any;
      id: string;
      answer: string;
      isCorrect: boolean;
    }> | null;
    multipleChoiceResponses?: Array<{
      __typename?: 'MultipleChoiceResponse';
      createdAt: any;
      id: string;
      isCorrect: boolean;
      selectedMultipleChoiceAnswerChoices: Array<{
        __typename?: 'SelectedMultipleChoiceAnswerChoice';
        id: string;
        multipleChoiceAnswerChoice: {
          __typename?: 'MultipleChoiceAnswerChoice';
          id: string;
          text: string;
          richText: any;
        };
      }>;
    }> | null;
  }>;
};

export type EnrollmentResponsesByDateQueryVariables = Types.Exact<{
  enrollmentId: Types.Scalars['ID']['input'];
  startDate: Types.Scalars['ISO8601DateTime']['input'];
  endDate?: Types.InputMaybe<Types.Scalars['ISO8601DateTime']['input']>;
}>;

export type EnrollmentResponsesByDateQuery = {
  __typename?: 'Query';
  enrollmentResponsesByDate: Array<{
    __typename?: 'PersonalDecksQuestion';
    question: {
      __typename?: 'Question';
      numAttemptsUntilAnswerShown: number;
      richText: any;
      id: string;
      plainText: string;
      questionType: Types.QuestionTypeEnum;
      freeResponseAnswer?: {
        __typename?: 'FreeResponseAnswer';
        id: string;
        text: string;
        richText: any;
      } | null;
      multipleChoiceAnswerChoices?: Array<{
        __typename?: 'MultipleChoiceAnswerChoice';
        id: string;
        isCorrect: boolean;
        plainText: string;
        richText: any;
        text: string;
      }> | null;
      shortAnswerAnswers?: Array<{
        __typename?: 'ShortAnswerAnswer';
        hidden: boolean;
        id: string;
        isCaseSensitive: boolean;
        richText: any;
        text: string;
      }> | null;
      supplement?: {
        __typename?: 'Supplement';
        id: string;
        plainText: string;
        richText: any;
      } | null;
      standards?: Array<{
        __typename?: 'Standard';
        description: string;
        id: string;
        title: string;
      }> | null;
    };
    freeResponseResponses?: Array<{
      __typename?: 'FreeResponseResponse';
      createdAt: any;
      id: string;
      selfAssessment?: Types.AssessmentValueEnum | null;
      teacherAssessment?: Types.AssessmentValueEnum | null;
      answer: string;
      richText: any;
      autograded: boolean;
    }> | null;
    shortAnswerResponses?: Array<{
      __typename?: 'ShortAnswerResponse';
      createdAt: any;
      id: string;
      answer: string;
      isCorrect: boolean;
    }> | null;
    multipleChoiceResponses?: Array<{
      __typename?: 'MultipleChoiceResponse';
      createdAt: any;
      id: string;
      isCorrect: boolean;
      selectedMultipleChoiceAnswerChoices: Array<{
        __typename?: 'SelectedMultipleChoiceAnswerChoice';
        id: string;
        multipleChoiceAnswerChoice: {
          __typename?: 'MultipleChoiceAnswerChoice';
          id: string;
          text: string;
          richText: any;
        };
      }>;
    }> | null;
  }>;
};

export type PersonalDeckStreakQueryVariables = Types.Exact<{
  enrollmentId: Types.Scalars['ID']['input'];
}>;

export type PersonalDeckStreakQuery = {
  __typename?: 'Query';
  personalDeckStreak: number;
};

export const NextPersonalDeckQuestionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'nextPersonalDeckQuestion' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'personalDeckStudySessionId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'previousQuestionId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nextPersonalDeckQuestion' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'personalDeckStudySessionId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'personalDeckStudySessionId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'previousQuestionId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'previousQuestionId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'question' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'questionAttributes' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'questionIndexAttributes' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Question' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plainText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'questionType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'standards' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'questionAttributes' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Question' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'questionIndexAttributes' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'freeResponseAnswer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                { kind: 'Field', name: { kind: 'Name', value: 'richText' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'multipleChoiceAnswerChoices' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isCorrect' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plainText' } },
                { kind: 'Field', name: { kind: 'Name', value: 'richText' } },
                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'numAttemptsUntilAnswerShown' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'richText' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shortAnswerAnswers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'hidden' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isCaseSensitive' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'richText' } },
                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'supplement' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plainText' } },
                { kind: 'Field', name: { kind: 'Name', value: 'richText' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  NextPersonalDeckQuestionQuery,
  NextPersonalDeckQuestionQueryVariables
>;
export const StudySessionResponsesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'studySessionResponses' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'personalDeckStudySessionId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'studySessionResponses' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'personalDeckStudySessionId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'personalDeckStudySessionId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'question' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'questionAttributes' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'freeResponseResponses' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'freeResponseAttributes' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shortAnswerResponses' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'shortAnswerResponseAttributes',
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'multipleChoiceResponses' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'multipleChoiceResponseAttributes',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'questionIndexAttributes' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Question' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plainText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'questionType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'standards' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'questionAttributes' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Question' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'questionIndexAttributes' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'freeResponseAnswer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                { kind: 'Field', name: { kind: 'Name', value: 'richText' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'multipleChoiceAnswerChoices' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isCorrect' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plainText' } },
                { kind: 'Field', name: { kind: 'Name', value: 'richText' } },
                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'numAttemptsUntilAnswerShown' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'richText' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shortAnswerAnswers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'hidden' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isCaseSensitive' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'richText' } },
                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'supplement' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plainText' } },
                { kind: 'Field', name: { kind: 'Name', value: 'richText' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'freeResponseAttributes' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FreeResponseResponse' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'selfAssessment' } },
          { kind: 'Field', name: { kind: 'Name', value: 'teacherAssessment' } },
          { kind: 'Field', name: { kind: 'Name', value: 'answer' } },
          { kind: 'Field', name: { kind: 'Name', value: 'richText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'autograded' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'shortAnswerResponseAttributes' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ShortAnswerResponse' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'answer' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isCorrect' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'multipleChoiceResponseAttributes' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MultipleChoiceResponse' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isCorrect' } },
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'selectedMultipleChoiceAnswerChoices',
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'multipleChoiceAnswerChoice' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'richText' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  StudySessionResponsesQuery,
  StudySessionResponsesQueryVariables
>;
export const EnrollmentResponsesByDateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'enrollmentResponsesByDate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'enrollmentId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ISO8601DateTime' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'ISO8601DateTime' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'enrollmentResponsesByDate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'enrollmentId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'enrollmentId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'startDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endDate' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'question' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'questionAttributes' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'freeResponseResponses' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'freeResponseAttributes' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shortAnswerResponses' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'shortAnswerResponseAttributes',
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'multipleChoiceResponses' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'multipleChoiceResponseAttributes',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'questionIndexAttributes' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Question' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plainText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'questionType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'standards' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'questionAttributes' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Question' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'questionIndexAttributes' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'freeResponseAnswer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                { kind: 'Field', name: { kind: 'Name', value: 'richText' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'multipleChoiceAnswerChoices' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isCorrect' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plainText' } },
                { kind: 'Field', name: { kind: 'Name', value: 'richText' } },
                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'numAttemptsUntilAnswerShown' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'richText' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shortAnswerAnswers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'hidden' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isCaseSensitive' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'richText' } },
                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'supplement' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plainText' } },
                { kind: 'Field', name: { kind: 'Name', value: 'richText' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'freeResponseAttributes' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FreeResponseResponse' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'selfAssessment' } },
          { kind: 'Field', name: { kind: 'Name', value: 'teacherAssessment' } },
          { kind: 'Field', name: { kind: 'Name', value: 'answer' } },
          { kind: 'Field', name: { kind: 'Name', value: 'richText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'autograded' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'shortAnswerResponseAttributes' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ShortAnswerResponse' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'answer' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isCorrect' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'multipleChoiceResponseAttributes' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MultipleChoiceResponse' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isCorrect' } },
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'selectedMultipleChoiceAnswerChoices',
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'multipleChoiceAnswerChoice' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'richText' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  EnrollmentResponsesByDateQuery,
  EnrollmentResponsesByDateQueryVariables
>;
export const PersonalDeckStreakDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'personalDeckStreak' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'enrollmentId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'personalDeckStreak' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'enrollmentId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'enrollmentId' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PersonalDeckStreakQuery,
  PersonalDeckStreakQueryVariables
>;
