import { Typography, type Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useContext } from 'react';
import type { QuestionAttributesFragment } from '../../../../gql/fragments/__generated__/question.generated';
import { QuillDeltaAsHtml } from '../../../shared/QuillDeltaAsHtml';
import { TextToSpeech } from '../../../shared/TextToSpeech';
import { TranslateText } from '../../../shared/TranslateText';
import { StudySessionContext } from '../../StudySession/context';
import { AnswerHidden } from './AnswerHidden';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  title: {
    marginTop: theme.spacing(1),
    fontWeight: 'bold',
    textDecoration: 'underline',
  },
  listItem: {
    display: 'flex',
    alignItems: 'center',
  },
}));

type MultipleChoiceFeedbackProps = {
  question: { __typename?: 'Question' } & QuestionAttributesFragment;
  isCorrect: boolean;
  nextQuestionButton: (isCorrect: boolean) => JSX.Element;
};

export function MultipleChoiceFeedback({
  question,
  isCorrect,
  nextQuestionButton,
}: MultipleChoiceFeedbackProps) {
  const classes = useStyles({ isCorrect });
  const { responseMeta } = useContext(StudySessionContext);
  const hideAnswer =
    !isCorrect &&
    typeof responseMeta?.numPriorResponses === 'number' &&
    responseMeta.numPriorResponses + 1 < question.numAttemptsUntilAnswerShown;

  return (
    <div className={classes.root}>
      {isCorrect ? (
        <Typography variant="h4">WAY TO GO!</Typography>
      ) : (
        <Typography variant="h4">Incorrect</Typography>
      )}

      <div>
        {hideAnswer ? (
          <AnswerHidden />
        ) : (
          <>
            <Typography variant="body1" className={classes.title}>
              Correct Answer:
            </Typography>
            <ul>
              {question.multipleChoiceAnswerChoices &&
              question.multipleChoiceAnswerChoices.some(
                (choice) => choice.isCorrect
              ) ? (
                question.multipleChoiceAnswerChoices
                  .filter((choice) => choice.isCorrect)
                  .map((answer) => (
                    <li key={`mc-answer-${answer.id}`}>
                      <div className={classes.listItem}>
                        <QuillDeltaAsHtml
                          delta={answer.richText.ops}
                          multipleChoice
                          zoomed
                        />
                        <TextToSpeech text={answer.text} />
                        <TranslateText delta={answer.richText.ops} />
                      </div>
                    </li>
                  ))
              ) : (
                <span>None of the above</span>
              )}
            </ul>
          </>
        )}
      </div>
      {nextQuestionButton(isCorrect)}
    </div>
  );
}
