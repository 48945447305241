import { Button } from '@mui/material';
import React from 'react';

type ButtonProps = {
  text: string;
  type?: 'submit';
  variant?: 'text' | 'outlined' | 'contained';
  className: string;
  fullWidth?: boolean;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

export function BaseButton({
  className,
  variant,
  type,
  text,
  onClick,
  ...props
}: ButtonProps) {
  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (typeof onClick !== 'function') return;

    onClick(e);
  };

  return (
    <Button
      {...props}
      className={className}
      variant={variant}
      color="secondary"
      onClick={handleClick}
      type={type || 'button'}
    >
      {text}
    </Button>
  );
}
