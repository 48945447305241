import { Info } from '@mui/icons-material';
import { Typography, type Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useState, type ReactElement } from 'react';
import { buttonLink } from '../../../assets/shared-styles/Button-Link';
import { CustomTooltip } from '../../shared/CustomTooltip';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  text: {
    fontWeight: theme.typography.fontWeightBold,
  },
  keyContainer: {
    ...buttonLink(theme),
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      cursor: 'pointer',
    },
    marginBottom: theme.spacing(1),
  },
  infoIcon: {
    marginRight: theme.spacing(0.5),
  },
}));

type LegendProps = {
  Tooltip: () => ReactElement;
  label: string;
  withIcon?: boolean;
};

export function Legend({ Tooltip, label, withIcon }: LegendProps) {
  const classes = useStyles();
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const openTooltip = () => setTooltipOpen(true);
  const closeTooltip = () => setTooltipOpen(false);
  return (
    <div className={classes.root}>
      <CustomTooltip open={tooltipOpen} title={<Tooltip />}>
        <button
          onBlur={closeTooltip}
          onClick={openTooltip}
          className={classes.keyContainer}
        >
          {withIcon && <Info className={classes.infoIcon} />}
          <Typography className={classes.text}>{label}</Typography>
        </button>
      </CustomTooltip>
    </div>
  );
}
