import { isUnknownRecord } from '@podsie/utils/object.js';
import {
  FreeResponseAttributesFragment,
  MultipleChoiceResponseAttributesFragment,
  ShortAnswerResponseAttributesFragment,
} from '../gql/fragments/__generated__/response.generated';
import {
  AssessmentValueEnum,
  FreeResponseResponse,
  MultipleChoiceResponse,
  ShortAnswerResponse,
} from '../gql/types';
import { AnyOperation, isDeltaOperation } from './quillHelper';

type BareMinimumResponse = { id: string };
type QuestionResponses = {
  freeResponseResponses?: FreeResponseAttributesFragment[] | null;
  shortAnswerResponses?: ShortAnswerResponseAttributesFragment[] | null;
  multipleChoiceResponses?: MultipleChoiceResponseAttributesFragment[] | null;
};

export const countTotalAttempts = ({
  freeResponseResponses,
  shortAnswerResponses,
  multipleChoiceResponses,
}: {
  freeResponseResponses?: BareMinimumResponse[] | FreeResponseResponse[] | null;
  shortAnswerResponses?: BareMinimumResponse[] | ShortAnswerResponse[] | null;
  multipleChoiceResponses?:
    | BareMinimumResponse[]
    | MultipleChoiceResponse[]
    | null;
}) => {
  return (
    (freeResponseResponses ? freeResponseResponses.length : 0) +
    (shortAnswerResponses ? shortAnswerResponses.length : 0) +
    (multipleChoiceResponses ? multipleChoiceResponses.length : 0)
  );
};
export const questionComplete = ({
  freeResponseResponses,
  shortAnswerResponses,
  multipleChoiceResponses,
}: QuestionResponses) => {
  if (freeResponseResponses && freeResponseResponses.length > 0) {
    return freeResponseResponses.some((response) => {
      if (response === undefined) {
        return false;
      }

      return (
        response.selfAssessment === AssessmentValueEnum.Three ||
        response.selfAssessment === AssessmentValueEnum.Four ||
        response.selfAssessment === AssessmentValueEnum.Five
      );
    });
  }
  if (multipleChoiceResponses && multipleChoiceResponses.length > 0) {
    return multipleChoiceResponses.some((mcr) => mcr.isCorrect);
  }

  if (shortAnswerResponses && shortAnswerResponses.length > 0) {
    return shortAnswerResponses.some((sar) => sar.isCorrect);
  }
  return false;
};

/**
 * This type is similar to Quill's `DeltaStatic` except that `ops` are required
 * so an empty object will not be sufficient.
 */
export type RichText = {
  ops: AnyOperation[];
};

export const isRichText = (value?: unknown): value is RichText =>
  isUnknownRecord(value) &&
  Array.isArray(value.ops) &&
  value.ops.every(isDeltaOperation);
