import { useMutation, useSubscription } from '@apollo/client';
import { Send } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import { AppBar, Drawer, IconButton, TextField, Toolbar } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { DotPulse } from '@uiball/loaders';
import 'katex/dist/katex.min.css';
import * as React from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeKatex from 'rehype-katex';
import remarkMath from 'remark-math';
import { AppContext } from '../../../AppContext';
import { CreateChatDocument } from '../../../gql/mutations/__generated__/chat.generated';
import { NewMessageDocument } from '../../../gql/subscriptions/__generated__/chatLog.generated';

const useStyles = makeStyles((theme) => ({
  drawer: {
    zIndex: 1300,
  },
  dragger: {
    width: '5px',
    cursor: 'ew-resize',
    padding: '4px 0 0',
    borderTop: '1px solid #ddd',
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    zIndex: 100,
    backgroundColor: '#f4f7f9',
  },
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  content: {
    padding: theme.spacing(2),
    flexGrow: 1,
    marginBottom: theme.spacing(2),
    overflowY: 'auto',
    marginTop: 64, // Account for the default AppBar height for desktop
  },
  loaderContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    padding: 8,
  },
  messages: {
    display: 'flex',
    flexDirection: 'column',
  },
  message: {
    padding: theme.spacing(1),
    color: theme.palette.text.primary,
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(1),
    alignSelf: 'flex-start',
    maxWidth: '80%',
    background: theme.palette.grey[100],
  },
  userMessage: {
    alignSelf: 'flex-end',
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  inputContainer: {
    borderTop: `1px solid ${theme.palette.grey[300]}`, // optional, to separate the inputContainer visually
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.background.paper,
  },

  input: {
    flexGrow: 1,
    marginRight: theme.spacing(1),
  },
  appBar: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
  },
  toolbarGrow: {
    flexGrow: 1,
  },
}));

type Message = {
  text: string;
  isBot: boolean;
};

type ChatBotProps = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  questionId: string;
};

// Add the minDrawerWidth and maxDrawerWidth constants
const minDrawerWidth = 350;
const maxDrawerWidth = 800;

export function ChatBot({ open, setOpen, questionId }: ChatBotProps) {
  const classes = useStyles();
  const [drawerWidth, setDrawerWidth] = React.useState(550);
  const [showLoader, setShowLoader] = React.useState(false);

  // Add the following mouse event handling functions
  const handleMouseDown = () => {
    document.addEventListener('mouseup', handleMouseUp, true);
    document.addEventListener('mousemove', handleMouseMove, true);
  };

  const handleMouseUp = () => {
    document.removeEventListener('mouseup', handleMouseUp, true);
    document.removeEventListener('mousemove', handleMouseMove, true);
  };

  const handleMouseMove = (e: MouseEvent) => {
    const newWidth = window.innerWidth - e.clientX;
    if (newWidth > minDrawerWidth && newWidth < maxDrawerWidth) {
      setDrawerWidth(newWidth);
    }
  };
  const {
    appState: { activeEnrollment },
  } = React.useContext(AppContext);
  const enrollmentId = activeEnrollment?.id || '';
  const [messages, setMessages] = React.useState<Message[]>([]);
  const addMessage = (message: Message) => {
    setMessages((prevMessages) => [...prevMessages, message]);
  };
  useSubscription(NewMessageDocument, {
    variables: { questionId, enrollmentId },
    onSubscriptionData: ({ subscriptionData }) => {
      if (subscriptionData.data) {
        const { newMessage } = subscriptionData.data;
        if (newMessage) {
          const updated = newMessage.chatLog.map((message) => {
            return {
              text: message.text,
              isBot: message.role === 'assistant',
            };
          });
          setMessages(updated);
          setShowLoader(false);
        }
      }
    },
  });
  const [createChat] = useMutation(CreateChatDocument, {
    onError: (error) => {
      alert(JSON.stringify(error, null, 2));
      setShowLoader(false);
    },
  });

  const [inputValue, setInputValue] = React.useState<string>('');
  const messagesEndRef = React.useRef<HTMLDivElement>(null);
  React.useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }, [messages]);

  const handleUserMessage = (message: string) => {
    if (!questionId || !enrollmentId) return;
    createChat({
      variables: {
        questionId,
        message: message,
        enrollmentId,
      },
    });
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const handleClose = () => setOpen(false);

  const sendMessage = () => {
    if (inputValue.trim() !== '') {
      addMessage({ text: inputValue, isBot: false });
      handleUserMessage(inputValue);
      setInputValue('');
      setShowLoader(true);
    }
  };

  const handleSendMessage = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    sendMessage();
  };
  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      sendMessage();
    }
  };

  if (!open) {
    return null;
  }

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={handleClose}
      className={classes.drawer}
      PaperProps={{ style: { width: drawerWidth, overflowY: 'hidden' } }} // Add this prop to set the drawer width
    >
      <div className={classes.root}>
        {/* Add the dragger element */}
        <div onMouseDown={handleMouseDown} className={classes.dragger} />
        {/* ... */}
      </div>
      <div className={classes.root}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <div className={classes.toolbarGrow} />
            <IconButton
              edge="end"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <div className={classes.content}>
          <div className={classes.messages}>
            {messages.map((message, index) => (
              <div
                key={index}
                className={`${classes.message} ${
                  message.isBot ? '' : classes.userMessage
                }`}
              >
                <ReactMarkdown
                  remarkPlugins={[remarkMath]}
                  rehypePlugins={[rehypeKatex]}
                >
                  {message.text}
                </ReactMarkdown>
              </div>
            ))}
            <div ref={messagesEndRef} />
          </div>
          {showLoader && (
            <div className={classes.loaderContainer}>
              <DotPulse />
            </div>
          )}
        </div>
        <form onSubmit={handleSendMessage} className={classes.inputContainer}>
          <TextField
            className={classes.input}
            value={inputValue}
            onChange={handleInputChange}
            label="Message"
            variant="outlined"
            multiline
            rows={2}
            InputProps={{
              onKeyDown: handleKeyDown,
            }}
          />
          <IconButton type="submit" color="primary" aria-label="send message">
            <Send />
          </IconButton>
        </form>
      </div>
    </Drawer>
  );
}
