import StarIcon from '@mui/icons-material/Star';
import { Box, Grid } from '@mui/material';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import theme from '../../../../theme';
import { BaseCard } from './BaseCard';
import { EmptyDeckActions } from './PersonalDeckAssignmentCard';

export function DeckPracticeCard(props: {
  points: number;
  completedSessionQuestions: number;
  totalSessionQuestions: number;
  totalDeckQuestions: number;
}) {
  const navigate = useNavigate();
  const noQuestionsDue = props.totalDeckQuestions == 0;

  const content = (
    <>
      <Grid item sx={{ width: '100%' }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: theme.spacing(1),
            fontWeight: 400,
            backgroundColor: '#FFF7B4',
            color: '#775601',
            width: '100%',
            padding: theme.spacing(1),
            marginBottom: theme.spacing(1),
          }}
        >
          <StarIcon sx={{ color: '#ffd600' }} />
          XP Level: {props.points} Points
        </Box>
      </Grid>
      <Grid
        container
        sx={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          gap: theme.spacing(1),
        }}
      >
        <Grid item sx={{ gap: theme.spacing(0.5), display: 'flex' }}>
          <Typography sx={{ fontWeight: 'light' }}>Completed: </Typography>
          <Typography sx={{ fontWeight: '500' }}>
            {props.completedSessionQuestions}/{props.totalSessionQuestions}
          </Typography>
        </Grid>
        <Grid item sx={{ gap: theme.spacing(0.5), display: 'flex' }}>
          <Typography sx={{ fontWeight: 'light' }}>
            Total Questions Due:
          </Typography>
          <Typography sx={{ fontWeight: '500' }}>
            {props.totalDeckQuestions}
          </Typography>
        </Grid>
      </Grid>
    </>
  );

  const actions = (
    <>
      {noQuestionsDue ? <EmptyDeckActions /> : null}
      <Button
        variant="contained"
        disabled={noQuestionsDue}
        fullWidth
        sx={{ fontWeight: 'light', backgroundColor: '#5CB277' }}
        onClick={() => {
          navigate('/personal-deck/study');
        }}
      >
        {props.completedSessionQuestions > 0
          ? 'Continue Study Session'
          : 'Start Study Session'}
      </Button>
    </>
  );
  return (
    <BaseCard
      content={content}
      actions={actions}
      assignmentName={'Personal Deck Practice'}
    />
  );
}
