import { Tab, Tabs, type Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Link, Route, Routes, useMatch } from 'react-router-dom';
import { LeaderboardContainer as Leaderboard } from '../../components/application/Progress/Leaderboard';
import { PersonalDeckProgress } from '../../components/application/Progress/PersonalDeck';
import { XpDetails } from '../../components/application/XpDetails';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    flexFlow: 'column',
  },
  descriptionContainer: {
    padding: `${theme.spacing(2.5)} ${theme.spacing(5)}`,
    borderBottom: `1px solid ${theme.palette.primary.main}`,
  },
  tableContainer: {
    padding: theme.spacing(5),
    position: 'relative',
    height: '100%',
  },
  bottomSection: {
    flex: '1 1 auto',
    backgroundColor: theme.palette.grays.main,
    position: 'relative',
  },
  topBackground: {
    backgroundImage: `linear-gradient(to bottom, ${theme.palette.mint.dark}, #7de0d2)`,
    position: 'absolute',
    borderRadius: 100,
    top: 0,
    right: -200,
    width: '80%',
    height: '60%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  tabsContainer: {
    borderBottom: `1px solid ${theme.palette.grays.dark}`,
    marginTop: theme.spacing(4),
    paddingLeft: theme.spacing(4),
  },
  bottomBackground: {
    backgroundImage: `linear-gradient(to bottom, ${theme.palette.mint.dark}, #7de0d2)`,
    position: 'absolute',
    bottom: 0,
    left: -200,
    borderRadius: 100,
    width: '70%',
    height: '50%',
    [theme.breakpoints.up('sm')]: {
      width: '60%',
    },
    [theme.breakpoints.up('md')]: {
      width: '60%',
    },
    [theme.breakpoints.up('lg')]: {
      width: '55%',
    },
    [theme.breakpoints.up('lg')]: {
      width: '45%',
    },
  },
}));

export function StudyProgress() {
  const classes = useStyles();
  const routeMatch = useMatch('/progress/*');
  const currentTab = routeMatch?.pathname;
  console.log(routeMatch, currentTab);
  console.log('YOOO');
  return (
    <div className={classes.root}>
      <Tabs
        className={classes.tabsContainer}
        value={currentTab}
        aria-label="Progress Tabs"
      >
        <Tab
          label="LEADERBOARD"
          value="/progress"
          to="/progress"
          component={Link}
        />
        <Tab
          label="XP Details"
          value="/progress/xp-details"
          to="/progress/xp-details"
          component={Link}
        />
        <Tab
          label="PERSONAL DECK"
          value="/progress/personal-deck"
          to="/progress/personal-deck"
          component={Link}
        />
      </Tabs>
      <Routes>
        <Route path="*" element={<Leaderboard />} />
        <Route path="xp-details" element={<XpDetails />} />
        <Route path="personal-deck" element={<PersonalDeckProgress />} />
      </Routes>
    </div>
  );
}
