import * as Types from '../../types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type StudentStandardMasteriesQueryVariables = Types.Exact<{
  studentIds:
    | Array<Types.Scalars['ID']['input']>
    | Types.Scalars['ID']['input'];
  standardIds:
    | Array<Types.Scalars['ID']['input']>
    | Types.Scalars['ID']['input'];
  groupIds: Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input'];
}>;

export type StudentStandardMasteriesQuery = {
  __typename?: 'Query';
  studentStandardMasteries: Array<{
    __typename?: 'StudentStandardsMastery';
    enrollmentId: string;
    data: Array<{
      __typename?: 'StandardsMastery';
      standardId: string;
      average: number;
    }>;
  }>;
};

export type EnrollmentQuestionsMasteriesQueryVariables = Types.Exact<{
  enrollmentId: Types.Scalars['ID']['input'];
  firstSeenStart?: Types.InputMaybe<Types.Scalars['ISO8601DateTime']['input']>;
  firstSeenEnd?: Types.InputMaybe<Types.Scalars['ISO8601DateTime']['input']>;
}>;

export type EnrollmentQuestionsMasteriesQuery = {
  __typename?: 'Query';
  enrollmentQuestionsMasteries: Array<{
    __typename?: 'EnrollmentsQuestionsMastery';
    id: string;
    masteryScore?: number | null;
    streak: number;
    questionId: number;
  }>;
};

export type QuestionSpecificEnrollmentQuestionsMasteriesQueryVariables =
  Types.Exact<{
    enrollmentId: Types.Scalars['ID']['input'];
    questionIds?: Types.InputMaybe<
      Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input']
    >;
    first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
    last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
    before?: Types.InputMaybe<Types.Scalars['String']['input']>;
    after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  }>;

export type QuestionSpecificEnrollmentQuestionsMasteriesQuery = {
  __typename?: 'Query';
  paginatedEnrollmentQuestionsMasteries: {
    __typename?: 'EnrollmentsQuestionsMasteryConnection';
    totalCount: number;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string | null;
      startCursor?: string | null;
    };
    edges?: Array<{
      __typename?: 'EnrollmentsQuestionsMasteryEdge';
      cursor: string;
      node?: {
        __typename?: 'EnrollmentsQuestionsMastery';
        firstSeen?: any | null;
        id: string;
        streak: number;
        totalAttempts: number;
        totalCorrect: number;
        masteryScore?: number | null;
        pdqDue?: any | null;
        question: {
          __typename?: 'Question';
          richText: any;
          plainText: string;
          questionType: Types.QuestionTypeEnum;
          multipleChoiceAnswerChoices?: Array<{
            __typename?: 'MultipleChoiceAnswerChoice';
            id: string;
            text: string;
            richText: any;
          }> | null;
        };
      } | null;
    } | null> | null;
  };
};

export type MasteriesByStandardAndEnrollmentQueryVariables = Types.Exact<{
  standardId: Types.Scalars['ID']['input'];
  enrollmentId: Types.Scalars['ID']['input'];
}>;

export type MasteriesByStandardAndEnrollmentQuery = {
  __typename?: 'Query';
  masteriesByStandardAndEnrollment: Array<{
    __typename?: 'EnrollmentsQuestionsMastery';
    id: string;
    streak: number;
    totalCorrect: number;
    totalAttempts: number;
    latestAttempt?: any | null;
    firstSeen?: any | null;
    question: { __typename?: 'Question'; richText: any; plainText: string };
  }>;
};

export const StudentStandardMasteriesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'studentStandardMasteries' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'studentIds' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'ID' },
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'standardIds' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'ID' },
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'groupIds' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'ID' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'studentStandardMasteries' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'studentIds' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'studentIds' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'standardIds' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'standardIds' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'groupIds' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'groupIds' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'enrollmentId' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'standardId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'average' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  StudentStandardMasteriesQuery,
  StudentStandardMasteriesQueryVariables
>;
export const EnrollmentQuestionsMasteriesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'enrollmentQuestionsMasteries' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'enrollmentId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'firstSeenStart' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'ISO8601DateTime' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'firstSeenEnd' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'ISO8601DateTime' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'enrollmentQuestionsMasteries' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'enrollmentId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'enrollmentId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'firstSeenStart' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'firstSeenStart' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'firstSeenEnd' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'firstSeenEnd' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'masteryScore' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'streak' } },
                { kind: 'Field', name: { kind: 'Name', value: 'questionId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  EnrollmentQuestionsMasteriesQuery,
  EnrollmentQuestionsMasteriesQueryVariables
>;
export const QuestionSpecificEnrollmentQuestionsMasteriesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {
        kind: 'Name',
        value: 'questionSpecificEnrollmentQuestionsMasteries',
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'enrollmentId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'questionIds' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'first' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'last' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'before' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'after' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'paginatedEnrollmentQuestionsMasteries',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'enrollmentId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'enrollmentId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'questionIds' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'questionIds' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'first' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'last' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'last' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'before' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'before' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'after' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'endCursor' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'startCursor' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cursor' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'firstSeen' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'streak' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'totalAttempts' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'totalCorrect' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'masteryScore' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'pdqDue' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'question' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'richText' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'plainText' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'questionType',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'multipleChoiceAnswerChoices',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'text' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'richText',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  QuestionSpecificEnrollmentQuestionsMasteriesQuery,
  QuestionSpecificEnrollmentQuestionsMasteriesQueryVariables
>;
export const MasteriesByStandardAndEnrollmentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'masteriesByStandardAndEnrollment' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'standardId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'enrollmentId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'masteriesByStandardAndEnrollment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'standardId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'standardId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'enrollmentId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'enrollmentId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'streak' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'totalCorrect' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'totalAttempts' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'latestAttempt' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'firstSeen' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'question' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'richText' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'plainText' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  MasteriesByStandardAndEnrollmentQuery,
  MasteriesByStandardAndEnrollmentQueryVariables
>;
