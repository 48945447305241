import { useQuery } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import {
  Avatar,
  Card,
  CardContent,
  Typography,
  type Theme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useState } from 'react';
import { StudentDocument } from '../../../gql/queries/__generated__/student.generated';
import { LoadingSkeletons } from '../../shared/Loaders/LoadingSkeletons';
import { EditProfileModal } from './EditProfileModal';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    padding: theme.spacing(5),
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: 200,
  },
  avatar: {
    width: theme.spacing(12),
    height: theme.spacing(12),
    border: `1px solid ${theme.palette.primary.main}`,
  },
  studentName: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightBold,
  },
  buttonLink: {
    color: theme.palette.secondary.main,
    textDecoration: 'underline',
    border: 'none',
    textAlign: 'left',
    backgroundColor: 'transparent',
    '&:hover': {
      cursor: 'pointer',
    },
    padding: 0,
    marginTop: '8px',
  },
}));

export function StudentInfo() {
  const classes = useStyles();

  const { data, loading } = useQuery(StudentDocument, {
    fetchPolicy: 'cache-and-network',
  });
  const isMissingAName = !data?.student.firstName || !data?.student.lastName;

  const { user } = useAuth0();
  const [open, setOpen] = useState(isMissingAName);
  if (!data || !user) return null;

  const { email, fullName } = data.student;

  return (
    <div>
      <Card className={classes.root}>
        <Avatar
          src={user.picture}
          alt={fullName || email}
          className={classes.avatar}
        />
        <CardContent className={classes.content}>
          {loading ? (
            <LoadingSkeletons num={2} />
          ) : (
            <>
              <Typography
                variant="h5"
                color="primary"
                className={classes.studentName}
              >
                {fullName}
              </Typography>
              <Typography variant="caption">{email}</Typography>
              <button
                className={classes.buttonLink}
                onClick={() => setOpen(true)}
              >
                <Typography variant="body1">Edit Profile</Typography>
              </button>
            </>
          )}
        </CardContent>
      </Card>
      <EditProfileModal open={open} handleClose={() => setOpen(false)} />
    </div>
  );
}
