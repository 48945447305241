import { useLazyQuery } from '@apollo/client';
import { ExpandMore, MenuBook, QuestionAnswer } from '@mui/icons-material';
import {
  Alert,
  Collapse,
  IconButton,
  Tooltip,
  Typography,
  type Theme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import React, { useContext, useEffect, useState, type ReactNode } from 'react';
import ReactMarkdown from 'react-markdown';
import { AppContext } from '../../../../AppContext';
import { AutogradeResponseCorrectnessDocument } from '../../../../gql/queries/__generated__/autogradeResponseCorrectness.generated';
import { QuestionTypeEnum } from '../../../../gql/types';
import { config } from '../../../../podsie-config';
import { containsImages } from '../../../../utils/quillHelper';
import { StandardButton } from '../../../shared/Buttons/StandardButton';
import { LoadingSkeletons } from '../../../shared/Loaders/LoadingSkeletons';
import { ChatBot } from '../../ChatBot';
import {
  StudySessionContext,
  type ResponseMeta,
} from '../../StudySession/context';
import { useExperimentCheck } from '../Answers/useExperimentCheck';
import { Supplement } from '../Supplement';
import { AutograderExplanationStreamer } from './AutogradeExplanationStreamer';
import { FreeResponseFeedback } from './FreeResponseFeedback';
import { MultipleChoiceFeedback } from './MultipleChoiceFeedback';
import { ShortAnswerFeedback } from './ShortAnswerFeedback';

type StyleProps = {
  isCorrect: boolean;
  isRetry: boolean;
  visible: boolean;
  questionType?: QuestionTypeEnum;
  isExperiment: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: '0 auto',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '95%',
    },
    [theme.breakpoints.up('md')]: {
      width: '85%',
    },
    [theme.breakpoints.up('lg')]: {
      width: '75%',
    },
    [theme.breakpoints.up('xl')]: {
      width: '60%',
    },
    maxHeight: '100vh',
    overflow: 'auto',
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    backgroundColor: ({
      isCorrect,
      isRetry,
      visible,
      questionType,
      isExperiment,
    }: StyleProps) => {
      if (!visible) {
        return 'transparent';
      }

      if (isRetry) {
        return theme.palette.warning.light;
      }

      if (questionType === QuestionTypeEnum.ShortAnswer && isExperiment) {
        return theme.palette.background.paper;
      }

      if (questionType === QuestionTypeEnum.FreeResponse) {
        return theme.palette.primary.main;
      }

      if (isCorrect) {
        return theme.palette.secondary.main;
      }

      return theme.palette.error.light;
    },
    color: theme.palette.primary.main,
  },
  aiExplanation: {
    backgroundColor: `${theme.palette.grays.light} !important`,
    color: `${theme.palette.text.primary} !important`,
  },
  aiExplanationTitle: {
    marginBottom: theme.spacing(2),
  },
  expand: {
    width: '100%',
    display: 'inherit',
    margin: '0 auto',
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
    color: theme.palette.primary.main,
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  loadingMessage: {
    textAlign: 'center',
  },
  feedbackContainer: {
    margin: '0 auto',
    width: '95%',
    [theme.breakpoints.up('sm')]: {
      width: '85%',
    },
    [theme.breakpoints.up('md')]: {
      width: '75%',
    },
    [theme.breakpoints.up('lg')]: {
      width: '60%',
    },
    [theme.breakpoints.up('xl')]: {
      width: '50%',
    },
  },
  aiExplanationContainer: {
    margin: `${theme.spacing(2)} ${theme.spacing(3)} ${theme.spacing(
      2
    )} ${theme.spacing(3)}`,
  },
  aiExplanationText: {
    margin: `${theme.spacing(3)} 0`,
  },
  supplementContainer: {
    paddingBottom: theme.spacing(5),
  },
  supplementButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  supplementButton: {
    color: theme.palette.primary.main,
  },
  nextQuestionButton: {
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
    color: theme.palette.common.white,
    boxShadow: 'none',
  },
  explanationButton: {
    backgroundColor: 'inherit',
    border: `1px solid ${theme.palette.primary.main}`,
    color: theme.palette.text.primary,
    marginRight: theme.spacing(1),
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: 'inherit',
    },
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'end',
    margin: `${theme.spacing(3)} 0`,
  },
  progress: {
    marginBottom: theme.spacing(2),
    width: '50%',
  },
}));

const ALLOWABLE_IDS = [1, 935, 22655];

type QuestionFeedbackProps = {
  visible: boolean;
  isCorrect: boolean;
  freeResponseResponseId?: string;
  supplementOpen: boolean;
  setSupplementOpen: React.Dispatch<React.SetStateAction<boolean>>;
  loading: boolean;
  isPersonal?: boolean;
  responseMeta?: ResponseMeta;
};

export function QuestionFeedback({
  visible,
  setSupplementOpen,
  supplementOpen,
  isCorrect,
  freeResponseResponseId,
  isPersonal = false,
  responseMeta,
}: QuestionFeedbackProps) {
  const [chatBotOpen, setChatBotOpen] = useState(false);

  const [fetchAutogradeCorrectness, autogradeCorrectnessQuery] = useLazyQuery(
    AutogradeResponseCorrectnessDocument
  );
  const autogradeCorrectnessResponse = autogradeCorrectnessQuery.data;
  const { isExperiment } = useExperimentCheck();
  const {
    setFeedbackVisible,
    question,
    getNextQuestion,
    setResponseMeta,
    isRetry,
  } = useContext(StudySessionContext);
  const {
    appState: { activeEnrollment },
  } = useContext(AppContext);
  const userId = parseInt(activeEnrollment?.student.id || '0');
  const chatBotEnabled =
    config.env !== 'production' || ALLOWABLE_IDS.includes(userId);

  const classes = useStyles({
    isCorrect,
    visible,
    questionType: question?.questionType,
    isRetry,
    isExperiment,
  });
  const [show, setShow] = useState(true);

  useEffect(() => {
    // when visible is toggled to false, setShow back to true so that feedback
    // will show up for next question
    if (!visible) {
      setShow(true);
    }
  }, [visible]);

  const hasImages = question && containsImages(question.richText);

  const handleNextQuestion = () => {
    getNextQuestion();
    setSupplementOpen(false);
    setFeedbackVisible(false);
    setResponseMeta(undefined);
  };

  const explainButton = () => {
    if (isCorrect || isExperiment) return null;
    if (question?.questionType !== QuestionTypeEnum.ShortAnswer) return null;
    if (autogradeCorrectnessQuery.data) return null;
    if (!activeEnrollment?.group?.course?.studentFacingAiEnabled) return null;

    const button = (
      <StandardButton
        text="Explain why I'm wrong"
        className={classes.explanationButton}
        disabled={hasImages || false}
        onClick={() => {
          if (question?.id && responseMeta?.id) {
            fetchAutogradeCorrectness({
              variables: {
                questionId: question.id,
                responseId: responseMeta.id,
              },
            });
          }
        }}
      />
    );

    const tooltipText =
      'This functionality is not supported for this question as it contains images.';

    if (hasImages) {
      return (
        <Tooltip title={tooltipText}>
          <span>{button}</span>
        </Tooltip>
      );
    }

    return button;
  };

  const nextQuestionButton = () => (
    <div className={classes.buttonContainer}>
      {explainButton()}
      <StandardButton
        text="Next Question"
        onClick={handleNextQuestion}
        className={classes.nextQuestionButton}
      />
    </div>
  );

  const aiAnalysisTrigger = (aiAnalysisCheck: boolean) => {
    if (aiAnalysisCheck && question?.id && responseMeta?.id) {
      fetchAutogradeCorrectness({
        variables: {
          questionId: question.id,
          responseId: responseMeta.id,
        },
      });
    }
  };

  const feedback = (): ReactNode => {
    if (autogradeCorrectnessQuery.loading) {
      return (
        <div className={classes.aiExplanationContainer}>
          <Alert color="info" variant="filled" severity="info">
            <LoadingSkeletons num={2} skeletonHeight={20} />
            <Typography
              color="primary"
              variant="h5"
              className={classes.loadingMessage}
            >
              Analyzing your answer! This may take up to 30 seconds...
            </Typography>
          </Alert>
        </div>
      );
    }

    if (autogradeCorrectnessQuery.error) {
      const errorMessage = autogradeCorrectnessQuery.error.message;
      return <ReactMarkdown>{errorMessage}</ReactMarkdown>;
    }

    if (autogradeCorrectnessResponse) {
      return (
        <div>
          <div className={classes.aiExplanationContainer}>
            <Typography variant="h5" className={classes.aiExplanationTitle}>
              {!autogradeCorrectnessResponse.autogradeResponseCorrectness
                ? 'AI Explanation:'
                : 'We have informed your teacher to review your answer.'}
            </Typography>
            <div className={classes.aiExplanationText}>
              {!autogradeCorrectnessResponse.autogradeResponseCorrectness &&
                question?.id &&
                responseMeta?.id && (
                  <AutograderExplanationStreamer
                    questionId={question.id}
                    responseId={responseMeta.id}
                    supplement={question.supplement}
                  />
                )}
            </div>
          </div>
          {nextQuestionButton()}
        </div>
      );
    }

    switch (question?.questionType) {
      case QuestionTypeEnum.FreeResponse:
        return (
          <FreeResponseFeedback
            getNextAssignmentQuestion={getNextQuestion}
            freeResponseResponseId={freeResponseResponseId}
            question={question}
            isPersonal={isPersonal}
            aiAnalysisTrigger={aiAnalysisTrigger}
            isExperiment={isExperiment}
          />
        );
      case QuestionTypeEnum.ShortAnswer:
        return (
          <ShortAnswerFeedback
            nextQuestionButton={nextQuestionButton}
            question={question}
            isCorrect={isCorrect}
            isExperiment={isExperiment}
          />
        );
      case QuestionTypeEnum.SelectAllMultipleChoice:
      case QuestionTypeEnum.SelectOneMultipleChoice:
        return (
          <MultipleChoiceFeedback
            nextQuestionButton={nextQuestionButton}
            question={question}
            isCorrect={isCorrect}
          />
        );

      default:
        return null;
    }
  };
  const toggleExpanded = () => setShow((currentlyShowing) => !currentlyShowing);
  const expanded = visible && show;
  const openChatBot = () => setChatBotOpen(true);

  // for free response, we'll always show it by default, because unlike
  // multiple choice and short answer, we won't be able to know whether or not
  // student got it right or wrong and whether we should show deeper learning:
  const showSupplementIcon =
    question?.questionType !== QuestionTypeEnum.FreeResponse &&
    question?.supplement &&
    !isRetry;
  return (
    <div
      className={clsx(classes.root, {
        [classes.aiExplanation]: autogradeCorrectnessQuery.data,
      })}
    >
      {visible && (
        <IconButton
          className={clsx(classes.expand, {
            [classes.expandOpen]: !expanded,
            [classes.aiExplanation]: autogradeCorrectnessQuery.data,
          })}
          onClick={toggleExpanded}
          aria-expanded={expanded}
          aria-label="show more"
          size="large"
        >
          <ExpandMore />
        </IconButton>
      )}
      <Collapse in={expanded}>
        <div className={classes.feedbackContainer}>
          <div className={classes.supplementButtonContainer}>
            {chatBotEnabled && (
              <Tooltip title="Chatbot">
                <IconButton
                  onClick={openChatBot}
                  aria-label="Chatbot"
                  className={clsx(classes.supplementButton, {
                    [classes.aiExplanation]: autogradeCorrectnessQuery.data,
                  })}
                >
                  <QuestionAnswer />
                </IconButton>
              </Tooltip>
            )}

            {showSupplementIcon && (
              <Tooltip title="See Explanation">
                <IconButton
                  onClick={() => setSupplementOpen(true)}
                  aria-label="See Explanation"
                  className={classes.supplementButton}
                  size="large"
                >
                  <MenuBook />
                </IconButton>
              </Tooltip>
            )}
          </div>
          {isCorrect && isPersonal && (
            <Typography variant="h4">
              You&apos;ve earned points for studying your personal deck!
            </Typography>
          )}
          {feedback()}
        </div>
      </Collapse>
      {showSupplementIcon && question?.supplement && (
        <Supplement
          open={supplementOpen}
          setOpen={setSupplementOpen}
          supplement={question.supplement}
        />
      )}
      {chatBotEnabled && (
        <ChatBot
          open={chatBotOpen}
          setOpen={setChatBotOpen}
          questionId={question?.id || ''}
        />
      )}
    </div>
  );
}
