import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Theme,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) => ({
  firstTextField: {
    marginRight: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(2),
    },
  },
  cancelButton: {
    marginRight: theme.spacing(2),
  },
  actionsContainer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    margin: '0 20px',
    padding: '15px 0',
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  inputContainer: {
    padding: '32px 0',
  },
  required: {
    color: theme.palette.error.main,
  },
  link: {
    textDecoration: 'underline',
    color: theme.palette.secondary.main,
  },
}));

type ArchivedModalProps = {
  open: boolean;
  handleClose: () => void;
};

export function ArchivedModal({ open, handleClose }: ArchivedModalProps) {
  const classes = useStyles();

  return (
    <Dialog
      maxWidth="md"
      fullWidth
      open={open}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        You&apos;re in an archived class!
      </DialogTitle>
      <form onSubmit={handleClose}>
        <DialogContent>
          <DialogContentText>
            <Typography variant="body1" color="primary">
              Heads up that you&apos;re currently in a class that&apos;s been
              archived by your teacher. If that&apos;s what you meant to do,
              then carry on. Otherwise, go to the{' '}
              <Link className={classes.link} to="/account">
                My Account
              </Link>{' '}
              page and set a different class as active!
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <div className={classes.actionsContainer}>
            <Button variant="outlined" onClick={handleClose} color="secondary">
              Got it!
            </Button>
          </div>
        </DialogActions>
      </form>
    </Dialog>
  );
}
