import type { FunctionComponent } from 'react';

import {
  ListItem,
  ListItemIcon,
  ListItemText,
  type Theme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Link, useMatch } from 'react-router-dom';
import { selected } from '../../../../assets/shared-styles/MuiListItem';

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    color: theme.palette.secondary.main,
  },
  root: {
    paddingLeft: theme.spacing(2),
  },
  selected: selected(theme, 16),
}));

type NavListItemProps = {
  text: string;
  route: string;
  Icon: FunctionComponent;
};

export function NavListItem({ Icon, text, route }: NavListItemProps) {
  const classes = useStyles();
  const match = useMatch(`${route}/*`);

  return (
    <Link to={route}>
      <ListItem
        button
        selected={!!match}
        classes={{ root: classes.root, selected: classes.selected }}
      >
        <ListItemIcon className={classes.icon}>
          <Icon />
        </ListItemIcon>
        <ListItemText primary={text} />
      </ListItem>
    </Link>
  );
}
