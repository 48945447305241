import type { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useContext } from 'react';
import { QuestionTypeEnum } from '../../../../gql/types';
import { StudySessionContext } from '../../StudySession/context';
import { FreeResponse } from './FreeResponse';
import { MultipleChoice } from './MultipleChoice';
import { ShortAnswer } from './ShortAnswer';

type StyleProps = {
  supplementOpen: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'relative',
    height: '100%',
  },
  supplementContainer: {
    position: 'absolute',
    visibility: ({ supplementOpen }: StyleProps) =>
      supplementOpen ? 'visible' : 'hidden',
    width: '100%',
    height: '100%',
    background: theme.palette.common.white,
    zIndex: 1,
  },
}));

type AnswersProps = {
  setSupplementOpen: React.Dispatch<React.SetStateAction<boolean>>;
  supplementOpen: boolean;
  isPersonal?: boolean;
};

export function Answers({
  setSupplementOpen,
  supplementOpen,
  isPersonal = false,
}: AnswersProps) {
  const classes = useStyles({ supplementOpen });
  const { question } = useContext(StudySessionContext);
  if (!question) {
    return null;
  }
  const answers = () => {
    switch (question.questionType) {
      case QuestionTypeEnum.FreeResponse:
        return <FreeResponse />;
      case QuestionTypeEnum.ShortAnswer:
        return (
          <ShortAnswer
            setSupplementOpen={setSupplementOpen}
            isPersonal={isPersonal}
          />
        );
      case QuestionTypeEnum.SelectAllMultipleChoice:
      case QuestionTypeEnum.SelectOneMultipleChoice:
        return (
          <MultipleChoice
            setSupplementOpen={setSupplementOpen}
            isPersonal={isPersonal}
          />
        );
      default:
        return null;
    }
  };

  return <div className={classes.root}>{answers()}</div>;
}
