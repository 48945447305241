import { useQuery } from '@apollo/client';
import { Close } from '@mui/icons-material';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  type Theme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useContext, useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { AppContext } from '../../../../AppContext';
import { QuestionSpecificEnrollmentQuestionsMasteriesDocument } from '../../../../gql/queries/__generated__/masteries.generated';
import { LoadingSkeletons } from '../../../shared/Loaders/LoadingSkeletons';
import { HelpKitMasteryScore } from '../../HelpKit/HelpKitArticles/HelpKitMasteryScore';
import { DetailsBody } from './DetailsBody';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    minHeight: '80vh',
  },
  body: {
    backgroundColor: theme.palette.background.default,
  },
  title: {
    color: theme.palette.primary.main,
  },
  closeButton: {
    color: theme.palette.secondary.main,
    padding: 0,
  },
  header: {
    borderBottom: `1px solid ${theme.palette.primary.main}`,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '14px 15px',
  },
  helpkitContainer: {
    marginTop: theme.spacing(2),
  },
}));

type QuestionsProgressDetailsProps = {
  questionIds: string[];
  handleClose: () => void;
};

const PAGINATION_COUNT = 10;

export function QuestionsProgressDetails({
  questionIds,
  handleClose,
}: QuestionsProgressDetailsProps) {
  const {
    appState: { activeEnrollment },
  } = useContext(AppContext);
  const classes = useStyles();
  const { data, fetchMore } = useQuery(
    QuestionSpecificEnrollmentQuestionsMasteriesDocument,
    {
      skip: !activeEnrollment,
      variables: {
        enrollmentId: activeEnrollment?.id || '',
        questionIds: questionIds,
        first: PAGINATION_COUNT,
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
    }
  );

  const fetchNextBatch = () => {
    if (!fetchMore) {
      return;
    }

    fetchMore({
      variables: {
        first: PAGINATION_COUNT,
        after: data?.paginatedEnrollmentQuestionsMasteries.pageInfo.endCursor,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return previousResult;
        }

        fetchMoreResult.paginatedEnrollmentQuestionsMasteries.edges = [
          ...(previousResult.paginatedEnrollmentQuestionsMasteries.edges || []),
          ...(fetchMoreResult.paginatedEnrollmentQuestionsMasteries.edges ||
            []),
        ];

        return { ...fetchMoreResult };
      },
    });
  };

  useEffect(() => {
    return () => {
      document
        .querySelector('#scrollable-items')
        ?.removeEventListener('scroll', fetchNextBatch);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const numItems =
    data?.paginatedEnrollmentQuestionsMasteries?.edges?.length || 0;
  const totalItems =
    data?.paginatedEnrollmentQuestionsMasteries.totalCount || 0;
  return (
    <Dialog
      open={true}
      onClose={handleClose}
      fullWidth
      maxWidth="md"
      scroll="paper"
      className={classes.root}
      PaperProps={{
        style: { overflowY: 'visible' },
      }}
    >
      <DialogTitle className={classes.header}>
        <Typography variant="h2" className={classes.title}>
          QUESTION DETAILS
        </Typography>
        <IconButton
          className={classes.closeButton}
          aria-label="close"
          onClick={handleClose}
          size="large"
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent id="scrollable-items" className={classes.body}>
        {!data && <LoadingSkeletons num={15} />}
        <div className={classes.helpkitContainer}>
          <HelpKitMasteryScore />
        </div>
        <InfiniteScroll
          dataLength={numItems}
          scrollableTarget="scrollable-items"
          next={fetchNextBatch}
          hasMore={numItems < totalItems}
          loader={<div>Loading...</div>}
        >
          <DetailsBody
            masteries={data?.paginatedEnrollmentQuestionsMasteries.edges}
          />
        </InfiniteScroll>
      </DialogContent>
    </Dialog>
  );
}
