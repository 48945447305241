import { Typography, type Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useContext } from 'react';
import { Quill } from 'react-quill';
import type { QuestionAttributesFragment } from '../../../../gql/fragments/__generated__/question.generated';
import { QuillDeltaAsHtml } from '../../../shared/QuillDeltaAsHtml';
import { TextToSpeech } from '../../../shared/TextToSpeech';
import { TranslateText } from '../../../shared/TranslateText';
import { StudySessionContext } from '../../StudySession/context';
import { AnswerHidden } from './AnswerHidden';
import { ShortAnswerTypoTryAgain } from './ShortAnswerTypoTryAgain';

const Delta = Quill.import('delta');

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  title: {
    marginTop: theme.spacing(1),
    fontWeight: 'bold',
    textDecoration: 'underline',
  },
  answer: {
    fontWeight: 'bold',
    fontSize: 20,
  },
  listItem: {
    display: 'flex',
    alignItems: 'center',
  },
}));

type ShortAnswerFeedbackProps = {
  question: { __typename?: 'Question' } & QuestionAttributesFragment;
  nextQuestionButton: (isCorrect: boolean) => JSX.Element;
  isCorrect: boolean;
  isExperiment: boolean;
};

export function ShortAnswerFeedback({
  question,
  nextQuestionButton,
  isCorrect,
  isExperiment,
}: ShortAnswerFeedbackProps) {
  const classes = useStyles({ isCorrect });
  const { responseMeta, isRetry } = useContext(StudySessionContext);
  const hideAnswer =
    !isCorrect &&
    typeof responseMeta?.numPriorResponses === 'number' &&
    responseMeta.numPriorResponses + 1 < question.numAttemptsUntilAnswerShown;
  if (isRetry) return <ShortAnswerTypoTryAgain />;

  return (
    <div className={classes.root}>
      {isExperiment ? (
        <> </>
      ) : isCorrect ? (
        <Typography variant="h4">WAY TO GO!</Typography>
      ) : (
        <Typography variant="h4">
          That answer did not match one of the potential answers.
        </Typography>
      )}
      <div>
        {hideAnswer ? (
          <AnswerHidden />
        ) : (
          <>
            <Typography variant="body1" className={classes.title}>
              Acceptable answers:
            </Typography>
            <ul>
              {question.shortAnswerAnswers &&
                question.shortAnswerAnswers
                  .filter((answer) => !answer.hidden)
                  .map((answer) => (
                    <li key={`short-answer-${answer.id}`}>
                      <div className={classes.listItem}>
                        <QuillDeltaAsHtml
                          delta={
                            answer?.richText?.ops?.length
                              ? answer.richText.ops
                              : new Delta({
                                  ops: [{ insert: answer.text }],
                                }).ops
                          }
                          style={{ backgroundColor: 'inherit' }}
                        />
                        <TextToSpeech text={answer.text} />
                        <TranslateText delta={answer.richText.ops} />
                      </div>
                    </li>
                  ))}
            </ul>
          </>
        )}
      </div>
      {nextQuestionButton(isCorrect)}
    </div>
  );
}
