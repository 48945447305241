import { useAuth0 } from '@auth0/auth0-react';
import { Alert, AlertTitle } from '@mui/lab';
import { Button, type Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: theme.palette.primary.main,
    height: '100vh',
    width: '100vw',
  },
  alertContainer: {
    maxWidth: '830px',
    margin: '5% 10px',
  },
  buttonsContainer: {
    display: 'flex',
    flexFlow: 'column',
  },
  logOutButton: {
    marginTop: theme.spacing(1),
    backgroundColor: theme.palette.common.white,
  },
}));

type EmailNotVerifiedProps = {
  email: string;
};

export function EmailNotVerified({ email }: EmailNotVerifiedProps) {
  const classes = useStyles();

  const { logout, user } = useAuth0();

  const logoutIfLoggedIn = () => {
    if (!user) return;
    const isMicrosoft = user['https://podsie.org/microsoft-auth'];

    logout({ logoutParams: { federated: !!isMicrosoft } });
  };

  const refresh = () => window.location.reload();

  return (
    <div className={classes.root} style={{}}>
      <div className={classes.alertContainer}>
        <Alert
          severity="warning"
          action={
            <div className={classes.buttonsContainer}>
              <Button color="primary" onClick={refresh} variant="contained">
                Refresh
              </Button>
              <Button
                className={classes.logOutButton}
                color="secondary"
                onClick={logoutIfLoggedIn}
                variant="outlined"
              >
                Log Out
              </Button>
            </div>
          }
        >
          <AlertTitle>Email Not Verified</AlertTitle>
          Please check your email <strong>({email})</strong> for a verification
          confirmation email titled &lsquo;Verify your email&rsquo;. After you
          have verified your email, <strong>please refresh the page.</strong>
        </Alert>
      </div>
    </div>
  );
}
