import type { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { format } from 'date-fns';
import type { MUIDataTableColumnDef } from 'mui-datatables';
import { useContext, useMemo, useState } from 'react';
import { AppContext } from '../../../AppContext';
import { buttonLink } from '../../../assets/shared-styles/Button-Link';
import {
  AssignmentMethodEnum,
  AssignmentTypeEnum,
  Scalars,
} from '../../../gql/types';
import { dateFormat } from '../../../utils/dates';
import { DatumType } from '../../../views/Assignments';
import { StandardButton } from '../../shared/Buttons/StandardButton';
import { LateAssignmentChip } from '../../shared/Chips/LateAssignmentChip';
import { CustomDataTable } from '../../shared/CustomTable/CustomDataTable';
import { CircularProgressWithLabel } from '../../shared/Loaders/CircularProgressWithLabel';
import { AssignmentResult } from '../Results/AssignmentResult';
import { PersonalDeckAssignmentResults } from '../Results/PersonalDeckAssignmentResults';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: theme.spacing(2),
  },
  progressContainer: {
    maxWidth: 75,
  },
  link: {
    ...buttonLink(theme),
  },
  nameWrapper: {
    display: 'flex',
    flexFlow: 'column',
  },
}));

export function PreviousAssignments({
  studentsAssignmentsData,
  loading,
}: {
  studentsAssignmentsData: DatumType[];
  loading: boolean;
}) {
  const classes = useStyles();
  const {
    appState: { activeEnrollment },
  } = useContext(AppContext);
  const [selectedGroupsAssignmentId, setSelectedGroupsAssignmentId] =
    useState('');

  const [selectedDatum, setSelectedDatum] = useState<undefined | DatumType>();
  const [selectedEnrollmentsAssignmentId, setSelectedEnrollmentsAssignmentId] =
    useState('');
  const [selectedStudentsAssignmentDatum, setSelectedStudentsAssignmentDatum] =
    useState<DatumType | null>(null);

  const closeResults = () => setSelectedDatum(undefined);
  const [restudy, setRestudy] = useState(false);

  const hideEvaluation = (value: { sad: DatumType }) => {
    let enrollmentsAssignment = value.sad.enrollmentsAssignment;
    if (enrollmentsAssignment === null || enrollmentsAssignment === undefined) {
      return false; // do not hide evaluation if there is no enrollmentsAssignment
    }
    return (
      enrollmentsAssignment.assignment.stage !== undefined &&
      enrollmentsAssignment.assignment.stage !== null
    );
  };

  const data = useMemo(() => {
    return studentsAssignmentsData.map((sad) => {
      const { questionsCorrect, questionsTotal, completedAt } = sad;
      const response: Record<
        string,
        {
          id: Scalars['ID']['input'];
          assignmentName: string;
          method: AssignmentMethodEnum;
          sad: (typeof studentsAssignmentsData)[number];
        }
      > = {};
      let dueDate;
      if (sad.groupsAssignment) {
        dueDate = sad.groupsAssignment.dueDate;
        response.assignedAssignment = {
          id: sad.groupsAssignment.id,
          assignmentName: sad.groupsAssignment.assignment.name,
          method: AssignmentMethodEnum.Group,
          sad: sad,
        };
      }
      if (sad.enrollmentsAssignment) {
        dueDate = sad.enrollmentsAssignment.dueDate;
        response.assignedAssignment = {
          id: sad.enrollmentsAssignment.id,
          assignmentName: sad.enrollmentsAssignment.assignment.name,
          method: AssignmentMethodEnum.Individual,
          sad: sad,
        };
      }
      let score =
        questionsCorrect === questionsTotal
          ? 100
          : Math.round((questionsCorrect / questionsTotal) * 100);

      if (
        sad.groupsAssignment?.assignment.assignmentType ===
        AssignmentTypeEnum.Distributed
      ) {
        score =
          ((sad.distributedPointsEarned || 0) /
            (sad.groupsAssignment.assignment.targetDistributedPoints || 1)) *
          100;
      }
      return {
        ...response,
        ...{
          due: dueDate,
          completedAt: completedAt,
          totalQuestions: questionsTotal,
          score: { score: score, sad: sad },
        },
      };
    });
  }, [studentsAssignmentsData]);

  const handleClick = (
    sad: DatumType,
    method: AssignmentMethodEnum,
    groupsAssignmentId: string,
    restudyMode: boolean
  ) => {
    if (
      sad.groupsAssignment?.assignment.assignmentType ===
      AssignmentTypeEnum.Distributed
    ) {
      return () => {
        setSelectedDatum(sad);
      };
    }
    return () => {
      if (method === AssignmentMethodEnum.Group) {
        setSelectedGroupsAssignmentId(groupsAssignmentId);
      } else {
        setSelectedEnrollmentsAssignmentId(groupsAssignmentId);
      }
      setSelectedStudentsAssignmentDatum(sad);
      setRestudy(restudyMode);
    };
  };

  const columns: MUIDataTableColumnDef[] = useMemo(() => {
    return [
      {
        label: 'DUE DATE',
        name: 'due',
        options: {
          customBodyRender: (value: string) => {
            return format(new Date(value), dateFormat);
          },
        },
      },
      {
        label: 'ASSIGNMENT NAME',
        name: 'assignedAssignment',
        options: {
          customBodyRender: (value: {
            id: string;
            assignmentName: string;
            dueDate: string;
            completedAt: string;
            method: AssignmentMethodEnum;
            sad: DatumType;
          }) => {
            return (
              <span className={classes.nameWrapper}>
                {hideEvaluation(value) ? (
                  <span>{value.assignmentName}</span>
                ) : (
                  <button
                    className={classes.link}
                    onClick={handleClick(
                      value.sad,
                      value.method,
                      value.id,
                      false
                    )}
                  >
                    {value.assignmentName}
                  </button>
                )}
                {new Date(value.completedAt) > new Date(value.dueDate) && (
                  <span>
                    <LateAssignmentChip />
                  </span>
                )}
              </span>
            );
          },
        },
      },
      {
        label: 'TOTAL QUESTIONS',
        name: 'totalQuestions',
      },
      {
        label: 'SCORE',
        name: 'score',
        options: {
          customBodyRender: (value: { score: number; sad: DatumType }) => {
            return (
              !hideEvaluation(value) && (
                <div className={classes.progressContainer}>
                  <CircularProgressWithLabel value={value.score} />
                </div>
              )
            );
          },
        },
      },
      {
        label: 'REVIEW',
        name: 'assignedAssignment',
        options: {
          customBodyRender: (value: {
            id: string;
            assignmentName: string;
            dueDate: string;
            completedAt: string;
            method: AssignmentMethodEnum;
            sad: DatumType;
          }) => {
            return (
              value.sad.groupsAssignment?.assignment.assignmentType !==
                AssignmentTypeEnum.Distributed &&
              !hideEvaluation(value) && (
                <StandardButton
                  text={'Restudy'}
                  onClick={handleClick(value.sad, value.method, value.id, true)}
                />
              )
            );
          },
        },
      },
    ];
  }, [classes]);

  const handleClose = () => {
    setSelectedGroupsAssignmentId('');
    setSelectedEnrollmentsAssignmentId('');
  };
  const standardAssignmentResultsOpen =
    (!!selectedGroupsAssignmentId || !!selectedEnrollmentsAssignmentId) &&
    !!selectedStudentsAssignmentDatum;
  return (
    <div className={classes.root}>
      <CustomDataTable
        title="PAST ASSIGNMENTS"
        columns={columns}
        options={{
          search: false,
        }}
        data={data}
        loading={loading}
      />
      {selectedDatum && (
        <PersonalDeckAssignmentResults
          open={!!selectedDatum}
          onClose={closeResults}
          closeDialog={closeResults}
          activeDistributedAssignment={selectedDatum}
        />
      )}
      <AssignmentResult
        open={standardAssignmentResultsOpen}
        groupsAssignmentId={selectedGroupsAssignmentId}
        enrollmentsAssignmentId={selectedEnrollmentsAssignmentId}
        studentId={activeEnrollment?.student.id || ''}
        onClose={handleClose}
        fullWidth
        maxWidth="md"
        handleClose={handleClose}
        restudy={restudy}
      />
    </div>
  );
}
