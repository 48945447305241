import { Typography, useTheme, type Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { format, startOfDay } from 'date-fns';
import React from 'react';
import type { TabValues } from '.';
import type { ActionItemsQuery } from '../../../gql/queries/__generated__/actionItem.generated';
import { ActionItemStateEnum } from '../../../gql/types';
import { monthAndDay, timeOnlyFormat } from '../../../utils/dates';
import { ActionType } from './ActionType';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  noNotifs: {
    padding: theme.spacing(4),
  },
  item: {
    padding: theme.spacing(3),
    borderBottom: '1px solid gray',
    '&:hover': {
      background: theme.palette.grays.main,
      cursor: 'pointer',
    },
  },
  subject: {
    marginTop: theme.spacing(1.5),
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: theme.spacing(1),
  },
  description: {
    fontWeight: theme.typography.fontWeightLight,
  },
  dateTime: {
    marginBottom: theme.spacing(1),
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));

type ItemsListProps = {
  items?: ActionItemsQuery['actionItems'];
  selectedIndex: number;
  setSelectedIndex: React.Dispatch<React.SetStateAction<number>>;
  tabValue: TabValues;
};

export function ItemsList({
  items,
  tabValue,
  setSelectedIndex,
  selectedIndex,
}: ItemsListProps) {
  const classes = useStyles();
  const theme = useTheme();
  if (!items) return null;

  const selectActionItem = (index: number) => {
    return () => {
      setSelectedIndex(index);
    };
  };

  if (
    items.edges?.filter((edge) => {
      const item = edge?.node;
      if (!item) {
        return false;
      }

      if (tabValue === 'open') {
        return item.state === ActionItemStateEnum.Open;
      } else if (tabValue === 'archived') {
        return item.state === ActionItemStateEnum.Archived;
      }
      return false;
    }).length === 0
  ) {
    return (
      <div className={classes.noNotifs}>
        {`There are currently no ${tabValue} notifications.`}
      </div>
    );
  }

  return (
    <div className={classes.root}>
      {items.edges?.map((edge, i) => {
        const actionItem = edge?.node;
        if (!actionItem) {
          return null;
        }
        const updatedAtTime = new Date(actionItem.updatedAt);
        let formatted = format(updatedAtTime, monthAndDay);
        if (updatedAtTime > startOfDay(new Date())) {
          formatted = format(updatedAtTime, timeOnlyFormat);
        }
        const style =
          selectedIndex === i
            ? {
                background: theme.palette.mint.main,
              }
            : undefined;
        return (
          <div
            key={`${actionItem.id}-action-item-key`}
            id={actionItem.id}
            className={classes.item}
            style={style}
            onClick={selectActionItem(i)}
          >
            <span className={classes.header}>
              <ActionType
                actionType={actionItem.actionable.flaggedResponseType}
              />
              <Typography variant="body2" className={classes.dateTime}>
                {formatted}
              </Typography>
            </span>
            <Typography variant="body1" className={classes.subject}>
              {actionItem.subject}
            </Typography>
            <Typography variant="body1" className={classes.description}>
              {actionItem.description}
            </Typography>
          </div>
        );
      })}
    </div>
  );
}
