import { Grid, LinearProgress, Typography, type Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useContext, useState } from 'react';
import { buttonLink } from '../../../assets/shared-styles/Button-Link';
import { CustomTooltip } from '../../shared/CustomTooltip';
import { LoadingSkeletons } from '../../shared/Loaders/LoadingSkeletons';
import { Answers } from '../Question/Answers';
import { useExperimentCheck } from '../Question/Answers/useExperimentCheck';
import { QuestionFeedback } from '../Question/Feedback';
import { Question } from '../Question/Question';
import { StudySessionContext } from './context';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexFlow: 'column',
    height: '100%',
    backgroundColor: 'transparent',
  },
  progressContainer: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(3),
    flex: '0 1 auto',
  },
  progressBarContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  progressBar: {
    width: '100%',
    padding: theme.spacing(1),
    borderRadius: 6,
    height: 10,
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.secondary.main}`,
  },
  content: {
    flex: '1 1 auto',
    position: 'relative',
  },
  questionContainer: {
    padding: theme.spacing(2),
    borderRight: `1px solid ${theme.palette.common.black}`,
  },
  answersContainer: {},
  paragraph: {
    marginBottom: theme.spacing(1.5),
  },
  label: {
    marginRight: theme.spacing(1),
  },
  typographyContainer: {
    display: 'flex',
  },
  explanation: {
    textDecoration: 'underline',
    display: 'inline-block',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  buttonLink: {
    ...buttonLink(theme),
  },
}));

export function StudySession({
  questionNum,
  isPersonal = false,
}: {
  questionNum: number;
  isPersonal?: boolean;
}) {
  const classes = useStyles();
  const { isExperiment } = useExperimentCheck();
  const [supplementOpen, setSupplementOpen] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const {
    feedbackVisible,
    isCorrect,
    question,
    questionsCompleted,
    questionsTotal,
    loading,
    freeResponseWithoutSelfAssessmentId,
    responseMeta,
  } = useContext(StudySessionContext);

  const openTooltip = () => setTooltipOpen(true);
  const closeTooltip = () => setTooltipOpen(false);

  let completion;
  if (questionsCompleted !== undefined && questionsTotal) {
    completion = Math.round((questionsCompleted / questionsTotal) * 100);
  }
  // adding a key so that question resets every time there is a refetch:
  return (
    <div className={classes.root} key={`${questionNum}-questionNum`}>
      {!isExperiment && (
        <div className={classes.progressContainer}>
          <div>
            <span className={classes.typographyContainer}>
              <Typography variant="h5" className={classes.label}>
                Questions Answered Correctly:
              </Typography>
              <Typography>{`${
                questionsCompleted === undefined ? '' : questionsCompleted
              } of ${
                questionsTotal === undefined ? '' : questionsTotal
              }`}</Typography>
            </span>
          </div>
          <div className={classes.progressBarContainer}>
            <LinearProgress
              color="secondary"
              value={completion}
              variant="determinate"
              className={classes.progressBar}
            />
          </div>
          <div>
            <CustomTooltip
              open={tooltipOpen}
              title={
                <>
                  <Typography className={classes.paragraph}>
                    Assignment progression is based on the number of correct
                    responses, not number of attempts.
                  </Typography>
                  <Typography>
                    In order for an assignment to be 100% completed, each
                    question needs to be answered correctly once.
                  </Typography>
                </>
              }
            >
              <button
                onClick={openTooltip}
                onBlur={closeTooltip}
                className={classes.buttonLink}
              >
                <Typography color="secondary" className={classes.explanation}>
                  Why isn&apos;t my progress bar filling up?
                </Typography>
              </button>
            </CustomTooltip>
          </div>
        </div>
      )}
      <Grid
        className={classes.content}
        container
        spacing={0}
        onClick={closeTooltip}
      >
        <Grid item xs={12} sm={6} className={classes.questionContainer}>
          {!question || loading ? <LoadingSkeletons num={10} /> : <Question />}
        </Grid>
        <Grid item xs={12} sm={6} className={classes.answersContainer}>
          {!question || loading ? (
            <LoadingSkeletons num={12} />
          ) : (
            <Answers
              setSupplementOpen={setSupplementOpen}
              supplementOpen={supplementOpen}
              // key is so that all answers unmount when a new question comes along:
              key={`answersForQuestionId-${question.id}`}
              isPersonal={isPersonal}
            />
          )}
        </Grid>
        <QuestionFeedback
          visible={feedbackVisible}
          isCorrect={isCorrect}
          loading={loading}
          supplementOpen={supplementOpen}
          setSupplementOpen={setSupplementOpen}
          freeResponseResponseId={freeResponseWithoutSelfAssessmentId}
          isPersonal={isPersonal}
          responseMeta={responseMeta}
        />
      </Grid>
    </div>
  );
}
