import { Typography, type Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ASSESSMENT_VALUES } from '../../../../utils/assessmentValueMappings';
import { Emoji } from '../../../shared/Emoji';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  header: {
    fontWeight: theme.typography.fontWeightBold,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(0.5),
  },
  line: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(2),
  },
  emoji: {
    fontSize: 24,
    marginRight: theme.spacing(1),
  },
  title: {
    marginRight: theme.spacing(0.5),
  },
}));

export function RatingKey() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography variant="h3">Rating Key</Typography>
      {ASSESSMENT_VALUES.map(
        ([, { emoji, label: emojiLabel, title, description }]) => (
          <div className={classes.line} key={`${emojiLabel}-rating-key`}>
            <span className={classes.emoji}>
              <Emoji symbol={emoji} label={emojiLabel} />
            </span>
            <Typography variant="caption">
              <strong className={classes.title}>{title}</strong>
              {description}
            </Typography>
          </div>
        )
      )}
    </div>
  );
}
