import * as Types from '../../types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type PersonalDeckDataQueryVariables = Types.Exact<{
  enrollmentId: Types.Scalars['ID']['input'];
  startDay: Types.Scalars['String']['input'];
}>;

export type PersonalDeckDataQuery = {
  __typename?: 'Query';
  personalDeckDataByEnrollment: Array<{
    __typename?: 'EnrollmentPersonalDeckDatum';
    id: string;
    date: any;
    frozenAtEndOfDay: boolean;
    numAttempted: number;
    numCorrect: number;
    numCompleted: number;
    numDueAtStartOfDay: number;
  }>;
};

export const PersonalDeckDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'personalDeckData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'enrollmentId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDay' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'personalDeckDataByEnrollment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'enrollmentId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'enrollmentId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDay' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'startDay' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'frozenAtEndOfDay' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'numAttempted' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'numCorrect' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'numCompleted' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'numDueAtStartOfDay' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PersonalDeckDataQuery,
  PersonalDeckDataQueryVariables
>;
