import { createTheme, Theme } from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface PaletteColor {
    300?: string;
    500?: string;
    900?: string;
  }

  interface SimplePaletteColorOptions {
    300?: string;
    500?: string;
    900?: string;
  }
}

export const secondaryMain = '#30B671';

const customTheme: Theme = createTheme({
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: 14,
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          paddingTop: '24px !important', // https://github.com/mui/material-ui/issues/27851
        },
      },
    },
  },
  palette: {
    background: {
      default: '#f6f6f6',
    },
    primary: {
      main: '#053b48',
    },
    secondary: {
      main: secondaryMain,
    },
    mint: {
      dark: '#47bda6',
      main: '#def3ef',
      light: '#DBF2ED',
    },
    warning: {
      dark: '#DFA214',
      main: '#FFE685',
      light: '#FFF2C1',
    },
    success: {
      dark: '#1b5e20',
      light: '#4caf50',
      main: '#2e7d32',
      '300': '#E8F5E9',
      '500': '#8BC34A',
      '900': '#1B5E20',
    },
    error: {
      main: '#CB1E00',
      light: '#FAD4CD',
      '300': '#FFD7D7',
      '500': '#E07979',
      '900': '#600000',
    },
    info: {
      '300': '#B9ECFE',
      '500': '#99CADC',
      '900': '#3D5660',
    },
    accent: {
      light: '#F4FBFA',
      main: '#F28775',
      '300': '#DBD8FF',
      '500': '#AE99DC',
      '900': '#3E3D60',
    },
    grays: {
      light: '#f1f1f1',
      main: '#e6e6e6',
      dark: '#999999',
    },
    darks: {
      main: '#343434',
    },
  },
  typography: {
    h1: {
      fontWeight: 'bold',
      fontSize: 32,
      lineHeight: '36px',
      textTransform: 'uppercase',
    },
    h2: {
      fontWeight: 'bold',
      fontSize: 22,
      lineHeight: '24px',
      textTransform: 'uppercase',
    },
    h3: {
      fontWeight: 'bold',
      fontSize: 20,
      lineHeight: '22px',
    },
    h4: {
      fontWeight: 'bold',
      fontSize: 18,
      lineHeight: '24px',
    },
    h5: {
      fontWeight: 'bold',
      fontSize: 18,
      lineHeight: '20px',
    },
    subtitle1: {
      fontWeight: 'bold',
      fontSize: 18,
      lineHeight: '20px',
      textTransform: 'uppercase',
    },
    body1: {
      fontWeight: 'normal',
      fontSize: 16,
      lineHeight: '20px',
    },
    caption: {
      fontWeight: 'normal',
      fontSize: 14,
      lineHeight: '18px',
    },
  },
});

export default customTheme;
