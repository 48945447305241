import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  useTheme,
} from '@mui/material';
import { ReactNode } from 'react';
import { AssignmentCardHeader, DisplayStatus } from './AssignmentCardHeader';

type BaseCardProps = {
  content: ReactNode;
  actions: ReactNode;
  assignmentName: string;
  assignmentStatus?: DisplayStatus;
};

export function BaseCard(props: BaseCardProps) {
  const theme = useTheme();
  return (
    <Card
      variant="outlined"
      sx={{
        padding: theme.spacing(2),
        boxShadow: '1px 3px 6px 0px rgba(0, 0, 0, 0.12)',
        borderRadius: 2,
      }}
    >
      <CardHeader
        component={() => (
          <AssignmentCardHeader
            assignmentName={props.assignmentName}
            assignmentStatus={props.assignmentStatus}
          />
        )}
      />
      <CardContent
        sx={{
          borderTop: 1,
          borderColor: theme.palette.grey['500'],
          padding: theme.spacing(1),
        }}
      >
        <Grid
          container
          sx={{
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: theme.spacing(2),
          }}
        >
          {props.content}
        </Grid>
      </CardContent>
      <CardActions>
        <Grid
          container
          sx={{
            flexDirection: 'column',
            width: '100%',
            textAlign: 'center',
            gap: theme.spacing(2),
          }}
        >
          {props.actions}
        </Grid>
      </CardActions>
    </Card>
  );
}
