import {
  Button,
  Card,
  CardActionArea,
  CardContent,
  Typography,
  useTheme,
  type Theme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import type { EnrollmentAttributesFragment } from '../../../gql/fragments/__generated__/enrollment.generated';
import { EnrollmentStatusEnum } from '../../../gql/types';

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    marginBottom: theme.spacing(2),
    flexBasis: '90%',
    [theme.breakpoints.up('md')]: {
      flexBasis: '28%',
      marginRight: theme.spacing(1.5),
    },
  },
  cardActionArea: {
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(5),
  },
  activeText: {
    color: theme.palette.accent.main,
  },
  setActiveButton: {
    textDecoration: 'underline',
    color: theme.palette.secondary.main,
  },
}));

type GroupCardProps = {
  enrollment: EnrollmentAttributesFragment;
};

export function GroupCard({ enrollment }: GroupCardProps) {
  const classes = useStyles();
  const { updateActiveEnrollment } = useContext(AppContext);
  const theme = useTheme();
  const group = enrollment.group;
  const isDenied = enrollment.enrollmentStatus === EnrollmentStatusEnum.Denied;
  const isPending =
    enrollment.enrollmentStatus === EnrollmentStatusEnum.Pending;
  const isInactive =
    enrollment.enrollmentStatus === EnrollmentStatusEnum.Inactive;
  const isEnrolled =
    enrollment.enrollmentStatus === EnrollmentStatusEnum.Enrolled;
  const activeGroup = enrollment.active;
  if (!group) {
    return null;
  }
  const teacherName = group.course.teachers
    .map((elem) => elem.lastName)
    .join(', ');

  let cardAction;
  if (activeGroup) {
    cardAction = (
      <Link to="/assignments">
        <Typography variant="body1" className={classes.activeText}>
          Currently Active
        </Typography>
      </Link>
    );
  } else if (isEnrolled) {
    cardAction = (
      <Button
        className={classes.setActiveButton}
        onClick={() => {
          updateActiveEnrollment(enrollment.groupId || '');
        }}
      >
        Set to Active
      </Button>
    );
  } else if (isDenied) {
    cardAction = <Typography variant="body1">Denied Enrollment</Typography>;
  } else if (isPending) {
    cardAction = (
      <Typography variant="body1" className={classes.activeText}>
        Enrollment Pending Teacher Approval
      </Typography>
    );
  } else if (isInactive) {
    cardAction = (
      <Typography variant="body1" className={classes.activeText}>
        Inactive Enrollment
      </Typography>
    );
  }

  return (
    <Card
      className={classes.card}
      key={group.id}
      style={{
        backgroundColor: activeGroup
          ? theme.palette.primary.main
          : theme.palette.common.white,
        color: activeGroup
          ? theme.palette.common.white
          : theme.palette.primary.main,
      }}
    >
      <CardContent>
        <Typography variant="h6">{group.course.name}</Typography>
        <Typography variant="subtitle1">{group.name}</Typography>
        {teacherName}
      </CardContent>
      <CardActionArea
        className={classes.cardActionArea}
        disabled={isDenied || isPending || isInactive}
      >
        {cardAction}
      </CardActionArea>
    </Card>
  );
}
