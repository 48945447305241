import { makeStyles } from '@mui/styles';
import { Notifications } from '../../components/application/Notifications';

const useStyles = makeStyles(() => ({
  root: {},
}));

export function NotificationsView() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Notifications />
    </div>
  );
}
