import type { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useContext } from 'react';
import { AppContext } from '../../../AppContext';
import { Leaderboard } from './Leaderboard';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(4),
  },
}));

export function TempLeaderboard() {
  const classes = useStyles();
  const { activeEnrollment } = useContext(AppContext).appState;
  const courseId = activeEnrollment?.group?.course.id;

  // FIXME: No magic constants!
  if (courseId !== '1114') return null;

  return (
    <div className={classes.root}>
      <Leaderboard />
    </div>
  );
}
