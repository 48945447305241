import { useQuery } from '@apollo/client';
import { Tooltip, Typography, type Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { useContext, useState } from 'react';
import { AppContext } from '../../../../AppContext';
import {
  EnrollmentQuestionsMasteriesDocument,
  EnrollmentQuestionsMasteriesQuery,
} from '../../../../gql/queries/__generated__/masteries.generated';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginRight: theme.spacing(0.5),
  },
  label: {
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: theme.spacing(0.5),
    borderColor: theme.palette.primary.main,
    padding: theme.spacing(2),
    width: 185,
    height: 76,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    [theme.breakpoints.down('lg')]: {
      width: 150,
    },
    [theme.breakpoints.down('md')]: {
      width: 125,
    },
    [theme.breakpoints.down('sm')]: {
      width: 100,
    },
  },
  labelText: {
    fontWeight: theme.typography.fontWeightBold,
  },
  breakdown: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    height: '800px',
  },
  breakdownText: {
    padding: theme.spacing(2),
  },
  breakdownButton: {
    border: 'none',
  },
  struggling: {
    color: theme.palette.primary.main,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    borderBottomWidth: 0.5,
    backgroundColor: '#FDE9E7',
    '&:hover': {
      backgroundColor: '#F7DEDF',
      cursor: 'pointer',
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  disabledStruggling: {
    '&:hover': {
      cursor: 'default',
      backgroundColor: '#FDE9E7',
    },
  },
  inProgress: {
    color: theme.palette.primary.main,
    backgroundColor: '#FFF2E1',
    borderTopWidth: 0.5,
    borderBottomWidth: 0.5,
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: '#FFE6CC',
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inProgressDisabled: {
    '&:hover': {
      cursor: 'default',
      backgroundColor: '#FFF2E1',
    },
  },
  mastered: {
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
    borderTopWidth: 0.5,
    color: theme.palette.primary.main,
    backgroundColor: '#E7F3E7',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: '#C9E0C9',
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  masteredDisabled: {
    '&:hover': {
      cursor: 'default',
      backgroundColor: '#E7F3E7',
    },
  },
}));

type Breakdown = {
  struggling: EnrollmentQuestionsMasteriesQuery['enrollmentQuestionsMasteries'];
  inProgress: EnrollmentQuestionsMasteriesQuery['enrollmentQuestionsMasteries'];
  mastered: EnrollmentQuestionsMasteriesQuery['enrollmentQuestionsMasteries'];
};

type MasteryStatus = 'struggling' | 'inProgress' | 'mastered';

type ProgressBreakdownProps = {
  handleClick: (questionIds: string[]) => void;
  firstSeenStart: Date | null;
  firstSeenEnd: Date | null;
  label: string;
};

export function ProgressBreakdown({
  handleClick,
  firstSeenStart,
  firstSeenEnd,
  label,
}: ProgressBreakdownProps) {
  const classes = useStyles();
  const [breakdown, setBreakdown] = useState<Breakdown>({
    struggling: [],
    inProgress: [],
    mastered: [],
  });
  const {
    appState: { activeEnrollment },
  } = useContext(AppContext);
  useQuery(EnrollmentQuestionsMasteriesDocument, {
    skip: !activeEnrollment,
    fetchPolicy: 'cache-and-network',
    variables: {
      enrollmentId: activeEnrollment?.id || '',
      firstSeenStart,
      firstSeenEnd,
    },
    onError: (err) => {
      console.error(err);
    },
    onCompleted: (data) => {
      const updates: Breakdown = {
        struggling: [],
        inProgress: [],
        mastered: [],
      };
      data.enrollmentQuestionsMasteries.forEach((mastery) => {
        const score = mastery.masteryScore || 0;
        if (score <= 50) {
          updates.struggling.push(mastery);
        }
        if (score > 50 && score < 85) {
          updates.inProgress.push(mastery);
        }
        if (score >= 85) {
          updates.mastered.push(mastery);
        }
      });
      setBreakdown(updates);
    },
  });

  const processQuestionIds = (status: MasteryStatus) => {
    return () => {
      const questionIds = breakdown[status].map((mastery) =>
        mastery.questionId.toString()
      );
      handleClick(questionIds);
    };
  };
  const numStruggling = breakdown.struggling.length;
  const numInProgress = breakdown.inProgress.length;
  const numMastered = breakdown.mastered.length;
  return (
    <div className={classes.root}>
      {breakdown && (
        <div className={classes.breakdown}>
          <div className={classes.label}>
            <Typography color="primary" className={classes.labelText}>
              {label}
            </Typography>
          </div>
          <Tooltip title={`${numStruggling} struggling questions`}>
            <button
              className={clsx(classes.breakdownButton, {
                [classes.struggling]: true,
                [classes.disabledStruggling]: numStruggling === 0,
              })}
              onClick={processQuestionIds('struggling')}
            >
              <Typography variant="h6" className={classes.breakdownText}>
                {`${numStruggling}`}
              </Typography>
            </button>
          </Tooltip>
          <Tooltip title={`${numInProgress} progressing questions`}>
            <button
              className={clsx(classes.breakdownButton, {
                [classes.inProgress]: true,
                [classes.inProgressDisabled]: numInProgress === 0,
              })}
              onClick={processQuestionIds('inProgress')}
            >
              <Typography variant="h6" className={classes.breakdownText}>
                {`${numInProgress}`}
              </Typography>
            </button>
          </Tooltip>
          <Tooltip title={`${numMastered} mastered questions`}>
            <button
              className={clsx(classes.breakdownButton, {
                [classes.mastered]: true,
                [classes.masteredDisabled]: numMastered === 0,
              })}
              onClick={processQuestionIds('mastered')}
            >
              <Typography variant="h6" className={classes.breakdownText}>
                {`${numMastered}`}
              </Typography>
            </button>
          </Tooltip>
        </div>
      )}
    </div>
  );
}
