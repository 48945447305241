import { PlayCircleFilledOutlined, Stop } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useState } from 'react';

const useStyles = makeStyles(() => ({
  root: {
    display: 'inline-block',
  },
  icon: {},
}));

type TextToSpeechProps = {
  text: string;
};

enum TextToSpeechStatus {
  STOPPED = 'stopped',
  PLAYING = 'playing',
}

const IMAGE_ONLY_PLAIN_TEXT = '[IMAGE]';

export function TextToSpeech({ text }: TextToSpeechProps) {
  const classes = useStyles();
  const [status, setStatus] = useState(TextToSpeechStatus.STOPPED);
  if ('speechSynthesis' in window === false) {
    // Speech Synthesis Not Supported:
    return null;
  }
  if (!text || text === IMAGE_ONLY_PLAIN_TEXT) {
    return null;
  }
  const playing = status === TextToSpeechStatus.PLAYING;
  const handle = () => {
    if (playing) {
      window.speechSynthesis.cancel();
      setStatus(TextToSpeechStatus.STOPPED);
      return;
    }
    const speech = new SpeechSynthesisUtterance();
    speech.rate = 0.88;
    speech.text = text;
    window.speechSynthesis.speak(speech);
    speech.onend = () => setStatus(TextToSpeechStatus.STOPPED);
    setStatus(TextToSpeechStatus.PLAYING);
  };
  return (
    <div className={classes.root}>
      <span>
        <Tooltip title={playing ? 'Stop' : 'Read Aloud'}>
          <IconButton onClick={handle} size="small">
            {playing ? (
              <Stop className={classes.icon} />
            ) : (
              <PlayCircleFilledOutlined className={classes.icon} />
            )}
          </IconButton>
        </Tooltip>
      </span>
    </div>
  );
}
