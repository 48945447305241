import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Tab, type Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useState } from 'react';
import { QuestionsProgress } from '../../components/application/Progress/Questions';
import { StandardsProgress } from '../../components/application/Progress/Standards';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    flexFlow: 'column',
  },
  descriptionContainer: {
    padding: `${theme.spacing(2.5)} ${theme.spacing(5)}`,
    borderBottom: `1px solid ${theme.palette.primary.main}`,
  },
  tableContainer: {
    padding: theme.spacing(5),
    position: 'relative',
    height: '100%',
  },
  bottomSection: {
    flex: '1 1 auto',
    backgroundColor: theme.palette.grays.main,
    position: 'relative',
  },
  topBackground: {
    backgroundImage: `linear-gradient(to bottom, ${theme.palette.mint.dark}, #7de0d2)`,
    position: 'absolute',
    borderRadius: 100,
    top: 0,
    right: -200,
    width: '80%',
    height: '60%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  tabsContainer: {
    borderBottom: `1px solid ${theme.palette.grays.dark}`,
    marginTop: theme.spacing(4),
    paddingLeft: theme.spacing(4),
  },
  bottomBackground: {
    backgroundImage: `linear-gradient(to bottom, ${theme.palette.mint.dark}, #7de0d2)`,
    position: 'absolute',
    bottom: 0,
    left: -200,
    borderRadius: 100,
    width: '70%',
    height: '50%',
    [theme.breakpoints.up('sm')]: {
      width: '60%',
    },
    [theme.breakpoints.up('md')]: {
      width: '60%',
    },
    [theme.breakpoints.up('lg')]: {
      width: '55%',
    },
    [theme.breakpoints.up('lg')]: {
      width: '45%',
    },
  },
}));

export function Mastery() {
  const classes = useStyles();
  const [value, setValue] = useState('1');

  const handleChange = (event: unknown, newValue: string) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <TabContext value={value}>
        <TabList
          className={classes.tabsContainer}
          onChange={handleChange}
          aria-label="Progress Tabs"
        >
          <Tab label="QUESTIONS" value="1" />
          <Tab label="STANDARDS" value="2" />
        </TabList>
        <TabPanel value={'1'}>
          <QuestionsProgress />
        </TabPanel>
        <TabPanel value={'2'}>
          <StandardsProgress />
        </TabPanel>
      </TabContext>
    </div>
  );
}
