import { Close } from '@mui/icons-material';
import {
  Dialog,
  DialogProps,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Switch,
  Typography,
  useMediaQuery,
  useTheme,
  type Theme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useEffect, useState } from 'react';
import type { StudentsAssignmentsDatumQuery } from '../../../gql/queries/__generated__/studentsAssignmentsDatum.generated';
import type { StudentsAssignmentsQuestionsQuery } from '../../../gql/queries/__generated__/studentsAssignmentsQuestion.generated';
import { LoadingSkeletons } from '../../shared/Loaders/LoadingSkeletons';
import type {
  PersonalDeckQuestionsInfo,
  PersonalDeckQuestionsResults,
} from '../Assignments/StandardAssignment/AssignmentFinished';
import { AssignmentInfo } from './AssignmentInfo';
import { AssignmentQuestionsResult } from './AssignmentQuestions';
import { Restudy } from './AssignmentQuestions/Restudy';

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    color: theme.palette.primary.main,
  },
  content: {
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      overflow: 'auto',
    },
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
  },
  closeButton: {
    color: theme.palette.secondary.main,
    padding: 0,
  },
  header: {
    borderBottom: `1px solid ${theme.palette.primary.main}`,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '14px 15px',
  },
  titleSwitch: {
    display: 'flex',
    alignItems: 'center',
  },
  restudyModeToggle: {
    marginLeft: theme.spacing(2),
  },
  dialog: {
    overflow: 'hidden',
  },
}));

type ResultsModalProps = {
  handleClose: () => void;
  assignmentData?: StudentsAssignmentsDatumQuery | PersonalDeckQuestionsInfo;
  assignmentLoading: boolean;
  data?: StudentsAssignmentsQuestionsQuery | PersonalDeckQuestionsResults;
  loading: boolean;
  restudy?: boolean;
};

export function ResultsModal({
  handleClose,
  assignmentData,
  assignmentLoading,
  data,
  loading,
  restudy,
  ...dialogProps
}: ResultsModalProps & DialogProps) {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [restudyMode, setRestudyMode] = useState(restudy);

  useEffect(() => {
    setRestudyMode(restudy);
  }, [restudy]);

  const handleRestudyModeToggle = () => {
    setRestudyMode((prevState) => !prevState);
  };

  let scroll: 'body' | 'paper' = matches ? 'paper' : 'body';
  if (restudyMode) {
    scroll = 'body';
  }

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      fullScreen={fullScreen}
      onClose={handleClose}
      {...dialogProps}
      scroll={scroll}
      className={classes.dialog}
    >
      <DialogTitle className={classes.header}>
        <div className={classes.titleSwitch}>
          <Typography variant="h2" className={classes.title}>
            ASSIGNMENT DETAILS
          </Typography>
          <FormControlLabel
            control={
              <Switch
                checked={restudyMode}
                onChange={handleRestudyModeToggle}
                name="restudyModeToggle"
                color="primary"
              />
            }
            label="Restudy Mode"
            className={classes.restudyModeToggle}
          />
        </div>

        <IconButton
          className={classes.closeButton}
          aria-label="close"
          onClick={handleClose}
          size="large"
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <div className={classes.content} style={{ overflow: 'hidden' }}>
        {!restudyMode && (
          <AssignmentInfo
            assignmentData={assignmentData}
            assignmentLoading={assignmentLoading}
          />
        )}
        {!data || loading ? (
          <LoadingSkeletons num={10} />
        ) : restudyMode ? (
          <Restudy studentsAssignmentQuestionsQuery={data} />
        ) : (
          <AssignmentQuestionsResult studentsAssignmentQuestionsQuery={data} />
        )}
      </div>
    </Dialog>
  );
}
