import { Button, Typography, type Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useState } from 'react';
import type { StudentsAssignmentsDatumQuery } from '../../../gql/queries/__generated__/studentsAssignmentsDatum.generated';
import { LateAssignmentChip } from '../../shared/Chips/LateAssignmentChip';
import { PersonalDeckAssignmentResults } from '../Results/PersonalDeckAssignmentResults';
import { ActiveLateDistributedAssignmentExplanation } from './ActiveLateDistributedAssignmentExplanation';
import { AssignmentCardDueDate } from './AssignmentCardDueDate';
import { DistributedAssignmentExplanation } from './DistributedAssignmentExplanation';
import { InactiveDistributedAssignmentExplanation } from './InactiveDistributedAssignmentExplanation';
import { Legend } from './Legend';

type StyleProps = {
  late?: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(1),
    backgroundColor: theme.palette.mint.light,
    borderRadius: 4,
    border: ({ late }: StyleProps) => {
      if (late) {
        return '1px solid #FE4D48';
      }
      return `1px solid ${theme.palette.mint.dark}`;
    },
    backgroundImage: ({ late }: StyleProps) => {
      if (late) {
        return `linear-gradient(to top, #FFE9E7, #FFE9E7)`;
      }

      return theme.palette.mint.dark;
    },
  },
  title: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.h5.fontSize,
    marginBottom: theme.spacing(1),
  },
  legendContainer: {
    marginTop: theme.spacing(1),
    fontSize: theme.typography.h1.fontSize,
  },
  button: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  lateChipContainer: {
    marginBottom: theme.spacing(1),
  },
}));

type DistributedAssignmentCardProps = {
  activeDistributedAssignment: StudentsAssignmentsDatumQuery['studentsAssignmentsDatum'];
  late?: boolean;
  inactive?: boolean;
};

export function DistributedAssignmentCard({
  activeDistributedAssignment,
  late,
  inactive,
}: DistributedAssignmentCardProps) {
  const classes = useStyles({ late });
  const [open, setOpen] = useState(false);
  const openResults = () => setOpen(true);
  const closeResults = () => setOpen(false);
  if (!activeDistributedAssignment) return null;

  const score = Math.round(
    ((activeDistributedAssignment.distributedPointsEarned || 0) /
      (activeDistributedAssignment.groupsAssignment?.assignment
        .targetDistributedPoints || 1)) *
      100
  );
  const assignment = activeDistributedAssignment.groupsAssignment?.assignment;
  const renderHelper = () => {
    if (inactive) {
      return (
        <Legend
          label="Why can't I work on this late Personal Deck Assignment?"
          Tooltip={InactiveDistributedAssignmentExplanation}
        />
      );
    } else if (late) {
      return (
        <Legend
          label="Why is this late Personal Deck Assignment shown here?"
          Tooltip={ActiveLateDistributedAssignmentExplanation}
        />
      );
    } else {
      return (
        <Legend
          label="What's a Personal Deck Assignment?"
          Tooltip={DistributedAssignmentExplanation}
        />
      );
    }
  };

  return (
    <div className={classes.root}>
      <Typography variant="body1" color="primary" className={classes.title}>
        {assignment ? assignment.name : ''}
      </Typography>
      <div className={classes.legendContainer}>{renderHelper()}</div>
      <div>
        {late && (
          <div className={classes.lateChipContainer}>
            {' '}
            <LateAssignmentChip />{' '}
          </div>
        )}
      </div>
      <Typography>
        <strong>Target Points: </strong>
        {
          activeDistributedAssignment.groupsAssignment?.assignment
            .targetDistributedPoints
        }
      </Typography>
      <Typography>
        <strong>Your Current Points: </strong>
        {`${activeDistributedAssignment.distributedPointsEarned} / ${activeDistributedAssignment.groupsAssignment?.assignment.targetDistributedPoints} (${score}%)`}
      </Typography>
      <AssignmentCardDueDate
        distributed
        due={new Date(activeDistributedAssignment.groupsAssignment?.dueDate)}
        current={new Date()}
        pastDue={!!late}
      />
      <Button
        variant="contained"
        color="primary"
        className={classes.button}
        onClick={openResults}
      >
        View Assignment Details
      </Button>
      {activeDistributedAssignment && (
        <PersonalDeckAssignmentResults
          open={open}
          onClose={closeResults}
          closeDialog={closeResults}
          activeDistributedAssignment={activeDistributedAssignment}
        />
      )}
    </div>
  );
}
