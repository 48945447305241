import { useQuery } from '@apollo/client';
import {
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  type Theme,
} from '@mui/material';
import { yellow } from '@mui/material/colors';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { useContext } from 'react';
import { AppContext } from '../../../../AppContext';
import { LeaderboardDocument } from '../../../../gql/queries/__generated__/leaderboard.generated';
import { HelpKitXP } from '../../HelpKit/HelpKitArticles/HelpKitXP';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginBottom: theme.spacing(4),
  },
  tableContainer: {
    marginTop: theme.spacing(2),
    borderRadius: 4,
    border: `1px solid ${theme.palette.primary.main}`,
    backgroundColor: 'rgb(239,249,244, 0.3)',
    maxHeight: '80vh',
  },
  titleContainer: {
    padding: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.grays.main}`,
    color: theme.palette.primary.main,
  },
  disabledNoticeContainer: {
    padding: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.grays.main}`,
    color: theme.palette.primary.main,
  },
  table: {
    overflowX: 'scroll',
  },
  row: {
    '& td': {
      padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
      borderBottom: `1px solid ${theme.palette.grays.main}`,
      maxWidth: '40vw',
    },
    '&:nth-of-type(even)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  },
  tableHead: {
    '& .MuiTableCell-stickyHeader': {
      backgroundColor: theme.palette.primary.main,
    },
    '& th': {
      color: theme.palette.common.white,
      padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
    },
  },
  noDataContainer: {
    textAlign: 'center',
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.primary.main,
    padding: theme.spacing(2),
  },
  onLeaderBoard: {
    backgroundColor: yellow[100], // set the background color to yellow
    boxShadow: `0 0 5px 2px ${yellow[400]}`, // add a yellow shadow
  },
}));

type LeaderboardProps = {
  groupId: string;
  enrollmentPoints: number;
};

export function Leaderboard({ groupId, enrollmentPoints }: LeaderboardProps) {
  const classes = useStyles();
  const { data, loading } = useQuery(LeaderboardDocument, {
    variables: {
      groupId: groupId,
    },
  });
  const {
    appState: { activeEnrollment },
  } = useContext(AppContext);
  const renderLoading = () => {
    if (loading) {
      return (
        <div>
          <LinearProgress />
        </div>
      );
    }
    return null;
  };
  return (
    <div className={classes.root}>
      <TableContainer className={classes.tableContainer}>
        <div className={classes.titleContainer}>
          <Typography variant="h6">
            {data?.leaderboard.length === 5
              ? 'Top 5 Leaderboard'
              : 'Leaderboard'}{' '}
            (Your XP: {enrollmentPoints})
          </Typography>
          <Typography variant="caption">
            Earn experience (XP) by consistently studying your Personal Deck!
            You&apos;ll earn a point for every successful recall and earn bonus
            points when you maintain a daily streak of studying your deck.{' '}
            <HelpKitXP />
          </Typography>
        </div>

        {data?.leaderboard.length === 0 && (
          <div className={classes.disabledNoticeContainer}>
            <Typography variant="h6">
              Your teacher has disabled leaderboards for this course!
            </Typography>
          </div>
        )}
        {data?.leaderboard.length ? (
          <Table stickyHeader className={classes.table}>
            <TableHead className={classes.tableHead}>
              <TableRow>
                <TableCell>Rank</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>XP</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {data?.leaderboard.map((d, i) => {
                const onLeaderBoard = d['id'] === activeEnrollment?.id;
                return (
                  <TableRow
                    key={`qkey-${d['student']['fullName']}`}
                    className={clsx(classes.row, {
                      [classes.onLeaderBoard]: onLeaderBoard,
                    })}
                  >
                    <TableCell>{`${i + 1}`}</TableCell>
                    <TableCell>{d['student']['fullName']}</TableCell>
                    <TableCell>{d['points'] ?? 0}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        ) : (
          renderLoading()
        )}
      </TableContainer>
    </div>
  );
}
