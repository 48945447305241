import { useMutation, useQuery } from '@apollo/client';
import { Dialog, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import { StartStudySessionDocument } from '../../../gql/mutations/__generated__/personalDeckStudySession.generated';
import { NextPersonalDeckQuestionDocument } from '../../../gql/queries/__generated__/personalDeckQuestion.generated';
import { StudySessionByIdDocument } from '../../../gql/queries/__generated__/personalDeckStudySession.generated';
import { onError } from '../../../utils/apollo/apolloHelper';
import { AlertsContext } from '../Alerts/context';
import { StudySessionContainer } from '../StudySession/StudySessionContainer';
import { StudySessionProvider } from '../StudySession/context';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
  },
}));

export function PersonalDeck() {
  const classes = useStyles();
  const {
    appState: { activeEnrollment },
  } = useContext(AppContext);
  const { dispatch } = useContext(AlertsContext);
  const activeEnrollmentId = activeEnrollment?.id || '';

  const [studySessionId, setStudySessionId] = useState('');
  const {
    data: studySession,
    refetch: refetchSession,
    loading: sessionLoading,
  } = useQuery(StudySessionByIdDocument, {
    skip: studySessionId === '',
    variables: {
      personalDeckStudySessionId: studySessionId,
    },
    fetchPolicy: 'network-only',
  });
  const { data, loading, refetch } = useQuery(
    NextPersonalDeckQuestionDocument,
    {
      skip: !studySessionId,
      fetchPolicy: 'cache-and-network',
      variables: {
        personalDeckStudySessionId: studySessionId,
        previousQuestionId: null,
      },
      notifyOnNetworkStatusChange: true,
    }
  );
  const [startSession] = useMutation(StartStudySessionDocument, {
    onError: onError(dispatch),
    onCompleted: (res) => {
      if (res.createStudySession.id) {
        setStudySessionId(res.createStudySession.id);
      }
    },
  });

  useEffect(() => {
    // wait for active enrollment to be set before starting session:
    if (activeEnrollmentId) {
      startSession({
        variables: {
          enrollmentId: activeEnrollmentId,
        },
      });
    }
  }, [activeEnrollmentId, startSession]);
  const [questionNum, setQuestionNum] = useState(1);
  const navigate = useNavigate();

  const getNextQuestion = () => {
    const questionId = data?.nextPersonalDeckQuestion?.question.id;
    refetch({
      personalDeckStudySessionId: studySessionId,
      previousQuestionId: questionId,
    });
    refetchSession();
    // when fetching next question, fetch the student to get updated personal
    // deck due count:
    setQuestionNum((currentQuestionNum) => currentQuestionNum + 1);
  };
  const handleClose = () => navigate('/');
  const isLoading = loading || sessionLoading;
  return (
    <Dialog
      classes={{ paper: classes.root }}
      open={true}
      fullScreen
      disableEnforceFocus
    >
      <StudySessionProvider
        questionsCompleted={studySession?.studySessionById.numCompleted}
        questionsTotal={studySession?.studySessionById.totalQuestions}
        personalDecksQuestionId={data?.nextPersonalDeckQuestion?.id}
        personalDeckStudySessionId={studySessionId}
        question={data?.nextPersonalDeckQuestion?.question}
        loading={isLoading}
        getNextQuestion={getNextQuestion}
      >
        <StudySessionContainer
          questionNum={questionNum}
          assignmentFinished={
            !loading && !!data && !data.nextPersonalDeckQuestion
          }
          handleClose={handleClose}
          name="Personal Deck Review Session"
          isPersonal={true}
        />
      </StudySessionProvider>
    </Dialog>
  );
}
