import * as Types from '../../types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type CreateFreeResponseResponseMutationVariables = Types.Exact<{
  questionId: Types.Scalars['ID']['input'];
  answer: Types.Scalars['String']['input'];
  richText: Types.Scalars['JSON']['input'];
  groupsAssignmentId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  enrollmentsAssignmentId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  personalDeckStudySessionId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  personalDecksQuestionId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
}>;

export type CreateFreeResponseResponseMutation = {
  __typename?: 'Mutation';
  createFreeResponseResponse: {
    __typename?: 'FreeResponseResponse';
    id: string;
    answer: string;
    autograded: boolean;
    richText: any;
  };
};

export type CreateShortAnswerResponseMutationVariables = Types.Exact<{
  questionId: Types.Scalars['ID']['input'];
  groupsAssignmentId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  enrollmentsAssignmentId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  personalDecksQuestionId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  personalDeckStudySessionId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  answer: Types.Scalars['String']['input'];
  richText: Types.Scalars['JSON']['input'];
}>;

export type CreateShortAnswerResponseMutation = {
  __typename?: 'Mutation';
  createShortAnswerResponse: {
    __typename?: 'ShortAnswerResponse';
    isCorrect: boolean;
    numPriorResponses?: number | null;
    id: string;
  };
};

export type CreateMultipleChoiceResponseMutationVariables = Types.Exact<{
  questionId: Types.Scalars['ID']['input'];
  groupsAssignmentId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  enrollmentsAssignmentId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  personalDecksQuestionId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  personalDeckStudySessionId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  answerChoiceIds:
    | Array<Types.Scalars['ID']['input']>
    | Types.Scalars['ID']['input'];
}>;

export type CreateMultipleChoiceResponseMutation = {
  __typename?: 'Mutation';
  createMultipleChoiceResponse: {
    __typename?: 'MultipleChoiceResponse';
    isCorrect: boolean;
    numPriorResponses?: number | null;
    id: string;
  };
};

export type UpdateFreeResponseResponseMutationVariables = Types.Exact<{
  freeResponseResponseId: Types.Scalars['ID']['input'];
  selfAssessment: Types.AssessmentValueEnum;
}>;

export type UpdateFreeResponseResponseMutation = {
  __typename?: 'Mutation';
  updateFreeResponseResponse: {
    __typename?: 'FreeResponseResponse';
    id: string;
    groupsAssignment?: { __typename?: 'GroupsAssignment'; id: string } | null;
  };
};

export type CreateFlaggedResponseMutationVariables = Types.Exact<{
  responseId: Types.Scalars['ID']['input'];
  responseType?: Types.InputMaybe<Types.QuestionTypeEnum>;
  comment?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;

export type CreateFlaggedResponseMutation = {
  __typename?: 'Mutation';
  createFlaggedResponse: {
    __typename?: 'FlaggedResponse';
    id: string;
    flaggedResponseType: Types.FlaggedResponseTypeEnum;
    responseType: string;
    responseId: number;
    response:
      | { __typename?: 'FreeResponseResponse'; id: string }
      | { __typename?: 'MultipleChoiceResponse'; id: string }
      | { __typename?: 'ShortAnswerResponse'; id: string };
  };
};

export type ShortAnswerTypoCheckMutationVariables = Types.Exact<{
  questionId: Types.Scalars['ID']['input'];
  groupsAssignmentId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  enrollmentsAssignmentId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  personalDecksQuestionId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  answer: Types.Scalars['String']['input'];
  richText: Types.Scalars['JSON']['input'];
}>;

export type ShortAnswerTypoCheckMutation = {
  __typename?: 'Mutation';
  shortAnswerTypoCheck: {
    __typename?: 'ShortAnswerTypoCheck';
    result: boolean;
  };
};

export const CreateFreeResponseResponseDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createFreeResponseResponse' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'questionId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'answer' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'richText' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'JSON' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'groupsAssignmentId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'enrollmentsAssignmentId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'personalDeckStudySessionId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'personalDecksQuestionId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createFreeResponseResponse' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'questionId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'questionId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'answer' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'answer' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'richText' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'richText' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'personalDecksQuestionId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'personalDecksQuestionId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'personalDeckStudySessionId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'personalDeckStudySessionId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'enrollmentsAssignmentId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'enrollmentsAssignmentId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'groupsAssignmentId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'groupsAssignmentId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'answer' } },
                { kind: 'Field', name: { kind: 'Name', value: 'autograded' } },
                { kind: 'Field', name: { kind: 'Name', value: 'richText' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateFreeResponseResponseMutation,
  CreateFreeResponseResponseMutationVariables
>;
export const CreateShortAnswerResponseDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createShortAnswerResponse' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'questionId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'groupsAssignmentId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'enrollmentsAssignmentId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'personalDecksQuestionId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'personalDeckStudySessionId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'answer' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'richText' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'JSON' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createShortAnswerResponse' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'questionId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'questionId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'personalDecksQuestionId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'personalDecksQuestionId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'personalDeckStudySessionId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'personalDeckStudySessionId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'enrollmentsAssignmentId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'enrollmentsAssignmentId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'groupsAssignmentId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'groupsAssignmentId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'answer' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'answer' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'richText' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'richText' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'isCorrect' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'numPriorResponses' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateShortAnswerResponseMutation,
  CreateShortAnswerResponseMutationVariables
>;
export const CreateMultipleChoiceResponseDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createMultipleChoiceResponse' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'questionId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'groupsAssignmentId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'enrollmentsAssignmentId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'personalDecksQuestionId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'personalDeckStudySessionId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'answerChoiceIds' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'ID' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createMultipleChoiceResponse' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'questionId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'questionId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'personalDecksQuestionId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'personalDecksQuestionId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'personalDeckStudySessionId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'personalDeckStudySessionId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'enrollmentsAssignmentId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'enrollmentsAssignmentId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'groupsAssignmentId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'groupsAssignmentId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'answerChoiceIds' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'answerChoiceIds' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'isCorrect' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'numPriorResponses' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateMultipleChoiceResponseMutation,
  CreateMultipleChoiceResponseMutationVariables
>;
export const UpdateFreeResponseResponseDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateFreeResponseResponse' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'freeResponseResponseId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'selfAssessment' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'AssessmentValueEnum' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateFreeResponseResponse' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'freeResponseResponseId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'freeResponseResponseId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'selfAssessment' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'selfAssessment' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'groupsAssignment' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateFreeResponseResponseMutation,
  UpdateFreeResponseResponseMutationVariables
>;
export const CreateFlaggedResponseDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createFlaggedResponse' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'responseId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'responseType' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'QuestionTypeEnum' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'comment' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createFlaggedResponse' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'responseId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'responseId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'responseType' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'responseType' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'comment' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'comment' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'flaggedResponseType' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'responseType' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'responseId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'response' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'ShortAnswerResponse' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'FreeResponseResponse' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: {
                            kind: 'Name',
                            value: 'MultipleChoiceResponse',
                          },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateFlaggedResponseMutation,
  CreateFlaggedResponseMutationVariables
>;
export const ShortAnswerTypoCheckDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'shortAnswerTypoCheck' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'questionId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'groupsAssignmentId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'enrollmentsAssignmentId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'personalDecksQuestionId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'answer' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'richText' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'JSON' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shortAnswerTypoCheck' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'questionId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'questionId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'groupsAssignmentId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'groupsAssignmentId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'enrollmentsAssignmentId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'enrollmentsAssignmentId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'personalDecksQuestionId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'personalDecksQuestionId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'answer' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'answer' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'richText' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'richText' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'result' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ShortAnswerTypoCheckMutation,
  ShortAnswerTypoCheckMutationVariables
>;
