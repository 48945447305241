import { useQuery } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import { ArrowDropDown, Menu as MenuIcon } from '@mui/icons-material';
import {
  AppBar,
  Avatar,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  type Theme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useContext, useState } from 'react';
import { StudentDocument } from '../../../../gql/queries/__generated__/student.generated';
import { onError } from '../../../../utils/apollo/apolloHelper';
import { AlertsContext } from '../../Alerts/context';
import { drawerWidth } from '../Nav';
import { HeaderTitle } from './HeaderTitle';

export const APP_HEADER_HEIGHT = 64;
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: APP_HEADER_HEIGHT,
  },
  avatar: {
    border: `1px solid ${theme.palette.secondary.main}`,
    width: theme.spacing(5),
    height: theme.spacing(5),
    marginRight: theme.spacing(1),
  },
  appBar: {
    height: APP_HEADER_HEIGHT,
    background: theme.palette.common.white,
    color: theme.palette.primary.main,
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}));

type HeaderProps = {
  handleDrawerToggle: () => void;
};

export function Header({ handleDrawerToggle }: HeaderProps) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const { logout, user = {} } = useAuth0();
  const handleLogout = () => {
    const isMicrosoft = user['https://podsie.org/microsoft-auth'];
    const params = {
      federated: !!isMicrosoft,
    };
    logout({ logoutParams: params });
  };
  const { dispatch } = useContext(AlertsContext);
  const { data } = useQuery(StudentDocument, {
    onError: onError(dispatch),
  });

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const renderedName = data?.student.fullName || data?.student.email;

  return (
    <AppBar position="fixed" className={classes.root}>
      <Toolbar className={classes.appBar}>
        <div>
          <IconButton
            color="inherit"
            aria-label="open menu"
            edge="start"
            className={classes.menuButton}
            onClick={handleDrawerToggle}
            size="large"
          >
            <MenuIcon />
          </IconButton>
          <HeaderTitle />
        </div>
        <Button
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleMenu}
          endIcon={<ArrowDropDown />}
        >
          <Avatar
            className={classes.avatar}
            alt={renderedName}
            src={user.picture}
          />
          <span>{renderedName}</span>
        </Button>
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={open}
          onClose={handleClose}
        >
          <MenuItem onClick={handleLogout}>Log Out</MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
}
