import { AlertsConfirmation, AlertsDialog, AlertsSnack } from './actions';

export type AlertsState = {
  snacks: {
    queue: AlertsSnack[];
    snack?: AlertsSnack;
    open: boolean;
  };
  dialog: AlertsDialog;
  confirmation: AlertsConfirmation;
};

const initialState: AlertsState = {
  snacks: {
    queue: [], // snack notifications are processed through a queue
    open: false,
  },
  dialog: {
    title: '',
    message: '',
  },
  confirmation: {
    message: '',
    confirmFunc: () => {},
  },
};

export default initialState;
