import { useMutation, useQuery } from '@apollo/client';
import { createContext, useCallback, type ReactNode } from 'react';
import { UpdateActiveEnrollmentDocument } from './gql/mutations/__generated__/updateActiveEnrollment.generated';
import {
  StudentDocument,
  type StudentQuery,
} from './gql/queries/__generated__/student.generated';
import type { Group } from './gql/types';

const initialState = {
  activeEnrollment: undefined,
} satisfies AppState;

type Enrollment = StudentQuery['student']['activeEnrollment'];
type AppState = {
  activeEnrollment?: Enrollment;
};

export const AppContext = createContext<{
  appState: AppState;
  updateActiveEnrollment: (groupId: Group['id']) => Promise<void>;
}>({
  appState: initialState,
  updateActiveEnrollment: async () => {},
});

type AppProviderProps = { children: ReactNode };

export function AppProvider({ children }: AppProviderProps) {
  const { data } = useQuery(StudentDocument);
  const [updateEnrollment] = useMutation(UpdateActiveEnrollmentDocument);
  const updateBackendEnrollment = useCallback(
    async (groupId: Group['id']) => {
      await updateEnrollment({
        variables: { groupId },
        refetchQueries: [StudentDocument],
      });
    },
    [updateEnrollment]
  );

  return (
    <AppContext.Provider
      value={{
        appState: {
          activeEnrollment: data?.student.activeEnrollment,
        },
        updateActiveEnrollment: updateBackendEnrollment,
      }}
    >
      {children}
    </AppContext.Provider>
  );
}
