import { useAuth0 } from '@auth0/auth0-react';
import {
  StyledEngineProvider,
  ThemeProvider,
  type Theme,
} from '@mui/material/styles';
import { BrowserRouter as Router } from 'react-router-dom';
import { AppProvider } from './AppContext';
import { CustomApolloProvider } from './CustomApolloProvider';
import { EmailNotVerified } from './components/application/ErrorPages/EmailNotVerified';
import theme from './theme';
import { Views } from './views';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

function App() {
  const { isAuthenticated, user } = useAuth0();

  if (!isAuthenticated) {
    return <div>Loading...</div>;
  }
  const unverified =
    user && !(user.email_verified || user['https://podsie.org/microsoft-auth']);

  if (unverified) {
    return <EmailNotVerified email={user?.email || ''} />;
  }

  return <Views />;
}

function StyledApp() {
  return (
    <CustomApolloProvider>
      <AppProvider>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <Router>
              <App />
            </Router>
          </ThemeProvider>
        </StyledEngineProvider>
      </AppProvider>
    </CustomApolloProvider>
  );
}

export { StyledApp as App };
