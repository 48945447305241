import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import CircularProgress, {
  type CircularProgressProps,
} from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import React from 'react';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
  label: {},
}));

type CircularProgressWithLabelProps = {
  value: number;
  labelStyle?: React.CSSProperties;
};

export function CircularProgressWithLabel(
  props: CircularProgressProps & CircularProgressWithLabelProps
) {
  const classes = useStyles();
  const { value, labelStyle } = props;
  const theme = useTheme();

  let color = theme.palette.accent.main;
  if (value > 85) {
    color = theme.palette.secondary.main;
  } else if (value > 70) {
    color = theme.palette.warning.main;
  }
  return (
    <Box className={classes.root} position="relative" display="inline-flex">
      <CircularProgress
        variant="determinate"
        style={{ color, width: '100%', height: '100%' }}
        {...props}
        value={props.value > 100 ? 100 : props.value}
      />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography
          variant="caption"
          component="div"
          style={labelStyle}
          className={classes.label}
        >{`${Math.round(value)}%`}</Typography>
      </Box>
    </Box>
  );
}
