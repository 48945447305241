import { StudentsAssignmentsDataQuery } from '../../gql/queries/__generated__/studentsAssignmentsDatum.generated';
import * as Types from '../../gql/types';

export const groupsAssignmentPastDue = (
  sad: StudentsAssignmentsDataQuery['studentsAssignmentsData'][0]
) => {
  if (!sad.groupsAssignment) {
    throw Error('This function is for Groups Assignments only!');
  }
  const current = new Date();
  const due = new Date(sad.groupsAssignment?.dueDate);
  return (
    (due && due < current) ||
    sad.groupsAssignment?.assignment.assignmentStatus ===
      Types.AssignmentStatusEnum.PastDue
  );
};
