import { useQuery } from '@apollo/client';
import { Close } from '@mui/icons-material';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  type Theme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { format } from 'date-fns';
import { useMemo } from 'react';
import {
  bubbleGreen,
  bubbleRed,
  bubbleYellow,
} from '../../../../assets/shared-styles/Bubble';
import { MasteriesByStandardAndEnrollmentDocument } from '../../../../gql/queries/__generated__/masteries.generated';
import { dateFormatWithoutTime } from '../../../../utils/dates';
import { CustomDataTable } from '../../../shared/CustomTable/CustomDataTable';
import { QuillDeltaAsHtml } from '../../../shared/QuillDeltaAsHtml';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  closeButton: {
    color: theme.palette.secondary.main,
    padding: 0,
  },
  header: {
    borderBottom: `1px solid ${theme.palette.primary.main}`,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '14px 15px',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  bubbleGreen: {
    ...bubbleGreen(),
  },
  bubbleYellow: {
    ...bubbleYellow(),
  },
  bubbleRed: {
    ...bubbleRed(),
  },
  empty: {},
  content: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.default,
  },
}));

type EnrollmentStandardDetailsProps = {
  enrollmentId: string;
  standardId: string;
  standardTitle: string;
  handleClose: () => void;
};

export function StandardDetails({
  enrollmentId,
  standardId,
  standardTitle,
  handleClose,
}: EnrollmentStandardDetailsProps) {
  const classes = useStyles();
  const { data } = useQuery(MasteriesByStandardAndEnrollmentDocument, {
    skip: !enrollmentId || !standardId,
    variables: {
      enrollmentId,
      standardId,
    },
  });
  const tableData = useMemo(() => {
    if (!data) {
      return [];
    }
    return data.masteriesByStandardAndEnrollment.map((mastery) => ({
      question: mastery.question.richText,
      streak: mastery.streak,
      attempts: mastery.totalAttempts,
      correct: mastery.totalCorrect,
      latestAttempt: mastery.latestAttempt
        ? new Date(mastery.latestAttempt)
        : null,
      firstSeen: mastery.firstSeen ? new Date(mastery.firstSeen) : null,
    }));
  }, [data]);
  return (
    <Dialog
      className={classes.root}
      maxWidth="md"
      fullWidth
      open={!!standardId}
      onClose={handleClose}
      scroll="body"
    >
      <DialogTitle className={classes.header}>
        <Typography variant="h4">{standardTitle}</Typography>
        <IconButton
          className={classes.closeButton}
          aria-label="close"
          onClick={handleClose}
          size="large"
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <CustomDataTable
          title={''}
          loading={false}
          options={{
            search: false,
            sortOrder: {
              name: 'streak',
              direction: 'asc',
            },
            responsive: 'standard',
          }}
          columns={[
            {
              label: 'Question',
              name: 'question',
              options: {
                customBodyRender: (value) => {
                  return <QuillDeltaAsHtml delta={value.ops} />;
                },
                sort: false,
              },
            },
            {
              label: 'Streak',
              name: 'streak',
              options: {
                customBodyRender: (value) => {
                  let style;
                  if (value > 1.0) {
                    style = classes.bubbleGreen;
                  } else if (value > 0) {
                    style = classes.bubbleYellow;
                  } else if (!isNaN(value)) {
                    style = classes.bubbleRed;
                  } else {
                    style = classes.empty;
                  }
                  return (
                    <div>
                      <span className={style}>{value}</span>
                    </div>
                  );
                },
              },
            },
            {
              label: 'First Seen',
              name: 'firstSeen',
              options: {
                customBodyRender: (value) => {
                  if (!value) {
                    return '';
                  }

                  return format(value, dateFormatWithoutTime);
                },
              },
            },
            {
              label: 'Latest Attempt',
              name: 'latestAttempt',
              options: {
                customBodyRender: (value) => {
                  if (!value) {
                    return '';
                  }

                  return format(value, dateFormatWithoutTime);
                },
              },
            },
            { label: 'Total Correct', name: 'correct' },
            { label: 'Total Attempts', name: 'attempts' },
          ]}
          data={tableData}
        />
      </DialogContent>
    </Dialog>
  );
}
