import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material';
import { Alert } from '@mui/lab';
import {
  Divider,
  Stack,
  Typography,
  useTheme,
  type Theme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import type { ActionItemQuery } from '../../../../gql/queries/__generated__/actionItem.generated';
import { AssessmentValueEnum } from '../../../../gql/types';
import { ASSESSMENT_VALUE_TO_EMOJI } from '../../../../utils/assessmentValueMappings';
import { Emoji } from '../../../shared/Emoji';
import { QuillDeltaAsHtml } from '../../../shared/QuillDeltaAsHtml';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  title: {
    color: theme.palette.primary.main,
    marginTop: theme.spacing(2.5),
    marginBottom: theme.spacing(1),
  },
  choice: {
    marginTop: theme.spacing(2.5),
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    color: theme.palette.secondary.main,
    marginRight: theme.spacing(1),
  },
  choiceContent: {
    width: '100%',
  },
  breakdown: {
    marginTop: theme.spacing(2),
  },
  alertContainer: {
    marginTop: theme.spacing(2),
  },
  divider: {
    color: theme.palette.divider,
    borderWidth: '1px',
  },
}));

type FlaggedResponseAnswersProps = {
  flaggedResponse: ActionItemQuery['actionItem']['actionable'];
};

export function FlaggedResponseAnswers({
  flaggedResponse,
}: FlaggedResponseAnswersProps) {
  const classes = useStyles();
  const theme = useTheme();
  const response = flaggedResponse.response;
  const systemAutomated = flaggedResponse.automatedResolution;
  const actor = systemAutomated ? 'System' : 'teacher';
  switch (response.__typename) {
    case 'FreeResponseResponse':
      return (
        <div className={classes.alertContainer}>
          {response.selfAssessment && (
            <Alert severity="info">
              Your self-rating:{' '}
              <Emoji
                symbol={
                  ASSESSMENT_VALUE_TO_EMOJI(
                    response.selfAssessment as AssessmentValueEnum
                  ).emoji
                }
              />
            </Alert>
          )}
          {response.teacherAssessment && (
            <div className={classes.alertContainer}>
              <Alert
                severity={
                  response.teacherAssessment === response.selfAssessment
                    ? 'success'
                    : 'error'
                }
              >
                {`${
                  response.teacherAssessment === response.selfAssessment
                    ? `The ${actor} agreed with your rating and gave you the following rating: `
                    : `The ${actor} disagreed with your rating and gave you the following rating: `
                }`}
                <Emoji
                  symbol={
                    ASSESSMENT_VALUE_TO_EMOJI(
                      response.teacherAssessment as AssessmentValueEnum
                    ).emoji
                  }
                />
              </Alert>
            </div>
          )}
          <Typography variant="h5" className={classes.title}>
            Acceptable Answers:
          </Typography>
          <div>
            <QuillDeltaAsHtml
              delta={response.question.freeResponseAnswer?.richText.ops}
            />
          </div>
          <Typography variant="h5" className={classes.title}>
            Your Answer:
          </Typography>
          <div>
            <QuillDeltaAsHtml delta={response.richText.ops} />
          </div>
          <Typography variant="h5" className={classes.title}>
            Deeper Learning
          </Typography>
          {response.question.supplement && (
            <QuillDeltaAsHtml
              delta={response.question.supplement.richText.ops}
            />
          )}
        </div>
      );
    case 'ShortAnswerResponse':
      return (
        <div>
          <Typography className={classes.title} variant="h5">
            Acceptable Answers:
          </Typography>
          <ul>
            {response.question.shortAnswerAnswers?.map((answer) => {
              if (answer.plainText !== response.answer) {
                return (
                  <li key={answer.id}>
                    <Stack direction="row" style={{ alignItems: 'center' }}>
                      <QuillDeltaAsHtml
                        delta={answer.richText.ops}
                        style={{ backgroundColor: 'transparent' }}
                      />
                      {answer.isCaseSensitive && (
                        <strong>(case-sensitive)</strong>
                      )}
                    </Stack>
                  </li>
                );
              }
              return null;
            })}
          </ul>
          <Divider className={classes.divider} />
          <Typography className={classes.title} variant="h5">
            Your Answer:
          </Typography>
          <Typography>{response.answer}</Typography>
        </div>
      );

    case 'MultipleChoiceResponse': {
      const selectedObj: { [key: string]: boolean } = {};
      response.selectedMultipleChoiceAnswerChoices.forEach((selected) => {
        selectedObj[selected.multipleChoiceAnswerChoice.id] = true;
      });
      return (
        <div>
          {response.question.multipleChoiceAnswerChoices?.map((choice) => {
            const selected = selectedObj[choice.id];
            return (
              <div className={classes.choice} key={choice.id}>
                {choice.isCorrect ? (
                  <CheckBox className={classes.icon} />
                ) : (
                  <CheckBoxOutlineBlank className={classes.icon} />
                )}
                <div
                  className={classes.choiceContent}
                  style={
                    selected
                      ? { border: `3px solid ${theme.palette.primary.main}` }
                      : {}
                  }
                >
                  <QuillDeltaAsHtml delta={choice.richText.ops} />
                </div>
              </div>
            );
          })}
          <Typography
            className={classes.breakdown}
            style={{
              color: response.isCorrect
                ? theme.palette.secondary.main
                : theme.palette.error.main,
            }}
          >{`Student selected the ${
            response.isCorrect ? 'correct' : 'incorrect'
          } answer choice(s). Answer choice(s) that student selected are outlined.`}</Typography>
        </div>
      );
    }

    default:
      return null;
  }
}
