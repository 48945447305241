import { makeStyles } from '@mui/styles';
import { Quill } from 'react-quill';
import type { QuestionAttributesFragment } from '../../../../gql/fragments/__generated__/question.generated';
import { QuestionTypeEnum } from '../../../../gql/types';
import { QuillDeltaAsHtml } from '../../../shared/QuillDeltaAsHtml';

const Delta = Quill.import('delta');

const useStyles = makeStyles(() => ({
  root: {},
  quillDeltaContainer: { display: 'flex', alignItems: 'center' },
  quillDelta: { backgroundColor: 'inherit' },
}));

type AnswerProps = {
  question: QuestionAttributesFragment;
};
export function Answer({ question }: AnswerProps) {
  const classes = useStyles();
  const answers = () => {
    switch (question.questionType) {
      case QuestionTypeEnum.FreeResponse:
        return (
          <div>
            <h3>Exemplar Answer:</h3>
            <ul>
              <li>{question.freeResponseAnswer?.text}</li>
            </ul>
          </div>
        );
      case QuestionTypeEnum.ShortAnswer:
        return (
          <div>
            <h3>Acceptable answers:</h3>
            <ul>
              {question.shortAnswerAnswers &&
                question.shortAnswerAnswers
                  .filter((answer) => !answer.hidden)
                  .map(({ id, isCaseSensitive, richText, text }) => {
                    return (
                      <li key={id}>
                        <div className={classes.quillDeltaContainer}>
                          <QuillDeltaAsHtml
                            delta={
                              richText?.ops?.length
                                ? richText.ops
                                : new Delta({ ops: [{ insert: text }] }).ops
                            }
                            className={classes.quillDelta}
                          />
                          {`(case sensitive: ${isCaseSensitive})`}
                        </div>
                      </li>
                    );
                  })}
            </ul>
          </div>
        );
      case QuestionTypeEnum.SelectAllMultipleChoice:
      case QuestionTypeEnum.SelectOneMultipleChoice:
        return (
          <div>
            <h3>Correct Answer(s)</h3>
            <ul>
              {question.multipleChoiceAnswerChoices &&
                question.multipleChoiceAnswerChoices.map(
                  ({ id, isCorrect, richText, text }) => {
                    if (isCorrect) {
                      return (
                        <li key={id}>
                          <QuillDeltaAsHtml
                            delta={
                              richText?.ops?.length
                                ? richText.ops
                                : new Delta({ ops: [{ insert: text }] }).ops
                            }
                            style={{ backgroundColor: 'inherit' }}
                          />
                        </li>
                      );
                    }
                    return null;
                  }
                )}
            </ul>
          </div>
        );

      default:
        return null;
    }
  };
  return <div className={classes.root}>{answers()}</div>;
}
