import { Tooltip, type Theme, type TooltipProps } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  arrow: {
    color: theme.palette.common.white,
  },
  tooltip: {
    color: theme.palette.common.black,
    backgroundColor: theme.palette.common.white,
    maxWidth: 400,
    ...theme.typography.caption,
    borderRadius: 8,
    padding: `${theme.spacing(3)} ${theme.spacing(2)}`,
    boxShadow: '0 2px 8px 0 rgba(5, 59, 72, 0.5)',
  },
}));

export function CustomTooltip(props: TooltipProps) {
  const classes = useStyles();

  return <Tooltip enterTouchDelay={0} arrow classes={classes} {...props} />;
}
