import { useAuth0 } from '@auth0/auth0-react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import * as Sentry from '@sentry/react';
import { useContext } from 'react';
import { AlertsContext } from './context';
import { closeDialog } from './context/actions';

export function AlertsDialog() {
  const {
    alerts: { dialog },
    dispatch,
  } = useContext(AlertsContext);
  const { user } = useAuth0();
  const { title, message, maxWidth, error } = dialog;
  const handleClose = () => {
    dispatch(closeDialog());
  };

  if (error) {
    Sentry.captureMessage(message, { user });
  }

  return (
    <Dialog
      maxWidth={maxWidth || 'lg'}
      disableScrollLock
      fullWidth={true}
      open={message.length > 0}
      onClose={handleClose}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {message.split('\n').map((individualMessage) => {
          return <div key={individualMessage}>{individualMessage}</div>;
        })}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" autoFocus>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
