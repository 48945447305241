import { Typography, type Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { useState } from 'react';
import type { StudentsAssignmentsQuestionsQuery } from '../../../../gql/queries/__generated__/studentsAssignmentsQuestion.generated';
import {
  QuestionTypeEnum,
  type Question,
  type StudentsAssignmentsQuestion,
} from '../../../../gql/types';
import { renderQuestionType } from '../../../../utils/renderQuestionType';
import { CustomChip } from '../../../shared/Chips/CustomChip';
import { QuillDeltaAsHtml } from '../../../shared/QuillDeltaAsHtml';
import type {
  PersonalDeckQuestionsResult,
  PersonalDeckQuestionsResults,
} from '../../Assignments/StandardAssignment/AssignmentFinished';
import { FreeResponse } from '../../Question/Answers/FreeResponse';
import { MultipleChoice } from '../../Question/Answers/MultipleChoice';
import { ShortAnswer } from '../../Question/Answers/ShortAnswer';
import { Answer } from './Answer';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    minHeight: '20%',
    display: 'flex',
    overflow: 'hidden',
    overflowY: 'auto',
    flexDirection: 'column',
    width: '100%',
  },
  questionType: {
    marginBottom: theme.spacing(0.5),
  },
  noData: {
    textAlign: 'center',
    padding: theme.spacing(3),
  },
  questionResultContainer: {
    borderBottom: `1px solid ${theme.palette.mint.dark}`,
  },
  questionInfoContainer: {
    padding: `0 ${theme.spacing(2)}`,
  },
  odd: {
    backgroundColor: theme.palette.common.white,
  },
  even: {
    backgroundColor: theme.palette.grays.light,
  },
  chipContainer: {
    marginRight: theme.spacing(1.5),
    marginBottom: theme.spacing(1),
  },
  questionContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    backgroundColor: 'transparent',
    color: theme.palette.common.black,
    borderBottom: `dotted .5px ${theme.palette.primary.main}`,
  },
  questionInputContainer: {
    marginBottom: theme.spacing(2),
  },
  answerContainer: {
    borderTop: `dashed 1px ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.mint.main,
    padding: theme.spacing(2),
  },
  richText: {
    backgroundColor: 'transparent',
  },
}));

type RestudyProps = {
  studentsAssignmentQuestionsQuery:
    | StudentsAssignmentsQuestionsQuery
    | PersonalDeckQuestionsResults;
};

export function Restudy({ studentsAssignmentQuestionsQuery }: RestudyProps) {
  const classes = useStyles();

  // eslint-disable-next-line
  const [showResult, setShowResult] = useState<string | null>(null);
  const setSupplementOpen = () => {}; // dummy function

  const renderQuestionInput = (question: Question) => {
    switch (question.questionType) {
      case QuestionTypeEnum.FreeResponse:
        return (
          <FreeResponse
            reviewQuestion={question}
            handleShow={() => setShowResult(question.id)}
          />
        );
      case QuestionTypeEnum.ShortAnswer:
        return (
          <ShortAnswer
            setSupplementOpen={setSupplementOpen}
            reviewQuestion={question}
            handleShow={() => setShowResult(question.id)}
          />
        );
      case QuestionTypeEnum.SelectAllMultipleChoice:
      case QuestionTypeEnum.SelectOneMultipleChoice:
        return (
          <MultipleChoice
            setSupplementOpen={setSupplementOpen}
            reviewQuestion={question}
            handleShow={() => setShowResult(question.id)}
          />
        );
      default:
        return null; // Or handle other question types if needed
    }
  };

  return (
    <div className={classes.root}>
      {studentsAssignmentQuestionsQuery.studentsAssignmentsQuestions.length ===
        0 && (
        <div>
          <Typography variant="h6" color="primary" className={classes.noData}>
            No Questions Data
          </Typography>
        </div>
      )}
      {(
        studentsAssignmentQuestionsQuery.studentsAssignmentsQuestions as Array<
          PersonalDeckQuestionsResult | StudentsAssignmentsQuestion
        >
      ).map((assignmentQuestion, i) => {
        const { question } = assignmentQuestion;
        return (
          <div
            id={`assignment-questions-result-${question.id}`}
            key={`assignmentQuestion-${question.id}`}
            className={clsx(classes.questionResultContainer, {
              [classes.odd]: i % 2 === 1,
              [classes.even]: i % 2 === 0,
            })}
          >
            <div className={classes.questionInfoContainer}>
              <div className={classes.questionContainer}>
                <QuillDeltaAsHtml
                  className={classes.richText}
                  delta={question.richText.ops}
                />
              </div>
              <div>
                <span className={classes.chipContainer}>
                  <CustomChip
                    className={classes.questionType}
                    label={renderQuestionType(question.questionType)}
                  />
                </span>
                {question.standards?.map(({ title, description }) => {
                  return (
                    <span
                      key={`${question.id}-${title}-standards-chip`}
                      className={classes.chipContainer}
                    >
                      <CustomChip label={title} tooltip={description} />
                    </span>
                  );
                })}
              </div>
              <div className={classes.questionInputContainer}>
                {renderQuestionInput(question)}
              </div>
              {showResult === question.id && (
                <div className={classes.answerContainer}>
                  <Answer question={question} />
                </div>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
}
