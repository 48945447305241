import { isUnknownRecord, type UnknownRecord } from '@podsie/utils/object.js';
import { toHTML } from '@podsie/utils/string.js';
import { default as Katex, type KatexOptions } from 'katex';
import type { DeltaOperation, OptionalAttributes } from 'quill';
import type { GroupType } from 'quill-delta-to-html';
import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html';
import { Value } from 'react-quill';

const defaultKatexOptions: KatexOptions = { throwOnError: false };

/**
 * Convert the given `operations` to an HTML string supporting KaTeX.
 *
 * @see {@link DeltaOperation}
 */
export const quillDeltaOperationsToHTMLString = (
  operations: DeltaOperation[]
): string => {
  const converter = new QuillDeltaToHtmlConverter(operations);
  converter.afterRender(renderKatexFromHTML);
  return converter.convert();
};

const renderKatexFromHTML = (_: GroupType, html: string): string => {
  const containerElement = toHTML(html);

  // properly render katex formulas:
  const formulaNodes = containerElement.querySelectorAll('.ql-formula');
  const nodeListLength = formulaNodes.length;
  for (let i = 0; i < nodeListLength; i += 1) {
    // rendering formula.textContent instead of formula.innerHTML
    // because innerHTML displays inequality symbols as html entities:
    // NOTE: Asserting the formula node is an `HTMLElement` is acceptable here
    //       because the `render` imlpementation[0] only requires a `Node` for
    //       `appendChild()` and `textContent` which an `Element` has.
    // [0]: https://github.com/KaTeX/KaTeX/blob/4f1d9166749ca4bd669381b84b45589f1500a476/katex.js#L41C11-L41C12
    Katex.render(
      formulaNodes[i].textContent ?? '',
      formulaNodes[i] as HTMLElement,
      defaultKatexOptions
    );
  }

  return containerElement.innerHTML;
};

export const getPlainTextFromDelta = (delta: Value): string => {
  if (typeof delta === 'string') {
    return delta;
  }
  const arr = typeof delta.reduce === 'function' ? delta : delta.ops || [];
  return arr
    .reduce(function (text, op) {
      if (!op.insert)
        throw new TypeError('only `insert` operations can be transformed!');
      if (typeof op.insert !== 'string' && op.insert['formula']) {
        return text + op.insert['formula'];
      }
      if (typeof op.insert !== 'string') return text + ' ';
      return text + op.insert;
    }, '')
    .slice(0, -1); // remove extra `\n` line break from end of plain text
};

export const extractPlainAndRichText = <Delta extends Value>(delta: Delta) => {
  return {
    text: getPlainTextFromDelta(delta),
    richText: delta,
  };
};

const isBaseDeltaOperation = (
  value: unknown
): value is UnknownRecord & OptionalAttributes =>
  isUnknownRecord(value) &&
  (value.attributes === undefined || isUnknownRecord(value));

export type DeleteOperation = { delete: number } & OptionalAttributes;
export const isDeleteOperation = (value: unknown): value is DeleteOperation =>
  isBaseDeltaOperation(value) && typeof value.delete === 'number';

export type InsertOperation = {
  insert: TextInsertion | UnknownRecord;
} & OptionalAttributes;

/**
 * Is the given `value` an {@link InsertOperation}?
 *
 * Note that insert operations may be extended by external libraries to include
 * unexpected types. Consider further consider further checking the shape of
 * insert operations by checking the `.insert` property with more specific type
 * guards like {@link isFormulaInsertion} or {@link isImageInsertion}.
 */
export const isInsertOperation = (value: unknown): value is InsertOperation =>
  isBaseDeltaOperation(value) &&
  (typeof value.insert === 'string' || isUnknownRecord(value.insert));

export type TextInsertion = string;
export const isTextInsertion = (
  insertion: unknown
): insertion is TextInsertion => typeof insertion === 'string';

export type FormulaInsertion = { formula: string };
export const isFormulaInsertion = (
  insertion: unknown
): insertion is FormulaInsertion =>
  isUnknownRecord(insertion) && typeof insertion.formula === 'string';

export type ImageInsertion = { image: string };
export const isImageInsertion = (
  insertion: unknown
): insertion is ImageInsertion =>
  isUnknownRecord(insertion) && typeof insertion.image === 'string';

export type RetainOperation = { retain: number } & OptionalAttributes;
export const isRetainOperation = (value: unknown): value is RetainOperation =>
  isBaseDeltaOperation(value) && typeof value.retain === 'number';

export type AnyOperation = DeleteOperation | InsertOperation | RetainOperation;

/**
 * Is the given value a valid {@link DeltaOperation} (as used by Quill)?
 *
 * @note The implementation of this type guard checks that the given `value` is
 *   a union of the 3 known operations ({@link DeleteOperation},
 *   {@link InsertOperation}, {@link RetainOperation}) as this meets the
 *   requirement of a {@link DeltaOperation} but is arguably narrower and safer.
 * @see {@link isDeleteOperation}
 * @see {@link isInsertOperation}
 * @see {@link isRetainOperation}
 */
export const isDeltaOperation = (value: unknown): value is AnyOperation =>
  isDeleteOperation(value) ||
  isInsertOperation(value) ||
  isRetainOperation(value);

export const containsImages = (delta: Value) => {
  if (typeof delta === 'string') {
    return false;
  }
  let foundImage = false;
  delta.ops?.some((op) => {
    if (op.insert?.image) {
      foundImage = true;
      return true;
    }
    return false;
  });

  return foundImage;
};
