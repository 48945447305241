import * as Types from '../../types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type NumPersonalQuestionsDueQueryVariables = Types.Exact<{
  enrollmentId: Types.Scalars['ID']['input'];
}>;

export type NumPersonalQuestionsDueQuery = {
  __typename?: 'Query';
  enrollment: {
    __typename?: 'Enrollment';
    id: string;
    numDuePersonalDecksQuestions?: number | null;
    points: number;
  };
};

export type NumSessionsCompletedTodayQueryVariables = Types.Exact<{
  enrollmentId: Types.Scalars['ID']['input'];
}>;

export type NumSessionsCompletedTodayQuery = {
  __typename?: 'Query';
  enrollment: {
    __typename?: 'Enrollment';
    id: string;
    numStudySessionsCompletedToday: number;
  };
};

export const NumPersonalQuestionsDueDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'numPersonalQuestionsDue' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'enrollmentId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'enrollment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'enrollmentId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'numDuePersonalDecksQuestions' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'points' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  NumPersonalQuestionsDueQuery,
  NumPersonalQuestionsDueQueryVariables
>;
export const NumSessionsCompletedTodayDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'numSessionsCompletedToday' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'enrollmentId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'enrollment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'enrollmentId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'numStudySessionsCompletedToday',
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  NumSessionsCompletedTodayQuery,
  NumSessionsCompletedTodayQueryVariables
>;
