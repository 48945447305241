import React, { useState } from 'react';

export const IndexContext = React.createContext<{
  enrollmentId: string;
  setEnrollmentId: React.Dispatch<React.SetStateAction<string>>;
}>({
  enrollmentId: '',
  setEnrollmentId: () => null,
});

type IndexProviderProps = { children: React.ReactNode };

export function IndexProvider({ children }: IndexProviderProps) {
  const [enrollmentId, setEnrollmentId] = useState('');

  return (
    <IndexContext.Provider
      value={{
        enrollmentId,
        setEnrollmentId,
      }}
    >
      {children}
    </IndexContext.Provider>
  );
}
