import { DisplayStatus } from '../components/application/Assignments/Home/AssignmentCardHeader';
import { StudentsAssignmentsDataQuery } from '../gql/queries/__generated__/studentsAssignmentsDatum.generated';
import { AssignmentStatusEnum } from '../gql/types';

type AssignmentData =
  StudentsAssignmentsDataQuery['studentsAssignmentsData'][number];
export const getUnderlyingAssignment = (assignmentData: AssignmentData) => {
  if (assignmentData.groupsAssignment) {
    return assignmentData.groupsAssignment.assignment;
  }
  return assignmentData.enrollmentsAssignment!.assignment;
};

export const filterActiveAssignments = (assignmentData: AssignmentData[]) => {
  return assignmentData.filter((assignmentDatum) => {
    const { assignmentStatus } = getUnderlyingAssignment(assignmentDatum);
    if (assignmentStatus === 'archived' || assignmentStatus === 'pending')
      return false;
    return true;
  });
};

export const isPersonalDeckAssignment = (
  assignmentData: AssignmentData
): boolean =>
  getUnderlyingAssignment(assignmentData).assignmentType === 'distributed';
export const isStandardAssignment = (assignmentData: AssignmentData): boolean =>
  getUnderlyingAssignment(assignmentData).assignmentType === 'standard';

// assignments that are viewable = assignments that get displayed as "Current Assignments"
export const isViewable = (assignmentData: AssignmentData): boolean => {
  const { assignmentStatus, optional } =
    getUnderlyingAssignment(assignmentData);

  // assignments that are active or not completed are viewable.
  // we want completed 'active' assignments to be viewable so that students can
  // see their progress after completing the assignment
  return (
    assignmentData &&
    !optional &&
    (assignmentStatus === 'active' || !isCompleted(assignmentData))
  );
};

export const getDueDate = (assignmentData: AssignmentData): Date =>
  new Date(
    assignmentData.groupsAssignment?.dueDate ??
      assignmentData.enrollmentsAssignment!.dueDate
  );

export const getStatus = (assignmentData: AssignmentData): DisplayStatus => {
  // first check if student is already done with the assignment:
  if (isCompleted(assignmentData)) {
    return 'Completed';
  }
  const assignmentStatus =
    getUnderlyingAssignment(assignmentData).assignmentStatus;
  switch (assignmentStatus) {
    case AssignmentStatusEnum.Archived:
      // should not throw an error though because it would break the app:
      console.error('Should not display archived assignment');
      return 'Not Started';
    case AssignmentStatusEnum.Pending:
      // should not throw an error though because it would break the app:
      console.error('Should not display pending assignment');
      return 'Not Started';
    case AssignmentStatusEnum.InProgress:
      return isStarted(assignmentData) ? 'In Progress' : 'Not Started';
    case AssignmentStatusEnum.Active:
      return isStarted(assignmentData) ? 'In Progress' : 'Not Started';
    case AssignmentStatusEnum.PastDue:
      return 'Past Due';
  }
};

// Returns the highest priority personal deck assignment, prioritizing active assignments first,
// then uncompleted past due assignments. Returns undefined if there are no candidate deck assignments
export const getHighestPriorityDeckAssignment = (
  deckAssignments: AssignmentData[]
) => {
  const activeAssignment = deckAssignments.find(
    (assignment) =>
      getUnderlyingAssignment(assignment).assignmentStatus === 'active'
  );
  // as long as there's an activeAssignment, display it under "Personal Deck"
  // as the highest priority because even if students hit the target points
  // they can accumulate points towards their personal deck assignment
  // until the due date has passed:
  if (activeAssignment) {
    return activeAssignment;
  }
  return deckAssignments.find(
    (assignment) =>
      getUnderlyingAssignment(assignment).assignmentStatus === 'past_due'
  );
};

export const isCompleted = (assignmentData: AssignmentData) => {
  if (isStandardAssignment(assignmentData)) {
    return assignmentData.questionsCompleted >= assignmentData.questionsTotal;
  } else {
    return (
      assignmentData.distributedPointsEarned! >=
      assignmentData.groupsAssignment!.assignment.targetDistributedPoints!
    );
  }
};

const isStarted = (assignmentData: AssignmentData) => {
  if (isStandardAssignment(assignmentData)) {
    return assignmentData.questionsCompleted > 0;
  } else {
    return assignmentData.distributedPointsEarned! > 0;
  }
};
