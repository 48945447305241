export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  ISO8601Date: { input: any; output: any };
  ISO8601DateTime: { input: any; output: any };
  JSON: { input: any; output: any };
};

export type Action = IdentifiableInterface &
  TimestampedInterface & {
    __typename?: 'Action';
    /**
     * The time at which the record was created. This timestamp is managed by
     * Rails and reflects the current time when the record was first saved.
     *
     */
    createdAt: Scalars['ISO8601DateTime']['output'];
    description?: Maybe<Scalars['String']['output']>;
    /** A serializable identifier that uniquely refers to a specific record. Two records with equal `id`s should be regarded as the same record with possibly different fields. */
    id: Scalars['ID']['output'];
    name: Scalars['String']['output'];
    system: Scalars['Boolean']['output'];
    /**
     * The time at which the record was last updated. This timestamp is managed
     * by Rails and is automatically updated to the current time when the
     * corresponding record is saved.
     *
     */
    updatedAt: Scalars['ISO8601DateTime']['output'];
    value: Scalars['Int']['output'];
  };

export type ActionItem = IdentifiableInterface &
  TimestampedInterface & {
    __typename?: 'ActionItem';
    actionable: Actionable;
    actionableId: Scalars['Int']['output'];
    actionableType: Scalars['String']['output'];
    /**
     * The time at which the record was created. This timestamp is managed by
     * Rails and reflects the current time when the record was first saved.
     *
     */
    createdAt: Scalars['ISO8601DateTime']['output'];
    description: Scalars['String']['output'];
    /** A serializable identifier that uniquely refers to a specific record. Two records with equal `id`s should be regarded as the same record with possibly different fields. */
    id: Scalars['ID']['output'];
    ownerId: Scalars['Int']['output'];
    ownerType: Scalars['String']['output'];
    state: ActionItemStateEnum;
    subject: Scalars['String']['output'];
    /**
     * The time at which the record was last updated. This timestamp is managed
     * by Rails and is automatically updated to the current time when the
     * corresponding record is saved.
     *
     */
    updatedAt: Scalars['ISO8601DateTime']['output'];
  };

/** The connection type for ActionItem. */
export type ActionItemConnection = {
  __typename?: 'ActionItemConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ActionItemEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<ActionItem>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type ActionItemEdge = {
  __typename?: 'ActionItemEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<ActionItem>;
};

export enum ActionItemStateEnum {
  /** Archived */
  Archived = 'archived',
  /** Open action item that user has not handled */
  Open = 'open',
}

/** Polymorphic action union type */
export type Actionable = FlaggedResponse;

export enum AssessmentValueEnum {
  /** Five */
  Five = 'five',
  /** Four */
  Four = 'four',
  /** One */
  One = 'one',
  /** Three */
  Three = 'three',
  /** Two */
  Two = 'two',
  /** Zero */
  Zero = 'zero',
}

export type Assignment = IdentifiableInterface &
  TimestampedInterface & {
    __typename?: 'Assignment';
    aggregatedInfo: AssignmentAggregatedInfo;
    assignmentMethod: AssignmentMethodEnum;
    assignmentStatus: AssignmentStatusEnum;
    assignmentType: AssignmentTypeEnum;
    course: Course;
    /**
     * The time at which the record was created. This timestamp is managed by
     * Rails and reflects the current time when the record was first saved.
     *
     */
    createdAt: Scalars['ISO8601DateTime']['output'];
    curriculumQuestions: Array<Question>;
    description?: Maybe<Scalars['String']['output']>;
    enrollmentsAssignments?: Maybe<Array<EnrollmentsAssignment>>;
    groupsAssignments?: Maybe<Array<GroupsAssignment>>;
    /** A serializable identifier that uniquely refers to a specific record. Two records with equal `id`s should be regarded as the same record with possibly different fields. */
    id: Scalars['ID']['output'];
    launchAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
    name: Scalars['String']['output'];
    numCurriculumQuestions: Scalars['Int']['output'];
    numQuestions: Scalars['Int']['output'];
    numQuestionsNeedReview: Scalars['Int']['output'];
    numQuestionsNeedRevisit: Scalars['Int']['output'];
    numSpiraledQuestions: Scalars['Int']['output'];
    numStudentsAssigned?: Maybe<Scalars['Int']['output']>;
    numStudentsAssignmentsData?: Maybe<Scalars['Int']['output']>;
    optional: Scalars['Boolean']['output'];
    questions: Array<Question>;
    shareable: Scalars['Boolean']['output'];
    stage?: Maybe<Scalars['String']['output']>;
    standards: Array<Standard>;
    targetDistributedPoints?: Maybe<Scalars['Float']['output']>;
    teacher: Teacher;
    unassignable: Scalars['Boolean']['output'];
    /**
     * The time at which the record was last updated. This timestamp is managed
     * by Rails and is automatically updated to the current time when the
     * corresponding record is saved.
     *
     */
    updatedAt: Scalars['ISO8601DateTime']['output'];
  };

export type AssignmentAggregatedInfo = {
  __typename?: 'AssignmentAggregatedInfo';
  averageScore?: Maybe<Scalars['Int']['output']>;
  totalStudents: Scalars['Int']['output'];
  totalStudentsFinished: Scalars['Int']['output'];
};

/** The connection type for Assignment. */
export type AssignmentConnection = {
  __typename?: 'AssignmentConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<AssignmentEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Assignment>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type AssignmentEdge = {
  __typename?: 'AssignmentEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Assignment>;
};

export enum AssignmentMethodEnum {
  /** Assigned to groups */
  Group = 'group',
  /** Assigned to individual students */
  Individual = 'individual',
}

export type AssignmentNumResponses = {
  __typename?: 'AssignmentNumResponses';
  groupsAssignmentId?: Maybe<Scalars['ID']['output']>;
  numResponses: Scalars['Int']['output'];
};

/** A student's score for a specific assignment */
export type AssignmentScore = {
  __typename?: 'AssignmentScore';
  /** List of assignment scores */
  scores: Array<AssignmentScoreDetail>;
  /** The name of the student */
  student: Scalars['String']['output'];
};

/** Details of a student's score for an assignment */
export type AssignmentScoreDetail = {
  __typename?: 'AssignmentScoreDetail';
  /** The ID of the assignment */
  assignmentId: Scalars['ID']['output'];
  /** The name of the assignment */
  assignmentName: Scalars['String']['output'];
  /** The score the student received */
  score: Scalars['Int']['output'];
};

export enum AssignmentStatusEnum {
  /**
   * Assignment that has been made public to students but does not have a
   * response yet
   *
   */
  Active = 'active',
  /**
   * Assignment that is no longer accessible to students
   *
   */
  Archived = 'archived',
  /**
   * Assignment that has been made public to students and has at
   * least one response
   *
   */
  InProgress = 'in_progress',
  /** Assignment that is now past its due date */
  PastDue = 'past_due',
  /** Assignment that has not been made public to students */
  Pending = 'pending',
}

export enum AssignmentTypeEnum {
  /** Distributed assignment */
  Distributed = 'distributed',
  /** Personal deck assignment */
  PersonalDeck = 'personal_deck',
  /** Assignment with some spiraled questions */
  Spiraled = 'spiraled',
  /** Assignment with 0 spiraled questions */
  Standard = 'standard',
}

export type AssignmentsQuestion = IdentifiableInterface &
  TimestampedInterface & {
    __typename?: 'AssignmentsQuestion';
    assignment: Assignment;
    /**
     * The time at which the record was created. This timestamp is managed by
     * Rails and reflects the current time when the record was first saved.
     *
     */
    createdAt: Scalars['ISO8601DateTime']['output'];
    /** A serializable identifier that uniquely refers to a specific record. Two records with equal `id`s should be regarded as the same record with possibly different fields. */
    id: Scalars['ID']['output'];
    question: Question;
    /**
     * The time at which the record was last updated. This timestamp is managed
     * by Rails and is automatically updated to the current time when the
     * corresponding record is saved.
     *
     */
    updatedAt: Scalars['ISO8601DateTime']['output'];
  };

/** Attributes for bulk uploading free response questions */
export type BulkFreeResponseInput = {
  deeperLearning?: InputMaybe<Scalars['String']['input']>;
  exemplar: Scalars['String']['input'];
  questionText: Scalars['String']['input'];
};

/** Attributes for bulk uploading multiple choice questions */
export type BulkMultipleChoiceInput = {
  answerChoices: Array<Scalars['String']['input']>;
  deeperLearning?: InputMaybe<Scalars['String']['input']>;
  questionText: Scalars['String']['input'];
};

/** Attributes for bulk uploading multiple choice questions */
export type BulkSelectAllInput = {
  answerChoices: Array<Scalars['String']['input']>;
  correctChoiceRefs: Array<Scalars['Int']['input']>;
  deeperLearning?: InputMaybe<Scalars['String']['input']>;
  questionText: Scalars['String']['input'];
};

/** Attributes for bulk uploading short answer questions */
export type BulkShortAnswerInput = {
  acceptableAnswers: Array<Scalars['String']['input']>;
  deeperLearning?: InputMaybe<Scalars['String']['input']>;
  questionText: Scalars['String']['input'];
};

/** Attributes for bulk uploading standards */
export type BulkStandardsInput = {
  category: Scalars['String']['input'];
  description: Scalars['String']['input'];
  standard?: InputMaybe<Scalars['String']['input']>;
};

export type ChatLog = IdentifiableInterface &
  TimestampedInterface & {
    __typename?: 'ChatLog';
    /**
     * The time at which the record was created. This timestamp is managed by
     * Rails and reflects the current time when the record was first saved.
     *
     */
    createdAt: Scalars['ISO8601DateTime']['output'];
    enrollmentId: Scalars['Int']['output'];
    /** A serializable identifier that uniquely refers to a specific record. Two records with equal `id`s should be regarded as the same record with possibly different fields. */
    id: Scalars['ID']['output'];
    questionId: Scalars['Int']['output'];
    role: Scalars['String']['output'];
    text: Scalars['String']['output'];
    /**
     * The time at which the record was last updated. This timestamp is managed
     * by Rails and is automatically updated to the current time when the
     * corresponding record is saved.
     *
     */
    updatedAt: Scalars['ISO8601DateTime']['output'];
  };

export type Collaboration = IdentifiableInterface &
  TimestampedInterface & {
    __typename?: 'Collaboration';
    collaborationStatus: CollaborationStatusEnum;
    /**
     * The time at which the record was created. This timestamp is managed by
     * Rails and reflects the current time when the record was first saved.
     *
     */
    createdAt: Scalars['ISO8601DateTime']['output'];
    /** A serializable identifier that uniquely refers to a specific record. Two records with equal `id`s should be regarded as the same record with possibly different fields. */
    id: Scalars['ID']['output'];
    teacherReceiver: Teacher;
    teacherReceiverId: Scalars['Int']['output'];
    teacherRequester: Teacher;
    teacherRequesterId: Scalars['Int']['output'];
    /**
     * The time at which the record was last updated. This timestamp is managed
     * by Rails and is automatically updated to the current time when the
     * corresponding record is saved.
     *
     */
    updatedAt: Scalars['ISO8601DateTime']['output'];
  };

export enum CollaborationStatusEnum {
  /** Accepted collaboration request */
  Accepted = 'accepted',
  /** Declined collaboration request */
  Declined = 'declined',
  /** Pending collaboration request */
  Pending = 'pending',
}

export type Course = IdentifiableInterface &
  TimestampedInterface & {
    __typename?: 'Course';
    /** Is AI-powered translation enabled and automatically used for this course? If not, content will only be available in the language it was written in. */
    aiTranslationEnabled: Scalars['Boolean']['output'];
    assignmentAlmostDueNotification: Scalars['Boolean']['output'];
    assignments: Array<Assignment>;
    coursesTeachers: Array<CoursesTeacher>;
    /**
     * The time at which the record was created. This timestamp is managed by
     * Rails and reflects the current time when the record was first saved.
     *
     */
    createdAt: Scalars['ISO8601DateTime']['output'];
    groups: Array<Group>;
    /** A serializable identifier that uniquely refers to a specific record. Two records with equal `id`s should be regarded as the same record with possibly different fields. */
    id: Scalars['ID']['output'];
    jurisdiction?: Maybe<Jurisdiction>;
    jurisdictionId?: Maybe<Scalars['ID']['output']>;
    leaderboardSetting: LeaderboardSettingEnum;
    level: Level;
    levelId: Scalars['ID']['output'];
    name: Scalars['String']['output'];
    recurring0: Scalars['Boolean']['output'];
    recurring1: Scalars['Boolean']['output'];
    recurring2: Scalars['Boolean']['output'];
    recurring3: Scalars['Boolean']['output'];
    recurring4: Scalars['Boolean']['output'];
    recurring5: Scalars['Boolean']['output'];
    recurring6: Scalars['Boolean']['output'];
    recurringLateAssignmentNotification: Scalars['Boolean']['output'];
    recurringPdqNotification: Scalars['Boolean']['output'];
    standardsChart?: Maybe<StandardsChart>;
    standardsChartId?: Maybe<Scalars['ID']['output']>;
    /** Are student-facing AI features enabled for this course? If so, features like AI-generated explanations for why responses were autograded as incorrect will be available to students. */
    studentFacingAiEnabled: Scalars['Boolean']['output'];
    subject: Subject;
    subjectId: Scalars['ID']['output'];
    teachers: Array<Teacher>;
    team?: Maybe<Team>;
    /**
     * The time at which the record was last updated. This timestamp is managed
     * by Rails and is automatically updated to the current time when the
     * corresponding record is saved.
     *
     */
    updatedAt: Scalars['ISO8601DateTime']['output'];
  };

export type CoursesTeacher = IdentifiableInterface &
  TimestampedInterface & {
    __typename?: 'CoursesTeacher';
    active: Scalars['Boolean']['output'];
    archived: Scalars['Boolean']['output'];
    course: Course;
    courseId: Scalars['Int']['output'];
    /**
     * The time at which the record was created. This timestamp is managed by
     * Rails and reflects the current time when the record was first saved.
     *
     */
    createdAt: Scalars['ISO8601DateTime']['output'];
    /** A serializable identifier that uniquely refers to a specific record. Two records with equal `id`s should be regarded as the same record with possibly different fields. */
    id: Scalars['ID']['output'];
    teacher: Teacher;
    teacherId: Scalars['Int']['output'];
    /**
     * The time at which the record was last updated. This timestamp is managed
     * by Rails and is automatically updated to the current time when the
     * corresponding record is saved.
     *
     */
    updatedAt: Scalars['ISO8601DateTime']['output'];
  };

/** Attributes for create a direct enrollment */
export type CreateStudentsInput = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
};

export type CreateStudentsResponse = {
  __typename?: 'CreateStudentsResponse';
  enrollments: Array<Enrollment>;
  failedStudents: Array<FailedStudent>;
};

export type Enrollment = IdentifiableInterface & {
  __typename?: 'Enrollment';
  active: Scalars['Boolean']['output'];
  assignmentsData?: Maybe<Array<StudentsAssignmentsDatum>>;
  enrollmentStatus: EnrollmentStatusEnum;
  /**
   * If this is __null__, it is likely that the group is deleted but the
   * enrollment was kept around to preserve other enrollment-related records.
   *
   */
  group?: Maybe<Group>;
  /**
   * If this is __null__, it is likely that the group is deleted but the
   * enrollment was kept around to preserve other enrollment-related records.
   *
   */
  groupId?: Maybe<Scalars['ID']['output']>;
  /** A serializable identifier that uniquely refers to a specific record. Two records with equal `id`s should be regarded as the same record with possibly different fields. */
  id: Scalars['ID']['output'];
  lastActivity?: Maybe<Scalars['ISO8601DateTime']['output']>;
  lateAssignments?: Maybe<Array<StudentsAssignmentsDatum>>;
  numDuePersonalDecksQuestions?: Maybe<Scalars['Int']['output']>;
  numStudySessionsCompletedToday: Scalars['Int']['output'];
  points: Scalars['Int']['output'];
  student: Student;
  unfreezePersonalDeck: Scalars['Boolean']['output'];
};

export type EnrollmentPersonalDeckDatum = IdentifiableInterface &
  TimestampedInterface & {
    __typename?: 'EnrollmentPersonalDeckDatum';
    /**
     * The time at which the record was created. This timestamp is managed by
     * Rails and reflects the current time when the record was first saved.
     *
     */
    createdAt: Scalars['ISO8601DateTime']['output'];
    date: Scalars['ISO8601Date']['output'];
    enrollment: Enrollment;
    enrollmentId: Scalars['Int']['output'];
    frozenAtEndOfDay: Scalars['Boolean']['output'];
    /** A serializable identifier that uniquely refers to a specific record. Two records with equal `id`s should be regarded as the same record with possibly different fields. */
    id: Scalars['ID']['output'];
    numAttempted: Scalars['Int']['output'];
    numCompleted: Scalars['Int']['output'];
    numCorrect: Scalars['Int']['output'];
    numDueAtStartOfDay: Scalars['Int']['output'];
    sessionsCompleted: Scalars['Int']['output'];
    /**
     * The time at which the record was last updated. This timestamp is managed
     * by Rails and is automatically updated to the current time when the
     * corresponding record is saved.
     *
     */
    updatedAt: Scalars['ISO8601DateTime']['output'];
  };

export enum EnrollmentStatusEnum {
  /** Denied enrollment */
  Denied = 'denied',
  /** Successfully enrolled */
  Enrolled = 'enrolled',
  /** Inactive enrollment */
  Inactive = 'inactive',
  /** Enrollment pending approval from teacher */
  Pending = 'pending',
  /** Transferring enrollment */
  Transferring = 'transferring',
}

export type EnrollmentsAction = IdentifiableInterface &
  TimestampedInterface & {
    __typename?: 'EnrollmentsAction';
    action: Action;
    base?: Maybe<Scalars['Int']['output']>;
    bonus?: Maybe<Scalars['Int']['output']>;
    /**
     * The time at which the record was created. This timestamp is managed by
     * Rails and reflects the current time when the record was first saved.
     *
     */
    createdAt: Scalars['ISO8601DateTime']['output'];
    date?: Maybe<Scalars['ISO8601Date']['output']>;
    enrollmentId: Scalars['Int']['output'];
    /** A serializable identifier that uniquely refers to a specific record. Two records with equal `id`s should be regarded as the same record with possibly different fields. */
    id: Scalars['ID']['output'];
    multiplier: Scalars['Int']['output'];
    /**
     * The time at which the record was last updated. This timestamp is managed
     * by Rails and is automatically updated to the current time when the
     * corresponding record is saved.
     *
     */
    updatedAt: Scalars['ISO8601DateTime']['output'];
    valueSum?: Maybe<Scalars['Int']['output']>;
  };

/** The connection type for EnrollmentsAction. */
export type EnrollmentsActionConnection = {
  __typename?: 'EnrollmentsActionConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<EnrollmentsActionEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<EnrollmentsAction>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type EnrollmentsActionEdge = {
  __typename?: 'EnrollmentsActionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<EnrollmentsAction>;
};

export type EnrollmentsAssignment = IdentifiableInterface &
  TimestampedInterface & {
    __typename?: 'EnrollmentsAssignment';
    assignment: Assignment;
    assignmentId: Scalars['Int']['output'];
    /**
     * The time at which the record was created. This timestamp is managed by
     * Rails and reflects the current time when the record was first saved.
     *
     */
    createdAt: Scalars['ISO8601DateTime']['output'];
    dueDate: Scalars['ISO8601DateTime']['output'];
    enrollment: Enrollment;
    enrollmentId: Scalars['Int']['output'];
    /** A serializable identifier that uniquely refers to a specific record. Two records with equal `id`s should be regarded as the same record with possibly different fields. */
    id: Scalars['ID']['output'];
    studentsAssignmentsDatum?: Maybe<StudentsAssignmentsDatum>;
    transferred: Scalars['Boolean']['output'];
    /**
     * The time at which the record was last updated. This timestamp is managed
     * by Rails and is automatically updated to the current time when the
     * corresponding record is saved.
     *
     */
    updatedAt: Scalars['ISO8601DateTime']['output'];
  };

export type EnrollmentsQuestionsMastery = EnrollmentsQuestionsMasteryInterface &
  IdentifiableInterface &
  TimestampedInterface & {
    __typename?: 'EnrollmentsQuestionsMastery';
    /**
     * The time at which the record was created. This timestamp is managed by
     * Rails and reflects the current time when the record was first saved.
     *
     */
    createdAt: Scalars['ISO8601DateTime']['output'];
    enrollment: Enrollment;
    enrollmentId: Scalars['Int']['output'];
    firstSeen?: Maybe<Scalars['ISO8601DateTime']['output']>;
    /** A serializable identifier that uniquely refers to a specific record. Two records with equal `id`s should be regarded as the same record with possibly different fields. */
    id: Scalars['ID']['output'];
    latestAttempt?: Maybe<Scalars['ISO8601DateTime']['output']>;
    masteryScore?: Maybe<Scalars['Float']['output']>;
    pdqDue?: Maybe<Scalars['ISO8601DateTime']['output']>;
    question: Question;
    questionId: Scalars['Int']['output'];
    streak: Scalars['Int']['output'];
    totalAttempts: Scalars['Int']['output'];
    totalCorrect: Scalars['Int']['output'];
    /**
     * The time at which the record was last updated. This timestamp is managed
     * by Rails and is automatically updated to the current time when the
     * corresponding record is saved.
     *
     */
    updatedAt: Scalars['ISO8601DateTime']['output'];
  };

/** The connection type for EnrollmentsQuestionsMastery. */
export type EnrollmentsQuestionsMasteryConnection = {
  __typename?: 'EnrollmentsQuestionsMasteryConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<EnrollmentsQuestionsMasteryEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<EnrollmentsQuestionsMastery>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type EnrollmentsQuestionsMasteryEdge = {
  __typename?: 'EnrollmentsQuestionsMasteryEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<EnrollmentsQuestionsMastery>;
};

/** Enrollments Question Mastery fields */
export type EnrollmentsQuestionsMasteryInterface = {
  /**
   * The time at which the record was created. This timestamp is managed by
   * Rails and reflects the current time when the record was first saved.
   *
   */
  createdAt: Scalars['ISO8601DateTime']['output'];
  enrollment: Enrollment;
  enrollmentId: Scalars['Int']['output'];
  firstSeen?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** A serializable identifier that uniquely refers to a specific record. Two records with equal `id`s should be regarded as the same record with possibly different fields. */
  id: Scalars['ID']['output'];
  latestAttempt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  masteryScore?: Maybe<Scalars['Float']['output']>;
  pdqDue?: Maybe<Scalars['ISO8601DateTime']['output']>;
  question: Question;
  questionId: Scalars['Int']['output'];
  streak: Scalars['Int']['output'];
  totalAttempts: Scalars['Int']['output'];
  totalCorrect: Scalars['Int']['output'];
  /**
   * The time at which the record was last updated. This timestamp is managed
   * by Rails and is automatically updated to the current time when the
   * corresponding record is saved.
   *
   */
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type FailedStudent = {
  __typename?: 'FailedStudent';
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
};

export type FlaggedResponse = IdentifiableInterface & {
  __typename?: 'FlaggedResponse';
  automatedResolution: Scalars['Boolean']['output'];
  flaggedResponseType: FlaggedResponseTypeEnum;
  /** A serializable identifier that uniquely refers to a specific record. Two records with equal `id`s should be regarded as the same record with possibly different fields. */
  id: Scalars['ID']['output'];
  response: Response;
  responseId: Scalars['Int']['output'];
  responseType: Scalars['String']['output'];
};

export enum FlaggedResponseTypeEnum {
  /** Student flagged this response */
  Student = 'student',
  /** System flagged this response */
  System = 'system',
}

export type FraRequiredKeyword = IdentifiableInterface & {
  __typename?: 'FraRequiredKeyword';
  /** A serializable identifier that uniquely refers to a specific record. Two records with equal `id`s should be regarded as the same record with possibly different fields. */
  id: Scalars['ID']['output'];
  keyword: Scalars['String']['output'];
};

export type FreeResponseAnswer = HumanTextRepresentableInterface &
  IdentifiableInterface & {
    __typename?: 'FreeResponseAnswer';
    comparable: Scalars['Boolean']['output'];
    fraRequiredKeywords: Array<FraRequiredKeyword>;
    /** A serializable identifier that uniquely refers to a specific record. Two records with equal `id`s should be regarded as the same record with possibly different fields. */
    id: Scalars['ID']['output'];
    /**
     * A plain text representation of the human-meaningful text. This
     * representation is typically simpler so it's best suited for uses where
     * formatting is either unhelpful or ignore like clipboard management,
     * logging, some class of error message, etc.
     *
     */
    plainText: Scalars['String']['output'];
    question?: Maybe<Question>;
    /**
     * A rich text representation of the human-meaningful text. This
     * currently following the [Quill `Delta` format][0] being an object
     * with a single `ops` property containing an array of delta
     * operations.
     *
     * When possible, favor using rich text to present information as it
     * supports a much broader range of features like LaTeX formula and
     * images.
     *
     * ```ts
     * type Delta = {
     *   ops: Array<
     *     | { insert: 'asdf' }
     *     | { delete: 2 }
     *     | { retain: 1 }
     *   >
     * }
     * ```
     *
     * [0]: https://github.com/quilljs/delta/
     *
     */
    richText: Scalars['JSON']['output'];
    text: Scalars['String']['output'];
  };

/** Attributes for creating a free response answer */
export type FreeResponseAnswerInput = {
  comparable?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  requiredKeywords?: InputMaybe<Array<Scalars['String']['input']>>;
  richText: Scalars['JSON']['input'];
  text: Scalars['String']['input'];
};

export type FreeResponseResponse = HumanTextRepresentableInterface &
  IdentifiableInterface &
  TimestampedInterface & {
    __typename?: 'FreeResponseResponse';
    answer: Scalars['String']['output'];
    autograded: Scalars['Boolean']['output'];
    /**
     * The time at which the record was created. This timestamp is managed by
     * Rails and reflects the current time when the record was first saved.
     *
     */
    createdAt: Scalars['ISO8601DateTime']['output'];
    groupsAssignment?: Maybe<GroupsAssignment>;
    /** A serializable identifier that uniquely refers to a specific record. Two records with equal `id`s should be regarded as the same record with possibly different fields. */
    id: Scalars['ID']['output'];
    personalDecksQuestion?: Maybe<PersonalDecksQuestion>;
    /**
     * A plain text representation of the human-meaningful text. This
     * representation is typically simpler so it's best suited for uses where
     * formatting is either unhelpful or ignore like clipboard management,
     * logging, some class of error message, etc.
     *
     */
    plainText: Scalars['String']['output'];
    question: Question;
    responseComments?: Maybe<Array<ResponseComment>>;
    /**
     * A rich text representation of the human-meaningful text. This
     * currently following the [Quill `Delta` format][0] being an object
     * with a single `ops` property containing an array of delta
     * operations.
     *
     * When possible, favor using rich text to present information as it
     * supports a much broader range of features like LaTeX formula and
     * images.
     *
     * ```ts
     * type Delta = {
     *   ops: Array<
     *     | { insert: 'asdf' }
     *     | { delete: 2 }
     *     | { retain: 1 }
     *   >
     * }
     * ```
     *
     * [0]: https://github.com/quilljs/delta/
     *
     */
    richText: Scalars['JSON']['output'];
    selfAssessment?: Maybe<AssessmentValueEnum>;
    student: Student;
    teacherAssessment?: Maybe<AssessmentValueEnum>;
    /**
     * The time at which the record was last updated. This timestamp is managed
     * by Rails and is automatically updated to the current time when the
     * corresponding record is saved.
     *
     */
    updatedAt: Scalars['ISO8601DateTime']['output'];
  };

export type Group = IdentifiableInterface & {
  __typename?: 'Group';
  activeDistributedGroupsAssignments: Array<GroupsAssignment>;
  course: Course;
  courseId: Scalars['ID']['output'];
  enableImmediatelyDue: Scalars['Boolean']['output'];
  enableRelaxedSpacing: Scalars['Boolean']['output'];
  enrollments?: Maybe<Array<Enrollment>>;
  freezeDate?: Maybe<Scalars['ISO8601Date']['output']>;
  freezePersonalDeck: Scalars['Boolean']['output'];
  groupCode: Scalars['String']['output'];
  /** A serializable identifier that uniquely refers to a specific record. Two records with equal `id`s should be regarded as the same record with possibly different fields. */
  id: Scalars['ID']['output'];
  lateAssignmentsData?: Maybe<Scalars['JSON']['output']>;
  name: Scalars['String']['output'];
  numEnrollments: Scalars['Int']['output'];
  numPersonalDecksQuestions: Scalars['Int']['output'];
  numShifted: Scalars['Int']['output'];
  personalDeckCounts?: Maybe<Scalars['JSON']['output']>;
  receivesEmails: Scalars['Boolean']['output'];
  students?: Maybe<Array<Student>>;
  studySessionMax: Scalars['Int']['output'];
  unfreezeInProgress: Scalars['Boolean']['output'];
};

export type GroupsAssignment = IdentifiableInterface & {
  __typename?: 'GroupsAssignment';
  assignment: Assignment;
  assignmentId: Scalars['ID']['output'];
  dueDate?: Maybe<Scalars['ISO8601DateTime']['output']>;
  enrolledStudentsAssignmentsData?: Maybe<Array<StudentsAssignmentsDatum>>;
  group: Group;
  groupId: Scalars['ID']['output'];
  /** A serializable identifier that uniquely refers to a specific record. Two records with equal `id`s should be regarded as the same record with possibly different fields. */
  id: Scalars['ID']['output'];
  launchDate?: Maybe<Scalars['ISO8601DateTime']['output']>;
  studentsAssignmentsData?: Maybe<Array<StudentsAssignmentsDatum>>;
};

/** The connection type for GroupsAssignment. */
export type GroupsAssignmentConnection = {
  __typename?: 'GroupsAssignmentConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<GroupsAssignmentEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<GroupsAssignment>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type GroupsAssignmentEdge = {
  __typename?: 'GroupsAssignmentEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<GroupsAssignment>;
};

/**
 * Something that can be represented by text meaningful to humans supporting
 * plain text and rich text formats
 *
 */
export type HumanTextRepresentableInterface = {
  /**
   * A plain text representation of the human-meaningful text. This
   * representation is typically simpler so it's best suited for uses where
   * formatting is either unhelpful or ignore like clipboard management,
   * logging, some class of error message, etc.
   *
   */
  plainText: Scalars['String']['output'];
  /**
   * A rich text representation of the human-meaningful text. This
   * currently following the [Quill `Delta` format][0] being an object
   * with a single `ops` property containing an array of delta
   * operations.
   *
   * When possible, favor using rich text to present information as it
   * supports a much broader range of features like LaTeX formula and
   * images.
   *
   * ```ts
   * type Delta = {
   *   ops: Array<
   *     | { insert: 'asdf' }
   *     | { delete: 2 }
   *     | { retain: 1 }
   *   >
   * }
   * ```
   *
   * [0]: https://github.com/quilljs/delta/
   *
   */
  richText: Scalars['JSON']['output'];
};

export type IdentifiableInterface = {
  /** A serializable identifier that uniquely refers to a specific record. Two records with equal `id`s should be regarded as the same record with possibly different fields. */
  id: Scalars['ID']['output'];
};

/** Autogenerated return type of ItemsCount. */
export type ItemsCountPayload = {
  __typename?: 'ItemsCountPayload';
  count: Scalars['Int']['output'];
};

export type Jurisdiction = IdentifiableInterface &
  TimestampedInterface & {
    __typename?: 'Jurisdiction';
    countryCode: Scalars['String']['output'];
    /**
     * The time at which the record was created. This timestamp is managed by
     * Rails and reflects the current time when the record was first saved.
     *
     */
    createdAt: Scalars['ISO8601DateTime']['output'];
    /** A serializable identifier that uniquely refers to a specific record. Two records with equal `id`s should be regarded as the same record with possibly different fields. */
    id: Scalars['ID']['output'];
    order: Scalars['Float']['output'];
    title: Scalars['String']['output'];
    /**
     * The time at which the record was last updated. This timestamp is managed
     * by Rails and is automatically updated to the current time when the
     * corresponding record is saved.
     *
     */
    updatedAt: Scalars['ISO8601DateTime']['output'];
    visibility?: Maybe<VisibilityEnum>;
  };

export enum LeaderboardSettingEnum {
  /** Show Top 5 Students */
  Show_5 = 'show_5',
  /** Show All */
  ShowAll = 'show_all',
  /** Leaderboard Off */
  ShowNone = 'show_none',
}

export type Level = IdentifiableInterface &
  TimestampedInterface & {
    __typename?: 'Level';
    /**
     * The time at which the record was created. This timestamp is managed by
     * Rails and reflects the current time when the record was first saved.
     *
     */
    createdAt: Scalars['ISO8601DateTime']['output'];
    /** A serializable identifier that uniquely refers to a specific record. Two records with equal `id`s should be regarded as the same record with possibly different fields. */
    id: Scalars['ID']['output'];
    name: Scalars['String']['output'];
    /**
     * The time at which the record was last updated. This timestamp is managed
     * by Rails and is automatically updated to the current time when the
     * corresponding record is saved.
     *
     */
    updatedAt: Scalars['ISO8601DateTime']['output'];
    visibility: VisibilityEnum;
  };

export type MasteryWithDueDate = EnrollmentsQuestionsMasteryInterface &
  IdentifiableInterface &
  TimestampedInterface & {
    __typename?: 'MasteryWithDueDate';
    /**
     * The time at which the record was created. This timestamp is managed by
     * Rails and reflects the current time when the record was first saved.
     *
     */
    createdAt: Scalars['ISO8601DateTime']['output'];
    due: Scalars['ISO8601Date']['output'];
    enrollment: Enrollment;
    enrollmentId: Scalars['Int']['output'];
    firstSeen?: Maybe<Scalars['ISO8601DateTime']['output']>;
    /** A serializable identifier that uniquely refers to a specific record. Two records with equal `id`s should be regarded as the same record with possibly different fields. */
    id: Scalars['ID']['output'];
    latestAttempt?: Maybe<Scalars['ISO8601DateTime']['output']>;
    masteryScore?: Maybe<Scalars['Float']['output']>;
    pdqDue?: Maybe<Scalars['ISO8601DateTime']['output']>;
    question: Question;
    questionId: Scalars['Int']['output'];
    streak: Scalars['Int']['output'];
    totalAttempts: Scalars['Int']['output'];
    totalCorrect: Scalars['Int']['output'];
    /**
     * The time at which the record was last updated. This timestamp is managed
     * by Rails and is automatically updated to the current time when the
     * corresponding record is saved.
     *
     */
    updatedAt: Scalars['ISO8601DateTime']['output'];
  };

export type MultipleChoiceAnswerChoice = HumanTextRepresentableInterface &
  IdentifiableInterface & {
    __typename?: 'MultipleChoiceAnswerChoice';
    /** A serializable identifier that uniquely refers to a specific record. Two records with equal `id`s should be regarded as the same record with possibly different fields. */
    id: Scalars['ID']['output'];
    isCorrect: Scalars['Boolean']['output'];
    /**
     * A plain text representation of the human-meaningful text. This
     * representation is typically simpler so it's best suited for uses where
     * formatting is either unhelpful or ignore like clipboard management,
     * logging, some class of error message, etc.
     *
     */
    plainText: Scalars['String']['output'];
    question?: Maybe<Question>;
    /**
     * A rich text representation of the human-meaningful text. This
     * currently following the [Quill `Delta` format][0] being an object
     * with a single `ops` property containing an array of delta
     * operations.
     *
     * When possible, favor using rich text to present information as it
     * supports a much broader range of features like LaTeX formula and
     * images.
     *
     * ```ts
     * type Delta = {
     *   ops: Array<
     *     | { insert: 'asdf' }
     *     | { delete: 2 }
     *     | { retain: 1 }
     *   >
     * }
     * ```
     *
     * [0]: https://github.com/quilljs/delta/
     *
     */
    richText: Scalars['JSON']['output'];
    text: Scalars['String']['output'];
  };

/** Attributes for creating a multiple choice answer choice */
export type MultipleChoiceAnswerChoiceInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  isCorrect: Scalars['Boolean']['input'];
  richText: Scalars['JSON']['input'];
  text: Scalars['String']['input'];
};

export type MultipleChoiceResponse = IdentifiableInterface &
  TimestampedInterface & {
    __typename?: 'MultipleChoiceResponse';
    /**
     * The time at which the record was created. This timestamp is managed by
     * Rails and reflects the current time when the record was first saved.
     *
     */
    createdAt: Scalars['ISO8601DateTime']['output'];
    groupsAssignment: GroupsAssignment;
    /** A serializable identifier that uniquely refers to a specific record. Two records with equal `id`s should be regarded as the same record with possibly different fields. */
    id: Scalars['ID']['output'];
    isCorrect: Scalars['Boolean']['output'];
    numPriorResponses?: Maybe<Scalars['Int']['output']>;
    personalDecksQuestion?: Maybe<PersonalDecksQuestion>;
    question: Question;
    responseComments?: Maybe<Array<ResponseComment>>;
    selectedMultipleChoiceAnswerChoices: Array<SelectedMultipleChoiceAnswerChoice>;
    student: Student;
    /**
     * The time at which the record was last updated. This timestamp is managed
     * by Rails and is automatically updated to the current time when the
     * corresponding record is saved.
     *
     */
    updatedAt: Scalars['ISO8601DateTime']['output'];
  };

export type Mutation = {
  __typename?: 'Mutation';
  /** Accept privacy policy and ToS */
  acceptPolicies: Teacher;
  /** Add multiple standard questions to an assignment */
  bulkAddAssignmentsQuestion: Array<AssignmentsQuestion>;
  /** Bulk upload free response questions */
  bulkUploadFreeResponse: Scalars['Int']['output'];
  /** Bulk uploads multiple choice questions */
  bulkUploadMultipleChoice: Scalars['Int']['output'];
  /** Bulk uploads multiple choice questions */
  bulkUploadSelectAll: Scalars['Int']['output'];
  /** Bulk uploads short answer questions */
  bulkUploadShortAnswer: Scalars['Int']['output'];
  /** Bulk upload standards */
  bulkUploadStandards: Scalars['Boolean']['output'];
  /** Allows a student to claim a free session */
  claimFreeSession: PersonalDeckStudySession;
  /** Clones an existing assignment */
  cloneAssignment: Assignment;
  /** Clones a collaboration assignment */
  collaborationCloneAssignment: Assignment;
  /** Creates an assignment for the current teacher */
  createAssignment: Assignment;
  /** Adds a standard question to an assignment */
  createAssignmentsQuestion: AssignmentsQuestion;
  /** creates a chat */
  createChat: Scalars['String']['output'];
  /** Creates a collaborations */
  createCollaboration: Collaboration;
  /** Creates a course for the current teacher */
  createCourse: Course;
  /** Creates a new pending enrollment */
  createEnrollment: Enrollment;
  /** Creates a batch of enrollments assignments */
  createEnrollmentsAssignment: EnrollmentsAssignment;
  /** Student flags a response for teacher review */
  createFlaggedResponse: FlaggedResponse;
  /** Creates a free response response */
  createFreeResponseResponse: FreeResponseResponse;
  /** Creates a course and transfers content from old course */
  createFromOldCourse: Course;
  /** Creates a group for the current_teacher */
  createGroup: Group;
  /** Assigns an assignment to a group */
  createGroupsAssignment: GroupsAssignment;
  /** Creates a multiple choice response */
  createMultipleChoiceResponse: MultipleChoiceResponse;
  /** Creates a question */
  createQuestion: Question;
  /** Creates a review session question */
  createReviewSessionQuestion: ReviewSessionsQuestion;
  /** Creates a scheduled freeze for a course */
  createScheduledFreeze: ScheduledFreeze;
  /** Creates a school for current_teacher */
  createSchool: School;
  /** Creates a short answer response */
  createShortAnswerResponse: ShortAnswerResponse;
  /** Creates a standard */
  createStandard: StandardsCategoriesStandard;
  /** Creates standards category */
  createStandardsCategory: StandardsCategory;
  /** promotes a teacher to admin for a standards chart */
  createStandardsChartsAdmin: StandardsChartsAdmin;
  /** Creates a batch of students and enrolls them into a class */
  createStudents: CreateStudentsResponse;
  /** Creates a new study session for an enrollment */
  createStudySession: PersonalDeckStudySession;
  /** Deletes an assignment that belongs to a teacher */
  deleteAssignment: Scalars['Boolean']['output'];
  /** Deletes a question from an assignment */
  deleteAssignmentsQuestion: AssignmentsQuestion;
  /** Deletes a collaboration */
  deleteCollaboration: Collaboration;
  /** Deletes a enrollments assignment */
  deleteEnrollmentsAssignment: EnrollmentsAssignment;
  /** Deletes a group that belongs to a teacher */
  deleteGroup: Group;
  /** Deletes a group assignment */
  deleteGroupsAssignment: GroupsAssignment;
  /** Deletes a question that belongs to teacher */
  deleteQuestion: Scalars['Boolean']['output'];
  /** Destroys a review session question */
  deleteReviewSessionQuestion: Scalars['Int']['output'];
  /** Deletes a scheduled freeze */
  deleteScheduledFreeze: Scalars['Int']['output'];
  /** Deletes a standard */
  deleteStandard: Scalars['Int']['output'];
  /** Deletes standards category */
  deleteStandardsCategory: Scalars['Int']['output'];
  /** Toggles whether or not question is flaggable */
  disableFlaggable: Array<ActionItem>;
  /** Edits a standard */
  editStandard: Standard;
  editStandardsCategory: StandardsCategory;
  editStandardsChart: StandardsChart;
  /** Updates a review session question */
  finishReviewSessionQuestion: ReviewSessionsQuestion;
  /** Creates demo data for the teacher active course */
  generateDemoData: Scalars['Boolean']['output'];
  /**
   * Generate `n` variations of the given question matching `baseQuestionId`
   * optionally omitting variations resembling those matched by
   * `previousVariationIds`.
   *
   */
  generateQuestionVariation: Question;
  /** Handles flagged response updates */
  handleFlaggedResponse?: Maybe<ActionItem>;
  /** Finds a standards chart and joins course into it */
  joinTeam: Team;
  /** Re-orders an object that has a position column */
  reorderItem: Scalars['Boolean']['output'];
  /** Resets all XP for a group */
  resetPoints: Scalars['Boolean']['output'];
  /** Sets the selected school for the current_teacher */
  setSchool: School;
  /**
   *         Checks if a short answer is available for a typo retry.
   *
   */
  shortAnswerTypoCheck: ShortAnswerTypoCheck;
  switchStandardsChart: Team;
  /** Toggles action item state */
  toggleActionItemState: ActionItem;
  /** Toggles the active field for a question */
  toggleQuestionActive: Question;
  /** Queue up an async job to track an internal event */
  trackEvent: Scalars['Boolean']['output'];
  /** Transfers an enrollment from one group to another */
  transferEnrollment: Enrollment;
  /** Update a student's active enrollment */
  updateActiveEnrollment: Enrollment;
  /** Updates an assignment */
  updateAssignment: Assignment;
  /** Updates a collaboration */
  updateCollaboration: Collaboration;
  /** Updates a course */
  updateCourse: Course;
  /** Updates a course settings */
  updateCourseSettings: Course;
  /** Updates an enrollment */
  updateEnrollment: Enrollment;
  /** Updates a enrollments assignment */
  updateEnrollmentsAssignment: EnrollmentsAssignment;
  /** Updates the assessments of a free response question */
  updateFreeResponseResponse: FreeResponseResponse;
  /** Updates a group that belongs to the current_teacher */
  updateGroup: Group;
  /** Updates a group assignment */
  updateGroupsAssignment: GroupsAssignment;
  /** Updates a question that belongs to a teacher */
  updateQuestion: Question;
  /** Updates the review severity for a question */
  updateQuestionReviewSeverity: Question;
  updateQuestionStandards: Array<Standard>;
  /** Updates the supplement for a question */
  updateQuestionSupplement: Question;
  /** Updates a scheduled freeze */
  updateScheduledFreeze: ScheduledFreeze;
  /** Update a student */
  updateStudent: Student;
  /** Update the teacher model */
  updateTeacher: Teacher;
};

export type MutationBulkAddAssignmentsQuestionArgs = {
  assignmentId: Scalars['ID']['input'];
  questionIds: Array<Scalars['ID']['input']>;
};

export type MutationBulkUploadFreeResponseArgs = {
  courseId: Scalars['ID']['input'];
  questions: Array<BulkFreeResponseInput>;
};

export type MutationBulkUploadMultipleChoiceArgs = {
  courseId: Scalars['ID']['input'];
  questions: Array<BulkMultipleChoiceInput>;
};

export type MutationBulkUploadSelectAllArgs = {
  courseId: Scalars['ID']['input'];
  questions: Array<BulkSelectAllInput>;
};

export type MutationBulkUploadShortAnswerArgs = {
  courseId: Scalars['ID']['input'];
  questions: Array<BulkShortAnswerInput>;
};

export type MutationBulkUploadStandardsArgs = {
  courseId: Scalars['ID']['input'];
  standards: Array<BulkStandardsInput>;
};

export type MutationClaimFreeSessionArgs = {
  enrollmentId: Scalars['ID']['input'];
  groupsAssignmentId: Scalars['ID']['input'];
};

export type MutationCloneAssignmentArgs = {
  assignmentId: Scalars['ID']['input'];
};

export type MutationCollaborationCloneAssignmentArgs = {
  assignmentId: Scalars['ID']['input'];
};

export type MutationCreateAssignmentArgs = {
  assignmentType: AssignmentTypeEnum;
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  standardIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type MutationCreateAssignmentsQuestionArgs = {
  assignmentId?: InputMaybe<Scalars['ID']['input']>;
  questionId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationCreateChatArgs = {
  enrollmentId: Scalars['ID']['input'];
  message: Scalars['String']['input'];
  questionId: Scalars['ID']['input'];
};

export type MutationCreateCollaborationArgs = {
  email: Scalars['String']['input'];
};

export type MutationCreateCourseArgs = {
  jurisdictionCountry?: InputMaybe<Scalars['String']['input']>;
  jurisdictionId?: InputMaybe<Scalars['ID']['input']>;
  jurisdictionTitle?: InputMaybe<Scalars['String']['input']>;
  level: Scalars['String']['input'];
  name: Scalars['String']['input'];
  subject: Scalars['String']['input'];
};

export type MutationCreateEnrollmentArgs = {
  groupCode: Scalars['String']['input'];
};

export type MutationCreateEnrollmentsAssignmentArgs = {
  assignmentId: Scalars['ID']['input'];
  dueDate: Scalars['ISO8601DateTime']['input'];
  enrollmentId: Scalars['ID']['input'];
};

export type MutationCreateFlaggedResponseArgs = {
  comment?: InputMaybe<Scalars['String']['input']>;
  responseId: Scalars['ID']['input'];
  responseType?: InputMaybe<QuestionTypeEnum>;
};

export type MutationCreateFreeResponseResponseArgs = {
  answer: Scalars['String']['input'];
  enrollmentsAssignmentId?: InputMaybe<Scalars['ID']['input']>;
  groupsAssignmentId?: InputMaybe<Scalars['ID']['input']>;
  personalDeckStudySessionId?: InputMaybe<Scalars['ID']['input']>;
  personalDecksQuestionId?: InputMaybe<Scalars['ID']['input']>;
  questionId: Scalars['ID']['input'];
  richText: Scalars['JSON']['input'];
};

export type MutationCreateFromOldCourseArgs = {
  courseId?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationCreateGroupArgs = {
  courseId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};

export type MutationCreateGroupsAssignmentArgs = {
  assignmentId: Scalars['ID']['input'];
  dueDate?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  groupId: Scalars['ID']['input'];
  launchDate?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
};

export type MutationCreateMultipleChoiceResponseArgs = {
  answerChoiceIds: Array<Scalars['ID']['input']>;
  enrollmentsAssignmentId?: InputMaybe<Scalars['ID']['input']>;
  groupsAssignmentId?: InputMaybe<Scalars['ID']['input']>;
  personalDeckStudySessionId?: InputMaybe<Scalars['ID']['input']>;
  personalDecksQuestionId?: InputMaybe<Scalars['ID']['input']>;
  questionId: Scalars['ID']['input'];
};

export type MutationCreateQuestionArgs = {
  fromSmartCreate?: InputMaybe<Scalars['Boolean']['input']>;
  question: QuestionInput;
};

export type MutationCreateReviewSessionQuestionArgs = {
  courseId: Scalars['ID']['input'];
  questionId: Scalars['ID']['input'];
};

export type MutationCreateScheduledFreezeArgs = {
  courseId: Scalars['ID']['input'];
  finish: Scalars['ISO8601DateTime']['input'];
  groupIds: Array<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  start: Scalars['ISO8601DateTime']['input'];
};

export type MutationCreateSchoolArgs = {
  address: Scalars['String']['input'];
  city: Scalars['String']['input'];
  country: Scalars['String']['input'];
  name: Scalars['String']['input'];
  stateCode?: InputMaybe<Scalars['String']['input']>;
  timezone: Scalars['String']['input'];
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type MutationCreateShortAnswerResponseArgs = {
  answer: Scalars['String']['input'];
  enrollmentsAssignmentId?: InputMaybe<Scalars['ID']['input']>;
  groupsAssignmentId?: InputMaybe<Scalars['ID']['input']>;
  personalDeckStudySessionId?: InputMaybe<Scalars['ID']['input']>;
  personalDecksQuestionId?: InputMaybe<Scalars['ID']['input']>;
  questionId: Scalars['ID']['input'];
  richText: Scalars['JSON']['input'];
};

export type MutationCreateStandardArgs = {
  description: Scalars['String']['input'];
  standardsCategoryId: Scalars['ID']['input'];
  title: Scalars['String']['input'];
};

export type MutationCreateStandardsCategoryArgs = {
  category: Scalars['String']['input'];
  standardsChartId: Scalars['ID']['input'];
};

export type MutationCreateStandardsChartsAdminArgs = {
  standardsChartId: Scalars['ID']['input'];
  teacherId: Scalars['ID']['input'];
};

export type MutationCreateStudentsArgs = {
  groupId: Scalars['ID']['input'];
  students: Array<CreateStudentsInput>;
};

export type MutationCreateStudySessionArgs = {
  enrollmentId: Scalars['ID']['input'];
};

export type MutationDeleteAssignmentArgs = {
  assignmentId: Scalars['ID']['input'];
};

export type MutationDeleteAssignmentsQuestionArgs = {
  assignmentId: Scalars['ID']['input'];
  questionId: Scalars['ID']['input'];
};

export type MutationDeleteCollaborationArgs = {
  collaborationId: Scalars['ID']['input'];
};

export type MutationDeleteEnrollmentsAssignmentArgs = {
  enrollmentsAssignmentId: Scalars['ID']['input'];
};

export type MutationDeleteGroupArgs = {
  groupId: Scalars['ID']['input'];
};

export type MutationDeleteGroupsAssignmentArgs = {
  groupsAssignmentId: Scalars['ID']['input'];
};

export type MutationDeleteQuestionArgs = {
  questionId: Scalars['ID']['input'];
};

export type MutationDeleteReviewSessionQuestionArgs = {
  courseId: Scalars['ID']['input'];
  questionId: Scalars['ID']['input'];
};

export type MutationDeleteScheduledFreezeArgs = {
  scheduledFreezeId: Scalars['ID']['input'];
};

export type MutationDeleteStandardArgs = {
  standardId: Scalars['ID']['input'];
};

export type MutationDeleteStandardsCategoryArgs = {
  standardsCategoryId: Scalars['ID']['input'];
};

export type MutationDisableFlaggableArgs = {
  actionItemId: Scalars['ID']['input'];
};

export type MutationEditStandardArgs = {
  description: Scalars['String']['input'];
  standardId: Scalars['ID']['input'];
  title: Scalars['String']['input'];
};

export type MutationEditStandardsCategoryArgs = {
  category: Scalars['String']['input'];
  standardsCategoryId: Scalars['ID']['input'];
};

export type MutationEditStandardsChartArgs = {
  standardsChartId: Scalars['ID']['input'];
  title: Scalars['String']['input'];
};

export type MutationFinishReviewSessionQuestionArgs = {
  courseId: Scalars['ID']['input'];
  questionId: Scalars['ID']['input'];
};

export type MutationGenerateQuestionVariationArgs = {
  baseQuestionId: Scalars['ID']['input'];
};

export type MutationHandleFlaggedResponseArgs = {
  action: Scalars['Boolean']['input'];
  addStudentAnswerAsAcceptable?: InputMaybe<Scalars['Boolean']['input']>;
  flaggedResponseId: Scalars['ID']['input'];
  teacherAssessment?: InputMaybe<AssessmentValueEnum>;
};

export type MutationJoinTeamArgs = {
  courseId: Scalars['ID']['input'];
  groupCode: Scalars['String']['input'];
};

export type MutationReorderItemArgs = {
  destinationId: Scalars['ID']['input'];
  parentId: Scalars['ID']['input'];
  parentType: Scalars['String']['input'];
  reorderedId: Scalars['ID']['input'];
  type: Scalars['String']['input'];
};

export type MutationResetPointsArgs = {
  groupId: Scalars['ID']['input'];
};

export type MutationSetSchoolArgs = {
  schoolId: Scalars['ID']['input'];
};

export type MutationShortAnswerTypoCheckArgs = {
  answer: Scalars['String']['input'];
  enrollmentsAssignmentId?: InputMaybe<Scalars['ID']['input']>;
  groupsAssignmentId?: InputMaybe<Scalars['ID']['input']>;
  personalDecksQuestionId?: InputMaybe<Scalars['ID']['input']>;
  questionId: Scalars['ID']['input'];
  richText: Scalars['JSON']['input'];
};

export type MutationSwitchStandardsChartArgs = {
  courseId: Scalars['ID']['input'];
  standardsChartId: Scalars['ID']['input'];
};

export type MutationToggleActionItemStateArgs = {
  actionItemId: Scalars['ID']['input'];
  state: ActionItemStateEnum;
};

export type MutationToggleQuestionActiveArgs = {
  questionId: Scalars['ID']['input'];
};

export type MutationTrackEventArgs = {
  event: Scalars['String']['input'];
  metadata?: InputMaybe<Scalars['JSON']['input']>;
};

export type MutationTransferEnrollmentArgs = {
  enrollmentId: Scalars['ID']['input'];
  groupId: Scalars['ID']['input'];
};

export type MutationUpdateActiveEnrollmentArgs = {
  groupId: Scalars['ID']['input'];
};

export type MutationUpdateAssignmentArgs = {
  assignmentId: Scalars['ID']['input'];
  assignmentMethod?: InputMaybe<AssignmentMethodEnum>;
  assignmentStatus?: InputMaybe<AssignmentStatusEnum>;
  assignmentType?: InputMaybe<AssignmentTypeEnum>;
  description?: InputMaybe<Scalars['String']['input']>;
  launchAt?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  numSpiraledQuestions?: InputMaybe<Scalars['Int']['input']>;
  optional?: InputMaybe<Scalars['Boolean']['input']>;
  shareable?: InputMaybe<Scalars['Boolean']['input']>;
  standardIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  targetDistributedPoints?: InputMaybe<Scalars['Float']['input']>;
};

export type MutationUpdateCollaborationArgs = {
  collaborationId: Scalars['ID']['input'];
  status?: InputMaybe<CollaborationStatusEnum>;
};

export type MutationUpdateCourseArgs = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  courseId: Scalars['ID']['input'];
  jurisdictionCountry?: InputMaybe<Scalars['String']['input']>;
  jurisdictionId?: InputMaybe<Scalars['ID']['input']>;
  jurisdictionTitle?: InputMaybe<Scalars['String']['input']>;
  level?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  subject?: InputMaybe<Scalars['String']['input']>;
};

export type MutationUpdateCourseSettingsArgs = {
  aiTranslationEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  assignmentAlmostDueNotification?: InputMaybe<Scalars['Boolean']['input']>;
  courseId: Scalars['ID']['input'];
  leaderboardSetting?: InputMaybe<LeaderboardSettingEnum>;
  recurringLateAssignmentNotification?: InputMaybe<Scalars['Boolean']['input']>;
  recurringPdqNotification?: InputMaybe<Scalars['Boolean']['input']>;
  recurringWday?: InputMaybe<Scalars['Int']['input']>;
  recurringWdayOn?: InputMaybe<Scalars['Boolean']['input']>;
  studentFacingAiEnabled?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationUpdateEnrollmentArgs = {
  enrollmentId: Scalars['ID']['input'];
  enrollmentStatus?: InputMaybe<EnrollmentStatusEnum>;
  unfreezePersonalDeck?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationUpdateEnrollmentsAssignmentArgs = {
  dueDate: Scalars['ISO8601DateTime']['input'];
  enrollmentsAssignmentId: Scalars['ID']['input'];
};

export type MutationUpdateFreeResponseResponseArgs = {
  freeResponseResponseId: Scalars['ID']['input'];
  selfAssessment: AssessmentValueEnum;
};

export type MutationUpdateGroupArgs = {
  enableImmediatelyDue?: InputMaybe<Scalars['Boolean']['input']>;
  enableRelaxedSpacing?: InputMaybe<Scalars['Boolean']['input']>;
  freezePersonalDeck?: InputMaybe<Scalars['Boolean']['input']>;
  generateNewClassCode?: InputMaybe<Scalars['Boolean']['input']>;
  groupId: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  receivesEmails?: InputMaybe<Scalars['Boolean']['input']>;
  studySessionMax?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationUpdateGroupsAssignmentArgs = {
  dueDate?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  groupsAssignmentId: Scalars['ID']['input'];
  launchDate?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
};

export type MutationUpdateQuestionArgs = {
  question: QuestionInput;
};

export type MutationUpdateQuestionReviewSeverityArgs = {
  questionId: Scalars['ID']['input'];
  reviewSeverity: Scalars['Int']['input'];
};

export type MutationUpdateQuestionStandardsArgs = {
  questionId: Scalars['ID']['input'];
  standardIds: Array<Scalars['ID']['input']>;
};

export type MutationUpdateQuestionSupplementArgs = {
  plainText?: InputMaybe<Scalars['String']['input']>;
  questionId: Scalars['ID']['input'];
  richText?: InputMaybe<Scalars['JSON']['input']>;
};

export type MutationUpdateScheduledFreezeArgs = {
  finish?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  groupIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  scheduledFreezeId: Scalars['ID']['input'];
  start?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
};

export type MutationUpdateStudentArgs = {
  allowNameChange?: InputMaybe<Scalars['Boolean']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  studentId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationUpdateTeacherArgs = {
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  schoolId?: InputMaybe<Scalars['ID']['input']>;
};

/** Autogenerated return type of NewEnrollment. */
export type NewEnrollmentPayload = {
  __typename?: 'NewEnrollmentPayload';
  enrollment?: Maybe<Enrollment>;
};

/** Autogenerated return type of NewMessage. */
export type NewMessagePayload = {
  __typename?: 'NewMessagePayload';
  chatLog: Array<ChatLog>;
};

export enum OwnershipEnum {
  /** others' content only */
  Others = 'others',
  /** own content only */
  Own = 'own',
  /** podsie published content only */
  Podsie = 'podsie',
}

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type PersonableInterface = {
  /**
   * An [valid email address][0] for the person
   *
   * [0]: https://html.spec.whatwg.org/multipage/input.html#valid-e-mail-address
   *
   */
  email: Scalars['String']['output'];
  /** The first name of the person. Empty names are coerced into `null` so returning a `string` here implies the name is not empty. */
  firstName?: Maybe<Scalars['String']['output']>;
  /** The full name of the person achieved by combining their first name and last name in that order. If only a first name is available, it is returned. If only a last name is available, it is returned. If no first name or last name are available, `null` is returned. In other words, if this is a string, it is non-empty. */
  fullName?: Maybe<Scalars['String']['output']>;
  /** The initials of the person given their first and last name. If both first and last names are missing, `null` is returned. */
  initials?: Maybe<Scalars['String']['output']>;
  /** The last name of the person. Empty names are coerced into `null` so returning a `string` here implies the name is not empty. */
  lastName?: Maybe<Scalars['String']['output']>;
  /** The sort name is a form of the person's full name ideal for sorting. The sort name combines their last name and first name in that order and separated by a comma. If only the first name or last name is present, it is returned. If no name is present, `nil` is returned. */
  sortName?: Maybe<Scalars['String']['output']>;
};

export type PersonalDeckStudySession = IdentifiableInterface &
  TimestampedInterface & {
    __typename?: 'PersonalDeckStudySession';
    /**
     * The time at which the record was created. This timestamp is managed by
     * Rails and reflects the current time when the record was first saved.
     *
     */
    createdAt: Scalars['ISO8601DateTime']['output'];
    enrollment: Enrollment;
    enrollmentId: Scalars['Int']['output'];
    finishedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
    groupsAssignmentId?: Maybe<Scalars['Int']['output']>;
    /** A serializable identifier that uniquely refers to a specific record. Two records with equal `id`s should be regarded as the same record with possibly different fields. */
    id: Scalars['ID']['output'];
    numCompleted: Scalars['Int']['output'];
    startedAt: Scalars['ISO8601DateTime']['output'];
    totalCorrect: Scalars['Int']['output'];
    totalQuestions: Scalars['Int']['output'];
    /**
     * The time at which the record was last updated. This timestamp is managed
     * by Rails and is automatically updated to the current time when the
     * corresponding record is saved.
     *
     */
    updatedAt: Scalars['ISO8601DateTime']['output'];
  };

export type PersonalDecksQuestion = IdentifiableInterface &
  TimestampedInterface & {
    __typename?: 'PersonalDecksQuestion';
    /**
     * The time at which the record was created. This timestamp is managed by
     * Rails and reflects the current time when the record was first saved.
     *
     */
    createdAt: Scalars['ISO8601DateTime']['output'];
    due: Scalars['ISO8601Date']['output'];
    ef: Scalars['Float']['output'];
    enrollment: Enrollment;
    freeResponseResponses?: Maybe<Array<FreeResponseResponse>>;
    /** A serializable identifier that uniquely refers to a specific record. Two records with equal `id`s should be regarded as the same record with possibly different fields. */
    id: Scalars['ID']['output'];
    interval: Scalars['Int']['output'];
    multipleChoiceResponses?: Maybe<Array<MultipleChoiceResponse>>;
    question: Question;
    questionId: Scalars['ID']['output'];
    shortAnswerResponses?: Maybe<Array<ShortAnswerResponse>>;
    /**
     * The time at which the record was last updated. This timestamp is managed
     * by Rails and is automatically updated to the current time when the
     * corresponding record is saved.
     *
     */
    updatedAt: Scalars['ISO8601DateTime']['output'];
  };

export type Query = {
  __typename?: 'Query';
  /** Returns a action item given the id */
  actionItem: ActionItem;
  /** fetches a teacher's action items based on filters */
  actionItems: ActionItemConnection;
  /** Return a list of active group assignments for a student */
  activeGroupsAssignmentsForStudent: Array<GroupsAssignment>;
  /** Given an enrollment id, return any active study session */
  activeStudySession?: Maybe<PersonalDeckStudySession>;
  /** Return assignment given the logged in teacher */
  assignment: Assignment;
  /** Return a shared collaboration assignment */
  assignmentCollaboration: Assignment;
  /**
   * Returns results for this assignment filtered by groups assignment or enrollments assignment. If the assignment matching the given `assignmentId' is a group assignment, a `groupId` must be provided.
   *
   */
  assignmentNumResponses: AssignmentNumResponses;
  /** Returns results for a groups assignment */
  assignmentResults: Scalars['JSON']['output'];
  /** Return assignments given the logged in teacher */
  assignments: AssignmentConnection;
  /** Fetches all assignments based on the given filters */
  assignmentsConnection: AssignmentConnection;
  /** Uses OpenAI to determine whether a response is correct. */
  autogradeResponseCorrectness: Scalars['Boolean']['output'];
  /** Return collaboration assignments */
  collaborationAssignments: AssignmentConnection;
  /** Return collaborations given the logged in teacher */
  collaborations: Array<Collaboration>;
  /** Show the number of collaborative assignments per teacher */
  collaborativeAssignmentsCount: Scalars['Int']['output'];
  /** Returns standards charts that teachers can collaborate on */
  collaborativeStandardsCharts: Array<StandardsChart>;
  /**
   * Return a list of collaborators for the currently logged in `Teacher`
   *
   */
  collaborators: Array<Teacher>;
  /** Returns a hash of dates and num pdqs due on those dates */
  courseAverageDuePdq: Scalars['JSON']['output'];
  /** returns distributed assignment results */
  distributedAssignmentResults: Scalars['JSON']['output'];
  /** Returns requested enrollment */
  enrollment: Enrollment;
  /** Returns requested enrollment */
  enrollmentLateAssignments: Enrollment;
  /** returns questions masteries for an enrollment */
  enrollmentQuestionsMasteries: Array<EnrollmentsQuestionsMastery>;
  enrollmentResponsesByDate: Array<PersonalDecksQuestion>;
  /** Returns a list of enrollments_actions for enrollment */
  enrollmentsActions: EnrollmentsActionConnection;
  /**
   * Return a list of enrollment assignments given an `enrollmentId`
   *
   * Returns an error if:
   * - an `Enrollment` matching the given `enrollmentId` could not be found
   * - the requester is not a `Student`
   * - if the requester is not the `Student` for the found `Enrollment`
   *
   */
  enrollmentsAssignments: Array<EnrollmentsAssignment>;
  /** Return a list of enrollments assignments for a student */
  enrollmentsAssignmentsByStudent: Array<EnrollmentsAssignment>;
  /**
   * Returns a free response response that still needs to be self-assessed
   * by the student
   *
   */
  freeResponseResponse?: Maybe<FreeResponseResponse>;
  generatedQuestions: Array<Question>;
  /** Return a group given a group id */
  group: Group;
  /** Returns the assignments and scores for a group */
  groupAssignmentsScores: Array<AssignmentScore>;
  /** Return groups given a list of group ids */
  groups: Array<Group>;
  /** Returns groups assignment */
  groupsAssignment: GroupsAssignment;
  /** Return a list of groups assignments  given a group id */
  groupsAssignments: Array<GroupsAssignment>;
  /** Return a list of groups assignments paginated  given a group id */
  groupsAssignmentsConnection: GroupsAssignmentConnection;
  /** Returns list of jurisdictions under a country */
  jurisdictions: Array<Jurisdiction>;
  /** Returns the latest first responsese for a question */
  latestFirstResponses: Scalars['JSON']['output'];
  /** Fetches the latest mastery data point for a question */
  latestMasteriesByQuestion: Array<EnrollmentsQuestionsMastery>;
  /** Returns requested enrollments sorted by points */
  leaderboard: Array<Enrollment>;
  /** Return a list of levels */
  levels: Array<Level>;
  masteriesByDueAndEnrollment: Array<MasteryWithDueDate>;
  /** Returns masteries by question and enrollment */
  masteriesByStandardAndEnrollment: Array<EnrollmentsQuestionsMastery>;
  /** Returns the next groups assignment question to be show to a student */
  nextAssignmentQuestion?: Maybe<Question>;
  /** Returns the next personal deck question to be shown to a student */
  nextPersonalDeckQuestion?: Maybe<PersonalDecksQuestion>;
  /** Returns number of open notifications for owner */
  notificationsCount: Scalars['Int']['output'];
  /** returns # pdq due for a group over time by enrollment */
  numPdqOverTimeForGroup: Scalars['JSON']['output'];
  paginatedEnrollmentQuestionsMasteries: EnrollmentsQuestionsMasteryConnection;
  personalDeckData: Array<EnrollmentPersonalDeckDatum>;
  /** Returns personal deck data for given enrollment id */
  personalDeckDataByEnrollment: Array<EnrollmentPersonalDeckDatum>;
  /** Returns current streak of clearing out personal deck */
  personalDeckStreak: Scalars['Int']['output'];
  /** Returns a specified question */
  question: Question;
  /** Returns a specified question that belongs to a collaborator */
  questionCollaboration: Question;
  /** Fetches all questions for a teacher */
  questions: Array<Question>;
  /** Returns question breakdown for an assigned assignment */
  questionsBreakdown: Scalars['JSON']['output'];
  /**
   * Fetches all active course questions for a teacher,
   *         not apart of assignment_id, and not deleted paginated
   */
  questionsConnection: QuestionConnection;
  /** Returns a list of questions tagged under a standard */
  questionsForStandard: QuestionConnection;
  /** Fetches all question stats for teacher */
  questionsStats: QuestionsStatConnection;
  /** Returns a review sessions question */
  reviewSessionsQuestion?: Maybe<ReviewSessionsQuestion>;
  /** Returns active review sessions */
  reviewSessionsQuestions: Array<ReviewSessionsQuestion>;
  /** Returns all active scheduled freezes for a course */
  scheduledFreezes: Array<ScheduledFreeze>;
  /** Returns a list of school given search query */
  schools: Array<School>;
  /** Returns a list of short answer variations for a question */
  shortAnswerVariations: Array<Scalars['JSON']['output']>;
  /** Returns a list of spiralable standards */
  spiralableStandards: Array<Standard>;
  /** Fetches standards for one of a teachers' course. */
  standards: Array<Standard>;
  /** Fetches standard chart by ID */
  standardsChart: StandardsChart;
  /**
   * Shows the currently authenticated student or falls back to trying the
   * `Student` matching the given `student_id` if the currently authenticated
   * user is a teacher.
   *
   */
  student: Student;
  /** Fetches masteries for students and standards */
  studentStandardMasteries: Array<StudentStandardsMastery>;
  /** Fetches masteries for students and standards category */
  studentStandardsCategoryMasteries: Array<StudentStandardsCategoryMastery>;
  /** Returns student assignment data given a group id */
  studentsAssignmentsData: Array<StudentsAssignmentsDatum>;
  /** Returns student assignment data given a groups assignment id */
  studentsAssignmentsDataForAssignment: Array<StudentsAssignmentsDatum>;
  /** Returns student assignment datum given a group assignment id */
  studentsAssignmentsDatum?: Maybe<StudentsAssignmentsDatum>;
  /** Returns a student assignment question */
  studentsAssignmentsQuestion: StudentsAssignmentsQuestion;
  /**
   * Returns students assignments questions for groups assignment
   *
   */
  studentsAssignmentsQuestions: Array<StudentsAssignmentsQuestion>;
  /** Given a session id, it returns the study session */
  studySessionById: PersonalDeckStudySession;
  /** Returns all responses for a study session */
  studySessionResponses: Array<PersonalDecksQuestion>;
  /** Return a list of subjects */
  subjects: Array<Subject>;
  /** Returns a suggested free response answer for a question */
  suggestedFreeResponseAnswer: Scalars['String']['output'];
  /**
   * Return the current teacher and teacher's queried associations
   *
   */
  teacher: Teacher;
  /**
   * Return a list of teachers
   *
   */
  teachers: Array<Teacher>;
  /** Returns a team */
  team: Team;
  /** Returns a temp leaderboard */
  tempLeaderboard: Scalars['JSON']['output'];
  /** Returns the translated text for a question */
  translateText: Scalars['String']['output'];
  /**
   * [DEPRECATED]
   * Gets the current major version of the app and forces a frontend reload
   * if version is outdated
   *
   */
  version: Scalars['String']['output'];
};

export type QueryActionItemArgs = {
  actionItemId: Scalars['ID']['input'];
};

export type QueryActionItemsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  open?: InputMaybe<Scalars['Boolean']['input']>;
  ownerId: Scalars['ID']['input'];
  ownerType: Scalars['String']['input'];
};

export type QueryActiveStudySessionArgs = {
  enrollmentId: Scalars['ID']['input'];
};

export type QueryAssignmentArgs = {
  assignmentId: Scalars['ID']['input'];
};

export type QueryAssignmentCollaborationArgs = {
  assignmentId: Scalars['ID']['input'];
};

export type QueryAssignmentNumResponsesArgs = {
  assignmentId: Scalars['ID']['input'];
  groupId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryAssignmentResultsArgs = {
  assignmentId: Scalars['ID']['input'];
  groupId?: InputMaybe<Scalars['ID']['input']>;
  showAllGroups?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryAssignmentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sortByName?: InputMaybe<Scalars['Boolean']['input']>;
  statuses?: InputMaybe<Array<AssignmentStatusEnum>>;
};

export type QueryAssignmentsConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  ownership?: OwnershipEnum;
  standardIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  standardsChartId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryAutogradeResponseCorrectnessArgs = {
  questionId: Scalars['ID']['input'];
  responseId: Scalars['ID']['input'];
};

export type QueryCollaborationAssignmentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  teamId: Scalars['ID']['input'];
};

export type QueryCollaborativeAssignmentsCountArgs = {
  teamId: Scalars['ID']['input'];
};

export type QueryCollaborativeStandardsChartsArgs = {
  ownership: OwnershipEnum;
};

export type QueryCourseAverageDuePdqArgs = {
  courseId: Scalars['ID']['input'];
  pastNumDays?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryDistributedAssignmentResultsArgs = {
  assignmentId: Scalars['ID']['input'];
  enrollmentId?: InputMaybe<Scalars['ID']['input']>;
  groupId: Scalars['ID']['input'];
};

export type QueryEnrollmentArgs = {
  id: Scalars['ID']['input'];
};

export type QueryEnrollmentLateAssignmentsArgs = {
  id: Scalars['ID']['input'];
};

export type QueryEnrollmentQuestionsMasteriesArgs = {
  enrollmentId: Scalars['ID']['input'];
  firstSeenEnd?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  firstSeenStart?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
};

export type QueryEnrollmentResponsesByDateArgs = {
  endDate?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  enrollmentId: Scalars['ID']['input'];
  startDate: Scalars['ISO8601DateTime']['input'];
};

export type QueryEnrollmentsActionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  enrollmentId: Scalars['ID']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryEnrollmentsAssignmentsArgs = {
  enrollmentId: Scalars['ID']['input'];
  statuses?: InputMaybe<Array<AssignmentStatusEnum>>;
};

export type QueryEnrollmentsAssignmentsByStudentArgs = {
  enrollmentId: Scalars['ID']['input'];
  statuses: Array<AssignmentStatusEnum>;
};

export type QueryFreeResponseResponseArgs = {
  enrollmentsAssignmentId?: InputMaybe<Scalars['ID']['input']>;
  groupsAssignmentId?: InputMaybe<Scalars['ID']['input']>;
  personalDecksQuestionId?: InputMaybe<Scalars['ID']['input']>;
  questionId: Scalars['ID']['input'];
};

export type QueryGeneratedQuestionsArgs = {
  instructions?: InputMaybe<Scalars['String']['input']>;
  levelName: Scalars['String']['input'];
  numQuestions: Scalars['Int']['input'];
  questionType: QuestionTypeEnum;
  text: Scalars['String']['input'];
};

export type QueryGroupArgs = {
  groupId: Scalars['ID']['input'];
};

export type QueryGroupAssignmentsScoresArgs = {
  groupId: Scalars['ID']['input'];
};

export type QueryGroupsArgs = {
  groupIds: Array<Scalars['ID']['input']>;
};

export type QueryGroupsAssignmentArgs = {
  assignmentId: Scalars['ID']['input'];
  groupId: Scalars['ID']['input'];
};

export type QueryGroupsAssignmentsArgs = {
  groupId: Scalars['ID']['input'];
  status?: InputMaybe<AssignmentStatusEnum>;
  statuses?: InputMaybe<Array<AssignmentStatusEnum>>;
};

export type QueryGroupsAssignmentsConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  groupId: Scalars['ID']['input'];
  last?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<AssignmentStatusEnum>;
  statuses?: InputMaybe<Array<AssignmentStatusEnum>>;
};

export type QueryJurisdictionsArgs = {
  country: Scalars['String']['input'];
};

export type QueryLatestFirstResponsesArgs = {
  groupId?: InputMaybe<Scalars['ID']['input']>;
  questionId: Scalars['ID']['input'];
};

export type QueryLatestMasteriesByQuestionArgs = {
  groupId?: InputMaybe<Scalars['ID']['input']>;
  questionId: Scalars['ID']['input'];
};

export type QueryLeaderboardArgs = {
  groupId: Scalars['ID']['input'];
  numShow?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryMasteriesByDueAndEnrollmentArgs = {
  due: Scalars['String']['input'];
  enrollmentId: Scalars['ID']['input'];
};

export type QueryMasteriesByStandardAndEnrollmentArgs = {
  enrollmentId: Scalars['ID']['input'];
  standardId: Scalars['ID']['input'];
};

export type QueryNextAssignmentQuestionArgs = {
  enrollmentsAssignmentId?: InputMaybe<Scalars['ID']['input']>;
  groupsAssignmentId?: InputMaybe<Scalars['ID']['input']>;
  previousQuestionId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryNextPersonalDeckQuestionArgs = {
  personalDeckStudySessionId: Scalars['ID']['input'];
  previousQuestionId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryNotificationsCountArgs = {
  ownerId: Scalars['ID']['input'];
  ownerType: Scalars['String']['input'];
};

export type QueryNumPdqOverTimeForGroupArgs = {
  groupId: Scalars['ID']['input'];
};

export type QueryPaginatedEnrollmentQuestionsMasteriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  enrollmentId: Scalars['ID']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  questionIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  standardId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryPersonalDeckDataArgs = {
  endDay?: InputMaybe<Scalars['String']['input']>;
  groupId: Scalars['ID']['input'];
  startDay: Scalars['String']['input'];
};

export type QueryPersonalDeckDataByEnrollmentArgs = {
  enrollmentId: Scalars['ID']['input'];
  startDay: Scalars['String']['input'];
};

export type QueryPersonalDeckStreakArgs = {
  enrollmentId: Scalars['ID']['input'];
};

export type QueryQuestionArgs = {
  questionId: Scalars['ID']['input'];
};

export type QueryQuestionCollaborationArgs = {
  questionId: Scalars['ID']['input'];
};

export type QueryQuestionsArgs = {
  plainText?: InputMaybe<Scalars['String']['input']>;
  questionType?: InputMaybe<QuestionTypeEnum>;
  standardIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type QueryQuestionsBreakdownArgs = {
  assignmentId: Scalars['ID']['input'];
  groupId?: InputMaybe<Scalars['ID']['input']>;
  showAllGroups?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryQuestionsConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  assignmentId: Scalars['ID']['input'];
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  ownership: OwnershipEnum;
  plainText?: InputMaybe<Scalars['String']['input']>;
  questionType?: InputMaybe<QuestionTypeEnum>;
  standardIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  standardsChartId: Scalars['ID']['input'];
  unused?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryQuestionsForStandardArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  standardId?: InputMaybe<Scalars['ID']['input']>;
  teamId: Scalars['ID']['input'];
};

export type QueryQuestionsStatsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  groupId?: InputMaybe<Scalars['ID']['input']>;
  homepage?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  onlyShowActiveQuestions?: InputMaybe<Scalars['Boolean']['input']>;
  onlyShowQuestionsWithData?: InputMaybe<Scalars['Boolean']['input']>;
  plainText?: InputMaybe<Scalars['String']['input']>;
  sortDirection: SortDirectionEnum;
  sortField: Scalars['String']['input'];
};

export type QueryReviewSessionsQuestionArgs = {
  courseId: Scalars['ID']['input'];
  questionId: Scalars['ID']['input'];
};

export type QueryReviewSessionsQuestionsArgs = {
  courseId: Scalars['ID']['input'];
};

export type QueryScheduledFreezesArgs = {
  courseId: Scalars['ID']['input'];
};

export type QuerySchoolsArgs = {
  country: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type QueryShortAnswerVariationsArgs = {
  currentAnswers: Array<Scalars['String']['input']>;
  question: Scalars['String']['input'];
};

export type QuerySpiralableStandardsArgs = {
  standardsChartId: Scalars['ID']['input'];
};

export type QueryStandardsArgs = {
  standardsChartId: Scalars['ID']['input'];
};

export type QueryStandardsChartArgs = {
  standardsChartGroupCode?: InputMaybe<Scalars['String']['input']>;
  standardsChartId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryStudentArgs = {
  studentId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryStudentStandardMasteriesArgs = {
  groupIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  standardIds: Array<Scalars['ID']['input']>;
  studentIds: Array<Scalars['ID']['input']>;
};

export type QueryStudentStandardsCategoryMasteriesArgs = {
  groupIds: Array<Scalars['ID']['input']>;
  standardsCategoryIds: Array<Scalars['ID']['input']>;
  studentIds: Array<Scalars['ID']['input']>;
};

export type QueryStudentsAssignmentsDataArgs = {
  groupId: Scalars['ID']['input'];
};

export type QueryStudentsAssignmentsDataForAssignmentArgs = {
  assignmentId: Scalars['ID']['input'];
  groupId?: InputMaybe<Scalars['ID']['input']>;
  showAllGroups?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryStudentsAssignmentsDatumArgs = {
  enrollmentsAssignmentId?: InputMaybe<Scalars['ID']['input']>;
  groupsAssignmentId?: InputMaybe<Scalars['ID']['input']>;
  studentId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryStudentsAssignmentsQuestionArgs = {
  enrollmentsAssignmentId?: InputMaybe<Scalars['ID']['input']>;
  groupsAssignmentId?: InputMaybe<Scalars['ID']['input']>;
  questionId: Scalars['ID']['input'];
  studentId: Scalars['ID']['input'];
};

export type QueryStudentsAssignmentsQuestionsArgs = {
  enrollmentsAssignmentId?: InputMaybe<Scalars['ID']['input']>;
  groupsAssignmentId?: InputMaybe<Scalars['ID']['input']>;
  studentId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryStudySessionByIdArgs = {
  personalDeckStudySessionId: Scalars['ID']['input'];
};

export type QueryStudySessionResponsesArgs = {
  personalDeckStudySessionId: Scalars['ID']['input'];
};

export type QuerySuggestedFreeResponseAnswerArgs = {
  backgroundText?: InputMaybe<Scalars['String']['input']>;
  questionText: Scalars['String']['input'];
};

export type QueryTeachersArgs = {
  filter: Scalars['String']['input'];
};

export type QueryTeamArgs = {
  groupCode?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryTranslateTextArgs = {
  language: Scalars['String']['input'];
  relatedEnrollmentId?: InputMaybe<Scalars['ID']['input']>;
  text: Scalars['String']['input'];
};

export type Question = HumanTextRepresentableInterface &
  IdentifiableInterface &
  QuestionInterface &
  TimestampedInterface & {
    __typename?: 'Question';
    copiedFromId?: Maybe<Scalars['ID']['output']>;
    /**
     * The time at which the record was created. This timestamp is managed by
     * Rails and reflects the current time when the record was first saved.
     *
     */
    createdAt: Scalars['ISO8601DateTime']['output'];
    freeResponseAnswer?: Maybe<FreeResponseAnswer>;
    /** A serializable identifier that uniquely refers to a specific record. Two records with equal `id`s should be regarded as the same record with possibly different fields. */
    id: Scalars['ID']['output'];
    importId?: Maybe<Scalars['String']['output']>;
    multipleChoiceAnswerChoices?: Maybe<Array<MultipleChoiceAnswerChoice>>;
    numAttemptsUntilAnswerShown: Scalars['Int']['output'];
    parent?: Maybe<Question>;
    /**
     * A plain text representation of the human-meaningful text. This
     * representation is typically simpler so it's best suited for uses where
     * formatting is either unhelpful or ignore like clipboard management,
     * logging, some class of error message, etc.
     *
     */
    plainText: Scalars['String']['output'];
    questionPart: Scalars['Int']['output'];
    questionType: QuestionTypeEnum;
    reviewSeverity: Scalars['Int']['output'];
    /**
     * A rich text representation of the human-meaningful text. This
     * currently following the [Quill `Delta` format][0] being an object
     * with a single `ops` property containing an array of delta
     * operations.
     *
     * When possible, favor using rich text to present information as it
     * supports a much broader range of features like LaTeX formula and
     * images.
     *
     * ```ts
     * type Delta = {
     *   ops: Array<
     *     | { insert: 'asdf' }
     *     | { delete: 2 }
     *     | { retain: 1 }
     *   >
     * }
     * ```
     *
     * [0]: https://github.com/quilljs/delta/
     *
     */
    richText: Scalars['JSON']['output'];
    shortAnswerAnswers?: Maybe<Array<ShortAnswerAnswer>>;
    standards?: Maybe<Array<Standard>>;
    state: QuestionStateEnum;
    supplement?: Maybe<Supplement>;
    teacher: Teacher;
    /**
     * The time at which the record was last updated. This timestamp is managed
     * by Rails and is automatically updated to the current time when the
     * corresponding record is saved.
     *
     */
    updatedAt: Scalars['ISO8601DateTime']['output'];
    variations?: Maybe<Array<Question>>;
  };

/** The connection type for Question. */
export type QuestionConnection = {
  __typename?: 'QuestionConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<QuestionEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Question>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type QuestionEdge = {
  __typename?: 'QuestionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Question>;
};

/** Attributes for updating or creating a question */
export type QuestionInput = {
  freeResponseAnswer?: InputMaybe<FreeResponseAnswerInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  multipleChoiceAnswerChoices?: InputMaybe<
    Array<MultipleChoiceAnswerChoiceInput>
  >;
  numAttemptsUntilAnswerShown: Scalars['Int']['input'];
  parentId?: InputMaybe<Scalars['ID']['input']>;
  plainText: Scalars['String']['input'];
  questionType: QuestionTypeEnum;
  richText: Scalars['JSON']['input'];
  shortAnswerAnswers?: InputMaybe<Array<ShortAnswerAnswerInput>>;
  standardId?: InputMaybe<Scalars['ID']['input']>;
  standardIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  supplement?: InputMaybe<SupplementInput>;
};

/** Question fields */
export type QuestionInterface = {
  copiedFromId?: Maybe<Scalars['ID']['output']>;
  /**
   * The time at which the record was created. This timestamp is managed by
   * Rails and reflects the current time when the record was first saved.
   *
   */
  createdAt: Scalars['ISO8601DateTime']['output'];
  freeResponseAnswer?: Maybe<FreeResponseAnswer>;
  /** A serializable identifier that uniquely refers to a specific record. Two records with equal `id`s should be regarded as the same record with possibly different fields. */
  id: Scalars['ID']['output'];
  importId?: Maybe<Scalars['String']['output']>;
  multipleChoiceAnswerChoices?: Maybe<Array<MultipleChoiceAnswerChoice>>;
  numAttemptsUntilAnswerShown: Scalars['Int']['output'];
  parent?: Maybe<Question>;
  /**
   * A plain text representation of the human-meaningful text. This
   * representation is typically simpler so it's best suited for uses where
   * formatting is either unhelpful or ignore like clipboard management,
   * logging, some class of error message, etc.
   *
   */
  plainText: Scalars['String']['output'];
  questionPart: Scalars['Int']['output'];
  questionType: QuestionTypeEnum;
  reviewSeverity: Scalars['Int']['output'];
  /**
   * A rich text representation of the human-meaningful text. This
   * currently following the [Quill `Delta` format][0] being an object
   * with a single `ops` property containing an array of delta
   * operations.
   *
   * When possible, favor using rich text to present information as it
   * supports a much broader range of features like LaTeX formula and
   * images.
   *
   * ```ts
   * type Delta = {
   *   ops: Array<
   *     | { insert: 'asdf' }
   *     | { delete: 2 }
   *     | { retain: 1 }
   *   >
   * }
   * ```
   *
   * [0]: https://github.com/quilljs/delta/
   *
   */
  richText: Scalars['JSON']['output'];
  shortAnswerAnswers?: Maybe<Array<ShortAnswerAnswer>>;
  standards?: Maybe<Array<Standard>>;
  state: QuestionStateEnum;
  supplement?: Maybe<Supplement>;
  teacher: Teacher;
  /**
   * The time at which the record was last updated. This timestamp is managed
   * by Rails and is automatically updated to the current time when the
   * corresponding record is saved.
   *
   */
  updatedAt: Scalars['ISO8601DateTime']['output'];
  variations?: Maybe<Array<Question>>;
};

export enum QuestionStateEnum {
  /** Question is active in personal deck */
  Active = 'active',
  /** Question has been deleted */
  Deleted = 'deleted',
  /** Question is not active in personal deck */
  Inactive = 'inactive',
}

export enum QuestionTypeEnum {
  /** Free response question type */
  FreeResponse = 'free_response',
  /**
   *       Multiple choice question type that has a variable number of correct answer
   *       choices
   *
   */
  SelectAllMultipleChoice = 'select_all_multiple_choice',
  /**
   *       Multiple choice question type that has one correct answer choice
   *
   */
  SelectOneMultipleChoice = 'select_one_multiple_choice',
  /** Short answer question type */
  ShortAnswer = 'short_answer',
}

/** Autogenerated return type of QuestionsBreakdown. */
export type QuestionsBreakdownPayload = {
  __typename?: 'QuestionsBreakdownPayload';
  results: Scalars['JSON']['output'];
};

export type QuestionsStat = HumanTextRepresentableInterface &
  IdentifiableInterface &
  QuestionInterface &
  TimestampedInterface & {
    __typename?: 'QuestionsStat';
    copiedFromId?: Maybe<Scalars['ID']['output']>;
    /**
     * The time at which the record was created. This timestamp is managed by
     * Rails and reflects the current time when the record was first saved.
     *
     */
    createdAt: Scalars['ISO8601DateTime']['output'];
    firstSeen?: Maybe<Scalars['ISO8601DateTime']['output']>;
    freeResponseAnswer?: Maybe<FreeResponseAnswer>;
    /** A serializable identifier that uniquely refers to a specific record. Two records with equal `id`s should be regarded as the same record with possibly different fields. */
    id: Scalars['ID']['output'];
    importId?: Maybe<Scalars['String']['output']>;
    multipleChoiceAnswerChoices?: Maybe<Array<MultipleChoiceAnswerChoice>>;
    numAttemptsUntilAnswerShown: Scalars['Int']['output'];
    numRecentCorrect?: Maybe<Scalars['Int']['output']>;
    numStudentsAttempted?: Maybe<Scalars['Int']['output']>;
    numVariations: Scalars['Int']['output'];
    parent?: Maybe<Question>;
    percentCorrect?: Maybe<Scalars['Int']['output']>;
    /**
     * A plain text representation of the human-meaningful text. This
     * representation is typically simpler so it's best suited for uses where
     * formatting is either unhelpful or ignore like clipboard management,
     * logging, some class of error message, etc.
     *
     */
    plainText: Scalars['String']['output'];
    questionPart: Scalars['Int']['output'];
    questionType: QuestionTypeEnum;
    recentPercentCorrect?: Maybe<Scalars['Int']['output']>;
    reviewSeverity: Scalars['Int']['output'];
    /**
     * A rich text representation of the human-meaningful text. This
     * currently following the [Quill `Delta` format][0] being an object
     * with a single `ops` property containing an array of delta
     * operations.
     *
     * When possible, favor using rich text to present information as it
     * supports a much broader range of features like LaTeX formula and
     * images.
     *
     * ```ts
     * type Delta = {
     *   ops: Array<
     *     | { insert: 'asdf' }
     *     | { delete: 2 }
     *     | { retain: 1 }
     *   >
     * }
     * ```
     *
     * [0]: https://github.com/quilljs/delta/
     *
     */
    richText: Scalars['JSON']['output'];
    shortAnswerAnswers?: Maybe<Array<ShortAnswerAnswer>>;
    standards?: Maybe<Array<Standard>>;
    state: QuestionStateEnum;
    strugglingStudents: Scalars['Int']['output'];
    supplement?: Maybe<Supplement>;
    teacher: Teacher;
    totalAttempts?: Maybe<Scalars['Int']['output']>;
    totalCorrect?: Maybe<Scalars['Int']['output']>;
    /**
     * The time at which the record was last updated. This timestamp is managed
     * by Rails and is automatically updated to the current time when the
     * corresponding record is saved.
     *
     */
    updatedAt: Scalars['ISO8601DateTime']['output'];
    variations?: Maybe<Array<Question>>;
  };

/** The connection type for QuestionsStat. */
export type QuestionsStatConnection = {
  __typename?: 'QuestionsStatConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<QuestionsStatEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<QuestionsStat>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type QuestionsStatEdge = {
  __typename?: 'QuestionsStatEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<QuestionsStat>;
};

/** Polymorphic response union type */
export type Response =
  | FreeResponseResponse
  | MultipleChoiceResponse
  | ShortAnswerResponse;

export type ResponseComment = IdentifiableInterface &
  TimestampedInterface & {
    __typename?: 'ResponseComment';
    comment: Scalars['String']['output'];
    /**
     * The time at which the record was created. This timestamp is managed by
     * Rails and reflects the current time when the record was first saved.
     *
     */
    createdAt: Scalars['ISO8601DateTime']['output'];
    /** A serializable identifier that uniquely refers to a specific record. Two records with equal `id`s should be regarded as the same record with possibly different fields. */
    id: Scalars['ID']['output'];
    response: Response;
    responseId: Scalars['Int']['output'];
    responseType: Scalars['String']['output'];
    /**
     * The time at which the record was last updated. This timestamp is managed
     * by Rails and is automatically updated to the current time when the
     * corresponding record is saved.
     *
     */
    updatedAt: Scalars['ISO8601DateTime']['output'];
    user: User;
    userId: Scalars['Int']['output'];
    userType: Scalars['String']['output'];
  };

export type ReviewSessionsQuestion = IdentifiableInterface & {
  __typename?: 'ReviewSessionsQuestion';
  finishedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** A serializable identifier that uniquely refers to a specific record. Two records with equal `id`s should be regarded as the same record with possibly different fields. */
  id: Scalars['ID']['output'];
  question: Question;
  questionId: Scalars['Int']['output'];
  reviewSessionId: Scalars['Int']['output'];
};

export type ScheduledFreeze = IdentifiableInterface &
  TimestampedInterface & {
    __typename?: 'ScheduledFreeze';
    courseId: Scalars['Int']['output'];
    /**
     * The time at which the record was created. This timestamp is managed by
     * Rails and reflects the current time when the record was first saved.
     *
     */
    createdAt: Scalars['ISO8601DateTime']['output'];
    finish: Scalars['ISO8601Date']['output'];
    groups: Array<Group>;
    /** A serializable identifier that uniquely refers to a specific record. Two records with equal `id`s should be regarded as the same record with possibly different fields. */
    id: Scalars['ID']['output'];
    name?: Maybe<Scalars['String']['output']>;
    start: Scalars['ISO8601Date']['output'];
    /**
     * The time at which the record was last updated. This timestamp is managed
     * by Rails and is automatically updated to the current time when the
     * corresponding record is saved.
     *
     */
    updatedAt: Scalars['ISO8601DateTime']['output'];
  };

export type School = IdentifiableInterface & {
  __typename?: 'School';
  address: Scalars['String']['output'];
  city: Scalars['String']['output'];
  country: Scalars['String']['output'];
  /** A serializable identifier that uniquely refers to a specific record. Two records with equal `id`s should be regarded as the same record with possibly different fields. */
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  stateCode?: Maybe<Scalars['String']['output']>;
  timezone: Scalars['String']['output'];
  zip?: Maybe<Scalars['String']['output']>;
};

export type SelectedMultipleChoiceAnswerChoice = IdentifiableInterface &
  TimestampedInterface & {
    __typename?: 'SelectedMultipleChoiceAnswerChoice';
    /**
     * The time at which the record was created. This timestamp is managed by
     * Rails and reflects the current time when the record was first saved.
     *
     */
    createdAt: Scalars['ISO8601DateTime']['output'];
    /** A serializable identifier that uniquely refers to a specific record. Two records with equal `id`s should be regarded as the same record with possibly different fields. */
    id: Scalars['ID']['output'];
    multipleChoiceAnswerChoice: MultipleChoiceAnswerChoice;
    multipleChoiceResponse: MultipleChoiceResponse;
    /**
     * The time at which the record was last updated. This timestamp is managed
     * by Rails and is automatically updated to the current time when the
     * corresponding record is saved.
     *
     */
    updatedAt: Scalars['ISO8601DateTime']['output'];
  };

export type ShortAnswerAnswer = HumanTextRepresentableInterface &
  IdentifiableInterface & {
    __typename?: 'ShortAnswerAnswer';
    hidden: Scalars['Boolean']['output'];
    /** A serializable identifier that uniquely refers to a specific record. Two records with equal `id`s should be regarded as the same record with possibly different fields. */
    id: Scalars['ID']['output'];
    ignoreWhitespaces: Scalars['Boolean']['output'];
    isCaseSensitive: Scalars['Boolean']['output'];
    /**
     * A plain text representation of the human-meaningful text. This
     * representation is typically simpler so it's best suited for uses where
     * formatting is either unhelpful or ignore like clipboard management,
     * logging, some class of error message, etc.
     *
     */
    plainText: Scalars['String']['output'];
    question?: Maybe<Question>;
    /**
     * A rich text representation of the human-meaningful text. This
     * currently following the [Quill `Delta` format][0] being an object
     * with a single `ops` property containing an array of delta
     * operations.
     *
     * When possible, favor using rich text to present information as it
     * supports a much broader range of features like LaTeX formula and
     * images.
     *
     * ```ts
     * type Delta = {
     *   ops: Array<
     *     | { insert: 'asdf' }
     *     | { delete: 2 }
     *     | { retain: 1 }
     *   >
     * }
     * ```
     *
     * [0]: https://github.com/quilljs/delta/
     *
     */
    richText: Scalars['JSON']['output'];
    text: Scalars['String']['output'];
  };

/** Attributes for creating a short answer answer */
export type ShortAnswerAnswerInput = {
  hidden: Scalars['Boolean']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  ignoreWhitespaces: Scalars['Boolean']['input'];
  isCaseSensitive: Scalars['Boolean']['input'];
  plainText?: InputMaybe<Scalars['String']['input']>;
  richText: Scalars['JSON']['input'];
  text: Scalars['String']['input'];
};

export type ShortAnswerResponse = HumanTextRepresentableInterface &
  IdentifiableInterface &
  TimestampedInterface & {
    __typename?: 'ShortAnswerResponse';
    answer: Scalars['String']['output'];
    /**
     * The time at which the record was created. This timestamp is managed by
     * Rails and reflects the current time when the record was first saved.
     *
     */
    createdAt: Scalars['ISO8601DateTime']['output'];
    groupsAssignment?: Maybe<GroupsAssignment>;
    /** A serializable identifier that uniquely refers to a specific record. Two records with equal `id`s should be regarded as the same record with possibly different fields. */
    id: Scalars['ID']['output'];
    isCorrect: Scalars['Boolean']['output'];
    numPriorResponses?: Maybe<Scalars['Int']['output']>;
    personalDecksQuestion?: Maybe<PersonalDecksQuestion>;
    /**
     * A plain text representation of the human-meaningful text. This
     * representation is typically simpler so it's best suited for uses where
     * formatting is either unhelpful or ignore like clipboard management,
     * logging, some class of error message, etc.
     *
     */
    plainText: Scalars['String']['output'];
    question: Question;
    responseComments?: Maybe<Array<ResponseComment>>;
    /**
     * A rich text representation of the human-meaningful text. This
     * currently following the [Quill `Delta` format][0] being an object
     * with a single `ops` property containing an array of delta
     * operations.
     *
     * When possible, favor using rich text to present information as it
     * supports a much broader range of features like LaTeX formula and
     * images.
     *
     * ```ts
     * type Delta = {
     *   ops: Array<
     *     | { insert: 'asdf' }
     *     | { delete: 2 }
     *     | { retain: 1 }
     *   >
     * }
     * ```
     *
     * [0]: https://github.com/quilljs/delta/
     *
     */
    richText: Scalars['JSON']['output'];
    student: Student;
    /**
     * The time at which the record was last updated. This timestamp is managed
     * by Rails and is automatically updated to the current time when the
     * corresponding record is saved.
     *
     */
    updatedAt: Scalars['ISO8601DateTime']['output'];
  };

export type ShortAnswerTypoCheck = {
  __typename?: 'ShortAnswerTypoCheck';
  result: Scalars['Boolean']['output'];
};

export enum SortDirectionEnum {
  /** Ascending */
  Asc = 'asc',
  /** Descending */
  Desc = 'desc',
}

export type Standard = IdentifiableInterface & {
  __typename?: 'Standard';
  description: Scalars['String']['output'];
  /** A serializable identifier that uniquely refers to a specific record. Two records with equal `id`s should be regarded as the same record with possibly different fields. */
  id: Scalars['ID']['output'];
  meta?: Maybe<Scalars['JSON']['output']>;
  standardsCategory?: Maybe<StandardsCategory>;
  standardsChart?: Maybe<StandardsChart>;
  title: Scalars['String']['output'];
};

export type StandardsCategoriesStandard = IdentifiableInterface &
  TimestampedInterface & {
    __typename?: 'StandardsCategoriesStandard';
    /**
     * The time at which the record was created. This timestamp is managed by
     * Rails and reflects the current time when the record was first saved.
     *
     */
    createdAt: Scalars['ISO8601DateTime']['output'];
    /** A serializable identifier that uniquely refers to a specific record. Two records with equal `id`s should be regarded as the same record with possibly different fields. */
    id: Scalars['ID']['output'];
    standard: Standard;
    standardId: Scalars['Int']['output'];
    standardsCategory: StandardsCategory;
    standardsCategoryId: Scalars['Int']['output'];
    /**
     * The time at which the record was last updated. This timestamp is managed
     * by Rails and is automatically updated to the current time when the
     * corresponding record is saved.
     *
     */
    updatedAt: Scalars['ISO8601DateTime']['output'];
  };

export type StandardsCategory = IdentifiableInterface &
  TimestampedInterface & {
    __typename?: 'StandardsCategory';
    category: Scalars['String']['output'];
    /**
     * The time at which the record was created. This timestamp is managed by
     * Rails and reflects the current time when the record was first saved.
     *
     */
    createdAt: Scalars['ISO8601DateTime']['output'];
    /** A serializable identifier that uniquely refers to a specific record. Two records with equal `id`s should be regarded as the same record with possibly different fields. */
    id: Scalars['ID']['output'];
    standards: Array<Standard>;
    /**
     * The time at which the record was last updated. This timestamp is managed
     * by Rails and is automatically updated to the current time when the
     * corresponding record is saved.
     *
     */
    updatedAt: Scalars['ISO8601DateTime']['output'];
  };

export type StandardsCategoryMastery = {
  __typename?: 'StandardsCategoryMastery';
  average: Scalars['Float']['output'];
  standardsCategoryId: Scalars['ID']['output'];
};

export type StandardsChart = IdentifiableInterface &
  TimestampedInterface & {
    __typename?: 'StandardsChart';
    admins: Array<Teacher>;
    /**
     * The time at which the record was created. This timestamp is managed by
     * Rails and reflects the current time when the record was first saved.
     *
     */
    createdAt: Scalars['ISO8601DateTime']['output'];
    groupCode?: Maybe<Scalars['String']['output']>;
    /** A serializable identifier that uniquely refers to a specific record. Two records with equal `id`s should be regarded as the same record with possibly different fields. */
    id: Scalars['ID']['output'];
    standards: Array<Standard>;
    standardsCategories: Array<StandardsCategory>;
    title: Scalars['String']['output'];
    /**
     * The time at which the record was last updated. This timestamp is managed
     * by Rails and is automatically updated to the current time when the
     * corresponding record is saved.
     *
     */
    updatedAt: Scalars['ISO8601DateTime']['output'];
  };

export type StandardsChartsAdmin = IdentifiableInterface &
  TimestampedInterface & {
    __typename?: 'StandardsChartsAdmin';
    /**
     * The time at which the record was created. This timestamp is managed by
     * Rails and reflects the current time when the record was first saved.
     *
     */
    createdAt: Scalars['ISO8601DateTime']['output'];
    /** A serializable identifier that uniquely refers to a specific record. Two records with equal `id`s should be regarded as the same record with possibly different fields. */
    id: Scalars['ID']['output'];
    promotedBy?: Maybe<Scalars['Int']['output']>;
    standardsChartId: Scalars['Int']['output'];
    teacher: Teacher;
    teacherId: Scalars['Int']['output'];
    /**
     * The time at which the record was last updated. This timestamp is managed
     * by Rails and is automatically updated to the current time when the
     * corresponding record is saved.
     *
     */
    updatedAt: Scalars['ISO8601DateTime']['output'];
  };

export type StandardsMastery = {
  __typename?: 'StandardsMastery';
  average: Scalars['Float']['output'];
  standardId: Scalars['ID']['output'];
};

export type Student = IdentifiableInterface &
  PersonableInterface &
  TimestampedInterface & {
    __typename?: 'Student';
    activeEnrollment?: Maybe<Enrollment>;
    allowNameChange: Scalars['Boolean']['output'];
    /**
     * The time at which the record was created. This timestamp is managed by
     * Rails and reflects the current time when the record was first saved.
     *
     */
    createdAt: Scalars['ISO8601DateTime']['output'];
    /**
     * An [valid email address][0] for the person
     *
     * [0]: https://html.spec.whatwg.org/multipage/input.html#valid-e-mail-address
     *
     */
    email: Scalars['String']['output'];
    enrollments?: Maybe<Array<Enrollment>>;
    /** The first name of the person. Empty names are coerced into `null` so returning a `string` here implies the name is not empty. */
    firstName?: Maybe<Scalars['String']['output']>;
    /** The full name of the person achieved by combining their first name and last name in that order. If only a first name is available, it is returned. If only a last name is available, it is returned. If no first name or last name are available, `null` is returned. In other words, if this is a string, it is non-empty. */
    fullName?: Maybe<Scalars['String']['output']>;
    groups?: Maybe<Array<Group>>;
    /** A serializable identifier that uniquely refers to a specific record. Two records with equal `id`s should be regarded as the same record with possibly different fields. */
    id: Scalars['ID']['output'];
    /** The initials of the person given their first and last name. If both first and last names are missing, `null` is returned. */
    initials?: Maybe<Scalars['String']['output']>;
    /** The last name of the person. Empty names are coerced into `null` so returning a `string` here implies the name is not empty. */
    lastName?: Maybe<Scalars['String']['output']>;
    school: School;
    /** The sort name is a form of the person's full name ideal for sorting. The sort name combines their last name and first name in that order and separated by a comma. If only the first name or last name is present, it is returned. If no name is present, `nil` is returned. */
    sortName?: Maybe<Scalars['String']['output']>;
    /**
     * The time at which the record was last updated. This timestamp is managed
     * by Rails and is automatically updated to the current time when the
     * corresponding record is saved.
     *
     */
    updatedAt: Scalars['ISO8601DateTime']['output'];
  };

export type StudentStandardsCategoryMastery = {
  __typename?: 'StudentStandardsCategoryMastery';
  data: Array<StandardsCategoryMastery>;
  enrollmentId: Scalars['ID']['output'];
};

export type StudentStandardsMastery = {
  __typename?: 'StudentStandardsMastery';
  data: Array<StandardsMastery>;
  enrollmentId: Scalars['String']['output'];
};

export type StudentsAssignmentsDatum = IdentifiableInterface &
  TimestampedInterface & {
    __typename?: 'StudentsAssignmentsDatum';
    completedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
    /**
     * The time at which the record was created. This timestamp is managed by
     * Rails and reflects the current time when the record was first saved.
     *
     */
    createdAt: Scalars['ISO8601DateTime']['output'];
    distributedPointsEarned?: Maybe<Scalars['Float']['output']>;
    enrollment: Enrollment;
    enrollmentId: Scalars['ID']['output'];
    enrollmentsAssignment?: Maybe<EnrollmentsAssignment>;
    groupId: Scalars['ID']['output'];
    groupsAssignment?: Maybe<GroupsAssignment>;
    /** A serializable identifier that uniquely refers to a specific record. Two records with equal `id`s should be regarded as the same record with possibly different fields. */
    id: Scalars['ID']['output'];
    questionsCompleted: Scalars['Int']['output'];
    questionsCorrect: Scalars['Int']['output'];
    questionsTotal: Scalars['Int']['output'];
    student: Student;
    /**
     * The time at which the record was last updated. This timestamp is managed
     * by Rails and is automatically updated to the current time when the
     * corresponding record is saved.
     *
     */
    updatedAt: Scalars['ISO8601DateTime']['output'];
  };

export type StudentsAssignmentsQuestion = IdentifiableInterface &
  TimestampedInterface & {
    __typename?: 'StudentsAssignmentsQuestion';
    completedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
    /**
     * The time at which the record was created. This timestamp is managed by
     * Rails and reflects the current time when the record was first saved.
     *
     */
    createdAt: Scalars['ISO8601DateTime']['output'];
    freeResponseResponses?: Maybe<Array<FreeResponseResponse>>;
    groupsAssignmentId: Scalars['Int']['output'];
    /** A serializable identifier that uniquely refers to a specific record. Two records with equal `id`s should be regarded as the same record with possibly different fields. */
    id: Scalars['ID']['output'];
    multipleChoiceResponses?: Maybe<Array<MultipleChoiceResponse>>;
    order: Scalars['ISO8601DateTime']['output'];
    question: Question;
    shortAnswerResponses?: Maybe<Array<ShortAnswerResponse>>;
    studentId: Scalars['Int']['output'];
    typoRetryCount: Scalars['Int']['output'];
    /**
     * The time at which the record was last updated. This timestamp is managed
     * by Rails and is automatically updated to the current time when the
     * corresponding record is saved.
     *
     */
    updatedAt: Scalars['ISO8601DateTime']['output'];
  };

export type Subject = IdentifiableInterface &
  TimestampedInterface & {
    __typename?: 'Subject';
    /**
     * The time at which the record was created. This timestamp is managed by
     * Rails and reflects the current time when the record was first saved.
     *
     */
    createdAt: Scalars['ISO8601DateTime']['output'];
    /** A serializable identifier that uniquely refers to a specific record. Two records with equal `id`s should be regarded as the same record with possibly different fields. */
    id: Scalars['ID']['output'];
    name: Scalars['String']['output'];
    /**
     * The time at which the record was last updated. This timestamp is managed
     * by Rails and is automatically updated to the current time when the
     * corresponding record is saved.
     *
     */
    updatedAt: Scalars['ISO8601DateTime']['output'];
    visibility: VisibilityEnum;
  };

export type Subscription = {
  __typename?: 'Subscription';
  actionItemsCount: ItemsCountPayload;
  newEnrollment: NewEnrollmentPayload;
  newMessage: NewMessagePayload;
  questionBreakdown: QuestionsBreakdownPayload;
};

export type SubscriptionActionItemsCountArgs = {
  ownerId: Scalars['ID']['input'];
  ownerType: Scalars['String']['input'];
};

export type SubscriptionNewEnrollmentArgs = {
  groupId: Scalars['ID']['input'];
};

export type SubscriptionNewMessageArgs = {
  enrollmentId: Scalars['ID']['input'];
  questionId: Scalars['ID']['input'];
};

export type SubscriptionQuestionBreakdownArgs = {
  assignmentId: Scalars['ID']['input'];
};

export type Supplement = HumanTextRepresentableInterface &
  IdentifiableInterface & {
    __typename?: 'Supplement';
    /** A serializable identifier that uniquely refers to a specific record. Two records with equal `id`s should be regarded as the same record with possibly different fields. */
    id: Scalars['ID']['output'];
    /**
     * A plain text representation of the human-meaningful text. This
     * representation is typically simpler so it's best suited for uses where
     * formatting is either unhelpful or ignore like clipboard management,
     * logging, some class of error message, etc.
     *
     */
    plainText: Scalars['String']['output'];
    question?: Maybe<Question>;
    /**
     * A rich text representation of the human-meaningful text. This
     * currently following the [Quill `Delta` format][0] being an object
     * with a single `ops` property containing an array of delta
     * operations.
     *
     * When possible, favor using rich text to present information as it
     * supports a much broader range of features like LaTeX formula and
     * images.
     *
     * ```ts
     * type Delta = {
     *   ops: Array<
     *     | { insert: 'asdf' }
     *     | { delete: 2 }
     *     | { retain: 1 }
     *   >
     * }
     * ```
     *
     * [0]: https://github.com/quilljs/delta/
     *
     */
    richText: Scalars['JSON']['output'];
  };

/** Attributes for creating a question's supplement */
export type SupplementInput = {
  plainText: Scalars['String']['input'];
  richText: Scalars['JSON']['input'];
};

export type Teacher = IdentifiableInterface &
  PersonableInterface &
  TimestampedInterface & {
    __typename?: 'Teacher';
    acceptedPrivacyAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
    acceptedTosAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
    activeCourse?: Maybe<Course>;
    assignments: Array<Assignment>;
    courses: Array<Course>;
    coursesTeachers: Array<CoursesTeacher>;
    /**
     * The time at which the record was created. This timestamp is managed by
     * Rails and reflects the current time when the record was first saved.
     *
     */
    createdAt: Scalars['ISO8601DateTime']['output'];
    /**
     * An [valid email address][0] for the person
     *
     * [0]: https://html.spec.whatwg.org/multipage/input.html#valid-e-mail-address
     *
     */
    email: Scalars['String']['output'];
    /** The first name of the person. Empty names are coerced into `null` so returning a `string` here implies the name is not empty. */
    firstName?: Maybe<Scalars['String']['output']>;
    /** The full name of the person achieved by combining their first name and last name in that order. If only a first name is available, it is returned. If only a last name is available, it is returned. If no first name or last name are available, `null` is returned. In other words, if this is a string, it is non-empty. */
    fullName?: Maybe<Scalars['String']['output']>;
    /** A serializable identifier that uniquely refers to a specific record. Two records with equal `id`s should be regarded as the same record with possibly different fields. */
    id: Scalars['ID']['output'];
    /** The initials of the person given their first and last name. If both first and last names are missing, `null` is returned. */
    initials?: Maybe<Scalars['String']['output']>;
    isPublisher: Scalars['Boolean']['output'];
    /** The last name of the person. Empty names are coerced into `null` so returning a `string` here implies the name is not empty. */
    lastName?: Maybe<Scalars['String']['output']>;
    school?: Maybe<School>;
    schoolId?: Maybe<Scalars['ID']['output']>;
    /** The sort name is a form of the person's full name ideal for sorting. The sort name combines their last name and first name in that order and separated by a comma. If only the first name or last name is present, it is returned. If no name is present, `nil` is returned. */
    sortName?: Maybe<Scalars['String']['output']>;
    standardsCharts?: Maybe<Array<StandardsChart>>;
    /**
     * The time at which the record was last updated. This timestamp is managed
     * by Rails and is automatically updated to the current time when the
     * corresponding record is saved.
     *
     */
    updatedAt: Scalars['ISO8601DateTime']['output'];
    uuid: Scalars['String']['output'];
  };

export type Team = {
  __typename?: 'Team';
  createdAt: Scalars['ISO8601DateTime']['output'];
  groupCode: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  teachers: Array<Teacher>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/**
 * Fields for a record whose creation time and last update time are tracked
 * with timestamps
 *
 */
export type TimestampedInterface = {
  /**
   * The time at which the record was created. This timestamp is managed by
   * Rails and reflects the current time when the record was first saved.
   *
   */
  createdAt: Scalars['ISO8601DateTime']['output'];
  /**
   * The time at which the record was last updated. This timestamp is managed
   * by Rails and is automatically updated to the current time when the
   * corresponding record is saved.
   *
   */
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** Polymorphic user union type */
export type User = Student | Teacher;

export enum VisibilityEnum {
  /** Custom and should not show up in search */
  Custom = 'custom',
  /** Public visibility that shows up in search */
  Public = 'public',
  /** Recently created and is currently under review */
  Reviewing = 'reviewing',
}
