import * as Types from '../../types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type StudentsAssignmentsDatumQueryVariables = Types.Exact<{
  groupsAssignmentId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  enrollmentsAssignmentId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  studentId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
}>;

export type StudentsAssignmentsDatumQuery = {
  __typename?: 'Query';
  studentsAssignmentsDatum?: {
    __typename?: 'StudentsAssignmentsDatum';
    id: string;
    questionsCompleted: number;
    questionsTotal: number;
    questionsCorrect: number;
    distributedPointsEarned?: number | null;
    completedAt?: any | null;
    student: { __typename?: 'Student'; id: string; fullName?: string | null };
    groupsAssignment?: {
      __typename?: 'GroupsAssignment';
      id: string;
      dueDate?: any | null;
      assignment: {
        __typename?: 'Assignment';
        id: string;
        name: string;
        targetDistributedPoints?: number | null;
        launchAt?: any | null;
        assignmentStatus: Types.AssignmentStatusEnum;
        assignmentType: Types.AssignmentTypeEnum;
      };
      group: { __typename?: 'Group'; id: string; name: string };
    } | null;
    enrollmentsAssignment?: {
      __typename?: 'EnrollmentsAssignment';
      id: string;
      dueDate: any;
      assignment: {
        __typename?: 'Assignment';
        id: string;
        assignmentStatus: Types.AssignmentStatusEnum;
        assignmentType: Types.AssignmentTypeEnum;
        name: string;
        stage?: string | null;
      };
    } | null;
  } | null;
};

export type StudentsAssignmentsDataQueryVariables = Types.Exact<{
  groupId: Types.Scalars['ID']['input'];
}>;

export type StudentsAssignmentsDataQuery = {
  __typename?: 'Query';
  studentsAssignmentsData: Array<{
    __typename?: 'StudentsAssignmentsDatum';
    id: string;
    questionsCompleted: number;
    questionsCorrect: number;
    questionsTotal: number;
    distributedPointsEarned?: number | null;
    completedAt?: any | null;
    student: { __typename?: 'Student'; id: string; fullName?: string | null };
    groupsAssignment?: {
      __typename?: 'GroupsAssignment';
      id: string;
      dueDate?: any | null;
      assignment: {
        __typename?: 'Assignment';
        id: string;
        targetDistributedPoints?: number | null;
        launchAt?: any | null;
        assignmentType: Types.AssignmentTypeEnum;
        assignmentStatus: Types.AssignmentStatusEnum;
        name: string;
        optional: boolean;
      };
      group: { __typename?: 'Group'; id: string; name: string };
    } | null;
    enrollmentsAssignment?: {
      __typename?: 'EnrollmentsAssignment';
      id: string;
      dueDate: any;
      assignment: {
        __typename?: 'Assignment';
        id: string;
        assignmentStatus: Types.AssignmentStatusEnum;
        assignmentType: Types.AssignmentTypeEnum;
        name: string;
        optional: boolean;
        stage?: string | null;
      };
    } | null;
  }>;
};

export const StudentsAssignmentsDatumDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'studentsAssignmentsDatum' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'groupsAssignmentId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'enrollmentsAssignmentId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'studentId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'studentsAssignmentsDatum' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'groupsAssignmentId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'groupsAssignmentId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'enrollmentsAssignmentId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'enrollmentsAssignmentId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'studentId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'studentId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'questionsCompleted' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'questionsTotal' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'questionsCorrect' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'distributedPointsEarned' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'completedAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'student' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'fullName' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'groupsAssignment' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dueDate' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'assignment' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'targetDistributedPoints',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'launchAt' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'assignmentStatus' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'assignmentType' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'group' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'enrollmentsAssignment' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dueDate' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'assignment' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'assignmentStatus' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'assignmentType' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'stage' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  StudentsAssignmentsDatumQuery,
  StudentsAssignmentsDatumQueryVariables
>;
export const StudentsAssignmentsDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'studentsAssignmentsData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'groupId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'studentsAssignmentsData' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'groupId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'groupId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'questionsCompleted' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'questionsCorrect' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'questionsTotal' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'distributedPointsEarned' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'completedAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'student' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'fullName' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'groupsAssignment' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dueDate' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'assignment' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'targetDistributedPoints',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'launchAt' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'assignmentType' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'assignmentStatus' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'optional' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'group' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'enrollmentsAssignment' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dueDate' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'assignment' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'assignmentStatus' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'assignmentType' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'optional' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'stage' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  StudentsAssignmentsDataQuery,
  StudentsAssignmentsDataQueryVariables
>;
