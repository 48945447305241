import * as Types from '../../types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type GroupsAssignmentsQueryVariables = Types.Exact<{
  groupId: Types.Scalars['ID']['input'];
  statuses?: Types.InputMaybe<
    Array<Types.AssignmentStatusEnum> | Types.AssignmentStatusEnum
  >;
}>;

export type GroupsAssignmentsQuery = {
  __typename?: 'Query';
  groupsAssignments: Array<{
    __typename?: 'GroupsAssignment';
    id: string;
    dueDate?: any | null;
    assignment: {
      __typename?: 'Assignment';
      id: string;
      name: string;
      assignmentType: Types.AssignmentTypeEnum;
    };
    group: { __typename?: 'Group'; id: string; name: string };
  }>;
};

export type ActiveGroupsAssignmentsForStudentQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type ActiveGroupsAssignmentsForStudentQuery = {
  __typename?: 'Query';
  activeGroupsAssignmentsForStudent: Array<{
    __typename?: 'GroupsAssignment';
    id: string;
    assignment: {
      __typename?: 'Assignment';
      id: string;
      name: string;
      assignmentType: Types.AssignmentTypeEnum;
    };
    group: { __typename?: 'Group'; id: string; name: string };
  }>;
};

export type DistributedAssignmentResultsQueryVariables = Types.Exact<{
  groupId: Types.Scalars['ID']['input'];
  assignmentId: Types.Scalars['ID']['input'];
  enrollmentId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
}>;

export type DistributedAssignmentResultsQuery = {
  __typename?: 'Query';
  distributedAssignmentResults: any;
};

export const GroupsAssignmentsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'groupsAssignments' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'groupId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'statuses' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'AssignmentStatusEnum' },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'groupsAssignments' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'groupId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'groupId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'statuses' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'statuses' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dueDate' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'assignment' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'assignmentType' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'group' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GroupsAssignmentsQuery,
  GroupsAssignmentsQueryVariables
>;
export const ActiveGroupsAssignmentsForStudentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'activeGroupsAssignmentsForStudent' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activeGroupsAssignmentsForStudent' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'assignment' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'assignmentType' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'group' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ActiveGroupsAssignmentsForStudentQuery,
  ActiveGroupsAssignmentsForStudentQueryVariables
>;
export const DistributedAssignmentResultsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'distributedAssignmentResults' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'groupId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'assignmentId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'enrollmentId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'distributedAssignmentResults' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'groupId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'groupId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'assignmentId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'assignmentId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'enrollmentId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'enrollmentId' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DistributedAssignmentResultsQuery,
  DistributedAssignmentResultsQueryVariables
>;
