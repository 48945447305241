import { useLazyQuery } from '@apollo/client';
import { ArrowDropDown, Close, Language } from '@mui/icons-material';
import {
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Popper,
  Typography,
  type Theme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { DeltaOperation } from 'quill';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../AppContext';
import { TranslateTextDocument } from '../../../gql/queries/__generated__/translate.generated';
import { LS_SELECTED_LANGUAGE } from '../../../utils/localStorageKeys';
import { quillDeltaOperationsToHTMLString } from '../../../utils/quillHelper';
import { LoadingSkeletons } from '../Loaders/LoadingSkeletons';
import { Textbox } from '../Textbox';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'inline-block',
  },
  icon: {},
  closeButton: {
    position: 'relative',
    left: '2px', // add a little extra padding to the left to make it look centered
    cursor: 'pointer',
  },
  paper: {
    padding: theme.spacing(1),
  },
  popper: {
    zIndex: 1300,
    minWidth: 300,
    maxHeight: 300,
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  languageButton: {
    borderRadius: '4px',
    border: '1px solid #053b48',
  },
  topBar: {
    paddingBottom: theme.spacing(1),
  },
}));

type TranslateTextProps = {
  delta: DeltaOperation[];
};

// Codes not currently used, but could be used to save space?
const languages = [
  { code: 'es', label: 'Spanish' },
  { code: 'zh-Hans', label: 'Simplified Chinese' },
  { code: 'zh-Hant', label: 'Traditional Chinese' },
  { code: 'ru', label: 'Russian' },
  { code: 'bn', label: 'Bangla' },
  { code: 'ht', label: 'Haitian Creole' },
  { code: 'ko', label: 'Korean' },
  { code: 'ar', label: 'Arabic' },
  { code: 'it', label: 'Italian' },
  { code: 'ur', label: 'Urdu' },
  { code: 'tl', label: 'Tagalog/Filipino' },
  { code: 'vi', label: 'Vietnamese' },
  { code: 'fr', label: 'French' },
  { code: 'de', label: 'German' },
];

export function TranslateText({ delta }: TranslateTextProps) {
  const { activeEnrollment } = useContext(AppContext).appState;
  const classes = useStyles();
  const [translatedText, setTranslatedText] = useState<null | string>(null);
  const [openPopper, setOpenPopper] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState<null | string>(null);
  const htmlText = quillDeltaOperationsToHTMLString(delta);
  const [maxHeight, setMaxHeight] = useState('80vh');

  const [translateText, { loading, error }] = useLazyQuery(
    TranslateTextDocument,
    {
      onCompleted: (data) => {
        setTranslatedText(data.translateText);
      },
    }
  );

  useEffect(() => {
    if (anchorEl) {
      const rect = anchorEl.getBoundingClientRect();
      const distanceFromTop = rect.top;
      const newMaxHeight =
        Math.min(distanceFromTop, window.innerHeight - distanceFromTop) * 0.8;
      setMaxHeight(`${newMaxHeight}px`);
    }
  }, [anchorEl]);

  useEffect(() => {
    const savedLanguage = localStorage.getItem(LS_SELECTED_LANGUAGE);
    if (savedLanguage) {
      setSelectedLanguage(savedLanguage);
    }
  }, []);

  const handleTranslate = (newLanguage?: string) => {
    // We use the student's active enrollment to authorize the use of
    // GPT-powered translation. If there's no active enrollment, we know the
    // request will fail so we bail early.
    if (!activeEnrollment || !activeEnrollment.group) {
      process.env.NODE_ENV === 'development' &&
        console.warn(
          `Attempted to use AI translation without an active enrollment`
        );
      return;
    }

    const { course } = activeEnrollment.group;
    if (!course.aiTranslationEnabled && process.env.NODE_ENV === 'development')
      console.warn(
        `Using AI translation while the active course has AI translation disabled`
      );

    const languageToUse = newLanguage || selectedLanguage;
    setOpenPopper(true);
    if (!languageToUse) {
      // Do not execute translation
      return;
    }
    translateText({
      variables: {
        enrollmentId: activeEnrollment.id,
        language: languageToUse,
        text: htmlText,
      },
    });
    // Store the selected language to localStorage
    localStorage.setItem(LS_SELECTED_LANGUAGE, languageToUse);
  };

  const handleClose = () => {
    setOpenPopper(false);
  };

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMenuAnchorEl(event.currentTarget);
    setMenuOpen(true);
  };

  const handleMenuClose = () => {
    setMenuOpen(false);
  };

  // When a language is selected from the menu
  const handleLanguageSelect = (label: string) => {
    setSelectedLanguage(label);
    handleTranslate(label);
    handleMenuClose();
  };

  if (activeEnrollment && !activeEnrollment.group?.course.aiTranslationEnabled)
    return null;

  return (
    <div className={classes.root}>
      <IconButton
        onClick={(event: React.MouseEvent<HTMLElement>) => {
          setAnchorEl(event.currentTarget);
          setMenuAnchorEl(event.currentTarget);
          handleTranslate();
        }}
        size="small"
      >
        <Language className={classes.icon} />
      </IconButton>
      <Menu anchorEl={menuAnchorEl} open={menuOpen} onClose={handleMenuClose}>
        {languages.map((language) => (
          <MenuItem
            key={language.code}
            onClick={() => handleLanguageSelect(language.label)}
          >
            {language.label}
          </MenuItem>
        ))}
      </Menu>

      {/* onResize and onResizeCapture necessary because of this https://github.com/mui/material-ui/issues/35287 */}
      <Popper
        open={openPopper}
        anchorEl={anchorEl}
        placement="top-start"
        className={classes.popper}
        // @ts-expect-error https://github.com/mui/material-ui/issues/35287
        onResize={undefined}
        onResizeCapture={undefined}
      >
        <Paper className={classes.paper}>
          <Box position="relative">
            <Box
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
              className={classes.topBar}
            >
              <Button
                onClick={handleMenuOpen}
                className={classes.languageButton}
              >
                <ArrowDropDown />
                {selectedLanguage
                  ? `Language: ${selectedLanguage}`
                  : 'Select Language'}
              </Button>
              <Box flexGrow={1}></Box>
              <Close className={classes.closeButton} onClick={handleClose} />
            </Box>
            {/* Handle Loading State */}
            {loading && (
              <div className={classes.loading}>
                <LoadingSkeletons />
              </div>
            )}
            {/* Handle Error State */}
            {error && (
              <Typography color="error">Error: {error.message}</Typography>
            )}
            <div style={{ maxHeight: maxHeight, overflowY: 'auto' }}>
              {translatedText && <Textbox htmlText={translatedText} />}
            </div>
          </Box>
        </Paper>
      </Popper>
    </div>
  );
}
