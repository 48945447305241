// https://stackoverflow.com/a/12646864

import { produce } from 'immer';

function shuffle<T>(array: Array<T>) {
  const shuffled = produce(array, (arrayDraft) => {
    for (let i = arrayDraft.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [arrayDraft[i], arrayDraft[j]] = [arrayDraft[j], arrayDraft[i]];
    }
  });
  return shuffled;
}

export default shuffle;
