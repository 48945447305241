import { useQuery } from '@apollo/client';
import { Typography, type Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import type { EnrollmentAttributesFragment } from '../../../gql/fragments/__generated__/enrollment.generated';
import { StudentDocument } from '../../../gql/queries/__generated__/student.generated';
import { LoadingSkeletons } from '../../shared/Loaders/LoadingSkeletons';
import { GroupCard } from './GroupCard';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    marginTop: theme.spacing(1),
  },

  header: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  title: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightBold,
  },
}));

const isArchivedCourse = (enrollment: EnrollmentAttributesFragment) =>
  enrollment.group?.course.coursesTeachers.some((ct) => ct.archived);

export function GroupList() {
  const classes = useStyles();
  const { data, loading } = useQuery(StudentDocument, {
    fetchPolicy: 'cache-and-network',
  });
  if (loading) {
    return <LoadingSkeletons num={10} />;
  }

  if (!data) {
    return null;
  }

  const { student } = data;
  const nonArchivedCourses = student.enrollments?.filter(
    (enrollment) => !isArchivedCourse(enrollment)
  );
  const archivedCourses = student.enrollments?.filter((enrollment) =>
    isArchivedCourse(enrollment)
  );
  return (
    <div className={classes.root}>
      <header className={classes.header}>
        <Typography className={classes.title} variant="h6">
          CURRENT CLASSES
        </Typography>
      </header>
      {nonArchivedCourses?.map((enrollment) => (
        <GroupCard enrollment={enrollment} key={enrollment.id} />
      ))}

      {!!archivedCourses?.length && (
        <header className={classes.header}>
          <Typography className={classes.title} variant="h6">
            PAST CLASSES
          </Typography>
        </header>
      )}

      {archivedCourses?.map((enrollment) => (
        <GroupCard enrollment={enrollment} key={enrollment.id} />
      ))}
    </div>
  );
}
