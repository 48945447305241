import { HelpKitButton } from '../HelpKitButton';

export function HelpKitXP() {
  return (
    <HelpKitButton
      label="Understanding XP"
      articleId="j9iKz7DzLkqe7A7vZX2sp3"
    />
  );
}
