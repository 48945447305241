import { useMutation, useQuery } from '@apollo/client';
import { InfoOutlined } from '@mui/icons-material';
import { LinearProgress, Tooltip, Typography, type Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import type { Sources } from 'quill';
import React, { useContext, useEffect, useRef, useState } from 'react';
import type { UnprivilegedEditor, Value } from 'react-quill';
import { CreateFreeResponseResponseDocument } from '../../../../gql/mutations/__generated__/response.generated';
import { FreeResponseResponseDocument } from '../../../../gql/queries/__generated__/response.generated';
import type { Question } from '../../../../gql/types';
import { onError } from '../../../../utils/apollo/apolloHelper';
import { isRichText } from '../../../../utils/question';
import {
  extractPlainAndRichText,
  getPlainTextFromDelta,
} from '../../../../utils/quillHelper';
import { StandardButton } from '../../../shared/Buttons/StandardButton';
import { Editor } from '../../../shared/Editor';
import { QuillDeltaAsHtml } from '../../../shared/QuillDeltaAsHtml';
import { AlertsContext } from '../../Alerts/context';
import { StudySessionContext } from '../../StudySession/context';
import { useExperimentCheck } from './useExperimentCheck';

export const FREE_RESPONSE_CHARACTER_LIMIT = 400;

const useStyles = makeStyles((theme: Theme) => ({
  freeResponseField: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
  form: {
    height: '100%',
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'space-between',
  },
  formFields: {
    padding: theme.spacing(3),
  },
  limit: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  errorText: {
    color: theme.palette.error.main,
    paddingLeft: theme.spacing(1),
  },
  buttonContainer: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  infoIcon: {
    marginLeft: theme.spacing(1),
  },
  richTextContainer: {
    marginTop: theme.spacing(2),
  },
  editorContainer: {
    background: theme.palette.common.white,
    marginTop: theme.spacing(2),
  },
}));

type FreeResponseProps = {
  handleShow?: () => void;
  reviewQuestion?: Question;
};

export function FreeResponse({
  reviewQuestion,
  handleShow,
}: FreeResponseProps) {
  const classes = useStyles();
  const [response, setResponse] = useState<Value>('');
  const { dispatch } = useContext(AlertsContext);
  const formRef = useRef<HTMLFormElement>(null);

  useEffect(() => {
    if (!formRef.current) {
      return;
    }

    // disable the ability to paste into free response fields so students can't
    // copy and paste in answers:
    formRef.current.onpaste = () => false;
  }, []);

  const contextValues = useContext(StudySessionContext);
  const question =
    reviewQuestion !== undefined ? reviewQuestion : contextValues.question;
  const {
    setFreeResponseWithoutSelfAssesmentId,
    setFeedbackVisible,
    groupsAssignmentId,
    enrollmentsAssignmentId,
    personalDecksQuestionId,
    personalDeckStudySessionId,
    feedbackVisible,
    setResponseMeta,
    getNextQuestion,
  } = contextValues;

  const questionId = question?.id || '';
  const [formError, setFormError] = useState(false);
  const [limitExceeded, setLimitExceeded] = useState(false);
  const { isPrePostTest } = useExperimentCheck();
  const { data, loading: responseLoading } = useQuery(
    FreeResponseResponseDocument,
    {
      skip: !question,
      variables: {
        questionId,
        personalDecksQuestionId,
        enrollmentsAssignmentId,
        groupsAssignmentId,
      },
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        if (data.freeResponseResponse?.id && question) {
          setFreeResponseWithoutSelfAssesmentId(data.freeResponseResponse.id);
          setFeedbackVisible(true);
          setResponseMeta({
            id: data.freeResponseResponse.id,
            autograded: data.freeResponseResponse.autograded,
            type: question.questionType,
          });
        }
      },
    }
  );
  const [createFreeResponseResponse, { loading }] = useMutation(
    CreateFreeResponseResponseDocument,
    {
      onError: onError(dispatch, true),
      onCompleted: (data) => {
        if (isPrePostTest) {
          getNextQuestion();
          return;
        }
        if (data.createFreeResponseResponse.id) {
          setFreeResponseWithoutSelfAssesmentId(
            data.createFreeResponseResponse.id
          );
          setFeedbackVisible(true);
          if (question) {
            setResponseMeta({
              id: data.createFreeResponseResponse.id,
              autograded: data.createFreeResponseResponse.autograded,
              type: question.questionType,
            });
          }
        }
      },
      update: (cache, res) => {
        let freeResponseQuery;
        try {
          freeResponseQuery = cache.readQuery({
            query: FreeResponseResponseDocument,
            variables: {
              questionId,
              groupsAssignmentId,
              enrollmentsAssignmentId,
              personalDecksQuestionId,
            },
          });
        } catch (e) {
          console.log(e);
        }

        if (freeResponseQuery && res.data?.createFreeResponseResponse) {
          const { answer, autograded, id, richText } =
            res.data.createFreeResponseResponse;
          cache.writeQuery({
            query: FreeResponseResponseDocument,
            variables: {
              questionId,
              groupsAssignmentId,
              enrollmentsAssignmentId,
              personalDecksQuestionId,
            },
            data: {
              freeResponseResponse: {
                answer,
                autograded,
                id,
                richText,
              },
            },
          });
        }
      },
    }
  );

  useEffect(() => {
    if (data && data.freeResponseResponse) {
      setResponse(data.freeResponseResponse.richText);
    }
  }, [data]);

  const textLength = getPlainTextFromDelta(response).length;

  const handleChange = (
    value: string,
    delta: Value,
    source: Sources,
    editor: UnprivilegedEditor
  ) => {
    setFormError(false);

    const newValue = editor.getContents();
    const plainText = getPlainTextFromDelta(newValue);
    if (plainText.length > FREE_RESPONSE_CHARACTER_LIMIT) {
      setLimitExceeded(true);
      return;
    }
    setLimitExceeded(false);
    setResponse(newValue);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (textLength === 0) {
      setFormError(true);
    } else {
      if (reviewQuestion === undefined) {
        const { text, richText } = extractPlainAndRichText(response);
        createFreeResponseResponse({
          variables: {
            answer: text,
            richText,
            questionId,
            groupsAssignmentId,
            personalDecksQuestionId,
            personalDeckStudySessionId,
            enrollmentsAssignmentId,
          },
        });
      } else {
        handleShow && handleShow();
      }
    }
  };
  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.ctrlKey && event.key === 'Enter') {
      // We shouldn't really be passing these incompatible events around
      // TODO: Remove this type assertion
      handleSubmit(event as unknown as React.FormEvent<HTMLFormElement>);
    }
  };

  const getErrorText = () => {
    if (formError) {
      return "Answer can't be blank.";
    } else if (limitExceeded) {
      return `You cannot have more than ${FREE_RESPONSE_CHARACTER_LIMIT} characters.`;
    } else {
      return '';
    }
  };

  const responseWithoutSelfAssessment = data?.freeResponseResponse;

  const answered = !!responseWithoutSelfAssessment;
  const isLoading = loading || responseLoading;
  return (
    <form className={classes.form} onSubmit={handleSubmit} ref={formRef}>
      <div className={classes.formFields}>
        <Typography variant="h4" color="primary">
          {answered ? 'You answered:' : 'Type your answer below:'}
        </Typography>
        {answered ? (
          <div className={classes.richTextContainer}>
            <QuillDeltaAsHtml
              delta={isRichText(response) ? response.ops : []}
            />
          </div>
        ) : (
          <div className={classes.editorContainer}>
            <Editor
              value={response}
              onChange={handleChange}
              disableImages
              onKeyDown={handleKeyDown}
            />
          </div>
        )}
        {!answered && (
          <div className={classes.limit}>
            <span className={classes.errorText}>{getErrorText()}</span>
            <Typography
              variant="caption"
              style={{
                color:
                  textLength >= FREE_RESPONSE_CHARACTER_LIMIT
                    ? 'red'
                    : 'inherit',
              }}
            >
              {`${textLength}/${FREE_RESPONSE_CHARACTER_LIMIT} characters used`}
            </Typography>
          </div>
        )}
        {!feedbackVisible && (
          <div className={classes.buttonContainer}>
            <StandardButton
              text="CHECK ANSWER"
              type="submit"
              disabled={isLoading}
            />
            <Tooltip title="Submit answer using Ctrl + Enter" arrow>
              <span className={classes.infoIcon}>
                <InfoOutlined fontSize="small" />
              </span>
            </Tooltip>
          </div>
        )}
        {isLoading && <LinearProgress />}
      </div>
    </form>
  );
}
